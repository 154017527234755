import { print } from 'graphql';
import gql from 'graphql-tag';
export var AcceleratorButtonTypes;
(function (AcceleratorButtonTypes) {
  AcceleratorButtonTypes["PRIMARY"] = "PRIMARY";
  AcceleratorButtonTypes["SECONDARY"] = "SECONDARY";
  AcceleratorButtonTypes["TERTIARY"] = "TERTIARY";
})(AcceleratorButtonTypes || (AcceleratorButtonTypes = {}));
export var ActionDetailsType;
(function (ActionDetailsType) {
  ActionDetailsType["AUTHWINDOW"] = "AUTHWINDOW";
  ActionDetailsType["CLOSE"] = "CLOSE";
  ActionDetailsType["COPY"] = "COPY";
  ActionDetailsType["DATEPICKER"] = "DATEPICKER";
  ActionDetailsType["EXTERNAL_LINK"] = "EXTERNAL_LINK";
  ActionDetailsType["FREE_LX"] = "FREE_LX";
  ActionDetailsType["OPEN"] = "OPEN";
  ActionDetailsType["TERMS_AND_CONDITIONS"] = "TERMS_AND_CONDITIONS";
})(ActionDetailsType || (ActionDetailsType = {}));
export var ActionType;
(function (ActionType) {
  ActionType["DownloadDPReport"] = "DownloadDPReport";
  ActionType["JUMPLINK"] = "JUMPLINK";
  ActionType["MODAL"] = "MODAL";
  ActionType["Open"] = "Open";
  ActionType["OpenPriceChangeAlert"] = "OpenPriceChangeAlert";
  ActionType["REDIRECT"] = "REDIRECT";
})(ActionType || (ActionType = {}));
export var ActiveState;
(function (ActiveState) {
  ActiveState["ACTIVE"] = "ACTIVE";
  ActiveState["INACTIVE"] = "INACTIVE";
  ActiveState["UNKNOWN"] = "UNKNOWN";
  ActiveState["USED"] = "USED";
})(ActiveState || (ActiveState = {}));
export var ActivityAvailabilityPriceTextTheme;
(function (ActivityAvailabilityPriceTextTheme) {
  ActivityAvailabilityPriceTextTheme["POSITIVE"] = "POSITIVE";
})(ActivityAvailabilityPriceTextTheme || (ActivityAvailabilityPriceTextTheme = {}));
export var ActivityBadgeType;
(function (ActivityBadgeType) {
  ActivityBadgeType["CAMPAIGN_DEAL"] = "CAMPAIGN_DEAL";
  ActivityBadgeType["DEAL_ADD_ON"] = "DEAL_ADD_ON";
  ActivityBadgeType["DEAL_GENERIC"] = "DEAL_GENERIC";
  ActivityBadgeType["DEAL_MEMBER"] = "DEAL_MEMBER";
  ActivityBadgeType["GLOBAL_LOW_TIER"] = "GLOBAL_LOW_TIER";
})(ActivityBadgeType || (ActivityBadgeType = {}));
export var ActivityBannerType;
(function (ActivityBannerType) {
  ActivityBannerType["ALERT"] = "ALERT";
  ActivityBannerType["INFO"] = "INFO";
  ActivityBannerType["SHOP_WITH_POINTS"] = "SHOP_WITH_POINTS";
})(ActivityBannerType || (ActivityBannerType = {}));
export var ActivityCancellationType;
(function (ActivityCancellationType) {
  ActivityCancellationType["FREE_CANCELLATION"] = "FREE_CANCELLATION";
  ActivityCancellationType["NO_CANCELLATION"] = "NO_CANCELLATION";
})(ActivityCancellationType || (ActivityCancellationType = {}));
export var ActivityCategory;
(function (ActivityCategory) {
  ActivityCategory["ADULTS_ONLY_SHOWS"] = "ADULTS_ONLY_SHOWS";
  ActivityCategory["ADVENTURES"] = "ADVENTURES";
  ActivityCategory["ADVENTURE_OUTDOOR"] = "ADVENTURE_OUTDOOR";
  ActivityCategory["AIRBOAT_TOURS"] = "AIRBOAT_TOURS";
  ActivityCategory["AIRPLANE_RIDES"] = "AIRPLANE_RIDES";
  ActivityCategory["AIRPLANE_TOURS"] = "AIRPLANE_TOURS";
  ActivityCategory["AIRPORT_GROUND_TRANSPORTATION"] = "AIRPORT_GROUND_TRANSPORTATION";
  ActivityCategory["AIRPORT_LOUNGES"] = "AIRPORT_LOUNGES";
  ActivityCategory["AIRPORT_TO_AIRPORT_TRANSFERS"] = "AIRPORT_TO_AIRPORT_TRANSFERS";
  ActivityCategory["AIR_BALLOON_HELICOPTER_TOURS"] = "AIR_BALLOON_HELICOPTER_TOURS";
  ActivityCategory["AIR_HELICOPTER_BALLOON_TOURS"] = "AIR_HELICOPTER_BALLOON_TOURS";
  ActivityCategory["AMUSEMENT_THEME_PARKS"] = "AMUSEMENT_THEME_PARKS";
  ActivityCategory["ARCHITECTURE_TOURS"] = "ARCHITECTURE_TOURS";
  ActivityCategory["ART_CLASSES"] = "ART_CLASSES";
  ActivityCategory["ART_CULTURE_HISTORY_TOURS"] = "ART_CULTURE_HISTORY_TOURS";
  ActivityCategory["ART_MUSIC_LITERARYTOURS"] = "ART_MUSIC_LITERARYTOURS";
  ActivityCategory["ATTRACTIONS"] = "ATTRACTIONS";
  ActivityCategory["ATTRACTION_TICKETS"] = "ATTRACTION_TICKETS";
  ActivityCategory["BALLOON_RIDES"] = "BALLOON_RIDES";
  ActivityCategory["BALLOON_TOURS"] = "BALLOON_TOURS";
  ActivityCategory["BAR_PUB_TOURS"] = "BAR_PUB_TOURS";
  ActivityCategory["BEER_BREWERY_TOURS"] = "BEER_BREWERY_TOURS";
  ActivityCategory["BEYOND_THE_CITY"] = "BEYOND_THE_CITY";
  ActivityCategory["BIKE_MOUNTAIN_BIKE_TOURS"] = "BIKE_MOUNTAIN_BIKE_TOURS";
  ActivityCategory["BIKE_RENTALS"] = "BIKE_RENTALS";
  ActivityCategory["BOAT_RENTAL"] = "BOAT_RENTAL";
  ActivityCategory["BOAT_TOURS"] = "BOAT_TOURS";
  ActivityCategory["BOAT_TOURS_CRUISES"] = "BOAT_TOURS_CRUISES";
  ActivityCategory["BRUNCHC_RUISES"] = "BRUNCHC_RUISES";
  ActivityCategory["BUNGEE_JUMPING_SKYDIVING"] = "BUNGEE_JUMPING_SKYDIVING";
  ActivityCategory["BUS_MINIVAN_TOURS"] = "BUS_MINIVAN_TOURS";
  ActivityCategory["CABARET"] = "CABARET";
  ActivityCategory["CAMPAIGN_DEALS"] = "CAMPAIGN_DEALS";
  ActivityCategory["CASTLE_PALACE_TOURS"] = "CASTLE_PALACE_TOURS";
  ActivityCategory["CHAUFFER_SERVICES"] = "CHAUFFER_SERVICES";
  ActivityCategory["CHRISTMAS"] = "CHRISTMAS";
  ActivityCategory["CIRQUE"] = "CIRQUE";
  ActivityCategory["CIRQUE_DU_SOLEIL"] = "CIRQUE_DU_SOLEIL";
  ActivityCategory["CIRQUE_DU_SOLEILSHOWS"] = "CIRQUE_DU_SOLEILSHOWS";
  ActivityCategory["CITY_AND_ATTRACTION_TRANSFERS"] = "CITY_AND_ATTRACTION_TRANSFERS";
  ActivityCategory["CITY_ATTRACTION_TRANSFERS"] = "CITY_ATTRACTION_TRANSFERS";
  ActivityCategory["CITY_TOURS"] = "CITY_TOURS";
  ActivityCategory["CITY_TOURS_PACKAGES"] = "CITY_TOURS_PACKAGES";
  ActivityCategory["CLASSES_WORKSHOPS"] = "CLASSES_WORKSHOPS";
  ActivityCategory["CLUB_AND_PARTY_BUS_TOURS"] = "CLUB_AND_PARTY_BUS_TOURS";
  ActivityCategory["CLUB_PARTY_BUS_TOURS"] = "CLUB_PARTY_BUS_TOURS";
  ActivityCategory["COFFEE_TEA_TOURS"] = "COFFEE_TEA_TOURS";
  ActivityCategory["COMEDY_SHOWS"] = "COMEDY_SHOWS";
  ActivityCategory["CONCERTS_SPECIAL_EVENTS"] = "CONCERTS_SPECIAL_EVENTS";
  ActivityCategory["COOKING_CLASSES"] = "COOKING_CLASSES";
  ActivityCategory["CRUISES_BOAT_TOURS"] = "CRUISES_BOAT_TOURS";
  ActivityCategory["CRUISES_WATER_TOURS"] = "CRUISES_WATER_TOURS";
  ActivityCategory["CULTURAL_HERITAGE_EXPERIENCES"] = "CULTURAL_HERITAGE_EXPERIENCES";
  ActivityCategory["CUSTOM_PRIVATE_TOURS"] = "CUSTOM_PRIVATE_TOURS";
  ActivityCategory["DANCE_CLASSES"] = "DANCE_CLASSES";
  ActivityCategory["DAY_OF_THE_DEAD"] = "DAY_OF_THE_DEAD";
  ActivityCategory["DAY_SPAS"] = "DAY_SPAS";
  ActivityCategory["DAY_TRIPS"] = "DAY_TRIPS";
  ActivityCategory["DAY_TRIPS_EXCURSIONS"] = "DAY_TRIPS_EXCURSIONS";
  ActivityCategory["DINING_EXPERIENCES"] = "DINING_EXPERIENCES";
  ActivityCategory["DINNER_CRUISES"] = "DINNER_CRUISES";
  ActivityCategory["DINNER_THEATER"] = "DINNER_THEATER";
  ActivityCategory["DISNEY"] = "DISNEY";
  ActivityCategory["DISNEY_PARKS"] = "DISNEY_PARKS";
  ActivityCategory["DOLPHIN_WHALE_WATCHING"] = "DOLPHIN_WHALE_WATCHING";
  ActivityCategory["DOSE_OF_CULTURE"] = "DOSE_OF_CULTURE";
  ActivityCategory["DUCK_TOURS"] = "DUCK_TOURS";
  ActivityCategory["EASTER"] = "EASTER";
  ActivityCategory["FAMILY_FRIENDLY"] = "FAMILY_FRIENDLY";
  ActivityCategory["FASHION_SHOWS_TOURS"] = "FASHION_SHOWS_TOURS";
  ActivityCategory["FISHING_CHARTERS_TOURS"] = "FISHING_CHARTERS_TOURS";
  ActivityCategory["FISHING_TOURS_AND_CHARTERS"] = "FISHING_TOURS_AND_CHARTERS";
  ActivityCategory["FOOD_DRINK"] = "FOOD_DRINK";
  ActivityCategory["FOOD_DRINK_NIGHTLIFE"] = "FOOD_DRINK_NIGHTLIFE";
  ActivityCategory["FOOD_TOURS"] = "FOOD_TOURS";
  ActivityCategory["FUN_FOR_FOODIES"] = "FUN_FOR_FOODIES";
  ActivityCategory["FUN_ON_THE_WATER"] = "FUN_ON_THE_WATER";
  ActivityCategory["GALLERIES_MUSEUMS"] = "GALLERIES_MUSEUMS";
  ActivityCategory["GAMES_SHOWS_SPECIAL_EVENTS"] = "GAMES_SHOWS_SPECIAL_EVENTS";
  ActivityCategory["GETTING_AROUND"] = "GETTING_AROUND";
  ActivityCategory["GOLF_TEE_TIMES"] = "GOLF_TEE_TIMES";
  ActivityCategory["GOLF_TOURS_TEE_TIMES"] = "GOLF_TOURS_TEE_TIMES";
  ActivityCategory["HALLOWEEN"] = "HALLOWEEN";
  ActivityCategory["HAMMAMS_TURKISH_BATHS"] = "HAMMAMS_TURKISH_BATHS";
  ActivityCategory["HELICOPTER_RIDES"] = "HELICOPTER_RIDES";
  ActivityCategory["HELICOPTER_TOURS"] = "HELICOPTER_TOURS";
  ActivityCategory["HIGH_SPEED_DRIVING"] = "HIGH_SPEED_DRIVING";
  ActivityCategory["HIGH_SPEED_RIDES"] = "HIGH_SPEED_RIDES";
  ActivityCategory["HIKING_CAMPING"] = "HIKING_CAMPING";
  ActivityCategory["HISTORICAL_TOURS"] = "HISTORICAL_TOURS";
  ActivityCategory["HISTORY_CULTURE"] = "HISTORY_CULTURE";
  ActivityCategory["HOLIDAY_SEASONAL_TOURS"] = "HOLIDAY_SEASONAL_TOURS";
  ActivityCategory["HONEYMOON_PACKAGE"] = "HONEYMOON_PACKAGE";
  ActivityCategory["HONEYMOON_PACKAGES"] = "HONEYMOON_PACKAGES";
  ActivityCategory["HOP_ON_HOP_OFF"] = "HOP_ON_HOP_OFF";
  ActivityCategory["HOP_ON_HOP_OFF_TOURS"] = "HOP_ON_HOP_OFF_TOURS";
  ActivityCategory["HORSE_CARRIAGE_RIDES"] = "HORSE_CARRIAGE_RIDES";
  ActivityCategory["HOT_SPRINGS_THERMAL_SPAS"] = "HOT_SPRINGS_THERMAL_SPAS";
  ActivityCategory["INTERESTS"] = "INTERESTS";
  ActivityCategory["KAYAKING_CANOEING"] = "KAYAKING_CANOEING";
  ActivityCategory["LATEST_GREATEST"] = "LATEST_GREATEST";
  ActivityCategory["LIFT_TICKETS"] = "LIFT_TICKETS";
  ActivityCategory["LIKELY_TO_SELL_OUT"] = "LIKELY_TO_SELL_OUT";
  ActivityCategory["LITERARY_ART_MUSIC_TOURS"] = "LITERARY_ART_MUSIC_TOURS";
  ActivityCategory["LOCAL_EXPERT_PICKS"] = "LOCAL_EXPERT_PICKS";
  ActivityCategory["LUNCH_CRUISES"] = "LUNCH_CRUISES";
  ActivityCategory["MOTORCYCLE_SCOOTER_TOURS"] = "MOTORCYCLE_SCOOTER_TOURS";
  ActivityCategory["MOVIE_TV_TOURS"] = "MOVIE_TV_TOURS";
  ActivityCategory["MULTIDAY_CRUISES"] = "MULTIDAY_CRUISES";
  ActivityCategory["MULTI_DAY_EXTENDED_TOURS"] = "MULTI_DAY_EXTENDED_TOURS";
  ActivityCategory["MUSEUM_TICKETS"] = "MUSEUM_TICKETS";
  ActivityCategory["MUSEUM_TICKETS_PASSES"] = "MUSEUM_TICKETS_PASSES";
  ActivityCategory["MUST_SEE_SIGHTS"] = "MUST_SEE_SIGHTS";
  ActivityCategory["NA"] = "NA";
  ActivityCategory["NATIONAL_HOLIDAYS"] = "NATIONAL_HOLIDAYS";
  ActivityCategory["NEW"] = "NEW";
  ActivityCategory["NEWY_EARS"] = "NEWY_EARS";
  ActivityCategory["NIGHTLIFE"] = "NIGHTLIFE";
  ActivityCategory["NIGHTLIFE_PASSES"] = "NIGHTLIFE_PASSES";
  ActivityCategory["NIGHT_CRUISES"] = "NIGHT_CRUISES";
  ActivityCategory["NIGHT_TOURS"] = "NIGHT_TOURS";
  ActivityCategory["ONSENS"] = "ONSENS";
  ActivityCategory["OTHER_ANIMALS_ACTIVITIES"] = "OTHER_ANIMALS_ACTIVITIES";
  ActivityCategory["OTHER_ANIMAL_ACTIVITIES"] = "OTHER_ANIMAL_ACTIVITIES";
  ActivityCategory["OTHER_CLASSES"] = "OTHER_CLASSES";
  ActivityCategory["OTHER_OUTDOOR_ACTIVITIES"] = "OTHER_OUTDOOR_ACTIVITIES";
  ActivityCategory["OTHER_SEASONAL_EVENTS"] = "OTHER_SEASONAL_EVENTS";
  ActivityCategory["OTHER_TOURS"] = "OTHER_TOURS";
  ActivityCategory["OTHER_TRANSFERS"] = "OTHER_TRANSFERS";
  ActivityCategory["OTHER_TRANSPORTATION"] = "OTHER_TRANSPORTATION";
  ActivityCategory["OTHER_WATER_ACTIVITIES"] = "OTHER_WATER_ACTIVITIES";
  ActivityCategory["PHOTOGRAPHY_TOURS"] = "PHOTOGRAPHY_TOURS";
  ActivityCategory["PORT_TRANSFERS"] = "PORT_TRANSFERS";
  ActivityCategory["PRIVATE_CUSTOM_TOURS"] = "PRIVATE_CUSTOM_TOURS";
  ActivityCategory["PRIVATE_DAY_TRIPS"] = "PRIVATE_DAY_TRIPS";
  ActivityCategory["PRIVATE_TOURS"] = "PRIVATE_TOURS";
  ActivityCategory["PRIVATE_TRANSFERS"] = "PRIVATE_TRANSFERS";
  ActivityCategory["PURE_ROMANCE"] = "PURE_ROMANCE";
  ActivityCategory["RELIGIOUS_SPIRITUAL_TOURS"] = "RELIGIOUS_SPIRITUAL_TOURS";
  ActivityCategory["RIVER_HARBOUR_CRUISES"] = "RIVER_HARBOUR_CRUISES";
  ActivityCategory["ROMAN_STAYCATION"] = "ROMAN_STAYCATION";
  ActivityCategory["SAFARIS"] = "SAFARIS";
  ActivityCategory["SAILING_TRIPS"] = "SAILING_TRIPS";
  ActivityCategory["SCOOTER_RENTALS"] = "SCOOTER_RENTALS";
  ActivityCategory["SEGWAY_TOURS"] = "SEGWAY_TOURS";
  ActivityCategory["SHARED_TRANSFERS"] = "SHARED_TRANSFERS";
  ActivityCategory["SHARK_DIVING"] = "SHARK_DIVING";
  ActivityCategory["SHOPPING_FASHION"] = "SHOPPING_FASHION";
  ActivityCategory["SHOPPING_PASSES_OFFERS"] = "SHOPPING_PASSES_OFFERS";
  ActivityCategory["SHOPPING_TOURS"] = "SHOPPING_TOURS";
  ActivityCategory["SHORE_EXCURIONS"] = "SHORE_EXCURIONS";
  ActivityCategory["SHOWS_CONCERTS"] = "SHOWS_CONCERTS";
  ActivityCategory["SHOW_SPORT_TICKETS"] = "SHOW_SPORT_TICKETS";
  ActivityCategory["SIGHTSEEING_ATTRACTION_PASSES"] = "SIGHTSEEING_ATTRACTION_PASSES";
  ActivityCategory["SIGHTSEEING_CITY_PASSES"] = "SIGHTSEEING_CITY_PASSES";
  ActivityCategory["SIGHTSEEING_PASSES"] = "SIGHTSEEING_PASSES";
  ActivityCategory["SKIING_RENTALS"] = "SKIING_RENTALS";
  ActivityCategory["SKIP_THE_LINE_TOURS"] = "SKIP_THE_LINE_TOURS";
  ActivityCategory["SNORKELING_SCUBA_DIVING"] = "SNORKELING_SCUBA_DIVING";
  ActivityCategory["SNOWBOARDING_RENTALS"] = "SNOWBOARDING_RENTALS";
  ActivityCategory["SNOWMOBILE_TOURS"] = "SNOWMOBILE_TOURS";
  ActivityCategory["SNOWSHOEING"] = "SNOWSHOEING";
  ActivityCategory["SPA"] = "SPA";
  ActivityCategory["SPA_WELLNESS"] = "SPA_WELLNESS";
  ActivityCategory["SPEED_BOATS_JET_SKIS"] = "SPEED_BOATS_JET_SKIS";
  ActivityCategory["SPORTING_EVENTS_PACKAGES"] = "SPORTING_EVENTS_PACKAGES";
  ActivityCategory["SPORTS_PACKAGES"] = "SPORTS_PACKAGES";
  ActivityCategory["STAY_ACTIVE"] = "STAY_ACTIVE";
  ActivityCategory["SUBMARINE_TOURS"] = "SUBMARINE_TOURS";
  ActivityCategory["SUNSET_CRUISES"] = "SUNSET_CRUISES";
  ActivityCategory["SUPERNATURAL_TOURS"] = "SUPERNATURAL_TOURS";
  ActivityCategory["SUSTAINABLE_TOURS"] = "SUSTAINABLE_TOURS";
  ActivityCategory["SWIM_WITH_DOLPHINS"] = "SWIM_WITH_DOLPHINS";
  ActivityCategory["THEATER_SHOWS_MUSICALS"] = "THEATER_SHOWS_MUSICALS";
  ActivityCategory["THEME_PARKS"] = "THEME_PARKS";
  ActivityCategory["THEME_PARK_TICKETS_TOURS"] = "THEME_PARK_TICKETS_TOURS";
  ActivityCategory["THE_GREAT_OUTDOORS"] = "THE_GREAT_OUTDOORS";
  ActivityCategory["THRILL_SEEKER"] = "THRILL_SEEKER";
  ActivityCategory["TICKETS"] = "TICKETS";
  ActivityCategory["TOURS_ACTIVITIES"] = "TOURS_ACTIVITIES";
  ActivityCategory["TOURS_SIGHTSEEING"] = "TOURS_SIGHTSEEING";
  ActivityCategory["TRANSPORTATION_SERVICES"] = "TRANSPORTATION_SERVICES";
  ActivityCategory["TREAT_YOURSELF"] = "TREAT_YOURSELF";
  ActivityCategory["TUBING"] = "TUBING";
  ActivityCategory["UNIVERSAL_THEME_PARKS"] = "UNIVERSAL_THEME_PARKS";
  ActivityCategory["VALENTINES_DAY"] = "VALENTINES_DAY";
  ActivityCategory["WALKING_BIKE_TOURS"] = "WALKING_BIKE_TOURS";
  ActivityCategory["WALKING_TOURS"] = "WALKING_TOURS";
  ActivityCategory["WATERSKIING_WAKEBOARDING"] = "WATERSKIING_WAKEBOARDING";
  ActivityCategory["WATER_ACTIVITIES"] = "WATER_ACTIVITIES";
  ActivityCategory["WATER_PARKS"] = "WATER_PARKS";
  ActivityCategory["WEDDING_CEREMONIES"] = "WEDDING_CEREMONIES";
  ActivityCategory["WEDDING_HONEYMOON"] = "WEDDING_HONEYMOON";
  ActivityCategory["WEDDING_PACKAGES"] = "WEDDING_PACKAGES";
  ActivityCategory["WEDDING_SHONEYMOONS"] = "WEDDING_SHONEYMOONS";
  ActivityCategory["WHAT_TO_DO_WITH_GROUPS"] = "WHAT_TO_DO_WITH_GROUPS";
  ActivityCategory["WHAT_TO_DO_WITH_KIDS"] = "WHAT_TO_DO_WITH_KIDS";
  ActivityCategory["WHITE_WATER_RAFTING"] = "WHITE_WATER_RAFTING";
  ActivityCategory["WILDLIFE_NATURE"] = "WILDLIFE_NATURE";
  ActivityCategory["WINE_TASTING_WINERY_TOURS"] = "WINE_TASTING_WINERY_TOURS";
  ActivityCategory["WINTER_ACTIVITIES"] = "WINTER_ACTIVITIES";
  ActivityCategory["YOGA_CLASS"] = "YOGA_CLASS";
  ActivityCategory["YOGA_CLASSES"] = "YOGA_CLASSES";
  ActivityCategory["ZOO_TICKETS"] = "ZOO_TICKETS";
  ActivityCategory["ZOO_TICKETS_PASSES"] = "ZOO_TICKETS_PASSES";
})(ActivityCategory || (ActivityCategory = {}));
export var ActivityCategoryType;
(function (ActivityCategoryType) {
  ActivityCategoryType["ACTIVITIES"] = "ACTIVITIES";
  ActivityCategoryType["CATEGORIES"] = "CATEGORIES";
  ActivityCategoryType["DEALS_PROMOTIONS"] = "DEALS_PROMOTIONS";
  ActivityCategoryType["NA"] = "NA";
  ActivityCategoryType["RECOMMENDATIONS"] = "RECOMMENDATIONS";
  ActivityCategoryType["TOURS"] = "TOURS";
  ActivityCategoryType["TRANSPORTATION"] = "TRANSPORTATION";
})(ActivityCategoryType || (ActivityCategoryType = {}));
export var ActivityDetailViewOptions;
(function (ActivityDetailViewOptions) {
  ActivityDetailViewOptions["NON_CONSOLIDATED_OFFERS"] = "NON_CONSOLIDATED_OFFERS";
  ActivityDetailViewOptions["SHOP_WITH_POINTS"] = "SHOP_WITH_POINTS";
  ActivityDetailViewOptions["THIRD_PARTY_REVIEWS"] = "THIRD_PARTY_REVIEWS";
})(ActivityDetailViewOptions || (ActivityDetailViewOptions = {}));
export var ActivityDiscountType;
(function (ActivityDiscountType) {
  ActivityDiscountType["AIR_ATTACH_MIP"] = "AIR_ATTACH_MIP";
  ActivityDiscountType["AIR_HOTEL_ATTACH_MIP"] = "AIR_HOTEL_ATTACH_MIP";
  ActivityDiscountType["CAMPAIGN_DEAL"] = "CAMPAIGN_DEAL";
  ActivityDiscountType["FREE_ACTIVITY"] = "FREE_ACTIVITY";
  ActivityDiscountType["GUEST"] = "GUEST";
  ActivityDiscountType["HOTEL_ATTACH_MIP"] = "HOTEL_ATTACH_MIP";
  ActivityDiscountType["MIP"] = "MIP";
  ActivityDiscountType["MOD"] = "MOD";
  ActivityDiscountType["STRIKE_OUT"] = "STRIKE_OUT";
})(ActivityDiscountType || (ActivityDiscountType = {}));
export var ActivityGroupType;
(function (ActivityGroupType) {
  ActivityGroupType["ACTIVITY_LIST"] = "ACTIVITY_LIST";
  ActivityGroupType["ACTIVITY_MESSAGE"] = "ACTIVITY_MESSAGE";
  ActivityGroupType["AVAILABLE_TODAY"] = "AVAILABLE_TODAY";
  ActivityGroupType["CATEGORY"] = "CATEGORY";
  ActivityGroupType["FILTERED_ACTIVITIES"] = "FILTERED_ACTIVITIES";
  ActivityGroupType["INTERESTS"] = "INTERESTS";
  ActivityGroupType["KEYWORD_SEARCH"] = "KEYWORD_SEARCH";
  ActivityGroupType["MORE_ACTIVITIES"] = "MORE_ACTIVITIES";
  ActivityGroupType["NEIGHBOURHOOD"] = "NEIGHBOURHOOD";
  ActivityGroupType["PINNED_ACTIVITY"] = "PINNED_ACTIVITY";
  ActivityGroupType["POI"] = "POI";
  ActivityGroupType["RECOMMENDATIONS"] = "RECOMMENDATIONS";
  ActivityGroupType["SLIM_CATEGORY"] = "SLIM_CATEGORY";
  ActivityGroupType["SUB_CATEGORIES"] = "SUB_CATEGORIES";
  ActivityGroupType["SUB_CATEGORY"] = "SUB_CATEGORY";
  ActivityGroupType["SWP_SORT_SECTION"] = "SWP_SORT_SECTION";
  ActivityGroupType["TOP_ACTIVITIES"] = "TOP_ACTIVITIES";
  ActivityGroupType["TRIPS_ACTIVITY_LIST"] = "TRIPS_ACTIVITY_LIST";
  ActivityGroupType["XSELL_RECOMMENDATIONS"] = "XSELL_RECOMMENDATIONS";
})(ActivityGroupType || (ActivityGroupType = {}));
export var ActivityGroupViewType;
(function (ActivityGroupViewType) {
  ActivityGroupViewType["ACTIVITY_LIST"] = "ACTIVITY_LIST";
  ActivityGroupViewType["ACTIVITY_SPANNED_TILE_LIST"] = "ACTIVITY_SPANNED_TILE_LIST";
  ActivityGroupViewType["ACTIVITY_TILES_CAROUSEL"] = "ACTIVITY_TILES_CAROUSEL";
  ActivityGroupViewType["CATEGORY_TILES_CAROUSEL"] = "CATEGORY_TILES_CAROUSEL";
  ActivityGroupViewType["KEYWORD_SEARCH"] = "KEYWORD_SEARCH";
  ActivityGroupViewType["MESSAGE"] = "MESSAGE";
  ActivityGroupViewType["SLIM_CATEGORY_TILES_CAROUSEL"] = "SLIM_CATEGORY_TILES_CAROUSEL";
  ActivityGroupViewType["TEXT"] = "TEXT";
})(ActivityGroupViewType || (ActivityGroupViewType = {}));
export var ActivityMapGroupType;
(function (ActivityMapGroupType) {
  ActivityMapGroupType["POI"] = "POI";
})(ActivityMapGroupType || (ActivityMapGroupType = {}));
export var ActivityMessageType;
(function (ActivityMessageType) {
  ActivityMessageType["ADDON"] = "ADDON";
  ActivityMessageType["EARN_2X_REWARD_POINTS"] = "EARN_2X_REWARD_POINTS";
  ActivityMessageType["GIFTING_LX_BANNER"] = "GIFTING_LX_BANNER";
  ActivityMessageType["LOGIN"] = "LOGIN";
  ActivityMessageType["SOURCED_CAMPAIGN"] = "SOURCED_CAMPAIGN";
  ActivityMessageType["SUMMER_CAMPAIGN_PRIMARY"] = "SUMMER_CAMPAIGN_PRIMARY";
  ActivityMessageType["SUMMER_CAMPAIGN_SECONDARY"] = "SUMMER_CAMPAIGN_SECONDARY";
})(ActivityMessageType || (ActivityMessageType = {}));
export var ActivityOfferUDSToken;
(function (ActivityOfferUDSToken) {
  ActivityOfferUDSToken["SELECTED"] = "SELECTED";
  ActivityOfferUDSToken["UNSELECTED"] = "UNSELECTED";
})(ActivityOfferUDSToken || (ActivityOfferUDSToken = {}));
export var ActivityPageMetaTagType;
(function (ActivityPageMetaTagType) {
  ActivityPageMetaTagType["HTTP_EQUIV"] = "HTTP_EQUIV";
  ActivityPageMetaTagType["NAME"] = "NAME";
  ActivityPageMetaTagType["PROPERTY"] = "PROPERTY";
})(ActivityPageMetaTagType || (ActivityPageMetaTagType = {}));
export var ActivityPageType;
(function (ActivityPageType) {
  ActivityPageType["ACTIVITY_INFO"] = "ACTIVITY_INFO";
})(ActivityPageType || (ActivityPageType = {}));
export var ActivityPriceType;
(function (ActivityPriceType) {
  ActivityPriceType["GROUP"] = "GROUP";
  ActivityPriceType["NORMAL"] = "NORMAL";
  ActivityPriceType["VOLUME_BASED"] = "VOLUME_BASED";
})(ActivityPriceType || (ActivityPriceType = {}));
export var ActivityRedemptionType;
(function (ActivityRedemptionType) {
  ActivityRedemptionType["PRINT"] = "PRINT";
  ActivityRedemptionType["VOUCHER_LESS"] = "VOUCHER_LESS";
})(ActivityRedemptionType || (ActivityRedemptionType = {}));
export var ActivityReviewSort;
(function (ActivityReviewSort) {
  ActivityReviewSort["HIGHEST_TO_LOWEST_RATED"] = "HIGHEST_TO_LOWEST_RATED";
  ActivityReviewSort["LOWEST_TO_HIGHEST_RATED"] = "LOWEST_TO_HIGHEST_RATED";
  ActivityReviewSort["NEWEST_TO_OLDEST"] = "NEWEST_TO_OLDEST";
})(ActivityReviewSort || (ActivityReviewSort = {}));
export var ActivitySearchOptions;
(function (ActivitySearchOptions) {
  /** @deprecated using ActivityGroupType now for filtering Activity Groups */
  ActivitySearchOptions["CATEGORY"] = "CATEGORY";
  ActivitySearchOptions["FILTER_NESTED_CATEGORIES"] = "FILTER_NESTED_CATEGORIES";
  ActivitySearchOptions["GAIA_RETURN_ADDITIONAL_DISTANCE_PARAMETER"] = "GAIA_RETURN_ADDITIONAL_DISTANCE_PARAMETER";
  ActivitySearchOptions["GIFTING_ACTIVITIES"] = "GIFTING_ACTIVITIES";
  /** @deprecated Guest Promotion is always true */
  ActivitySearchOptions["GUEST_PROMO"] = "GUEST_PROMO";
  ActivitySearchOptions["NON_CONSOLIDATED_OFFERS"] = "NON_CONSOLIDATED_OFFERS";
  ActivitySearchOptions["SHOP_WITH_POINTS"] = "SHOP_WITH_POINTS";
  ActivitySearchOptions["SHOW_GT_BANNER"] = "SHOW_GT_BANNER";
})(ActivitySearchOptions || (ActivitySearchOptions = {}));
export var ActivitySearchViewOptions;
(function (ActivitySearchViewOptions) {
  ActivitySearchViewOptions["DISTANCE_FROM_HOTEL_TO_ACTIVITY"] = "DISTANCE_FROM_HOTEL_TO_ACTIVITY";
  ActivitySearchViewOptions["FILTER_NESTED_CATEGORIES"] = "FILTER_NESTED_CATEGORIES";
  ActivitySearchViewOptions["NEARBY_ACTIVITIES"] = "NEARBY_ACTIVITIES";
  ActivitySearchViewOptions["SHOP_WITH_POINTS"] = "SHOP_WITH_POINTS";
  ActivitySearchViewOptions["SHOW_GT_BANNER"] = "SHOW_GT_BANNER";
  ActivitySearchViewOptions["THIRD_PARTY_REVIEWS"] = "THIRD_PARTY_REVIEWS";
  ActivitySearchViewOptions["TRAVEL_DISTANCE_FROM_HOTEL"] = "TRAVEL_DISTANCE_FROM_HOTEL";
})(ActivitySearchViewOptions || (ActivitySearchViewOptions = {}));
export var ActivitySort;
(function (ActivitySort) {
  ActivitySort["CAMPAIGN_DEALS"] = "CAMPAIGN_DEALS";
  ActivitySort["DISCOUNT"] = "DISCOUNT";
  ActivitySort["DISTANCE_FROM_HOTEL"] = "DISTANCE_FROM_HOTEL";
  ActivitySort["PRICE_HIGH_TO_LOW"] = "PRICE_HIGH_TO_LOW";
  ActivitySort["PRICE_LOW_TO_HIGH"] = "PRICE_LOW_TO_HIGH";
  ActivitySort["RECOMMENDED"] = "RECOMMENDED";
})(ActivitySort || (ActivitySort = {}));
export var ActivityTextAlignment;
(function (ActivityTextAlignment) {
  ActivityTextAlignment["CENTRE"] = "CENTRE";
  ActivityTextAlignment["LEFT"] = "LEFT";
  ActivityTextAlignment["RIGHT"] = "RIGHT";
})(ActivityTextAlignment || (ActivityTextAlignment = {}));
export var ActivityTextSize;
(function (ActivityTextSize) {
  ActivityTextSize["LARGE"] = "LARGE";
  ActivityTextSize["MEDIUM"] = "MEDIUM";
  ActivityTextSize["SMALL"] = "SMALL";
})(ActivityTextSize || (ActivityTextSize = {}));
export var ActivityUDSToken;
(function (ActivityUDSToken) {
  ActivityUDSToken["MULTI_SELECT_LIST"] = "MULTI_SELECT_LIST";
  ActivityUDSToken["MULTI_SELECT_RANGE"] = "MULTI_SELECT_RANGE";
  ActivityUDSToken["SINGLE_SELECT"] = "SINGLE_SELECT";
  ActivityUDSToken["TEXT"] = "TEXT";
})(ActivityUDSToken || (ActivityUDSToken = {}));
export var AdType;
(function (AdType) {
  AdType["FLIGHT_SPONSORED_LISTING"] = "FLIGHT_SPONSORED_LISTING";
  AdType["LODGING_BRAND_RESULT_LISTING"] = "LODGING_BRAND_RESULT_LISTING";
  AdType["LODGING_SPONSORED_LISTING"] = "LODGING_SPONSORED_LISTING";
  AdType["UNKNOWN"] = "UNKNOWN";
})(AdType || (AdType = {}));
export var AddOnRequested;
(function (AddOnRequested) {
  AddOnRequested["CB1"] = "CB1";
  AddOnRequested["COB"] = "COB";
  AddOnRequested["SC"] = "SC";
})(AddOnRequested || (AddOnRequested = {}));
export var AdditionalProductsOperationType;
(function (AdditionalProductsOperationType) {
  AdditionalProductsOperationType["ADD"] = "ADD";
  AdditionalProductsOperationType["REMOVE"] = "REMOVE";
})(AdditionalProductsOperationType || (AdditionalProductsOperationType = {}));
export var AdditionalShoppedAncillaries;
(function (AdditionalShoppedAncillaries) {
  AdditionalShoppedAncillaries["FIRST_CHECKED_BAG"] = "FIRST_CHECKED_BAG";
})(AdditionalShoppedAncillaries || (AdditionalShoppedAncillaries = {}));
export var AdditionalShopping;
(function (AdditionalShopping) {
  AdditionalShopping["FIRST_CHECKED_BAG"] = "FIRST_CHECKED_BAG";
})(AdditionalShopping || (AdditionalShopping = {}));
export var AdjustmentType;
(function (AdjustmentType) {
  AdjustmentType["Nightly"] = "Nightly";
  AdjustmentType["PerStay"] = "PerStay";
})(AdjustmentType || (AdjustmentType = {}));
export var AdvertiserReportingContext;
(function (AdvertiserReportingContext) {
  AdvertiserReportingContext["FLIGHTS"] = "FLIGHTS";
})(AdvertiserReportingContext || (AdvertiserReportingContext = {}));
export var AdvertiserReportingGroupBy;
(function (AdvertiserReportingGroupBy) {
  AdvertiserReportingGroupBy["AD"] = "AD";
  AdvertiserReportingGroupBy["AD_GROUP"] = "AD_GROUP";
  AdvertiserReportingGroupBy["CAMPAIGN"] = "CAMPAIGN";
  AdvertiserReportingGroupBy["COUNTRY_CODE"] = "COUNTRY_CODE";
  AdvertiserReportingGroupBy["PERIOD"] = "PERIOD";
})(AdvertiserReportingGroupBy || (AdvertiserReportingGroupBy = {}));
export var AdvertiserReportingLevel;
(function (AdvertiserReportingLevel) {
  AdvertiserReportingLevel["AD_GROUP"] = "AD_GROUP";
  AdvertiserReportingLevel["CAMPAIGN"] = "CAMPAIGN";
  AdvertiserReportingLevel["CAMPAIGN_AND_AD_GROUP"] = "CAMPAIGN_AND_AD_GROUP";
})(AdvertiserReportingLevel || (AdvertiserReportingLevel = {}));
export var AffiliatesChannelType;
(function (AffiliatesChannelType) {
  AffiliatesChannelType["ANDROID"] = "ANDROID";
  AffiliatesChannelType["IOS"] = "IOS";
  AffiliatesChannelType["WEB"] = "WEB";
})(AffiliatesChannelType || (AffiliatesChannelType = {}));
export var AffiliatesCheckFailureType;
(function (AffiliatesCheckFailureType) {
  AffiliatesCheckFailureType["PARTNER_NOT_LINKED"] = "PARTNER_NOT_LINKED";
  AffiliatesCheckFailureType["STATUS_NOT_APPROVED"] = "STATUS_NOT_APPROVED";
  AffiliatesCheckFailureType["TOOL_DISABLED"] = "TOOL_DISABLED";
})(AffiliatesCheckFailureType || (AffiliatesCheckFailureType = {}));
export var AgencyBusinessModel;
(function (AgencyBusinessModel) {
  AgencyBusinessModel["EXPEDIA_COLLECT"] = "EXPEDIA_COLLECT";
  AgencyBusinessModel["HOTEL_COLLECT"] = "HOTEL_COLLECT";
})(AgencyBusinessModel || (AgencyBusinessModel = {}));
export var AgencyQuoteListSortType;
(function (AgencyQuoteListSortType) {
  AgencyQuoteListSortType["ASCENDING"] = "ASCENDING";
  AgencyQuoteListSortType["DESCENDING"] = "DESCENDING";
  AgencyQuoteListSortType["NOT_SORTED"] = "NOT_SORTED";
})(AgencyQuoteListSortType || (AgencyQuoteListSortType = {}));
export var AgencyServiceChargeMessageLineType;
(function (AgencyServiceChargeMessageLineType) {
  AgencyServiceChargeMessageLineType["ERROR"] = "ERROR";
  AgencyServiceChargeMessageLineType["WARNING"] = "WARNING";
})(AgencyServiceChargeMessageLineType || (AgencyServiceChargeMessageLineType = {}));
export var AgentStateType;
(function (AgentStateType) {
  AgentStateType["NotReady"] = "NotReady";
  AgentStateType["Offline"] = "Offline";
  AgentStateType["Ready"] = "Ready";
})(AgentStateType || (AgentStateType = {}));
export var AgentToolsChannelType;
(function (AgentToolsChannelType) {
  AgentToolsChannelType["CHAT"] = "CHAT";
  AgentToolsChannelType["EMAIL"] = "EMAIL";
  AgentToolsChannelType["SMS"] = "SMS";
  AgentToolsChannelType["VOICE"] = "VOICE";
})(AgentToolsChannelType || (AgentToolsChannelType = {}));
export var AgentToolsLodgingActionType;
(function (AgentToolsLodgingActionType) {
  AgentToolsLodgingActionType["Cancel"] = "Cancel";
  AgentToolsLodgingActionType["ComboChange"] = "ComboChange";
  AgentToolsLodgingActionType["OccupancyChange"] = "OccupancyChange";
  AgentToolsLodgingActionType["RoomTypeChange"] = "RoomTypeChange";
})(AgentToolsLodgingActionType || (AgentToolsLodgingActionType = {}));
export var AgentToolsPaymentFlow;
(function (AgentToolsPaymentFlow) {
  AgentToolsPaymentFlow["PAYMENT_ASSIST"] = "PAYMENT_ASSIST";
  AgentToolsPaymentFlow["PAYMENT_VERIFICATION"] = "PAYMENT_VERIFICATION";
})(AgentToolsPaymentFlow || (AgentToolsPaymentFlow = {}));
export var AgentToolsSummaryCardComponentType;
(function (AgentToolsSummaryCardComponentType) {
  AgentToolsSummaryCardComponentType["FLIGHT_CANCEL"] = "FLIGHT_CANCEL";
  AgentToolsSummaryCardComponentType["LODGING_CANCEL"] = "LODGING_CANCEL";
  AgentToolsSummaryCardComponentType["LODGING_CHANGE"] = "LODGING_CHANGE";
})(AgentToolsSummaryCardComponentType || (AgentToolsSummaryCardComponentType = {}));
export var AirportLocationType;
(function (AirportLocationType) {
  AirportLocationType["AIRPORT"] = "AIRPORT";
  AirportLocationType["METROCODE"] = "METROCODE";
  AirportLocationType["UNSPECIFIED"] = "UNSPECIFIED";
})(AirportLocationType || (AirportLocationType = {}));
export var Alignment;
(function (Alignment) {
  Alignment["CENTER"] = "CENTER";
  Alignment["LEFT"] = "LEFT";
  Alignment["RIGHT"] = "RIGHT";
})(Alignment || (Alignment = {}));
export var AlterMode;
(function (AlterMode) {
  AlterMode["DEBUG"] = "DEBUG";
  AlterMode["NONE"] = "NONE";
  AlterMode["PREVIEW"] = "PREVIEW";
  AlterMode["RELEASED"] = "RELEASED";
})(AlterMode || (AlterMode = {}));
export var AlternativeLinkType;
(function (AlternativeLinkType) {
  AlternativeLinkType["ALTERNATIVE_ACCOMMODATION"] = "ALTERNATIVE_ACCOMMODATION";
  AlternativeLinkType["ALTERNATIVE_LOBS"] = "ALTERNATIVE_LOBS";
})(AlternativeLinkType || (AlternativeLinkType = {}));
export var AmenityCategory;
(function (AmenityCategory) {
  AmenityCategory["ACCESSIBLE_ROLL_IN_SHOWER"] = "ACCESSIBLE_ROLL_IN_SHOWER";
  AmenityCategory["AIR_CONDITIONING"] = "AIR_CONDITIONING";
  AmenityCategory["ALL_INCLUSIVE"] = "ALL_INCLUSIVE";
  AmenityCategory["ARCADE"] = "ARCADE";
  AmenityCategory["BABYSITTING"] = "BABYSITTING";
  AmenityCategory["BAR"] = "BAR";
  AmenityCategory["BARBECUE"] = "BARBECUE";
  AmenityCategory["BOWLING"] = "BOWLING";
  AmenityCategory["BREAKFAST_AVAILABLE"] = "BREAKFAST_AVAILABLE";
  AmenityCategory["BREAKFAST_INCLUDED"] = "BREAKFAST_INCLUDED";
  AmenityCategory["BREAKFAST_NOT_AVAILABLE"] = "BREAKFAST_NOT_AVAILABLE";
  AmenityCategory["BUSINESS_SERVICES"] = "BUSINESS_SERVICES";
  AmenityCategory["CHILDREN_CLUB"] = "CHILDREN_CLUB";
  AmenityCategory["DAILY_HOUSEKEEPING"] = "DAILY_HOUSEKEEPING";
  AmenityCategory["EXTRA_BED"] = "EXTRA_BED";
  AmenityCategory["FIRE_PLACE"] = "FIRE_PLACE";
  AmenityCategory["FITNESS_EQUIPMENT"] = "FITNESS_EQUIPMENT";
  AmenityCategory["FREE_AIRPORT_TRANSPORTATION"] = "FREE_AIRPORT_TRANSPORTATION";
  AmenityCategory["FREE_BREAKFAST"] = "FREE_BREAKFAST";
  AmenityCategory["FREE_CANCEL"] = "FREE_CANCEL";
  AmenityCategory["FREE_PARKING"] = "FREE_PARKING";
  AmenityCategory["FREE_WELCOME_DRINK"] = "FREE_WELCOME_DRINK";
  AmenityCategory["FREE_WIRED_INTERNET"] = "FREE_WIRED_INTERNET";
  AmenityCategory["FRONT_DESK_24_HOURS"] = "FRONT_DESK_24_HOURS";
  AmenityCategory["GROCERY"] = "GROCERY";
  AmenityCategory["HIGH_SPEED_INTERNET"] = "HIGH_SPEED_INTERNET";
  AmenityCategory["HOT_TUB"] = "HOT_TUB";
  AmenityCategory["INTERNET_NOT_AVAILABLE"] = "INTERNET_NOT_AVAILABLE";
  AmenityCategory["IN_ROOM_INTERNET"] = "IN_ROOM_INTERNET";
  AmenityCategory["KIDS_POOL"] = "KIDS_POOL";
  AmenityCategory["KITCHEN"] = "KITCHEN";
  AmenityCategory["LAUNDRY"] = "LAUNDRY";
  AmenityCategory["LIVING_AREA"] = "LIVING_AREA";
  AmenityCategory["MICROWAVE"] = "MICROWAVE";
  AmenityCategory["MINI_GOLF"] = "MINI_GOLF";
  AmenityCategory["NON_REFUNDABLE"] = "NON_REFUNDABLE";
  AmenityCategory["ON_PRIVATE_BEACH"] = "ON_PRIVATE_BEACH";
  AmenityCategory["ON_THE_BEACH"] = "ON_THE_BEACH";
  AmenityCategory["OUTDOOR_SPACE"] = "OUTDOOR_SPACE";
  AmenityCategory["PARKING"] = "PARKING";
  AmenityCategory["PARTIAL_REFUND"] = "PARTIAL_REFUND";
  AmenityCategory["PETS_ALLOWED"] = "PETS_ALLOWED";
  AmenityCategory["PLAYGROUND"] = "PLAYGROUND";
  AmenityCategory["POOL"] = "POOL";
  AmenityCategory["RESERVE_NOW_PAY_DEPOSIT"] = "RESERVE_NOW_PAY_DEPOSIT";
  AmenityCategory["RESERVE_NOW_PAY_LATER"] = "RESERVE_NOW_PAY_LATER";
  AmenityCategory["RESTAURANT_IN_HOTEL"] = "RESTAURANT_IN_HOTEL";
  AmenityCategory["ROOM_SERVICE"] = "ROOM_SERVICE";
  AmenityCategory["SMOKE_FREE"] = "SMOKE_FREE";
  AmenityCategory["SOUNDPROOF_ROOM"] = "SOUNDPROOF_ROOM";
  AmenityCategory["SPA_SERVICES_ON_SITE"] = "SPA_SERVICES_ON_SITE";
  AmenityCategory["SPECIAL_DEAL"] = "SPECIAL_DEAL";
  AmenityCategory["TENNIS_COURT"] = "TENNIS_COURT";
  AmenityCategory["TOYS"] = "TOYS";
  AmenityCategory["WASHER"] = "WASHER";
  AmenityCategory["WATERSLIDE"] = "WATERSLIDE";
  AmenityCategory["WIFI_SURCHARGE"] = "WIFI_SURCHARGE";
  AmenityCategory["WIRED_INTERNET_SURCHARGE"] = "WIRED_INTERNET_SURCHARGE";
})(AmenityCategory || (AmenityCategory = {}));
export var AmenityFilter;
(function (AmenityFilter) {
  AmenityFilter["CARRY_ON_BAG"] = "CARRY_ON_BAG";
  AmenityFilter["INVALID"] = "INVALID";
  AmenityFilter["NO_CANCEL_FEE"] = "NO_CANCEL_FEE";
  AmenityFilter["NO_CHANGE_FEE"] = "NO_CHANGE_FEE";
  AmenityFilter["SEAT_CHOICE"] = "SEAT_CHOICE";
})(AmenityFilter || (AmenityFilter = {}));
export var AnalyticsKey;
(function (AnalyticsKey) {
  AnalyticsKey["NUMBER_OF_BAGS"] = "NUMBER_OF_BAGS";
  AnalyticsKey["PIECE_OR_WEIGHT_CONCEPT"] = "PIECE_OR_WEIGHT_CONCEPT";
  AnalyticsKey["QUANTITY_OF_BAGS"] = "QUANTITY_OF_BAGS";
})(AnalyticsKey || (AnalyticsKey = {}));
export var AncillaryShoppingPath;
(function (AncillaryShoppingPath) {
  AncillaryShoppingPath["PACKAGE_INFOSITE"] = "PACKAGE_INFOSITE";
  AncillaryShoppingPath["PACKAGE_TRIPS"] = "PACKAGE_TRIPS";
  AncillaryShoppingPath["STANDALONE_INFOSITE"] = "STANDALONE_INFOSITE";
  AncillaryShoppingPath["STANDALONE_TRIPS"] = "STANDALONE_TRIPS";
})(AncillaryShoppingPath || (AncillaryShoppingPath = {}));
export var AppGrowthBorderShape;
(function (AppGrowthBorderShape) {
  AppGrowthBorderShape["ROUNDED"] = "ROUNDED";
  AppGrowthBorderShape["SQUARE"] = "SQUARE";
})(AppGrowthBorderShape || (AppGrowthBorderShape = {}));
export var AppGrowthBorderStyle;
(function (AppGrowthBorderStyle) {
  AppGrowthBorderStyle["BOLD"] = "BOLD";
  AppGrowthBorderStyle["DEFAULT"] = "DEFAULT";
  AppGrowthBorderStyle["NONE"] = "NONE";
})(AppGrowthBorderStyle || (AppGrowthBorderStyle = {}));
export var AppGrowthLineOfBusiness;
(function (AppGrowthLineOfBusiness) {
  AppGrowthLineOfBusiness["ACTIVITIES"] = "ACTIVITIES";
  AppGrowthLineOfBusiness["CAR"] = "CAR";
  AppGrowthLineOfBusiness["CRUISES"] = "CRUISES";
  AppGrowthLineOfBusiness["DEFAULT"] = "DEFAULT";
  AppGrowthLineOfBusiness["FLIGHT"] = "FLIGHT";
  AppGrowthLineOfBusiness["GROUNDTRANSFERS"] = "GROUNDTRANSFERS";
  AppGrowthLineOfBusiness["HOTEL"] = "HOTEL";
  AppGrowthLineOfBusiness["PACKAGES"] = "PACKAGES";
  AppGrowthLineOfBusiness["RAILS"] = "RAILS";
  AppGrowthLineOfBusiness["UNCLASSIFIED"] = "UNCLASSIFIED";
})(AppGrowthLineOfBusiness || (AppGrowthLineOfBusiness = {}));
export var AppGrowthToolbarIcon;
(function (AppGrowthToolbarIcon) {
  AppGrowthToolbarIcon["BACK"] = "BACK";
  AppGrowthToolbarIcon["CLOSE"] = "CLOSE";
})(AppGrowthToolbarIcon || (AppGrowthToolbarIcon = {}));
export var AppGrowthToolbarStyle;
(function (AppGrowthToolbarStyle) {
  AppGrowthToolbarStyle["TRANSPARENT_ACTION_OVERLAY"] = "TRANSPARENT_ACTION_OVERLAY";
})(AppGrowthToolbarStyle || (AppGrowthToolbarStyle = {}));
export var AppGrowthUIActionName;
(function (AppGrowthUIActionName) {
  AppGrowthUIActionName["ACCEPT"] = "ACCEPT";
  AppGrowthUIActionName["DEEPLINK"] = "DEEPLINK";
  AppGrowthUIActionName["DENY"] = "DENY";
  AppGrowthUIActionName["EXIT_TO_APP_HOME"] = "EXIT_TO_APP_HOME";
  AppGrowthUIActionName["GOTO_DASHBOARD"] = "GOTO_DASHBOARD";
  AppGrowthUIActionName["LOGIN_AND_SIGNUP"] = "LOGIN_AND_SIGNUP";
  AppGrowthUIActionName["NONE"] = "NONE";
  AppGrowthUIActionName["OK"] = "OK";
  AppGrowthUIActionName["SHARE"] = "SHARE";
  AppGrowthUIActionName["SIGNUP"] = "SIGNUP";
  AppGrowthUIActionName["TRIVIA_ANSWER"] = "TRIVIA_ANSWER";
  AppGrowthUIActionName["TRIVIA_END"] = "TRIVIA_END";
  AppGrowthUIActionName["TRIVIA_HOW_TO"] = "TRIVIA_HOW_TO";
  AppGrowthUIActionName["TRIVIA_NEXT"] = "TRIVIA_NEXT";
  AppGrowthUIActionName["TRIVIA_START"] = "TRIVIA_START";
})(AppGrowthUIActionName || (AppGrowthUIActionName = {}));
export var ApprovalStatusType;
(function (ApprovalStatusType) {
  ApprovalStatusType["APPROVED"] = "APPROVED";
  ApprovalStatusType["DENIED"] = "DENIED";
  ApprovalStatusType["PENDING"] = "PENDING";
})(ApprovalStatusType || (ApprovalStatusType = {}));
export var ArticleSearchType;
(function (ArticleSearchType) {
  ArticleSearchType["CONTEXTUAL_FOLLOW_UP"] = "CONTEXTUAL_FOLLOW_UP";
  ArticleSearchType["GENERIC"] = "GENERIC";
  /** @deprecated LLM search is deprecated and replaced with Intelligent Search */
  ArticleSearchType["LLM"] = "LLM";
})(ArticleSearchType || (ArticleSearchType = {}));
export var AspectRatio;
(function (AspectRatio) {
  AspectRatio["FOUR_ONE"] = "FOUR_ONE";
  AspectRatio["FOUR_THREE"] = "FOUR_THREE";
  AspectRatio["ONE_ONE"] = "ONE_ONE";
  AspectRatio["SIXTEEN_NINE"] = "SIXTEEN_NINE";
  AspectRatio["THREE_FOUR"] = "THREE_FOUR";
  AspectRatio["THREE_TWO"] = "THREE_TWO";
  AspectRatio["TWENTY_ONE_NINE"] = "TWENTY_ONE_NINE";
})(AspectRatio || (AspectRatio = {}));
export var AtoChallengePlacement;
(function (AtoChallengePlacement) {
  AtoChallengePlacement["DirectFeedbackSubmitFormAction"] = "DirectFeedbackSubmitFormAction";
})(AtoChallengePlacement || (AtoChallengePlacement = {}));
export var AuthenticationState;
(function (AuthenticationState) {
  AuthenticationState["ANONYMOUS"] = "ANONYMOUS";
  AuthenticationState["AUTHENTICATED"] = "AUTHENTICATED";
  AuthenticationState["IDENTIFIED"] = "IDENTIFIED";
})(AuthenticationState || (AuthenticationState = {}));
export var AvatarSize;
(function (AvatarSize) {
  AvatarSize["LARGE"] = "LARGE";
  AvatarSize["MEDIUM"] = "MEDIUM";
  AvatarSize["SMALL"] = "SMALL";
  AvatarSize["XLARGE"] = "XLARGE";
})(AvatarSize || (AvatarSize = {}));
export var BackgroundType;
(function (BackgroundType) {
  BackgroundType["PRIMARY"] = "PRIMARY";
  BackgroundType["SECONDARY"] = "SECONDARY";
})(BackgroundType || (BackgroundType = {}));
export var BadgeIcon;
(function (BadgeIcon) {
  BadgeIcon["ACCESS_TIME"] = "ACCESS_TIME";
  BadgeIcon["ADD"] = "ADD";
  BadgeIcon["BUNDLED_SAVINGS_PLUS"] = "BUNDLED_SAVINGS_PLUS";
  BadgeIcon["EXP29639_ORB"] = "EXP29639_ORB";
  BadgeIcon["FAMILY_FRIENDLY"] = "FAMILY_FRIENDLY";
  BadgeIcon["INFO_OUTLINE"] = "INFO_OUTLINE";
  BadgeIcon["LOB_DEALS"] = "LOB_DEALS";
  BadgeIcon["LOCAL_OFFER"] = "LOCAL_OFFER";
  BadgeIcon["MOD"] = "MOD";
  BadgeIcon["SMARTPHONE"] = "SMARTPHONE";
})(BadgeIcon || (BadgeIcon = {}));
export var BaggageType;
(function (BaggageType) {
  BaggageType["CARRY_BAG"] = "CARRY_BAG";
  BaggageType["CHECKED_BAG"] = "CHECKED_BAG";
  BaggageType["UNKNOWN"] = "UNKNOWN";
})(BaggageType || (BaggageType = {}));
export var BagsSelectionType;
(function (BagsSelectionType) {
  BagsSelectionType["ALL"] = "ALL";
  BagsSelectionType["NONE"] = "NONE";
  BagsSelectionType["SOME"] = "SOME";
})(BagsSelectionType || (BagsSelectionType = {}));
export var BannerDisplayType;
(function (BannerDisplayType) {
  BannerDisplayType["ERROR"] = "ERROR";
  BannerDisplayType["INFO"] = "INFO";
  BannerDisplayType["NO_BANNER"] = "NO_BANNER";
  BannerDisplayType["WARN"] = "WARN";
})(BannerDisplayType || (BannerDisplayType = {}));
export var BannerTag;
(function (BannerTag) {
  BannerTag["AD"] = "AD";
  BannerTag["AD_GROUP"] = "AD_GROUP";
  BannerTag["CAMPAIGN"] = "CAMPAIGN";
})(BannerTag || (BannerTag = {}));
export var BannerTheme;
(function (BannerTheme) {
  BannerTheme["BRAND_PROMO_PRIMARY"] = "BRAND_PROMO_PRIMARY";
  BannerTheme["PRIMARY"] = "PRIMARY";
  BannerTheme["SECONDARY"] = "SECONDARY";
})(BannerTheme || (BannerTheme = {}));
export var BannerThemes;
(function (BannerThemes) {
  BannerThemes["CRITICAL"] = "CRITICAL";
  BannerThemes["ERROR"] = "ERROR";
})(BannerThemes || (BannerThemes = {}));
export var BannerType;
(function (BannerType) {
  BannerType["ERROR"] = "ERROR";
  BannerType["INFO"] = "INFO";
})(BannerType || (BannerType = {}));
export var BiddingStrategyType;
(function (BiddingStrategyType) {
  BiddingStrategyType["CPC"] = "CPC";
  BiddingStrategyType["CPM"] = "CPM";
  BiddingStrategyType["UNKNOWN"] = "UNKNOWN";
})(BiddingStrategyType || (BiddingStrategyType = {}));
export var BookingServicingAcceptViews;
(function (BookingServicingAcceptViews) {
  BookingServicingAcceptViews["CONFIRMATION"] = "CONFIRMATION";
  BookingServicingAcceptViews["HEADS_UP"] = "HEADS_UP";
  BookingServicingAcceptViews["REVIEW"] = "REVIEW";
})(BookingServicingAcceptViews || (BookingServicingAcceptViews = {}));
export var BookingServicingCancelType;
(function (BookingServicingCancelType) {
  BookingServicingCancelType["INVOLUNTARY"] = "INVOLUNTARY";
  BookingServicingCancelType["VOLUNTARY"] = "VOLUNTARY";
})(BookingServicingCancelType || (BookingServicingCancelType = {}));
export var BookingServicingCancelViews;
(function (BookingServicingCancelViews) {
  BookingServicingCancelViews["CONFIRMATION"] = "CONFIRMATION";
  BookingServicingCancelViews["HEADS_UP"] = "HEADS_UP";
  BookingServicingCancelViews["REVIEW"] = "REVIEW";
})(BookingServicingCancelViews || (BookingServicingCancelViews = {}));
export var BookingServicingCardTheme;
(function (BookingServicingCardTheme) {
  BookingServicingCardTheme["NEGATIVE"] = "NEGATIVE";
})(BookingServicingCardTheme || (BookingServicingCardTheme = {}));
export var BookingServicingChangeViews;
(function (BookingServicingChangeViews) {
  BookingServicingChangeViews["CHANGE_FORM"] = "CHANGE_FORM";
})(BookingServicingChangeViews || (BookingServicingChangeViews = {}));
export var BookingServicingClient;
(function (BookingServicingClient) {
  BookingServicingClient["VIRTUAL_AGENT"] = "VIRTUAL_AGENT";
  BookingServicingClient["VNEXT"] = "VNEXT";
  BookingServicingClient["WEB"] = "WEB";
})(BookingServicingClient || (BookingServicingClient = {}));
export var BookingServicingDetailsStrategy;
(function (BookingServicingDetailsStrategy) {
  BookingServicingDetailsStrategy["BOOKING_DETAILS"] = "BOOKING_DETAILS";
  BookingServicingDetailsStrategy["BOOKING_PRICE_DETAILS"] = "BOOKING_PRICE_DETAILS";
})(BookingServicingDetailsStrategy || (BookingServicingDetailsStrategy = {}));
export var BookingServicingFlow;
(function (BookingServicingFlow) {
  BookingServicingFlow["INVOLUNTARY"] = "INVOLUNTARY";
  BookingServicingFlow["VOLUNTARY"] = "VOLUNTARY";
})(BookingServicingFlow || (BookingServicingFlow = {}));
export var BookingServicingLinkTarget;
(function (BookingServicingLinkTarget) {
  BookingServicingLinkTarget["EXTERNAL"] = "EXTERNAL";
  BookingServicingLinkTarget["INTERNAL"] = "INTERNAL";
})(BookingServicingLinkTarget || (BookingServicingLinkTarget = {}));
export var BookingServicingPath;
(function (BookingServicingPath) {
  BookingServicingPath["ACCEPT"] = "ACCEPT";
  BookingServicingPath["CANCEL"] = "CANCEL";
  BookingServicingPath["CHANGE"] = "CHANGE";
})(BookingServicingPath || (BookingServicingPath = {}));
export var BookingServicingToolbarNavigationType;
(function (BookingServicingToolbarNavigationType) {
  BookingServicingToolbarNavigationType["BACK"] = "BACK";
  BookingServicingToolbarNavigationType["BACK_DIALOG"] = "BACK_DIALOG";
  BookingServicingToolbarNavigationType["CLOSE"] = "CLOSE";
  BookingServicingToolbarNavigationType["EXPAND_LESS"] = "EXPAND_LESS";
  BookingServicingToolbarNavigationType["EXPAND_UP"] = "EXPAND_UP";
})(BookingServicingToolbarNavigationType || (BookingServicingToolbarNavigationType = {}));
export var BookingServicingToolbarType;
(function (BookingServicingToolbarType) {
  BookingServicingToolbarType["STANDARD"] = "STANDARD";
  BookingServicingToolbarType["TRANSPARENT"] = "TRANSPARENT";
})(BookingServicingToolbarType || (BookingServicingToolbarType = {}));
export var BookingServicingTravelerType;
(function (BookingServicingTravelerType) {
  BookingServicingTravelerType["ADULT"] = "ADULT";
  BookingServicingTravelerType["CHILD"] = "CHILD";
  BookingServicingTravelerType["INFANT"] = "INFANT";
})(BookingServicingTravelerType || (BookingServicingTravelerType = {}));
export var BookingServicingTravelerValidationType;
(function (BookingServicingTravelerValidationType) {
  BookingServicingTravelerValidationType["CHILD_TRAVELING_ALONE"] = "CHILD_TRAVELING_ALONE";
})(BookingServicingTravelerValidationType || (BookingServicingTravelerValidationType = {}));
export var BookingStatus;
(function (BookingStatus) {
  BookingStatus["CANCELLED"] = "CANCELLED";
  BookingStatus["IN_PROGRESS"] = "IN_PROGRESS";
  BookingStatus["PAST"] = "PAST";
  BookingStatus["UPCOMING"] = "UPCOMING";
})(BookingStatus || (BookingStatus = {}));
export var BooleanEnum;
(function (BooleanEnum) {
  BooleanEnum["FALSE"] = "FALSE";
  BooleanEnum["TRUE"] = "TRUE";
})(BooleanEnum || (BooleanEnum = {}));
export var BulkEditResponseType;
(function (BulkEditResponseType) {
  BulkEditResponseType["ERROR"] = "ERROR";
  BulkEditResponseType["SUCCESS"] = "SUCCESS";
})(BulkEditResponseType || (BulkEditResponseType = {}));
export var BulletStyle;
(function (BulletStyle) {
  BulletStyle["DEFAULT"] = "DEFAULT";
  BulletStyle["ICON_ALTERNATE"] = "ICON_ALTERNATE";
  BulletStyle["ICON_STANDARD"] = "ICON_STANDARD";
  BulletStyle["NO_BULLET"] = "NO_BULLET";
  BulletStyle["UNRECOGNIZED"] = "UNRECOGNIZED";
})(BulletStyle || (BulletStyle = {}));
export var BusinessModelType;
(function (BusinessModelType) {
  BusinessModelType["EXPEDIA_COLLECT"] = "EXPEDIA_COLLECT";
  BusinessModelType["HOTEL_COLLECT"] = "HOTEL_COLLECT";
})(BusinessModelType || (BusinessModelType = {}));
export var ButtonSourceType;
(function (ButtonSourceType) {
  ButtonSourceType["SCRIPT"] = "SCRIPT";
  ButtonSourceType["URL"] = "URL";
})(ButtonSourceType || (ButtonSourceType = {}));
export var ButtonType;
(function (ButtonType) {
  ButtonType["PRIMARY"] = "PRIMARY";
  ButtonType["SECONDARY"] = "SECONDARY";
  ButtonType["TERTIARY"] = "TERTIARY";
})(ButtonType || (ButtonType = {}));
export var CampaignOfferBadgeType;
(function (CampaignOfferBadgeType) {
  CampaignOfferBadgeType["DEFAULT"] = "DEFAULT";
  CampaignOfferBadgeType["DISCOUNT"] = "DISCOUNT";
})(CampaignOfferBadgeType || (CampaignOfferBadgeType = {}));
export var CampaignProductType;
(function (CampaignProductType) {
  CampaignProductType["ACTIVITY"] = "ACTIVITY";
  CampaignProductType["FLIGHT"] = "FLIGHT";
  CampaignProductType["HOTEL"] = "HOTEL";
  CampaignProductType["PACKAGE"] = "PACKAGE";
})(CampaignProductType || (CampaignProductType = {}));
export var CarAccessorySelectionState;
(function (CarAccessorySelectionState) {
  CarAccessorySelectionState["NOT_SELECTED"] = "NOT_SELECTED";
  CarAccessorySelectionState["SELECTED"] = "SELECTED";
})(CarAccessorySelectionState || (CarAccessorySelectionState = {}));
export var CarActionType;
(function (CarActionType) {
  CarActionType["APPLY_LOYALTY_POINTS"] = "APPLY_LOYALTY_POINTS";
  CarActionType["CAR_MAP_MARKER"] = "CAR_MAP_MARKER";
  CarActionType["CHANGE_SEARCH"] = "CHANGE_SEARCH";
  CarActionType["DISMISS"] = "DISMISS";
  CarActionType["FILTER_SALE_OFFER"] = "FILTER_SALE_OFFER";
  CarActionType["FORM_SUBMISSION"] = "FORM_SUBMISSION";
  CarActionType["GO_BACK_TO_SEARCH"] = "GO_BACK_TO_SEARCH";
  CarActionType["GO_TO_CANCELLATION_POLICY"] = "GO_TO_CANCELLATION_POLICY";
  CarActionType["GO_TO_CHECKOUT"] = "GO_TO_CHECKOUT";
  CarActionType["GO_TO_CLEANLINESS_DIALOG"] = "GO_TO_CLEANLINESS_DIALOG";
  CarActionType["GO_TO_DELTA_CAMPAIGN_LINK"] = "GO_TO_DELTA_CAMPAIGN_LINK";
  CarActionType["GO_TO_DETAILS"] = "GO_TO_DETAILS";
  CarActionType["GO_TO_PACKAGES"] = "GO_TO_PACKAGES";
  CarActionType["GO_TO_RECOMMENDED_SORT_FAQ"] = "GO_TO_RECOMMENDED_SORT_FAQ";
  CarActionType["GO_TO_SIGNIN"] = "GO_TO_SIGNIN";
  CarActionType["GO_TO_SIGNUP"] = "GO_TO_SIGNUP";
  CarActionType["GO_TO_SIGN_IN_AND_UNLOCK_CAR_SALES"] = "GO_TO_SIGN_IN_AND_UNLOCK_CAR_SALES";
  CarActionType["GO_TO_WPLUS_MEMBER_TRIAL_LINK"] = "GO_TO_WPLUS_MEMBER_TRIAL_LINK";
  CarActionType["LOAD_MORE"] = "LOAD_MORE";
  CarActionType["OFF_AIRPORT_CHANGE_SEARCH"] = "OFF_AIRPORT_CHANGE_SEARCH";
  CarActionType["OPEN_DIALOG"] = "OPEN_DIALOG";
  CarActionType["OPEN_DOC"] = "OPEN_DOC";
  CarActionType["OPEN_GROUND_TRANSFERS"] = "OPEN_GROUND_TRANSFERS";
  CarActionType["OPEN_INLINE_TIME_WIZARD"] = "OPEN_INLINE_TIME_WIZARD";
  CarActionType["OPEN_INLINE_WIZARD"] = "OPEN_INLINE_WIZARD";
  CarActionType["OPEN_NEW_TAB"] = "OPEN_NEW_TAB";
  CarActionType["OPEN_ON_CURRENT_TAB"] = "OPEN_ON_CURRENT_TAB";
  CarActionType["OPEN_PARTNER_MESSAGING_LINK"] = "OPEN_PARTNER_MESSAGING_LINK";
  CarActionType["OPEN_RULE"] = "OPEN_RULE";
  CarActionType["OPEN_TEL"] = "OPEN_TEL";
  CarActionType["REFRESH"] = "REFRESH";
  CarActionType["REMOVE_LOYALTY_POINTS"] = "REMOVE_LOYALTY_POINTS";
  CarActionType["SEE_CARS_AT_MAP_MARKER"] = "SEE_CARS_AT_MAP_MARKER";
  CarActionType["SHARE_FEEDBACK"] = "SHARE_FEEDBACK";
  CarActionType["SHOW_AARP_EXCLUSIVE_OFFERS"] = "SHOW_AARP_EXCLUSIVE_OFFERS";
  CarActionType["SHOW_FREE_CANCELLATION_OFFERS"] = "SHOW_FREE_CANCELLATION_OFFERS";
  CarActionType["UNLOCK_CAR_SALES"] = "UNLOCK_CAR_SALES";
})(CarActionType || (CarActionType = {}));
export var CarFormatStyle;
(function (CarFormatStyle) {
  CarFormatStyle["IFRAME"] = "IFRAME";
  CarFormatStyle["LIST"] = "LIST";
})(CarFormatStyle || (CarFormatStyle = {}));
export var CarMapMarkerType;
(function (CarMapMarkerType) {
  CarMapMarkerType["CarMarker"] = "CarMarker";
  CarMapMarkerType["PlaceMarker"] = "PlaceMarker";
})(CarMapMarkerType || (CarMapMarkerType = {}));
export var CarRouteType;
(function (CarRouteType) {
  CarRouteType["ONEWAY"] = "ONEWAY";
  CarRouteType["ROUNDTRIP"] = "ROUNDTRIP";
})(CarRouteType || (CarRouteType = {}));
export var CarSearchFormPreferredBrand;
(function (CarSearchFormPreferredBrand) {
  CarSearchFormPreferredBrand["AC"] = "AC";
  CarSearchFormPreferredBrand["AD"] = "AD";
  CarSearchFormPreferredBrand["AL"] = "AL";
  CarSearchFormPreferredBrand["EP"] = "EP";
  CarSearchFormPreferredBrand["ET"] = "ET";
  CarSearchFormPreferredBrand["EY"] = "EY";
  CarSearchFormPreferredBrand["FX"] = "FX";
  CarSearchFormPreferredBrand["GI"] = "GI";
  CarSearchFormPreferredBrand["MW"] = "MW";
  CarSearchFormPreferredBrand["NU"] = "NU";
  CarSearchFormPreferredBrand["RO"] = "RO";
  CarSearchFormPreferredBrand["SC"] = "SC";
  CarSearchFormPreferredBrand["SV"] = "SV";
  CarSearchFormPreferredBrand["SX"] = "SX";
  CarSearchFormPreferredBrand["ZA"] = "ZA";
  CarSearchFormPreferredBrand["ZD"] = "ZD";
  CarSearchFormPreferredBrand["ZE"] = "ZE";
  CarSearchFormPreferredBrand["ZI"] = "ZI";
  CarSearchFormPreferredBrand["ZL"] = "ZL";
  CarSearchFormPreferredBrand["ZR"] = "ZR";
  CarSearchFormPreferredBrand["ZT"] = "ZT";
})(CarSearchFormPreferredBrand || (CarSearchFormPreferredBrand = {}));
export var CardActions;
(function (CardActions) {
  CardActions["DISMISS"] = "DISMISS";
})(CardActions || (CardActions = {}));
export var CardSize;
(function (CardSize) {
  CardSize["LARGE"] = "LARGE";
  CardSize["MEDIUM"] = "MEDIUM";
  CardSize["SMALL"] = "SMALL";
})(CardSize || (CardSize = {}));
export var CardStatus;
(function (CardStatus) {
  CardStatus["NOT_UPGRADED"] = "NOT_UPGRADED";
  CardStatus["REFUNDABLE"] = "REFUNDABLE";
  CardStatus["UPDATED"] = "UPDATED";
  CardStatus["UPGRADED"] = "UPGRADED";
})(CardStatus || (CardStatus = {}));
export var CardTheme;
(function (CardTheme) {
  CardTheme["GLOBAL_LOYALTY_EXTRAHIGH"] = "GLOBAL_LOYALTY_EXTRAHIGH";
  CardTheme["GLOBAL_LOYALTY_HIGH"] = "GLOBAL_LOYALTY_HIGH";
  CardTheme["GLOBAL_LOYALTY_LOW"] = "GLOBAL_LOYALTY_LOW";
  CardTheme["GLOBAL_LOYALTY_MIDDLE"] = "GLOBAL_LOYALTY_MIDDLE";
  CardTheme["GLOBAL_LOYALTY_STANDARD"] = "GLOBAL_LOYALTY_STANDARD";
  CardTheme["PRIMARY"] = "PRIMARY";
  CardTheme["SECONDARY"] = "SECONDARY";
})(CardTheme || (CardTheme = {}));
export var CarouselScrimType;
(function (CarouselScrimType) {
  CarouselScrimType["INSET_PAGINATION_DOT"] = "INSET_PAGINATION_DOT";
  CarouselScrimType["OFFSET_PAGINATION_DOT"] = "OFFSET_PAGINATION_DOT";
})(CarouselScrimType || (CarouselScrimType = {}));
export var CarryOnBagType;
(function (CarryOnBagType) {
  CarryOnBagType["CARRY_ON_BAG"] = "CARRY_ON_BAG";
})(CarryOnBagType || (CarryOnBagType = {}));
export var CarsDialogType;
(function (CarsDialogType) {
  CarsDialogType["LIMITED_RESULTS"] = "LIMITED_RESULTS";
  CarsDialogType["LIMITED_RESULTS_WITHOUT_TURO"] = "LIMITED_RESULTS_WITHOUT_TURO";
  CarsDialogType["LIMITED_RESULTS_WITH_TURO"] = "LIMITED_RESULTS_WITH_TURO";
})(CarsDialogType || (CarsDialogType = {}));
export var CarsRichTextStyle;
(function (CarsRichTextStyle) {
  CarsRichTextStyle["BOLD"] = "BOLD";
  CarsRichTextStyle["BULLET"] = "BULLET";
  CarsRichTextStyle["ITALICIZE"] = "ITALICIZE";
})(CarsRichTextStyle || (CarsRichTextStyle = {}));
export var CartTtl;
(function (CartTtl) {
  CartTtl["FIVE_MINUTES"] = "FIVE_MINUTES";
  CartTtl["SEVEN_DAYS"] = "SEVEN_DAYS";
  CartTtl["TWELVE_HOURS"] = "TWELVE_HOURS";
})(CartTtl || (CartTtl = {}));
export var ChangeType;
(function (ChangeType) {
  ChangeType["DATES"] = "DATES";
  ChangeType["GUEST_COUNT"] = "GUEST_COUNT";
  ChangeType["ROOM"] = "ROOM";
})(ChangeType || (ChangeType = {}));
export var ChannelType;
(function (ChannelType) {
  ChannelType["VIRTUAL_AGENT"] = "VIRTUAL_AGENT";
})(ChannelType || (ChannelType = {}));
export var ChatBotIntentType;
(function (ChatBotIntentType) {
  ChatBotIntentType["CANCEL"] = "CANCEL";
  ChatBotIntentType["CHANGE"] = "CHANGE";
  ChatBotIntentType["VIEW_BOOKING"] = "VIEW_BOOKING";
})(ChatBotIntentType || (ChatBotIntentType = {}));
export var ChatBotProductType;
(function (ChatBotProductType) {
  ChatBotProductType["FLIGHT"] = "FLIGHT";
})(ChatBotProductType || (ChatBotProductType = {}));
export var ChatBotWaiverRedemptionState;
(function (ChatBotWaiverRedemptionState) {
  ChatBotWaiverRedemptionState["CREDIT_REDEMPTION"] = "CREDIT_REDEMPTION";
})(ChatBotWaiverRedemptionState || (ChatBotWaiverRedemptionState = {}));
export var ChatInputStatus;
(function (ChatInputStatus) {
  ChatInputStatus["DISABLE"] = "DISABLE";
  ChatInputStatus["ENABLE"] = "ENABLE";
})(ChatInputStatus || (ChatInputStatus = {}));
export var ChatbotCapabilities;
(function (ChatbotCapabilities) {
  ChatbotCapabilities["CLICKSTREAM"] = "CLICKSTREAM";
  ChatbotCapabilities["GLASSBOX"] = "GLASSBOX";
})(ChatbotCapabilities || (ChatbotCapabilities = {}));
export var ChatbotVersionId;
(function (ChatbotVersionId) {
  ChatbotVersionId["V1"] = "V1";
  ChatbotVersionId["V2"] = "V2";
})(ChatbotVersionId || (ChatbotVersionId = {}));
export var CheckinValidityWithReason;
(function (CheckinValidityWithReason) {
  CheckinValidityWithReason["INVALID"] = "INVALID";
  CheckinValidityWithReason["INVALID_DUE_TO_BEING_IN_PAST"] = "INVALID_DUE_TO_BEING_IN_PAST";
  CheckinValidityWithReason["INVALID_DUE_TO_CHANGEOVER_EXCLUSION"] = "INVALID_DUE_TO_CHANGEOVER_EXCLUSION";
  CheckinValidityWithReason["INVALID_DUE_TO_MAX_FUTURE_BOOKING"] = "INVALID_DUE_TO_MAX_FUTURE_BOOKING";
  CheckinValidityWithReason["INVALID_DUE_TO_MIN_PRIOR_NOTIFICATION"] = "INVALID_DUE_TO_MIN_PRIOR_NOTIFICATION";
  CheckinValidityWithReason["INVALID_DUE_TO_MIN_STAY_NOT_ACHIEVABLE"] = "INVALID_DUE_TO_MIN_STAY_NOT_ACHIEVABLE";
  CheckinValidityWithReason["INVALID_DUE_TO_NON_CHANGEOVER_DAY_OF_WEEK"] = "INVALID_DUE_TO_NON_CHANGEOVER_DAY_OF_WEEK";
  CheckinValidityWithReason["INVALID_DUE_TO_NOT_AVAILABLE"] = "INVALID_DUE_TO_NOT_AVAILABLE";
  CheckinValidityWithReason["INVALID_DUE_TO_NO_VALID_CHECKOUT_WITHIN_CONSTRAINTS"] = "INVALID_DUE_TO_NO_VALID_CHECKOUT_WITHIN_CONSTRAINTS";
  CheckinValidityWithReason["VALID"] = "VALID";
})(CheckinValidityWithReason || (CheckinValidityWithReason = {}));
export var CheckoutOptionType;
(function (CheckoutOptionType) {
  CheckoutOptionType["CROSS_SELL"] = "CROSS_SELL";
  CheckoutOptionType["DESTINATION_ID"] = "DESTINATION_ID";
  CheckoutOptionType["FENCING_ATTRIBUTES"] = "FENCING_ATTRIBUTES";
  /** @deprecated Use PropertyNaturalKeyInput.businessModelType instead */
  CheckoutOptionType["HOTEL_BUSINESS_MODEL"] = "HOTEL_BUSINESS_MODEL";
  CheckoutOptionType["HOTEL_MARKETING_CHANNEL"] = "HOTEL_MARKETING_CHANNEL";
  CheckoutOptionType["INSTANT_BOOK"] = "INSTANT_BOOK";
  CheckoutOptionType["LOYALTY_REWARDS_ID"] = "LOYALTY_REWARDS_ID";
  CheckoutOptionType["META_DEEPLINK"] = "META_DEEPLINK";
  CheckoutOptionType["NATIVE"] = "NATIVE";
  CheckoutOptionType["PRICE_TOKEN"] = "PRICE_TOKEN";
  CheckoutOptionType["PROPERTY_UPSELL_AMOUNT"] = "PROPERTY_UPSELL_AMOUNT";
  CheckoutOptionType["RATE_UPGRADE_ELIGIBLE"] = "RATE_UPGRADE_ELIGIBLE";
  CheckoutOptionType["RATE_UPSELL"] = "RATE_UPSELL";
  CheckoutOptionType["RATE_UPSELL_AMOUNT"] = "RATE_UPSELL_AMOUNT";
  CheckoutOptionType["REFERRER_ID"] = "REFERRER_ID";
  CheckoutOptionType["REFUNDABLE_DAMAGE_DEPOSIT_REQUIRED"] = "REFUNDABLE_DAMAGE_DEPOSIT_REQUIRED";
  CheckoutOptionType["ROOM_UPGRADE_ELIGIBLE"] = "ROOM_UPGRADE_ELIGIBLE";
  CheckoutOptionType["ROOM_UPSELL"] = "ROOM_UPSELL";
  CheckoutOptionType["SEARCH_ID"] = "SEARCH_ID";
  CheckoutOptionType["SHOPPING_DEAL_BADGE_PERCENTAGE"] = "SHOPPING_DEAL_BADGE_PERCENTAGE";
  CheckoutOptionType["SHOP_WITH_POINTS"] = "SHOP_WITH_POINTS";
  CheckoutOptionType["TAAP_PACKAGE_RATES"] = "TAAP_PACKAGE_RATES";
})(CheckoutOptionType || (CheckoutOptionType = {}));
export var CheckoutValidityWithReason;
(function (CheckoutValidityWithReason) {
  CheckoutValidityWithReason["INVALID"] = "INVALID";
  CheckoutValidityWithReason["INVALID_DUE_TO_CHANGEOVER_EXCLUSION"] = "INVALID_DUE_TO_CHANGEOVER_EXCLUSION";
  CheckoutValidityWithReason["INVALID_DUE_TO_NON_CHANGEOVER_DAY_OF_WEEK"] = "INVALID_DUE_TO_NON_CHANGEOVER_DAY_OF_WEEK";
  CheckoutValidityWithReason["VALID"] = "VALID";
})(CheckoutValidityWithReason || (CheckoutValidityWithReason = {}));
export var ChronoUnit;
(function (ChronoUnit) {
  ChronoUnit["CENTURIES"] = "CENTURIES";
  ChronoUnit["DAYS"] = "DAYS";
  ChronoUnit["DECADES"] = "DECADES";
  ChronoUnit["ERAS"] = "ERAS";
  ChronoUnit["FOREVER"] = "FOREVER";
  ChronoUnit["HALFDAYS"] = "HALFDAYS";
  ChronoUnit["HOURS"] = "HOURS";
  ChronoUnit["MICROS"] = "MICROS";
  ChronoUnit["MILLENNIA"] = "MILLENNIA";
  ChronoUnit["MILLIS"] = "MILLIS";
  ChronoUnit["MINUTES"] = "MINUTES";
  ChronoUnit["MONTHS"] = "MONTHS";
  ChronoUnit["NANOS"] = "NANOS";
  ChronoUnit["SECONDS"] = "SECONDS";
  ChronoUnit["WEEKS"] = "WEEKS";
  ChronoUnit["YEARS"] = "YEARS";
})(ChronoUnit || (ChronoUnit = {}));
export var CleaningAndSafetyPractice;
(function (CleaningAndSafetyPractice) {
  CleaningAndSafetyPractice["ENHANCED_CLEANING"] = "ENHANCED_CLEANING";
})(CleaningAndSafetyPractice || (CleaningAndSafetyPractice = {}));
export var ClientSideIllustrationAsset;
(function (ClientSideIllustrationAsset) {
  ClientSideIllustrationAsset["FlightHotelIncludedSet"] = "FlightHotelIncludedSet";
})(ClientSideIllustrationAsset || (ClientSideIllustrationAsset = {}));
export var ClosedPeriodBounding;
(function (ClosedPeriodBounding) {
  ClosedPeriodBounding["LOWER"] = "LOWER";
  ClosedPeriodBounding["UPPER"] = "UPPER";
})(ClosedPeriodBounding || (ClosedPeriodBounding = {}));
export var CoachingTipsView;
(function (CoachingTipsView) {
  CoachingTipsView["IN_CONTEXT_COACHING_CAROUSEL"] = "IN_CONTEXT_COACHING_CAROUSEL";
  CoachingTipsView["OPPY_HUB"] = "OPPY_HUB";
  CoachingTipsView["STANDALONE_MINIMAL"] = "STANDALONE_MINIMAL";
  CoachingTipsView["STANDALONE_SUGGESTED"] = "STANDALONE_SUGGESTED";
})(CoachingTipsView || (CoachingTipsView = {}));
export var CollectionItemClickthrough;
(function (CollectionItemClickthrough) {
  CollectionItemClickthrough["PDP"] = "PDP";
  CollectionItemClickthrough["PINNED_SRP"] = "PINNED_SRP";
})(CollectionItemClickthrough || (CollectionItemClickthrough = {}));
export var CollectionType;
(function (CollectionType) {
  CollectionType["FLIGHT"] = "FLIGHT";
  CollectionType["GEOGRAPHY"] = "GEOGRAPHY";
  CollectionType["LODGING"] = "LODGING";
  CollectionType["PACKAGE"] = "PACKAGE";
})(CollectionType || (CollectionType = {}));
export var CommissionTier;
(function (CommissionTier) {
  CommissionTier["BASIC"] = "BASIC";
  CommissionTier["BASIC_PLUS"] = "BASIC_PLUS";
  CommissionTier["PREMIUM"] = "PREMIUM";
  CommissionTier["PREMIUM_PLUS"] = "PREMIUM_PLUS";
})(CommissionTier || (CommissionTier = {}));
export var CommunicationCenterConversationState;
(function (CommunicationCenterConversationState) {
  CommunicationCenterConversationState["READ"] = "READ";
  CommunicationCenterConversationState["UNKNOWN"] = "UNKNOWN";
  CommunicationCenterConversationState["UNREAD"] = "UNREAD";
})(CommunicationCenterConversationState || (CommunicationCenterConversationState = {}));
export var CommunicationCenterConversationType;
(function (CommunicationCenterConversationType) {
  CommunicationCenterConversationType["SUPPLIER"] = "SUPPLIER";
  CommunicationCenterConversationType["TRIP_COLLABORATION"] = "TRIP_COLLABORATION";
})(CommunicationCenterConversationType || (CommunicationCenterConversationType = {}));
export var CommunicationCenterMessageAlignment;
(function (CommunicationCenterMessageAlignment) {
  CommunicationCenterMessageAlignment["FULL"] = "FULL";
  CommunicationCenterMessageAlignment["LEFT"] = "LEFT";
  CommunicationCenterMessageAlignment["RIGHT"] = "RIGHT";
})(CommunicationCenterMessageAlignment || (CommunicationCenterMessageAlignment = {}));
export var CommunicationCenterMessagePreviewState;
(function (CommunicationCenterMessagePreviewState) {
  CommunicationCenterMessagePreviewState["READ"] = "READ";
  CommunicationCenterMessagePreviewState["UNREAD"] = "UNREAD";
})(CommunicationCenterMessagePreviewState || (CommunicationCenterMessagePreviewState = {}));
export var CommunicationCenterNotificationMessageDisplayType;
(function (CommunicationCenterNotificationMessageDisplayType) {
  CommunicationCenterNotificationMessageDisplayType["FORM"] = "FORM";
  CommunicationCenterNotificationMessageDisplayType["PAGE"] = "PAGE";
})(CommunicationCenterNotificationMessageDisplayType || (CommunicationCenterNotificationMessageDisplayType = {}));
export var CommunicationCenterNotificationMessageLevel;
(function (CommunicationCenterNotificationMessageLevel) {
  CommunicationCenterNotificationMessageLevel["ERROR"] = "ERROR";
  CommunicationCenterNotificationMessageLevel["INFO"] = "INFO";
  CommunicationCenterNotificationMessageLevel["SUCCESS"] = "SUCCESS";
  CommunicationCenterNotificationMessageLevel["WARNING"] = "WARNING";
})(CommunicationCenterNotificationMessageLevel || (CommunicationCenterNotificationMessageLevel = {}));
export var CommunicationCenterNotificationState;
(function (CommunicationCenterNotificationState) {
  CommunicationCenterNotificationState["READ"] = "READ";
  CommunicationCenterNotificationState["SEEN"] = "SEEN";
  CommunicationCenterNotificationState["UNKNOWN"] = "UNKNOWN";
  CommunicationCenterNotificationState["UNSEEN"] = "UNSEEN";
})(CommunicationCenterNotificationState || (CommunicationCenterNotificationState = {}));
export var CommunicationCenterNotificationType;
(function (CommunicationCenterNotificationType) {
  CommunicationCenterNotificationType["COUPON_CAMPAIGN"] = "COUPON_CAMPAIGN";
  CommunicationCenterNotificationType["DEFAULT"] = "DEFAULT";
})(CommunicationCenterNotificationType || (CommunicationCenterNotificationType = {}));
export var CommunicationCenterNotificationUserActionType;
(function (CommunicationCenterNotificationUserActionType) {
  CommunicationCenterNotificationUserActionType["DELETE"] = "DELETE";
  CommunicationCenterNotificationUserActionType["MARK_AS_READ"] = "MARK_AS_READ";
  CommunicationCenterNotificationUserActionType["MARK_AS_UNREAD"] = "MARK_AS_UNREAD";
})(CommunicationCenterNotificationUserActionType || (CommunicationCenterNotificationUserActionType = {}));
export var CommunicationCenterOperationStatus;
(function (CommunicationCenterOperationStatus) {
  CommunicationCenterOperationStatus["GENERIC_FAILURE"] = "GENERIC_FAILURE";
  CommunicationCenterOperationStatus["INVALID_INPUT"] = "INVALID_INPUT";
  CommunicationCenterOperationStatus["SERVICE_ERROR"] = "SERVICE_ERROR";
  CommunicationCenterOperationStatus["SUCCESS"] = "SUCCESS";
})(CommunicationCenterOperationStatus || (CommunicationCenterOperationStatus = {}));
export var CommunicationChannel;
(function (CommunicationChannel) {
  CommunicationChannel["EMAIL"] = "EMAIL";
  CommunicationChannel["PUSH"] = "PUSH";
  CommunicationChannel["SMS"] = "SMS";
})(CommunicationChannel || (CommunicationChannel = {}));
export var CommunicationPreferencesOptionsType;
(function (CommunicationPreferencesOptionsType) {
  CommunicationPreferencesOptionsType["ACCOUNT_SUPPORT"] = "ACCOUNT_SUPPORT";
  CommunicationPreferencesOptionsType["COMMUNICATION_PREFERENCES_EXPEDIA"] = "COMMUNICATION_PREFERENCES_EXPEDIA";
  CommunicationPreferencesOptionsType["COMMUNICATION_PREFERENCES_HOTELS"] = "COMMUNICATION_PREFERENCES_HOTELS";
  CommunicationPreferencesOptionsType["COMMUNICATION_PREFERENCES_VRBO"] = "COMMUNICATION_PREFERENCES_VRBO";
  CommunicationPreferencesOptionsType["COMMUNICATION_PREFERENCES_WOTIF"] = "COMMUNICATION_PREFERENCES_WOTIF";
  CommunicationPreferencesOptionsType["CONFIRMATION_AND_CRITICAL_UPDATES"] = "CONFIRMATION_AND_CRITICAL_UPDATES";
  CommunicationPreferencesOptionsType["DEFAULT"] = "DEFAULT";
  CommunicationPreferencesOptionsType["MANAGE_EMAIL_PREFERENCE"] = "MANAGE_EMAIL_PREFERENCE";
  CommunicationPreferencesOptionsType["OFFERS_AND_INSPIRATION"] = "OFFERS_AND_INSPIRATION";
  CommunicationPreferencesOptionsType["ONE_KEY"] = "ONE_KEY";
  CommunicationPreferencesOptionsType["ONE_KEY_MONTHLY_STATEMENTS"] = "ONE_KEY_MONTHLY_STATEMENTS";
  CommunicationPreferencesOptionsType["PARTNERS_OFFERS_WITH_US"] = "PARTNERS_OFFERS_WITH_US";
  CommunicationPreferencesOptionsType["PERSONALIZED_TRIP_RECOMMENDATIONS"] = "PERSONALIZED_TRIP_RECOMMENDATIONS";
  CommunicationPreferencesOptionsType["PRIVACY_STATEMENT"] = "PRIVACY_STATEMENT";
  CommunicationPreferencesOptionsType["REVIEWS"] = "REVIEWS";
  CommunicationPreferencesOptionsType["SURVEYS"] = "SURVEYS";
  CommunicationPreferencesOptionsType["THE_TRAVEL_EDIT"] = "THE_TRAVEL_EDIT";
  CommunicationPreferencesOptionsType["TRIP_BOARD_ACTIVITY"] = "TRIP_BOARD_ACTIVITY";
  CommunicationPreferencesOptionsType["TRIP_PLANNING_ASSISTANCE"] = "TRIP_PLANNING_ASSISTANCE";
  CommunicationPreferencesOptionsType["UNSUB_ALL"] = "UNSUB_ALL";
  CommunicationPreferencesOptionsType["UNSUB_OTHER_EMAILS"] = "UNSUB_OTHER_EMAILS";
})(CommunicationPreferencesOptionsType || (CommunicationPreferencesOptionsType = {}));
export var CompressionLevel;
(function (CompressionLevel) {
  CompressionLevel["CAP"] = "CAP";
  CompressionLevel["HIGH"] = "HIGH";
  CompressionLevel["LOW"] = "LOW";
  CompressionLevel["MEDIUM"] = "MEDIUM";
  CompressionLevel["NA"] = "NA";
})(CompressionLevel || (CompressionLevel = {}));
export var ConfigTag;
(function (ConfigTag) {
  ConfigTag["H1"] = "H1";
  ConfigTag["H2"] = "H2";
  ConfigTag["H3"] = "H3";
  ConfigTag["H4"] = "H4";
  ConfigTag["H5"] = "H5";
  ConfigTag["H6"] = "H6";
})(ConfigTag || (ConfigTag = {}));
export var ConnectivitySettingsButtonType;
(function (ConnectivitySettingsButtonType) {
  ConnectivitySettingsButtonType["BACK"] = "BACK";
  ConnectivitySettingsButtonType["CANCEL"] = "CANCEL";
  ConnectivitySettingsButtonType["CHANGE"] = "CHANGE";
  ConnectivitySettingsButtonType["CONFIRM"] = "CONFIRM";
  ConnectivitySettingsButtonType["CONNECT"] = "CONNECT";
  ConnectivitySettingsButtonType["DISCONNECT"] = "DISCONNECT";
  ConnectivitySettingsButtonType["NEXT"] = "NEXT";
})(ConnectivitySettingsButtonType || (ConnectivitySettingsButtonType = {}));
export var ConnectivitySettingsTabName;
(function (ConnectivitySettingsTabName) {
  ConnectivitySettingsTabName["BY_PROVIDER"] = "BY_PROVIDER";
  ConnectivitySettingsTabName["BY_SERVICE"] = "BY_SERVICE";
})(ConnectivitySettingsTabName || (ConnectivitySettingsTabName = {}));
export var ContactUsOptionType;
(function (ContactUsOptionType) {
  ContactUsOptionType["CALL_BACK_WITH_AGENT"] = "CALL_BACK_WITH_AGENT";
  ContactUsOptionType["CALL_TO_AGENT"] = "CALL_TO_AGENT";
  ContactUsOptionType["CHAT_BACK_WITH_AGENT"] = "CHAT_BACK_WITH_AGENT";
  ContactUsOptionType["CHAT_WITH_AGENT"] = "CHAT_WITH_AGENT";
  ContactUsOptionType["SEND_EMAIL_AGENT"] = "SEND_EMAIL_AGENT";
})(ContactUsOptionType || (ContactUsOptionType = {}));
export var ContainerTheme;
(function (ContainerTheme) {
  ContainerTheme["DEFAULT"] = "DEFAULT";
  ContainerTheme["IMPORTANT"] = "IMPORTANT";
})(ContainerTheme || (ContainerTheme = {}));
export var ContentSource;
(function (ContentSource) {
  ContentSource["DESTINATION_API"] = "DESTINATION_API";
  ContentSource["EM"] = "EM";
})(ContentSource || (ContentSource = {}));
export var ConversationMode;
(function (ConversationMode) {
  ConversationMode["CREATE_CONVERSATION"] = "CREATE_CONVERSATION";
  ConversationMode["JOIN_CONVERSATION"] = "JOIN_CONVERSATION";
  ConversationMode["RESUME_CONVERSATION"] = "RESUME_CONVERSATION";
})(ConversationMode || (ConversationMode = {}));
export var ConversationParticipantType;
(function (ConversationParticipantType) {
  ConversationParticipantType["HumanAgent"] = "HumanAgent";
  ConversationParticipantType["HumanAgentVA"] = "HumanAgentVA";
  ConversationParticipantType["Supplier"] = "Supplier";
  ConversationParticipantType["SupplierVA"] = "SupplierVA";
  ConversationParticipantType["Traveler"] = "Traveler";
  ConversationParticipantType["TravelerVA"] = "TravelerVA";
})(ConversationParticipantType || (ConversationParticipantType = {}));
export var ConversationType;
(function (ConversationType) {
  ConversationType["AI_CONCIERGE"] = "AI_CONCIERGE";
  ConversationType["CHATGPT"] = "CHATGPT";
  ConversationType["GOOGLE_ITINERARY"] = "GOOGLE_ITINERARY";
  ConversationType["MAIN"] = "MAIN";
  ConversationType["PROPERTY_COMPARE"] = "PROPERTY_COMPARE";
  ConversationType["SUPPLIER"] = "SUPPLIER";
  ConversationType["TRIP_COLLABORATION"] = "TRIP_COLLABORATION";
})(ConversationType || (ConversationType = {}));
export var CouponCampaignStatus;
(function (CouponCampaignStatus) {
  CouponCampaignStatus["ACTIVE"] = "ACTIVE";
  CouponCampaignStatus["EXPIRED"] = "EXPIRED";
  CouponCampaignStatus["INACTIVE"] = "INACTIVE";
  CouponCampaignStatus["NOT_STARTED"] = "NOT_STARTED";
})(CouponCampaignStatus || (CouponCampaignStatus = {}));
export var CouponIdentifierType;
(function (CouponIdentifierType) {
  CouponIdentifierType["CODE"] = "CODE";
  CouponIdentifierType["INSTANCE_ID"] = "INSTANCE_ID";
})(CouponIdentifierType || (CouponIdentifierType = {}));
export var CreditCardAccountPlacementSourcePage;
(function (CreditCardAccountPlacementSourcePage) {
  CreditCardAccountPlacementSourcePage["GLOBAL_NAV"] = "GLOBAL_NAV";
  CreditCardAccountPlacementSourcePage["REWARDS"] = "REWARDS";
  CreditCardAccountPlacementSourcePage["UNIVERSAL_PROFILE"] = "UNIVERSAL_PROFILE";
})(CreditCardAccountPlacementSourcePage || (CreditCardAccountPlacementSourcePage = {}));
export var CreditCardAccountPlacementVariant;
(function (CreditCardAccountPlacementVariant) {
  CreditCardAccountPlacementVariant["MEDIUM"] = "MEDIUM";
  CreditCardAccountPlacementVariant["SMALL"] = "SMALL";
})(CreditCardAccountPlacementVariant || (CreditCardAccountPlacementVariant = {}));
export var CreditCardApplicationFormOperation;
(function (CreditCardApplicationFormOperation) {
  CreditCardApplicationFormOperation["EQ"] = "EQ";
  CreditCardApplicationFormOperation["GE"] = "GE";
  CreditCardApplicationFormOperation["GT"] = "GT";
  CreditCardApplicationFormOperation["LE"] = "LE";
  CreditCardApplicationFormOperation["LT"] = "LT";
  CreditCardApplicationFormOperation["NE"] = "NE";
  CreditCardApplicationFormOperation["NREX"] = "NREX";
  CreditCardApplicationFormOperation["REX"] = "REX";
})(CreditCardApplicationFormOperation || (CreditCardApplicationFormOperation = {}));
export var CreditCardApplicationHeadingSize;
(function (CreditCardApplicationHeadingSize) {
  CreditCardApplicationHeadingSize["LARGE"] = "LARGE";
  CreditCardApplicationHeadingSize["SMALL"] = "SMALL";
})(CreditCardApplicationHeadingSize || (CreditCardApplicationHeadingSize = {}));
export var CreditCardApplyButtonActionType;
(function (CreditCardApplyButtonActionType) {
  CreditCardApplyButtonActionType["EMBEDDED"] = "EMBEDDED";
  CreditCardApplyButtonActionType["EXTERNAL"] = "EXTERNAL";
})(CreditCardApplyButtonActionType || (CreditCardApplyButtonActionType = {}));
export var CreditCardCheckoutSignalType;
(function (CreditCardCheckoutSignalType) {
  CreditCardCheckoutSignalType["OFFER_CHANGED"] = "OFFER_CHANGED";
  CreditCardCheckoutSignalType["PRICE_ADJUSTED"] = "PRICE_ADJUSTED";
  CreditCardCheckoutSignalType["PRICE_CHANGED"] = "PRICE_CHANGED";
  CreditCardCheckoutSignalType["USER_TIER_UPGRADED"] = "USER_TIER_UPGRADED";
})(CreditCardCheckoutSignalType || (CreditCardCheckoutSignalType = {}));
export var CreditCardContentFormat;
(function (CreditCardContentFormat) {
  CreditCardContentFormat["HTML"] = "HTML";
  CreditCardContentFormat["PDF"] = "PDF";
})(CreditCardContentFormat || (CreditCardContentFormat = {}));
export var CreditCardContentSource;
(function (CreditCardContentSource) {
  CreditCardContentSource["TERMS_AND_CONDITIONS"] = "TERMS_AND_CONDITIONS";
})(CreditCardContentSource || (CreditCardContentSource = {}));
export var CreditCardDialogType;
(function (CreditCardDialogType) {
  CreditCardDialogType["EARLY_EXIT"] = "EARLY_EXIT";
})(CreditCardDialogType || (CreditCardDialogType = {}));
export var CreditCardEncodingType;
(function (CreditCardEncodingType) {
  CreditCardEncodingType["Base64"] = "Base64";
})(CreditCardEncodingType || (CreditCardEncodingType = {}));
export var CreditCardFraudOTPScreenType;
(function (CreditCardFraudOTPScreenType) {
  CreditCardFraudOTPScreenType["GENERATE_CODE"] = "GENERATE_CODE";
  CreditCardFraudOTPScreenType["VERIFY_CODE"] = "VERIFY_CODE";
})(CreditCardFraudOTPScreenType || (CreditCardFraudOTPScreenType = {}));
export var CreditCardRemoteValidationType;
(function (CreditCardRemoteValidationType) {
  CreditCardRemoteValidationType["SSN_ITIN"] = "SSN_ITIN";
})(CreditCardRemoteValidationType || (CreditCardRemoteValidationType = {}));
export var CreditCardScreen;
(function (CreditCardScreen) {
  CreditCardScreen["ERROR"] = "ERROR";
  CreditCardScreen["FRAUD_OTP_GENERATE"] = "FRAUD_OTP_GENERATE";
  CreditCardScreen["FRAUD_OTP_VERIFY"] = "FRAUD_OTP_VERIFY";
  CreditCardScreen["OFFER"] = "OFFER";
  CreditCardScreen["ORIGIN"] = "ORIGIN";
  CreditCardScreen["TERMS_AND_CONDITIONS"] = "TERMS_AND_CONDITIONS";
  CreditCardScreen["THANK_YOU_APPROVED"] = "THANK_YOU_APPROVED";
  CreditCardScreen["THANK_YOU_PENDING"] = "THANK_YOU_PENDING";
})(CreditCardScreen || (CreditCardScreen = {}));
export var CreditCardSourcePage;
(function (CreditCardSourcePage) {
  CreditCardSourcePage["APPLICATION"] = "APPLICATION";
  CreditCardSourcePage["CHECKOUT"] = "CHECKOUT";
})(CreditCardSourcePage || (CreditCardSourcePage = {}));
export var CreditCardThankYouScreenType;
(function (CreditCardThankYouScreenType) {
  CreditCardThankYouScreenType["APPROVED"] = "APPROVED";
  CreditCardThankYouScreenType["PENDING"] = "PENDING";
})(CreditCardThankYouScreenType || (CreditCardThankYouScreenType = {}));
export var CreditCardType;
(function (CreditCardType) {
  CreditCardType["FEE"] = "FEE";
  CreditCardType["NO_FEE"] = "NO_FEE";
})(CreditCardType || (CreditCardType = {}));
export var CreditEligibleFilterType;
(function (CreditEligibleFilterType) {
  CreditEligibleFilterType["CREDIT_ELIGIBLE"] = "CREDIT_ELIGIBLE";
})(CreditEligibleFilterType || (CreditEligibleFilterType = {}));
export var CrossSellProductType;
(function (CrossSellProductType) {
  CrossSellProductType["ACTIVITY"] = "ACTIVITY";
  CrossSellProductType["CAR"] = "CAR";
  CrossSellProductType["FLIGHT"] = "FLIGHT";
  CrossSellProductType["GROUND_TRANSFER"] = "GROUND_TRANSFER";
  CrossSellProductType["HOTEL"] = "HOTEL";
})(CrossSellProductType || (CrossSellProductType = {}));
export var CruiseBadgeTheme;
(function (CruiseBadgeTheme) {
  CruiseBadgeTheme["DEAL_GENERIC"] = "DEAL_GENERIC";
  CruiseBadgeTheme["DEAL_MEMBER"] = "DEAL_MEMBER";
  CruiseBadgeTheme["GLOBAL_LOYALTY_EXTRAHIGH_TIER"] = "GLOBAL_LOYALTY_EXTRAHIGH_TIER";
  CruiseBadgeTheme["GLOBAL_LOYALTY_HIGH_TIER"] = "GLOBAL_LOYALTY_HIGH_TIER";
  CruiseBadgeTheme["GLOBAL_LOYALTY_LOW_TIER"] = "GLOBAL_LOYALTY_LOW_TIER";
  CruiseBadgeTheme["GLOBAL_LOYALTY_MID_TIER"] = "GLOBAL_LOYALTY_MID_TIER";
  CruiseBadgeTheme["VIP"] = "VIP";
})(CruiseBadgeTheme || (CruiseBadgeTheme = {}));
export var CruiseFeature;
(function (CruiseFeature) {
  CruiseFeature["LIVE_SHOPPING_USING_MSC_ADAPTER"] = "LIVE_SHOPPING_USING_MSC_ADAPTER";
  CruiseFeature["MEMBER_DEALS"] = "MEMBER_DEALS";
  CruiseFeature["MEMBER_DEALS_FOR_NON_MEMBERS"] = "MEMBER_DEALS_FOR_NON_MEMBERS";
})(CruiseFeature || (CruiseFeature = {}));
export var CruiseFilterType;
(function (CruiseFilterType) {
  CruiseFilterType["CABIN"] = "CABIN";
  CruiseFilterType["DEPARTURE"] = "DEPARTURE";
  CruiseFilterType["DESTINATION"] = "DESTINATION";
  CruiseFilterType["LENGTH"] = "LENGTH";
  CruiseFilterType["NAME"] = "NAME";
  CruiseFilterType["NOT_SET"] = "NOT_SET";
  CruiseFilterType["SHIP"] = "SHIP";
  CruiseFilterType["THEME_OCEAN"] = "THEME_OCEAN";
  CruiseFilterType["THEME_RIVER"] = "THEME_RIVER";
})(CruiseFilterType || (CruiseFilterType = {}));
export var CustomerGender;
(function (CustomerGender) {
  CustomerGender["FEMALE"] = "FEMALE";
  CustomerGender["MALE"] = "MALE";
  CustomerGender["UNKNOWN"] = "UNKNOWN";
})(CustomerGender || (CustomerGender = {}));
export var CustomerNotificationFormButtonType;
(function (CustomerNotificationFormButtonType) {
  CustomerNotificationFormButtonType["FLOATING_ACTION_BUTTON"] = "FLOATING_ACTION_BUTTON";
  CustomerNotificationFormButtonType["PRIMARY_BUTTON"] = "PRIMARY_BUTTON";
  CustomerNotificationFormButtonType["SECONDARY_BUTTON"] = "SECONDARY_BUTTON";
  CustomerNotificationFormButtonType["TERTIARY_BUTTON"] = "TERTIARY_BUTTON";
})(CustomerNotificationFormButtonType || (CustomerNotificationFormButtonType = {}));
export var CustomerNotificationLinkTarget;
(function (CustomerNotificationLinkTarget) {
  CustomerNotificationLinkTarget["NEW_TAB"] = "NEW_TAB";
  CustomerNotificationLinkTarget["SAME_TAB"] = "SAME_TAB";
})(CustomerNotificationLinkTarget || (CustomerNotificationLinkTarget = {}));
export var CustomerNotificationLinkType;
(function (CustomerNotificationLinkType) {
  CustomerNotificationLinkType["ANCHOR"] = "ANCHOR";
  CustomerNotificationLinkType["APP_DOWNLOAD_LINK"] = "APP_DOWNLOAD_LINK";
  CustomerNotificationLinkType["BUTTON"] = "BUTTON";
  CustomerNotificationLinkType["FLOATING_ACTION_BUTTON"] = "FLOATING_ACTION_BUTTON";
  CustomerNotificationLinkType["PRIMARY_BUTTON"] = "PRIMARY_BUTTON";
  CustomerNotificationLinkType["SECONDARY_BUTTON"] = "SECONDARY_BUTTON";
  CustomerNotificationLinkType["TERTIARY_BUTTON"] = "TERTIARY_BUTTON";
})(CustomerNotificationLinkType || (CustomerNotificationLinkType = {}));
export var CustomerNotificationMessageDisplayType;
(function (CustomerNotificationMessageDisplayType) {
  CustomerNotificationMessageDisplayType["FORM"] = "FORM";
  CustomerNotificationMessageDisplayType["PAGE"] = "PAGE";
})(CustomerNotificationMessageDisplayType || (CustomerNotificationMessageDisplayType = {}));
export var CustomerNotificationMessageLevel;
(function (CustomerNotificationMessageLevel) {
  CustomerNotificationMessageLevel["ERROR"] = "ERROR";
  CustomerNotificationMessageLevel["SUCCESS"] = "SUCCESS";
  CustomerNotificationMessageLevel["WARNING"] = "WARNING";
})(CustomerNotificationMessageLevel || (CustomerNotificationMessageLevel = {}));
export var CustomerNotificationMessageType;
(function (CustomerNotificationMessageType) {
  CustomerNotificationMessageType["INVALID_INPUT"] = "INVALID_INPUT";
  CustomerNotificationMessageType["SERVICE_ERROR"] = "SERVICE_ERROR";
  CustomerNotificationMessageType["SUCCESS"] = "SUCCESS";
})(CustomerNotificationMessageType || (CustomerNotificationMessageType = {}));
export var CustomerNotificationPhraseLinkStyle;
(function (CustomerNotificationPhraseLinkStyle) {
  CustomerNotificationPhraseLinkStyle["FLOATING_ACTION_BUTTON"] = "FLOATING_ACTION_BUTTON";
  CustomerNotificationPhraseLinkStyle["PRIMARY_BUTTON"] = "PRIMARY_BUTTON";
  CustomerNotificationPhraseLinkStyle["SECONDARY_BUTTON"] = "SECONDARY_BUTTON";
  CustomerNotificationPhraseLinkStyle["TERTIARY_BUTTON"] = "TERTIARY_BUTTON";
})(CustomerNotificationPhraseLinkStyle || (CustomerNotificationPhraseLinkStyle = {}));
export var CustomerNotificationPhrasePartStyle;
(function (CustomerNotificationPhrasePartStyle) {
  CustomerNotificationPhrasePartStyle["EMPHASIS"] = "EMPHASIS";
  CustomerNotificationPhrasePartStyle["IMPORTANT"] = "IMPORTANT";
  CustomerNotificationPhrasePartStyle["STANDARD"] = "STANDARD";
})(CustomerNotificationPhrasePartStyle || (CustomerNotificationPhrasePartStyle = {}));
export var CustomerNotificationType;
(function (CustomerNotificationType) {
  CustomerNotificationType["APP_DOWNLOAD"] = "APP_DOWNLOAD";
  CustomerNotificationType["APP_HERO"] = "APP_HERO";
  CustomerNotificationType["CITI_COBRAND"] = "CITI_COBRAND";
  CustomerNotificationType["CITI_COBRAND_VERTICAL"] = "CITI_COBRAND_VERTICAL";
  CustomerNotificationType["CLEANLINESS"] = "CLEANLINESS";
  CustomerNotificationType["GDPR"] = "GDPR";
  CustomerNotificationType["GENERIC"] = "GENERIC";
  CustomerNotificationType["HEADER"] = "HEADER";
  CustomerNotificationType["INBOX"] = "INBOX";
  CustomerNotificationType["MAD_MARQUEE"] = "MAD_MARQUEE";
  CustomerNotificationType["NONE"] = "NONE";
  CustomerNotificationType["OSANO"] = "OSANO";
  CustomerNotificationType["TAKEOVER"] = "TAKEOVER";
  CustomerNotificationType["TRAVEL_ADVISORY"] = "TRAVEL_ADVISORY";
})(CustomerNotificationType || (CustomerNotificationType = {}));
export var DamageLiability;
(function (DamageLiability) {
  DamageLiability["COF"] = "COF";
  DamageLiability["RDD"] = "RDD";
})(DamageLiability || (DamageLiability = {}));
export var DashboardType;
(function (DashboardType) {
  DashboardType["DEFERRED_PAYMENT"] = "DEFERRED_PAYMENT";
  DashboardType["PERFORMANCE"] = "PERFORMANCE";
})(DashboardType || (DashboardType = {}));
export var DataSourceType;
(function (DataSourceType) {
  DataSourceType["CART"] = "CART";
  DataSourceType["CHECKOUT"] = "CHECKOUT";
  DataSourceType["ENTITY"] = "ENTITY";
  DataSourceType["OFFER"] = "OFFER";
  DataSourceType["ORDER"] = "ORDER";
})(DataSourceType || (DataSourceType = {}));
export var DataTableActionType;
(function (DataTableActionType) {
  DataTableActionType["REFRESH"] = "REFRESH";
  DataTableActionType["RESET"] = "RESET";
  DataTableActionType["SNAPSHOT"] = "SNAPSHOT";
})(DataTableActionType || (DataTableActionType = {}));
export var DataTableAlignItems;
(function (DataTableAlignItems) {
  DataTableAlignItems["CENTER"] = "CENTER";
  DataTableAlignItems["END"] = "END";
  DataTableAlignItems["START"] = "START";
  DataTableAlignItems["STRETCH"] = "STRETCH";
})(DataTableAlignItems || (DataTableAlignItems = {}));
export var DataTableElementsFlowDirection;
(function (DataTableElementsFlowDirection) {
  DataTableElementsFlowDirection["COLUMN"] = "COLUMN";
  DataTableElementsFlowDirection["COLUMN_REVERSE"] = "COLUMN_REVERSE";
  DataTableElementsFlowDirection["ROW"] = "ROW";
  DataTableElementsFlowDirection["ROW_REVERSE"] = "ROW_REVERSE";
})(DataTableElementsFlowDirection || (DataTableElementsFlowDirection = {}));
export var DataTableElementsJustifyContent;
(function (DataTableElementsJustifyContent) {
  DataTableElementsJustifyContent["CENTER"] = "CENTER";
  DataTableElementsJustifyContent["END"] = "END";
  DataTableElementsJustifyContent["SPACE_AROUND"] = "SPACE_AROUND";
  DataTableElementsJustifyContent["SPACE_BETWEEN"] = "SPACE_BETWEEN";
  DataTableElementsJustifyContent["START"] = "START";
})(DataTableElementsJustifyContent || (DataTableElementsJustifyContent = {}));
export var DataTableElementsSpace;
(function (DataTableElementsSpace) {
  DataTableElementsSpace["FOUR"] = "FOUR";
  DataTableElementsSpace["HALF"] = "HALF";
  DataTableElementsSpace["ONE"] = "ONE";
  DataTableElementsSpace["SIX"] = "SIX";
  DataTableElementsSpace["THREE"] = "THREE";
  DataTableElementsSpace["TWELVE"] = "TWELVE";
  DataTableElementsSpace["TWO"] = "TWO";
})(DataTableElementsSpace || (DataTableElementsSpace = {}));
export var DataTableElementsWrap;
(function (DataTableElementsWrap) {
  DataTableElementsWrap["NO_WRAP"] = "NO_WRAP";
  DataTableElementsWrap["WRAP"] = "WRAP";
  DataTableElementsWrap["WRAP_REVERSE"] = "WRAP_REVERSE";
})(DataTableElementsWrap || (DataTableElementsWrap = {}));
export var DatePeriodUnit;
(function (DatePeriodUnit) {
  DatePeriodUnit["DAY"] = "DAY";
  DatePeriodUnit["MONTH"] = "MONTH";
  DatePeriodUnit["WEEK"] = "WEEK";
  DatePeriodUnit["WEEKEND"] = "WEEKEND";
})(DatePeriodUnit || (DatePeriodUnit = {}));
export var DealType;
(function (DealType) {
  DealType["AARP"] = "AARP";
  DealType["POINTS_PROMOTION"] = "POINTS_PROMOTION";
})(DealType || (DealType = {}));
export var DealsErrorType;
(function (DealsErrorType) {
  DealsErrorType["NO_DEALS_FOUND"] = "NO_DEALS_FOUND";
  DealsErrorType["SYSTEM_ERROR"] = "SYSTEM_ERROR";
})(DealsErrorType || (DealsErrorType = {}));
export var DeeplinkType;
(function (DeeplinkType) {
  DeeplinkType["HSR"] = "HSR";
  DeeplinkType["PDP"] = "PDP";
})(DeeplinkType || (DeeplinkType = {}));
export var DeliveryPriorityType;
(function (DeliveryPriorityType) {
  DeliveryPriorityType["AUCTION"] = "AUCTION";
  DeliveryPriorityType["GUARANTEED"] = "GUARANTEED";
  DeliveryPriorityType["HOUSE_ADS"] = "HOUSE_ADS";
  DeliveryPriorityType["TAKEOVER"] = "TAKEOVER";
  DeliveryPriorityType["UNKNOWN"] = "UNKNOWN";
})(DeliveryPriorityType || (DeliveryPriorityType = {}));
export var DestinationNeighborhoodsConfigurationIdentifier;
(function (DestinationNeighborhoodsConfigurationIdentifier) {
  DestinationNeighborhoodsConfigurationIdentifier["DEFAULT"] = "DEFAULT";
  DestinationNeighborhoodsConfigurationIdentifier["TOP_NEIGHBORHOODS"] = "TOP_NEIGHBORHOODS";
})(DestinationNeighborhoodsConfigurationIdentifier || (DestinationNeighborhoodsConfigurationIdentifier = {}));
export var DestinationRecommendationPlaceType;
(function (DestinationRecommendationPlaceType) {
  DestinationRecommendationPlaceType["CITY"] = "CITY";
  DestinationRecommendationPlaceType["NEIGHBORHOOD"] = "NEIGHBORHOOD";
  DestinationRecommendationPlaceType["POI"] = "POI";
})(DestinationRecommendationPlaceType || (DestinationRecommendationPlaceType = {}));
export var DestinationRecommendationStrategy;
(function (DestinationRecommendationStrategy) {
  DestinationRecommendationStrategy["NEARBY"] = "NEARBY";
  DestinationRecommendationStrategy["SIMILAR"] = "SIMILAR";
  DestinationRecommendationStrategy["TOP"] = "TOP";
})(DestinationRecommendationStrategy || (DestinationRecommendationStrategy = {}));
export var DestinationTravelGuidePolicy;
(function (DestinationTravelGuidePolicy) {
  DestinationTravelGuidePolicy["PERSONALIZED"] = "PERSONALIZED";
  DestinationTravelGuidePolicy["PLACE_ID"] = "PLACE_ID";
  DestinationTravelGuidePolicy["USER_LOCATION"] = "USER_LOCATION";
})(DestinationTravelGuidePolicy || (DestinationTravelGuidePolicy = {}));
export var DeviceType;
(function (DeviceType) {
  DeviceType["APP_PHONE"] = "APP_PHONE";
  DeviceType["APP_TABLET"] = "APP_TABLET";
  DeviceType["CHAT_APP"] = "CHAT_APP";
  DeviceType["DESKTOP"] = "DESKTOP";
  DeviceType["MOBILE_PHONE"] = "MOBILE_PHONE";
  DeviceType["MOBILE_TABLET"] = "MOBILE_TABLET";
  DeviceType["VOICE_APP"] = "VOICE_APP";
})(DeviceType || (DeviceType = {}));
export var DialogButtonActionType;
(function (DialogButtonActionType) {
  DialogButtonActionType["CLOSE_DIALOG"] = "CLOSE_DIALOG";
  DialogButtonActionType["CREATE_TRACKING_MUTATION"] = "CREATE_TRACKING_MUTATION";
  DialogButtonActionType["DISABLE_TRACKING_MUTATION"] = "DISABLE_TRACKING_MUTATION";
  DialogButtonActionType["EMAIL_OPT_OUT"] = "EMAIL_OPT_OUT";
  DialogButtonActionType["UPDATE_TRACKING_MUTATION"] = "UPDATE_TRACKING_MUTATION";
})(DialogButtonActionType || (DialogButtonActionType = {}));
export var DialogElementType;
(function (DialogElementType) {
  DialogElementType["CLEAR_RATES"] = "CLEAR_RATES";
  DialogElementType["DELETE_QUOTES"] = "DELETE_QUOTES";
})(DialogElementType || (DialogElementType = {}));
export var DirectFeedbackMultiSelectStyle;
(function (DirectFeedbackMultiSelectStyle) {
  DirectFeedbackMultiSelectStyle["DEFAULT"] = "DEFAULT";
  DirectFeedbackMultiSelectStyle["PILL"] = "PILL";
})(DirectFeedbackMultiSelectStyle || (DirectFeedbackMultiSelectStyle = {}));
export var DirectFeedbackPageCommandOrientation;
(function (DirectFeedbackPageCommandOrientation) {
  DirectFeedbackPageCommandOrientation["HORIZONTAL"] = "HORIZONTAL";
  DirectFeedbackPageCommandOrientation["VERTICAL"] = "VERTICAL";
})(DirectFeedbackPageCommandOrientation || (DirectFeedbackPageCommandOrientation = {}));
export var DirectFeedbackPageDisplayConditionType;
(function (DirectFeedbackPageDisplayConditionType) {
  DirectFeedbackPageDisplayConditionType["BOOL"] = "BOOL";
  DirectFeedbackPageDisplayConditionType["NONE"] = "NONE";
  DirectFeedbackPageDisplayConditionType["PATTERN"] = "PATTERN";
  DirectFeedbackPageDisplayConditionType["TEXT"] = "TEXT";
})(DirectFeedbackPageDisplayConditionType || (DirectFeedbackPageDisplayConditionType = {}));
export var DirectFeedbackSheetType;
(function (DirectFeedbackSheetType) {
  DirectFeedbackSheetType["BOTTOM_SHEET"] = "BOTTOM_SHEET";
  DirectFeedbackSheetType["CENTERED_SHEET"] = "CENTERED_SHEET";
  DirectFeedbackSheetType["FULL_SHEET"] = "FULL_SHEET";
})(DirectFeedbackSheetType || (DirectFeedbackSheetType = {}));
export var DiscoveryCardContentSize;
(function (DiscoveryCardContentSize) {
  DiscoveryCardContentSize["ABBREVIATED"] = "ABBREVIATED";
  DiscoveryCardContentSize["FOUNDATION"] = "FOUNDATION";
  DiscoveryCardContentSize["FOUNDATION_DETAILS_LIST"] = "FOUNDATION_DETAILS_LIST";
  DiscoveryCardContentSize["LANDING"] = "LANDING";
  DiscoveryCardContentSize["LONG_FORM"] = "LONG_FORM";
  DiscoveryCardContentSize["LONG_FORM_FEATURED"] = "LONG_FORM_FEATURED";
  DiscoveryCardContentSize["SHORT_FORM"] = "SHORT_FORM";
  DiscoveryCardContentSize["SMART_LANDING"] = "SMART_LANDING";
  DiscoveryCardContentSize["UBER_ABBREVIATED"] = "UBER_ABBREVIATED";
  DiscoveryCardContentSize["UBER_ABBREVIATED_PRICE"] = "UBER_ABBREVIATED_PRICE";
  DiscoveryCardContentSize["UBER_ABBREVIATED_RATING"] = "UBER_ABBREVIATED_RATING";
})(DiscoveryCardContentSize || (DiscoveryCardContentSize = {}));
export var DiscoveryContainerType;
(function (DiscoveryContainerType) {
  DiscoveryContainerType["CAROUSEL"] = "CAROUSEL";
  DiscoveryContainerType["EXPANDO"] = "EXPANDO";
  DiscoveryContainerType["GRID"] = "GRID";
  DiscoveryContainerType["LIST"] = "LIST";
})(DiscoveryContainerType || (DiscoveryContainerType = {}));
export var DiscoveryOfferingType;
(function (DiscoveryOfferingType) {
  DiscoveryOfferingType["DESTINATION"] = "DESTINATION";
  DiscoveryOfferingType["PRODUCT"] = "PRODUCT";
})(DiscoveryOfferingType || (DiscoveryOfferingType = {}));
export var DiscoveryPriceStrategy;
(function (DiscoveryPriceStrategy) {
  DiscoveryPriceStrategy["DATELESS"] = "DATELESS";
  DiscoveryPriceStrategy["LIVE"] = "LIVE";
  DiscoveryPriceStrategy["NONE"] = "NONE";
})(DiscoveryPriceStrategy || (DiscoveryPriceStrategy = {}));
export var DiscoveryRecommendationInputContext;
(function (DiscoveryRecommendationInputContext) {
  DiscoveryRecommendationInputContext["DESTINATION"] = "DESTINATION";
  DiscoveryRecommendationInputContext["ORDER"] = "ORDER";
  DiscoveryRecommendationInputContext["PRODUCT"] = "PRODUCT";
  DiscoveryRecommendationInputContext["RECENTLY_VIEWED_PRODUCT"] = "RECENTLY_VIEWED_PRODUCT";
  DiscoveryRecommendationInputContext["RECENT_SEARCHES"] = "RECENT_SEARCHES";
  DiscoveryRecommendationInputContext["USER_LOCATION"] = "USER_LOCATION";
})(DiscoveryRecommendationInputContext || (DiscoveryRecommendationInputContext = {}));
export var DiscoveryRecommendationStrategy;
(function (DiscoveryRecommendationStrategy) {
  DiscoveryRecommendationStrategy["ALTERNATIVE"] = "ALTERNATIVE";
  DiscoveryRecommendationStrategy["EG_PLACES"] = "EG_PLACES";
  DiscoveryRecommendationStrategy["EG_SEARCH_NEAR_PLACE"] = "EG_SEARCH_NEAR_PLACE";
  DiscoveryRecommendationStrategy["EG_SEARCH_SELECTION"] = "EG_SEARCH_SELECTION";
  DiscoveryRecommendationStrategy["LOOKUP_AND_ASSEMBLE"] = "LOOKUP_AND_ASSEMBLE";
  DiscoveryRecommendationStrategy["NEARBY"] = "NEARBY";
  DiscoveryRecommendationStrategy["POPULAR"] = "POPULAR";
  DiscoveryRecommendationStrategy["SEM_RESULT"] = "SEM_RESULT";
  DiscoveryRecommendationStrategy["SIMILAR"] = "SIMILAR";
  DiscoveryRecommendationStrategy["SPONSORED"] = "SPONSORED";
  DiscoveryRecommendationStrategy["THEMED_POPULAR"] = "THEMED_POPULAR";
  DiscoveryRecommendationStrategy["THEMED_REFINEMENT"] = "THEMED_REFINEMENT";
  DiscoveryRecommendationStrategy["TOP_CITIES"] = "TOP_CITIES";
  DiscoveryRecommendationStrategy["TRENDING"] = "TRENDING";
})(DiscoveryRecommendationStrategy || (DiscoveryRecommendationStrategy = {}));
export var DistanceUnit;
(function (DistanceUnit) {
  DistanceUnit["KILOMETER"] = "KILOMETER";
  DistanceUnit["MILE"] = "MILE";
})(DistanceUnit || (DistanceUnit = {}));
export var DurationSpanValidationType;
(function (DurationSpanValidationType) {
  DurationSpanValidationType["MAXIMUM_GREATER_THAN_MINIMUM"] = "MAXIMUM_GREATER_THAN_MINIMUM";
  DurationSpanValidationType["MAXIMUM_VALUE"] = "MAXIMUM_VALUE";
  DurationSpanValidationType["MINIMUM_LESS_THAN_MAXIMUM"] = "MINIMUM_LESS_THAN_MAXIMUM";
  DurationSpanValidationType["MINIMUM_VALUE"] = "MINIMUM_VALUE";
  DurationSpanValidationType["REQUIRED_VALUE"] = "REQUIRED_VALUE";
})(DurationSpanValidationType || (DurationSpanValidationType = {}));
export var DynamicCardOverlayPosition;
(function (DynamicCardOverlayPosition) {
  DynamicCardOverlayPosition["BOTTOM_LEFT"] = "BOTTOM_LEFT";
  DynamicCardOverlayPosition["TOP_RIGHT"] = "TOP_RIGHT";
})(DynamicCardOverlayPosition || (DynamicCardOverlayPosition = {}));
export var EGDSButtonStyle;
(function (EGDSButtonStyle) {
  EGDSButtonStyle["PRIMARY"] = "PRIMARY";
  EGDSButtonStyle["SECONDARY"] = "SECONDARY";
  EGDSButtonStyle["TERTIARY"] = "TERTIARY";
})(EGDSButtonStyle || (EGDSButtonStyle = {}));
export var EGDSCardBackgroundTheme;
(function (EGDSCardBackgroundTheme) {
  EGDSCardBackgroundTheme["GLOBAL_LOYALTY_EXTRAHIGH"] = "GLOBAL_LOYALTY_EXTRAHIGH";
  EGDSCardBackgroundTheme["GLOBAL_LOYALTY_HIGH"] = "GLOBAL_LOYALTY_HIGH";
  EGDSCardBackgroundTheme["GLOBAL_LOYALTY_LOW"] = "GLOBAL_LOYALTY_LOW";
  EGDSCardBackgroundTheme["GLOBAL_LOYALTY_MIDDLE"] = "GLOBAL_LOYALTY_MIDDLE";
  EGDSCardBackgroundTheme["GLOBAL_LOYALTY_STANDARD"] = "GLOBAL_LOYALTY_STANDARD";
  EGDSCardBackgroundTheme["HIGHLIGHT"] = "HIGHLIGHT";
  EGDSCardBackgroundTheme["PRIMARY"] = "PRIMARY";
  EGDSCardBackgroundTheme["SECONDARY"] = "SECONDARY";
})(EGDSCardBackgroundTheme || (EGDSCardBackgroundTheme = {}));
export var EGDSCardThemes;
(function (EGDSCardThemes) {
  EGDSCardThemes["BORDERED"] = "BORDERED";
  EGDSCardThemes["PADDED"] = "PADDED";
})(EGDSCardThemes || (EGDSCardThemes = {}));
export var EGDSCheckBoxState;
(function (EGDSCheckBoxState) {
  EGDSCheckBoxState["CHECKED"] = "CHECKED";
  EGDSCheckBoxState["INDETERMINATE"] = "INDETERMINATE";
  EGDSCheckBoxState["UNCHECKED"] = "UNCHECKED";
})(EGDSCheckBoxState || (EGDSCheckBoxState = {}));
export var EGDSDateRangeResultTheme;
(function (EGDSDateRangeResultTheme) {
  EGDSDateRangeResultTheme["LINK"] = "LINK";
  EGDSDateRangeResultTheme["POSITIVE"] = "POSITIVE";
})(EGDSDateRangeResultTheme || (EGDSDateRangeResultTheme = {}));
export var EGDSDayName;
(function (EGDSDayName) {
  EGDSDayName["FRI"] = "FRI";
  EGDSDayName["MON"] = "MON";
  EGDSDayName["SAT"] = "SAT";
  EGDSDayName["SUN"] = "SUN";
  EGDSDayName["THU"] = "THU";
  EGDSDayName["TUE"] = "TUE";
  EGDSDayName["WED"] = "WED";
})(EGDSDayName || (EGDSDayName = {}));
export var EGDSDecorativeText;
(function (EGDSDecorativeText) {
  EGDSDecorativeText["HIGHLIGHT"] = "HIGHLIGHT";
  EGDSDecorativeText["LINE_THROUGH"] = "LINE_THROUGH";
  EGDSDecorativeText["SUBSCRIPT"] = "SUBSCRIPT";
  EGDSDecorativeText["SUPERSCRIPT"] = "SUPERSCRIPT";
  EGDSDecorativeText["UNDERLINE"] = "UNDERLINE";
})(EGDSDecorativeText || (EGDSDecorativeText = {}));
export var EGDSHeadingStyle;
(function (EGDSHeadingStyle) {
  EGDSHeadingStyle["H1"] = "H1";
  EGDSHeadingStyle["H2"] = "H2";
  EGDSHeadingStyle["H3"] = "H3";
  EGDSHeadingStyle["H4"] = "H4";
  EGDSHeadingStyle["H5"] = "H5";
  EGDSHeadingStyle["H6"] = "H6";
  EGDSHeadingStyle["H7"] = "H7";
  EGDSHeadingStyle["H8"] = "H8";
})(EGDSHeadingStyle || (EGDSHeadingStyle = {}));
export var EGDSIconPosition;
(function (EGDSIconPosition) {
  EGDSIconPosition["LEADING"] = "LEADING";
  EGDSIconPosition["TRAILING"] = "TRAILING";
})(EGDSIconPosition || (EGDSIconPosition = {}));
export var EGDSInfantSeat;
(function (EGDSInfantSeat) {
  EGDSInfantSeat["IN_SEAT"] = "IN_SEAT";
  EGDSInfantSeat["ON_LAP"] = "ON_LAP";
})(EGDSInfantSeat || (EGDSInfantSeat = {}));
export var EGDSLinkSize;
(function (EGDSLinkSize) {
  EGDSLinkSize["LARGE"] = "LARGE";
  EGDSLinkSize["MEDIUM"] = "MEDIUM";
  EGDSLinkSize["SMALL"] = "SMALL";
})(EGDSLinkSize || (EGDSLinkSize = {}));
export var EGDSMapFeatureType;
(function (EGDSMapFeatureType) {
  EGDSMapFeatureType["PLACE"] = "PLACE";
  EGDSMapFeatureType["PRODUCT"] = "PRODUCT";
  /** @deprecated Use product */
  EGDSMapFeatureType["PROPERTY"] = "PROPERTY";
})(EGDSMapFeatureType || (EGDSMapFeatureType = {}));
export var EGDSMapInitialViewport;
(function (EGDSMapInitialViewport) {
  EGDSMapInitialViewport["BOUNDS"] = "BOUNDS";
  EGDSMapInitialViewport["CENTERED_COMPUTED_BOUNDS"] = "CENTERED_COMPUTED_BOUNDS";
  EGDSMapInitialViewport["CENTER_AND_ZOOM"] = "CENTER_AND_ZOOM";
  EGDSMapInitialViewport["COMPUTED_BOUNDS"] = "COMPUTED_BOUNDS";
  EGDSMapInitialViewport["FIT_MARKERS"] = "FIT_MARKERS";
})(EGDSMapInitialViewport || (EGDSMapInitialViewport = {}));
export var EGDSMapMarkerStatus;
(function (EGDSMapMarkerStatus) {
  EGDSMapMarkerStatus["AVAILABLE"] = "AVAILABLE";
  EGDSMapMarkerStatus["FILTERED"] = "FILTERED";
  EGDSMapMarkerStatus["SOLD_OUT"] = "SOLD_OUT";
  EGDSMapMarkerStatus["UNKNOWN"] = "UNKNOWN";
})(EGDSMapMarkerStatus || (EGDSMapMarkerStatus = {}));
export var EGDSMapPositionMode;
(function (EGDSMapPositionMode) {
  EGDSMapPositionMode["FIT_BOUNDS"] = "FIT_BOUNDS";
  EGDSMapPositionMode["ZOOM_AND_CENTER"] = "ZOOM_AND_CENTER";
})(EGDSMapPositionMode || (EGDSMapPositionMode = {}));
export var EGDSMapProvider;
(function (EGDSMapProvider) {
  EGDSMapProvider["GOOGLE"] = "GOOGLE";
  EGDSMapProvider["MAPBOX"] = "MAPBOX";
  EGDSMapProvider["MAPKIT"] = "MAPKIT";
})(EGDSMapProvider || (EGDSMapProvider = {}));
export var EGDSMark;
(function (EGDSMark) {
  EGDSMark["AARP"] = "AARP";
  EGDSMark["AARP_MEMBER_BENEFITS"] = "AARP_MEMBER_BENEFITS";
  EGDSMark["ADDON"] = "ADDON";
  EGDSMark["AIR_NEW_ZEALAND"] = "AIR_NEW_ZEALAND";
  EGDSMark["APPLE"] = "APPLE";
  EGDSMark["APPLE_MAPS"] = "APPLE_MAPS";
  EGDSMark["APP_CTX"] = "APP_CTX";
  EGDSMark["APP_EBOOK"] = "APP_EBOOK";
  EGDSMark["APP_EXP"] = "APP_EXP";
  EGDSMark["APP_HOTWIRE"] = "APP_HOTWIRE";
  EGDSMark["APP_ORB"] = "APP_ORB";
  EGDSMark["APP_TVLY"] = "APP_TVLY";
  EGDSMark["APP_WOTIF"] = "APP_WOTIF";
  EGDSMark["BONUS_EBOOK"] = "BONUS_EBOOK";
  EGDSMark["COMMISSION_TIER_FOUR"] = "COMMISSION_TIER_FOUR";
  EGDSMark["COMMISSION_TIER_ONE"] = "COMMISSION_TIER_ONE";
  EGDSMark["COMMISSION_TIER_THREE"] = "COMMISSION_TIER_THREE";
  EGDSMark["COMMISSION_TIER_TWO"] = "COMMISSION_TIER_TWO";
  EGDSMark["FACEBOOK"] = "FACEBOOK";
  EGDSMark["FLAGS__AR"] = "FLAGS__AR";
  EGDSMark["FLAGS__AS"] = "FLAGS__AS";
  EGDSMark["FLAGS__AT"] = "FLAGS__AT";
  EGDSMark["FLAGS__AU"] = "FLAGS__AU";
  EGDSMark["FLAGS__BE"] = "FLAGS__BE";
  EGDSMark["FLAGS__BO"] = "FLAGS__BO";
  EGDSMark["FLAGS__BR"] = "FLAGS__BR";
  EGDSMark["FLAGS__BZ"] = "FLAGS__BZ";
  EGDSMark["FLAGS__CA"] = "FLAGS__CA";
  EGDSMark["FLAGS__CH"] = "FLAGS__CH";
  EGDSMark["FLAGS__CL"] = "FLAGS__CL";
  EGDSMark["FLAGS__CN"] = "FLAGS__CN";
  EGDSMark["FLAGS__CO"] = "FLAGS__CO";
  EGDSMark["FLAGS__CR"] = "FLAGS__CR";
  EGDSMark["FLAGS__CZ"] = "FLAGS__CZ";
  EGDSMark["FLAGS__DE"] = "FLAGS__DE";
  EGDSMark["FLAGS__DK"] = "FLAGS__DK";
  EGDSMark["FLAGS__EC"] = "FLAGS__EC";
  EGDSMark["FLAGS__EE"] = "FLAGS__EE";
  EGDSMark["FLAGS__EG"] = "FLAGS__EG";
  EGDSMark["FLAGS__ES"] = "FLAGS__ES";
  EGDSMark["FLAGS__EU"] = "FLAGS__EU";
  EGDSMark["FLAGS__FI"] = "FLAGS__FI";
  EGDSMark["FLAGS__FR"] = "FLAGS__FR";
  EGDSMark["FLAGS__GR"] = "FLAGS__GR";
  EGDSMark["FLAGS__GT"] = "FLAGS__GT";
  EGDSMark["FLAGS__GY"] = "FLAGS__GY";
  EGDSMark["FLAGS__HK"] = "FLAGS__HK";
  EGDSMark["FLAGS__HN"] = "FLAGS__HN";
  EGDSMark["FLAGS__HR"] = "FLAGS__HR";
  EGDSMark["FLAGS__HU"] = "FLAGS__HU";
  EGDSMark["FLAGS__ID"] = "FLAGS__ID";
  EGDSMark["FLAGS__IE"] = "FLAGS__IE";
  EGDSMark["FLAGS__IL"] = "FLAGS__IL";
  EGDSMark["FLAGS__IN"] = "FLAGS__IN";
  EGDSMark["FLAGS__IS"] = "FLAGS__IS";
  EGDSMark["FLAGS__IT"] = "FLAGS__IT";
  EGDSMark["FLAGS__JP"] = "FLAGS__JP";
  EGDSMark["FLAGS__KR"] = "FLAGS__KR";
  EGDSMark["FLAGS__L1"] = "FLAGS__L1";
  EGDSMark["FLAGS__LT"] = "FLAGS__LT";
  EGDSMark["FLAGS__LV"] = "FLAGS__LV";
  EGDSMark["FLAGS__MX"] = "FLAGS__MX";
  EGDSMark["FLAGS__MY"] = "FLAGS__MY";
  EGDSMark["FLAGS__NI"] = "FLAGS__NI";
  EGDSMark["FLAGS__NL"] = "FLAGS__NL";
  EGDSMark["FLAGS__NO"] = "FLAGS__NO";
  EGDSMark["FLAGS__NZ"] = "FLAGS__NZ";
  EGDSMark["FLAGS__PA"] = "FLAGS__PA";
  EGDSMark["FLAGS__PE"] = "FLAGS__PE";
  EGDSMark["FLAGS__PH"] = "FLAGS__PH";
  EGDSMark["FLAGS__PL"] = "FLAGS__PL";
  EGDSMark["FLAGS__PT"] = "FLAGS__PT";
  EGDSMark["FLAGS__PY"] = "FLAGS__PY";
  EGDSMark["FLAGS__RU"] = "FLAGS__RU";
  EGDSMark["FLAGS__SA"] = "FLAGS__SA";
  EGDSMark["FLAGS__SE"] = "FLAGS__SE";
  EGDSMark["FLAGS__SG"] = "FLAGS__SG";
  EGDSMark["FLAGS__SK"] = "FLAGS__SK";
  EGDSMark["FLAGS__SR"] = "FLAGS__SR";
  EGDSMark["FLAGS__SV"] = "FLAGS__SV";
  EGDSMark["FLAGS__TH"] = "FLAGS__TH";
  EGDSMark["FLAGS__TR"] = "FLAGS__TR";
  EGDSMark["FLAGS__TW"] = "FLAGS__TW";
  EGDSMark["FLAGS__UA"] = "FLAGS__UA";
  EGDSMark["FLAGS__UAE"] = "FLAGS__UAE";
  EGDSMark["FLAGS__UK"] = "FLAGS__UK";
  EGDSMark["FLAGS__US"] = "FLAGS__US";
  EGDSMark["FLAGS__UY"] = "FLAGS__UY";
  EGDSMark["FLAGS__VE"] = "FLAGS__VE";
  EGDSMark["FLAGS__VN"] = "FLAGS__VN";
  EGDSMark["FLAGS__WW"] = "FLAGS__WW";
  EGDSMark["FLAGS__ZA"] = "FLAGS__ZA";
  EGDSMark["GOOGLE"] = "GOOGLE";
  EGDSMark["GOOGLE_LOGO"] = "GOOGLE_LOGO";
  EGDSMark["GOOGLE_MAP"] = "GOOGLE_MAP";
  EGDSMark["GOOGLE_TRANSLATED"] = "GOOGLE_TRANSLATED";
  EGDSMark["HAWAIIAN"] = "HAWAIIAN";
  EGDSMark["HOTWIRE_HOTRATE_CIRCLE"] = "HOTWIRE_HOTRATE_CIRCLE";
  EGDSMark["LINE"] = "LINE";
  EGDSMark["LOYALTY_HOTELS__HIGH_TIER"] = "LOYALTY_HOTELS__HIGH_TIER";
  EGDSMark["LOYALTY_HOTELS__LOW_TIER"] = "LOYALTY_HOTELS__LOW_TIER";
  EGDSMark["LOYALTY_HOTELS__MIDDLE_TIER"] = "LOYALTY_HOTELS__MIDDLE_TIER";
  EGDSMark["LOYALTY_HOTELS__REWARDS"] = "LOYALTY_HOTELS__REWARDS";
  EGDSMark["LOYALTY_HOTELS__STAMP_EMPTY"] = "LOYALTY_HOTELS__STAMP_EMPTY";
  EGDSMark["LOYALTY_HOTELS__STAMP_PENDING"] = "LOYALTY_HOTELS__STAMP_PENDING";
  EGDSMark["LOYALTY_HOTELS__STAMP_SEARCHED"] = "LOYALTY_HOTELS__STAMP_SEARCHED";
  EGDSMark["LOYALTY_TVLY"] = "LOYALTY_TVLY";
  EGDSMark["MAPBOX"] = "MAPBOX";
  EGDSMark["MIP_PRIMARY"] = "MIP_PRIMARY";
  EGDSMark["MIP_REVERSE"] = "MIP_REVERSE";
  EGDSMark["MIP_SECONDARY"] = "MIP_SECONDARY";
  EGDSMark["MOD_CTX"] = "MOD_CTX";
  EGDSMark["MOD_EBOOK"] = "MOD_EBOOK";
  EGDSMark["MOD_EXP"] = "MOD_EXP";
  EGDSMark["MOD_HOTELS"] = "MOD_HOTELS";
  EGDSMark["MOD_ORB"] = "MOD_ORB";
  EGDSMark["MOD_TVLY"] = "MOD_TVLY";
  EGDSMark["MOD_WOTIF"] = "MOD_WOTIF";
  EGDSMark["NAVER"] = "NAVER";
  EGDSMark["ONEKEY__ABRITEL"] = "ONEKEY__ABRITEL";
  EGDSMark["ONEKEY__BLUE"] = "ONEKEY__BLUE";
  EGDSMark["ONEKEY__BOOKABACH"] = "ONEKEY__BOOKABACH";
  EGDSMark["ONEKEY__CASH"] = "ONEKEY__CASH";
  EGDSMark["ONEKEY__EXPEDIA"] = "ONEKEY__EXPEDIA";
  EGDSMark["ONEKEY__FEWODIREKT"] = "ONEKEY__FEWODIREKT";
  EGDSMark["ONEKEY__GOLD"] = "ONEKEY__GOLD";
  EGDSMark["ONEKEY__HOTELS__CHINESE"] = "ONEKEY__HOTELS__CHINESE";
  EGDSMark["ONEKEY__HOTELS__ENGLISH"] = "ONEKEY__HOTELS__ENGLISH";
  EGDSMark["ONEKEY__HOTELS__PORTUGUESE"] = "ONEKEY__HOTELS__PORTUGUESE";
  EGDSMark["ONEKEY__HOTELS__SPANISH"] = "ONEKEY__HOTELS__SPANISH";
  EGDSMark["ONEKEY__KNOCKEDOUT__ALWAYS_LIGHT"] = "ONEKEY__KNOCKEDOUT__ALWAYS_LIGHT";
  EGDSMark["ONEKEY__PLATINUM"] = "ONEKEY__PLATINUM";
  EGDSMark["ONEKEY__SILVER"] = "ONEKEY__SILVER";
  EGDSMark["ONEKEY__STANDARD"] = "ONEKEY__STANDARD";
  EGDSMark["ONEKEY__STANDARD__ALWAYS_LIGHT"] = "ONEKEY__STANDARD__ALWAYS_LIGHT";
  EGDSMark["ONEKEY__STAYZ"] = "ONEKEY__STAYZ";
  EGDSMark["ONEKEY__VRBO"] = "ONEKEY__VRBO";
  EGDSMark["ONEKEY__WORDMARK"] = "ONEKEY__WORDMARK";
  EGDSMark["ONEKEY__WORDMARK__ALWAYS_LIGHT"] = "ONEKEY__WORDMARK__ALWAYS_LIGHT";
  EGDSMark["PAYMENT__7_ELEVEN"] = "PAYMENT__7_ELEVEN";
  EGDSMark["PAYMENT__AFFIRM"] = "PAYMENT__AFFIRM";
  EGDSMark["PAYMENT__AIRPLUS"] = "PAYMENT__AIRPLUS";
  EGDSMark["PAYMENT__ALIPAY"] = "PAYMENT__ALIPAY";
  EGDSMark["PAYMENT__AMEX"] = "PAYMENT__AMEX";
  EGDSMark["PAYMENT__APPLEPAY"] = "PAYMENT__APPLEPAY";
  EGDSMark["PAYMENT__CHASE"] = "PAYMENT__CHASE";
  EGDSMark["PAYMENT__CLICKTOPAY"] = "PAYMENT__CLICKTOPAY";
  EGDSMark["PAYMENT__DINERS_CLUB_INTERNATIONAL"] = "PAYMENT__DINERS_CLUB_INTERNATIONAL";
  EGDSMark["PAYMENT__DISCOVER"] = "PAYMENT__DISCOVER";
  EGDSMark["PAYMENT__GOOGLE_PAY"] = "PAYMENT__GOOGLE_PAY";
  EGDSMark["PAYMENT__IDEAL"] = "PAYMENT__IDEAL";
  EGDSMark["PAYMENT__JAPAN_CREDIT_BUREAU"] = "PAYMENT__JAPAN_CREDIT_BUREAU";
  EGDSMark["PAYMENT__KLARNA"] = "PAYMENT__KLARNA";
  EGDSMark["PAYMENT__MAESTRO"] = "PAYMENT__MAESTRO";
  EGDSMark["PAYMENT__MASTERCARD"] = "PAYMENT__MASTERCARD";
  EGDSMark["PAYMENT__MASTERPASS"] = "PAYMENT__MASTERPASS";
  EGDSMark["PAYMENT__PAYPAL"] = "PAYMENT__PAYPAL";
  EGDSMark["PAYMENT__SAMSUNG_CARD"] = "PAYMENT__SAMSUNG_CARD";
  EGDSMark["PAYMENT__UATP"] = "PAYMENT__UATP";
  EGDSMark["PAYMENT__UNION_PAY"] = "PAYMENT__UNION_PAY";
  EGDSMark["PAYMENT__VISA"] = "PAYMENT__VISA";
  EGDSMark["PLUS_CIRCLE"] = "PLUS_CIRCLE";
  EGDSMark["PLUS_CIRCLE_CTX"] = "PLUS_CIRCLE_CTX";
  EGDSMark["PLUS_CIRCLE_EBOOK"] = "PLUS_CIRCLE_EBOOK";
  EGDSMark["PLUS_CIRCLE_ORB"] = "PLUS_CIRCLE_ORB";
  EGDSMark["PLUS_CIRCLE_TVLY"] = "PLUS_CIRCLE_TVLY";
  EGDSMark["PLUS_CIRCLE_WOTIF"] = "PLUS_CIRCLE_WOTIF";
  EGDSMark["PREMIUM_TVLY"] = "PREMIUM_TVLY";
  EGDSMark["REWARDS_ORB"] = "REWARDS_ORB";
  EGDSMark["TRIPADVISOR"] = "TRIPADVISOR";
  EGDSMark["TRIVAGO"] = "TRIVAGO";
  EGDSMark["VAP_HOTELS__HIGH_TIER"] = "VAP_HOTELS__HIGH_TIER";
  EGDSMark["VAP_HOTELS__LOW_TIER"] = "VAP_HOTELS__LOW_TIER";
  EGDSMark["VAP_HOTELS__MIDDLE_TIER"] = "VAP_HOTELS__MIDDLE_TIER";
  EGDSMark["VIP_BEX"] = "VIP_BEX";
  EGDSMark["VIP_CIRCLE_BEX"] = "VIP_CIRCLE_BEX";
  EGDSMark["VIP_CIRCLE_EBOOKERS"] = "VIP_CIRCLE_EBOOKERS";
  EGDSMark["VIP_CIRCLE_HOTELS"] = "VIP_CIRCLE_HOTELS";
  EGDSMark["VIP_CIRCLE_ORBITZ"] = "VIP_CIRCLE_ORBITZ";
  EGDSMark["VIP_EBOOKERS"] = "VIP_EBOOKERS";
  EGDSMark["VIP_ORBITZ"] = "VIP_ORBITZ";
  EGDSMark["VIRGIN_AU"] = "VIRGIN_AU";
  EGDSMark["VRBO"] = "VRBO";
})(EGDSMark || (EGDSMark = {}));
export var EGDSParagraphStyle;
(function (EGDSParagraphStyle) {
  EGDSParagraphStyle["P1"] = "P1";
  EGDSParagraphStyle["P2"] = "P2";
  EGDSParagraphStyle["P3"] = "P3";
})(EGDSParagraphStyle || (EGDSParagraphStyle = {}));
export var EGDSPathType;
(function (EGDSPathType) {
  EGDSPathType["CYCLING"] = "CYCLING";
  EGDSPathType["DRIVING"] = "DRIVING";
  EGDSPathType["FLYING"] = "FLYING";
  EGDSPathType["TRANSIT"] = "TRANSIT";
  EGDSPathType["WALKING"] = "WALKING";
})(EGDSPathType || (EGDSPathType = {}));
export var EGDSPhotosphereCameraMovement;
(function (EGDSPhotosphereCameraMovement) {
  EGDSPhotosphereCameraMovement["AUTO_PAN"] = "AUTO_PAN";
  EGDSPhotosphereCameraMovement["DISABLED"] = "DISABLED";
  EGDSPhotosphereCameraMovement["FULL_MOVE"] = "FULL_MOVE";
})(EGDSPhotosphereCameraMovement || (EGDSPhotosphereCameraMovement = {}));
export var EGDSSheetType;
(function (EGDSSheetType) {
  EGDSSheetType["BOTTOM"] = "BOTTOM";
  EGDSSheetType["CENTERED"] = "CENTERED";
  EGDSSheetType["FULLSCREEN"] = "FULLSCREEN";
  EGDSSheetType["POPOVER"] = "POPOVER";
  EGDSSheetType["SIDE"] = "SIDE";
})(EGDSSheetType || (EGDSSheetType = {}));
export var EGDSSheetTypeViewSize;
(function (EGDSSheetTypeViewSize) {
  EGDSSheetTypeViewSize["LARGE"] = "LARGE";
  EGDSSheetTypeViewSize["MEDIUM"] = "MEDIUM";
  EGDSSheetTypeViewSize["SMALL"] = "SMALL";
})(EGDSSheetTypeViewSize || (EGDSSheetTypeViewSize = {}));
export var EGDSTextListItemStyle;
(function (EGDSTextListItemStyle) {
  EGDSTextListItemStyle["DEFAULT"] = "DEFAULT";
  EGDSTextListItemStyle["EMPHASIS"] = "EMPHASIS";
  EGDSTextListItemStyle["LOYALTY"] = "LOYALTY";
  EGDSTextListItemStyle["NEGATIVE"] = "NEGATIVE";
  EGDSTextListItemStyle["POSITIVE"] = "POSITIVE";
})(EGDSTextListItemStyle || (EGDSTextListItemStyle = {}));
export var EGDSTextListSize;
(function (EGDSTextListSize) {
  EGDSTextListSize["LIST_1"] = "LIST_1";
  EGDSTextListSize["LIST_2"] = "LIST_2";
  EGDSTextListSize["LIST_3"] = "LIST_3";
})(EGDSTextListSize || (EGDSTextListSize = {}));
export var EGDSTextListType;
(function (EGDSTextListType) {
  EGDSTextListType["BULLET"] = "BULLET";
  EGDSTextListType["ICON"] = "ICON";
  EGDSTextListType["NO_BULLET"] = "NO_BULLET";
  EGDSTextListType["NUMBER"] = "NUMBER";
})(EGDSTextListType || (EGDSTextListType = {}));
export var EGDSThemeProviderColor;
(function (EGDSThemeProviderColor) {
  EGDSThemeProviderColor["BRIGHT_1"] = "BRIGHT_1";
  EGDSThemeProviderColor["DARK_1"] = "DARK_1";
  EGDSThemeProviderColor["GLOBAL_LOYALTY_EXTRA_HIGH"] = "GLOBAL_LOYALTY_EXTRA_HIGH";
  EGDSThemeProviderColor["GLOBAL_LOYALTY_EXTRA_HIGH_BRIGHT"] = "GLOBAL_LOYALTY_EXTRA_HIGH_BRIGHT";
  EGDSThemeProviderColor["GLOBAL_LOYALTY_HIGH"] = "GLOBAL_LOYALTY_HIGH";
  EGDSThemeProviderColor["GLOBAL_LOYALTY_HIGH_BRIGHT"] = "GLOBAL_LOYALTY_HIGH_BRIGHT";
  EGDSThemeProviderColor["GLOBAL_LOYALTY_LOW"] = "GLOBAL_LOYALTY_LOW";
  EGDSThemeProviderColor["GLOBAL_LOYALTY_LOW_BRIGHT"] = "GLOBAL_LOYALTY_LOW_BRIGHT";
  EGDSThemeProviderColor["GLOBAL_LOYALTY_MID"] = "GLOBAL_LOYALTY_MID";
  EGDSThemeProviderColor["GLOBAL_LOYALTY_MID_BRIGHT"] = "GLOBAL_LOYALTY_MID_BRIGHT";
  EGDSThemeProviderColor["GLOBAL_LOYALTY_STANDARD"] = "GLOBAL_LOYALTY_STANDARD";
  EGDSThemeProviderColor["LIGHT_1"] = "LIGHT_1";
  EGDSThemeProviderColor["MARKETING_1"] = "MARKETING_1";
  EGDSThemeProviderColor["MARKETING_2"] = "MARKETING_2";
  EGDSThemeProviderColor["NONE"] = "NONE";
  EGDSThemeProviderColor["STANDARD"] = "STANDARD";
})(EGDSThemeProviderColor || (EGDSThemeProviderColor = {}));
export var EGDSToolbarStyle;
(function (EGDSToolbarStyle) {
  EGDSToolbarStyle["STANDARD"] = "STANDARD";
  EGDSToolbarStyle["TRANSPARENT"] = "TRANSPARENT";
})(EGDSToolbarStyle || (EGDSToolbarStyle = {}));
export var EGDSTravelerType;
(function (EGDSTravelerType) {
  EGDSTravelerType["ADULT"] = "ADULT";
  EGDSTravelerType["CHILD"] = "CHILD";
  EGDSTravelerType["INFANT"] = "INFANT";
  EGDSTravelerType["SENIOR"] = "SENIOR";
  EGDSTravelerType["YOUTH"] = "YOUTH";
})(EGDSTravelerType || (EGDSTravelerType = {}));
export var EGDSTravelersValidationType;
(function (EGDSTravelersValidationType) {
  EGDSTravelersValidationType["CHILD_AGE_REQUIRED"] = "CHILD_AGE_REQUIRED";
  EGDSTravelersValidationType["MANY_INFANT_IN_SEAT"] = "MANY_INFANT_IN_SEAT";
  EGDSTravelersValidationType["MANY_INFANT_ON_LAP"] = "MANY_INFANT_ON_LAP";
  EGDSTravelersValidationType["MAX_TRAVELERS"] = "MAX_TRAVELERS";
})(EGDSTravelersValidationType || (EGDSTravelersValidationType = {}));
export var EGDSTypeaheadRenderType;
(function (EGDSTypeaheadRenderType) {
  EGDSTypeaheadRenderType["ADAPTIVE"] = "ADAPTIVE";
  EGDSTypeaheadRenderType["DIALOG"] = "DIALOG";
  EGDSTypeaheadRenderType["MENU"] = "MENU";
})(EGDSTypeaheadRenderType || (EGDSTypeaheadRenderType = {}));
export var EGReferrerEventType;
(function (EGReferrerEventType) {
  EGReferrerEventType["CLICK"] = "CLICK";
  EGReferrerEventType["IMPRESSION"] = "IMPRESSION";
})(EGReferrerEventType || (EGReferrerEventType = {}));
export var EMImageSize;
(function (EMImageSize) {
  EMImageSize["L"] = "L";
  EMImageSize["M"] = "M";
  EMImageSize["S"] = "S";
  EMImageSize["XL"] = "XL";
  EMImageSize["XS"] = "XS";
})(EMImageSize || (EMImageSize = {}));
export var EmptyStateType;
(function (EmptyStateType) {
  EmptyStateType["ENABLE_PUSH"] = "ENABLE_PUSH";
  EmptyStateType["EXPLORE_TRIPS"] = "EXPLORE_TRIPS";
  EmptyStateType["SIGN_IN"] = "SIGN_IN";
})(EmptyStateType || (EmptyStateType = {}));
export var EventType;
(function (EventType) {
  EventType["CancelledTripsItemDetailViewed"] = "CancelledTripsItemDetailViewed";
  EventType["DeleteTripAlertPresented"] = "DeleteTripAlertPresented";
  EventType["DeleteTripDialogPresented"] = "DeleteTripDialogPresented";
  EventType["DeleteTripErrorToastPresented"] = "DeleteTripErrorToastPresented";
  EventType["DeleteTripFailed"] = "DeleteTripFailed";
  EventType["DeleteTripSucceeded"] = "DeleteTripSucceeded";
  EventType["DeleteTripSuccessToastPresented"] = "DeleteTripSuccessToastPresented";
  EventType["HeroModuleCampaign"] = "HeroModuleCampaign";
  EventType["HeroModuleColdStart"] = "HeroModuleColdStart";
  EventType["ItinActiveCar"] = "ItinActiveCar";
  EventType["ItinActiveCruise"] = "ItinActiveCruise";
  EventType["ItinActiveFlight"] = "ItinActiveFlight";
  EventType["ItinActiveGT"] = "ItinActiveGT";
  EventType["ItinActiveHotel"] = "ItinActiveHotel";
  EventType["ItinActiveLX"] = "ItinActiveLX";
  EventType["ItinActivePackageFH"] = "ItinActivePackageFH";
  EventType["ItinActiveRail"] = "ItinActiveRail";
  EventType["ItinCancellation"] = "ItinCancellation";
  EventType["MoveItemDrawerPresented"] = "MoveItemDrawerPresented";
  EventType["MoveItemErrorToastPresented"] = "MoveItemErrorToastPresented";
  EventType["MoveItemSubmitted"] = "MoveItemSubmitted";
  EventType["MoveItemSuccessToastPresented"] = "MoveItemSuccessToastPresented";
  EventType["MoveItemSuceeded"] = "MoveItemSuceeded";
  EventType["MoveLinkedItemsDialogPresented"] = "MoveLinkedItemsDialogPresented";
  EventType["RemoveItemDialogPresented"] = "RemoveItemDialogPresented";
  EventType["TripItemMenuPresented"] = "TripItemMenuPresented";
  EventType["TripSignInDialogPresented"] = "TripSignInDialogPresented";
  EventType["TripsCancelledItemDetailViewed"] = "TripsCancelledItemDetailViewed";
  EventType["TripsCollabInviteConfirmationViewed"] = "TripsCollabInviteConfirmationViewed";
  EventType["TripsCollabInviteFailureViewed"] = "TripsCollabInviteFailureViewed";
  EventType["TripsCollabInviteSucceeded"] = "TripsCollabInviteSucceeded";
  EventType["TripsCollabJoinGroupFailed"] = "TripsCollabJoinGroupFailed";
  EventType["TripsCollabJoinGroupSucceeded"] = "TripsCollabJoinGroupSucceeded";
  EventType["TripsCollabJoinTripConfirmationViewed"] = "TripsCollabJoinTripConfirmationViewed";
  EventType["TripsCollabJoinTripErrorToastPresented"] = "TripsCollabJoinTripErrorToastPresented";
  EventType["TripsCollabLandingScreenRedirectToTripOverView"] = "TripsCollabLandingScreenRedirectToTripOverView";
  EventType["TripsCollabLandingScreenViewed"] = "TripsCollabLandingScreenViewed";
  EventType["TripsCollabManageGroupViewed"] = "TripsCollabManageGroupViewed";
  EventType["TripsCollabViewPresentedActivities"] = "TripsCollabViewPresentedActivities";
  EventType["TripsCollabViewPresentedCars"] = "TripsCollabViewPresentedCars";
  EventType["TripsCollabViewPresentedCruise"] = "TripsCollabViewPresentedCruise";
  EventType["TripsCollabViewPresentedFlight"] = "TripsCollabViewPresentedFlight";
  EventType["TripsCollabViewPresentedHotel"] = "TripsCollabViewPresentedHotel";
  EventType["TripsCollabViewPresentedVacationRental"] = "TripsCollabViewPresentedVacationRental";
  EventType["TripsEditItemSucceeded"] = "TripsEditItemSucceeded";
  EventType["TripsEditTripErrorToastPresented"] = "TripsEditTripErrorToastPresented";
  EventType["TripsEditTripPresented"] = "TripsEditTripPresented";
  EventType["TripsEditTripSubmitted"] = "TripsEditTripSubmitted";
  EventType["TripsEditTripSuccessToastPresented"] = "TripsEditTripSuccessToastPresented";
  EventType["TripsFlightScheduleChangeBannerDisplayed"] = "TripsFlightScheduleChangeBannerDisplayed";
  EventType["TripsHeroModuleCurrentTrip"] = "TripsHeroModuleCurrentTrip";
  EventType["TripsHeroModulePossibleActivityTrip"] = "TripsHeroModulePossibleActivityTrip";
  EventType["TripsHeroModulePossibleAirTrip"] = "TripsHeroModulePossibleAirTrip";
  EventType["TripsHeroModulePossibleCarTrip"] = "TripsHeroModulePossibleCarTrip";
  EventType["TripsHeroModulePossibleHotelTrip"] = "TripsHeroModulePossibleHotelTrip";
  EventType["TripsHeroModulePossiblePackageHFTrip"] = "TripsHeroModulePossiblePackageHFTrip";
  EventType["TripsHeroModulePossibleTrip"] = "TripsHeroModulePossibleTrip";
  EventType["TripsHeroModulePotentialTrip"] = "TripsHeroModulePotentialTrip";
  EventType["TripsHeroModuleUpcomingTrip"] = "TripsHeroModuleUpcomingTrip";
  EventType["TripsItemDetailViewed"] = "TripsItemDetailViewed";
  EventType["TripsListViewed"] = "TripsListViewed";
  EventType["TripsModuleDestinationGuide"] = "TripsModuleDestinationGuide";
  EventType["TripsModuleRecommendedActivity"] = "TripsModuleRecommendedActivity";
  EventType["TripsModuleRecommendedCar"] = "TripsModuleRecommendedCar";
  EventType["TripsModuleRecommendedHotel"] = "TripsModuleRecommendedHotel";
  EventType["TripsOverviewViewed"] = "TripsOverviewViewed";
  EventType["TripsTravelPlannerItinNumberPresent"] = "TripsTravelPlannerItinNumberPresent";
  EventType["Unknown"] = "Unknown";
})(EventType || (EventType = {}));
export var EventsRecommendationIdType;
(function (EventsRecommendationIdType) {
  EventsRecommendationIdType["TRIP_ID"] = "TRIP_ID";
  EventsRecommendationIdType["TRIP_ITEM_ID"] = "TRIP_ITEM_ID";
})(EventsRecommendationIdType || (EventsRecommendationIdType = {}));
export var ExpLineOfBusiness;
(function (ExpLineOfBusiness) {
  ExpLineOfBusiness["ACTIVITIES"] = "ACTIVITIES";
  ExpLineOfBusiness["CAR"] = "CAR";
  ExpLineOfBusiness["CRUISES"] = "CRUISES";
  ExpLineOfBusiness["FLIGHT"] = "FLIGHT";
  ExpLineOfBusiness["GROUNDTRANSFERS"] = "GROUNDTRANSFERS";
  ExpLineOfBusiness["HOTEL"] = "HOTEL";
  ExpLineOfBusiness["PACKAGES"] = "PACKAGES";
  ExpLineOfBusiness["RAILS"] = "RAILS";
  ExpLineOfBusiness["UNCLASSIFIED"] = "UNCLASSIFIED";
})(ExpLineOfBusiness || (ExpLineOfBusiness = {}));
export var ExpandoTriggerPosition;
(function (ExpandoTriggerPosition) {
  ExpandoTriggerPosition["LEADING"] = "LEADING";
  ExpandoTriggerPosition["TRAILING"] = "TRAILING";
})(ExpandoTriggerPosition || (ExpandoTriggerPosition = {}));
export var ExperimentalActionType;
(function (ExperimentalActionType) {
  ExperimentalActionType["JUMPLINK"] = "JUMPLINK";
  ExperimentalActionType["MODAL"] = "MODAL";
  ExperimentalActionType["REDIRECT"] = "REDIRECT";
})(ExperimentalActionType || (ExperimentalActionType = {}));
export var ExperimentalContainerSize;
(function (ExperimentalContainerSize) {
  ExperimentalContainerSize["EIGHTY_EIGHT"] = "EIGHTY_EIGHT";
  ExperimentalContainerSize["FIVE_HUNDRED"] = "FIVE_HUNDRED";
  ExperimentalContainerSize["FORTY_EIGHT"] = "FORTY_EIGHT";
  ExperimentalContainerSize["NINETY_SIX"] = "NINETY_SIX";
  ExperimentalContainerSize["ONE_HUNDRED_EIGHTY"] = "ONE_HUNDRED_EIGHTY";
  ExperimentalContainerSize["ONE_HUNDRED_THIRTY"] = "ONE_HUNDRED_THIRTY";
  ExperimentalContainerSize["ONE_HUNDRED_TWELVE"] = "ONE_HUNDRED_TWELVE";
  ExperimentalContainerSize["SEVENTY"] = "SEVENTY";
  ExperimentalContainerSize["SIXTY"] = "SIXTY";
  ExperimentalContainerSize["THIRTY_SIX"] = "THIRTY_SIX";
  ExperimentalContainerSize["THREE_HUNDRED_TWELVE"] = "THREE_HUNDRED_TWELVE";
  ExperimentalContainerSize["TWELVE"] = "TWELVE";
  ExperimentalContainerSize["TWENTY_FOUR"] = "TWENTY_FOUR";
  ExperimentalContainerSize["TWO_HUNDRED_FOUR"] = "TWO_HUNDRED_FOUR";
})(ExperimentalContainerSize || (ExperimentalContainerSize = {}));
export var ExperimentalElements;
(function (ExperimentalElements) {
  ExperimentalElements["Card"] = "Card";
  ExperimentalElements["Carousel"] = "Carousel";
  /** @deprecated Deprecated: Use 'Container', replace with Container */
  ExperimentalElements["Clickable"] = "Clickable";
  ExperimentalElements["Container"] = "Container";
  ExperimentalElements["Expando"] = "Expando";
  ExperimentalElements["Grid"] = "Grid";
  ExperimentalElements["InfoSection"] = "InfoSection";
  /** @deprecated Deprecated: Use 'Container.impression', replace with Container.impression */
  ExperimentalElements["IntersectionTracker"] = "IntersectionTracker";
  ExperimentalElements["Media"] = "Media";
  ExperimentalElements["Modal"] = "Modal";
  ExperimentalElements["Rating"] = "Rating";
  ExperimentalElements["Script"] = "Script";
  ExperimentalElements["Spacing"] = "Spacing";
  ExperimentalElements["Tabs"] = "Tabs";
  ExperimentalElements["TextIconTooltip"] = "TextIconTooltip";
})(ExperimentalElements || (ExperimentalElements = {}));
export var ExperimentalFlexAlign;
(function (ExperimentalFlexAlign) {
  ExperimentalFlexAlign["BASELINE"] = "BASELINE";
  ExperimentalFlexAlign["CENTER"] = "CENTER";
  ExperimentalFlexAlign["END"] = "END";
  ExperimentalFlexAlign["START"] = "START";
  ExperimentalFlexAlign["STRETCH"] = "STRETCH";
})(ExperimentalFlexAlign || (ExperimentalFlexAlign = {}));
export var ExperimentalFlexDirection;
(function (ExperimentalFlexDirection) {
  ExperimentalFlexDirection["COLUMN"] = "COLUMN";
  ExperimentalFlexDirection["ROW"] = "ROW";
})(ExperimentalFlexDirection || (ExperimentalFlexDirection = {}));
export var ExperimentalFlexJustify;
(function (ExperimentalFlexJustify) {
  ExperimentalFlexJustify["CENTER"] = "CENTER";
  ExperimentalFlexJustify["END"] = "END";
  ExperimentalFlexJustify["START"] = "START";
})(ExperimentalFlexJustify || (ExperimentalFlexJustify = {}));
export var ExperimentalHeadingType;
(function (ExperimentalHeadingType) {
  ExperimentalHeadingType["h1"] = "h1";
  ExperimentalHeadingType["h2"] = "h2";
  ExperimentalHeadingType["h3"] = "h3";
  ExperimentalHeadingType["h4"] = "h4";
  ExperimentalHeadingType["h5"] = "h5";
  ExperimentalHeadingType["h6"] = "h6";
})(ExperimentalHeadingType || (ExperimentalHeadingType = {}));
export var ExperimentalImageFit;
(function (ExperimentalImageFit) {
  ExperimentalImageFit["CONTAIN"] = "CONTAIN";
  ExperimentalImageFit["COVER"] = "COVER";
})(ExperimentalImageFit || (ExperimentalImageFit = {}));
export var ExperimentalImageLazyload;
(function (ExperimentalImageLazyload) {
  ExperimentalImageLazyload["EAGER"] = "EAGER";
  ExperimentalImageLazyload["LAZY"] = "LAZY";
  ExperimentalImageLazyload["OFF"] = "OFF";
})(ExperimentalImageLazyload || (ExperimentalImageLazyload = {}));
export var ExperimentalImageRounded;
(function (ExperimentalImageRounded) {
  ExperimentalImageRounded["ALL"] = "ALL";
  ExperimentalImageRounded["END_END"] = "END_END";
  ExperimentalImageRounded["END_START"] = "END_START";
  ExperimentalImageRounded["FALSE"] = "FALSE";
  ExperimentalImageRounded["MAX"] = "MAX";
  ExperimentalImageRounded["START_END"] = "START_END";
  ExperimentalImageRounded["START_START"] = "START_START";
  ExperimentalImageRounded["TRUE"] = "TRUE";
})(ExperimentalImageRounded || (ExperimentalImageRounded = {}));
export var ExperimentalModalPosition;
(function (ExperimentalModalPosition) {
  ExperimentalModalPosition["LEADING"] = "LEADING";
  ExperimentalModalPosition["TRAILING"] = "TRAILING";
})(ExperimentalModalPosition || (ExperimentalModalPosition = {}));
export var ExperimentalModalType;
(function (ExperimentalModalType) {
  ExperimentalModalType["BOTTOM"] = "BOTTOM";
  ExperimentalModalType["CENTERED"] = "CENTERED";
  ExperimentalModalType["FULL"] = "FULL";
  ExperimentalModalType["POPOVER"] = "POPOVER";
  ExperimentalModalType["SIDE"] = "SIDE";
})(ExperimentalModalType || (ExperimentalModalType = {}));
export var ExperimentalOverflow;
(function (ExperimentalOverflow) {
  ExperimentalOverflow["TRUNCATE"] = "TRUNCATE";
  ExperimentalOverflow["TRUNCATE_FOUR_LINES"] = "TRUNCATE_FOUR_LINES";
  ExperimentalOverflow["TRUNCATE_THREE_LINES"] = "TRUNCATE_THREE_LINES";
  ExperimentalOverflow["TRUNCATE_TWO_LINES"] = "TRUNCATE_TWO_LINES";
  ExperimentalOverflow["WRAP"] = "WRAP";
})(ExperimentalOverflow || (ExperimentalOverflow = {}));
export var ExperimentalSpace;
(function (ExperimentalSpace) {
  ExperimentalSpace["four"] = "four";
  ExperimentalSpace["half"] = "half";
  ExperimentalSpace["one"] = "one";
  ExperimentalSpace["six"] = "six";
  ExperimentalSpace["three"] = "three";
  ExperimentalSpace["twelve"] = "twelve";
  ExperimentalSpace["two"] = "two";
})(ExperimentalSpace || (ExperimentalSpace = {}));
export var FallBackType;
(function (FallBackType) {
  FallBackType["ERROR"] = "ERROR";
})(FallBackType || (FallBackType = {}));
export var FallbackOperation;
(function (FallbackOperation) {
  FallbackOperation["APPLY_COUPON"] = "APPLY_COUPON";
  FallbackOperation["REMOVE_COUPON"] = "REMOVE_COUPON";
})(FallbackOperation || (FallbackOperation = {}));
export var FareNameAvailable;
(function (FareNameAvailable) {
  FareNameAvailable["AVAILABLE"] = "AVAILABLE";
  FareNameAvailable["NOT_AVAILABLE"] = "NOT_AVAILABLE";
})(FareNameAvailable || (FareNameAvailable = {}));
export var FareState;
(function (FareState) {
  FareState["META_UNSELECTED"] = "META_UNSELECTED";
  FareState["SELECTED"] = "SELECTED";
  FareState["SELECTED_PMP_SELECTED"] = "SELECTED_PMP_SELECTED";
  FareState["UNSELECTED"] = "UNSELECTED";
  FareState["UNSELECTED_PMP_SELECTED"] = "UNSELECTED_PMP_SELECTED";
})(FareState || (FareState = {}));
export var FastTrackActionType;
(function (FastTrackActionType) {
  FastTrackActionType["JUMPLINK"] = "JUMPLINK";
  FastTrackActionType["MODAL"] = "MODAL";
  FastTrackActionType["REDIRECT"] = "REDIRECT";
})(FastTrackActionType || (FastTrackActionType = {}));
export var FastTrackConfigTag;
(function (FastTrackConfigTag) {
  FastTrackConfigTag["H1"] = "H1";
  FastTrackConfigTag["H2"] = "H2";
  FastTrackConfigTag["H3"] = "H3";
  FastTrackConfigTag["H4"] = "H4";
  FastTrackConfigTag["H5"] = "H5";
  FastTrackConfigTag["H6"] = "H6";
})(FastTrackConfigTag || (FastTrackConfigTag = {}));
export var FastTrackContainerSize;
(function (FastTrackContainerSize) {
  FastTrackContainerSize["EIGHTY_EIGHT"] = "EIGHTY_EIGHT";
  FastTrackContainerSize["FIVE_HUNDRED"] = "FIVE_HUNDRED";
  FastTrackContainerSize["FORTY_EIGHT"] = "FORTY_EIGHT";
  FastTrackContainerSize["NINETY_SIX"] = "NINETY_SIX";
  FastTrackContainerSize["ONE_HUNDRED_EIGHTY"] = "ONE_HUNDRED_EIGHTY";
  FastTrackContainerSize["ONE_HUNDRED_THIRTY"] = "ONE_HUNDRED_THIRTY";
  FastTrackContainerSize["ONE_HUNDRED_TWELVE"] = "ONE_HUNDRED_TWELVE";
  FastTrackContainerSize["SEVENTY"] = "SEVENTY";
  FastTrackContainerSize["SIXTY"] = "SIXTY";
  FastTrackContainerSize["THIRTY_SIX"] = "THIRTY_SIX";
  FastTrackContainerSize["THREE_HUNDRED_TWELVE"] = "THREE_HUNDRED_TWELVE";
  FastTrackContainerSize["TWELVE"] = "TWELVE";
  FastTrackContainerSize["TWENTY_FOUR"] = "TWENTY_FOUR";
  FastTrackContainerSize["TWO_HUNDRED_FOUR"] = "TWO_HUNDRED_FOUR";
})(FastTrackContainerSize || (FastTrackContainerSize = {}));
export var FastTrackElements;
(function (FastTrackElements) {
  FastTrackElements["Card"] = "Card";
  FastTrackElements["Carousel"] = "Carousel";
  FastTrackElements["Clickable"] = "Clickable";
  FastTrackElements["Expando"] = "Expando";
  FastTrackElements["Grid"] = "Grid";
  FastTrackElements["InfoSection"] = "InfoSection";
  FastTrackElements["IntersectionTracker"] = "IntersectionTracker";
  FastTrackElements["Media"] = "Media";
  FastTrackElements["Modal"] = "Modal";
  FastTrackElements["Rating"] = "Rating";
  FastTrackElements["Spacing"] = "Spacing";
  FastTrackElements["Tabs"] = "Tabs";
  FastTrackElements["TextIconTooltip"] = "TextIconTooltip";
})(FastTrackElements || (FastTrackElements = {}));
export var FastTrackFlexAlign;
(function (FastTrackFlexAlign) {
  FastTrackFlexAlign["BASELINE"] = "BASELINE";
  FastTrackFlexAlign["CENTER"] = "CENTER";
  FastTrackFlexAlign["END"] = "END";
  FastTrackFlexAlign["START"] = "START";
  FastTrackFlexAlign["STRETCH"] = "STRETCH";
})(FastTrackFlexAlign || (FastTrackFlexAlign = {}));
export var FastTrackFlexDirection;
(function (FastTrackFlexDirection) {
  FastTrackFlexDirection["COLUMN"] = "COLUMN";
  FastTrackFlexDirection["ROW"] = "ROW";
})(FastTrackFlexDirection || (FastTrackFlexDirection = {}));
export var FastTrackFlexJustify;
(function (FastTrackFlexJustify) {
  FastTrackFlexJustify["CENTER"] = "CENTER";
  FastTrackFlexJustify["END"] = "END";
  FastTrackFlexJustify["START"] = "START";
})(FastTrackFlexJustify || (FastTrackFlexJustify = {}));
export var FastTrackHelperConfigMap;
(function (FastTrackHelperConfigMap) {
  FastTrackHelperConfigMap["And"] = "And";
  FastTrackHelperConfigMap["CapitalizeFirst"] = "CapitalizeFirst";
  FastTrackHelperConfigMap["DeviceTypeEvaluator"] = "DeviceTypeEvaluator";
  FastTrackHelperConfigMap["Equals"] = "Equals";
  FastTrackHelperConfigMap["EvaluateExperiment"] = "EvaluateExperiment";
  FastTrackHelperConfigMap["EvaluateExperimentAndLog"] = "EvaluateExperimentAndLog";
  FastTrackHelperConfigMap["ExperimentEvaluator"] = "ExperimentEvaluator";
  FastTrackHelperConfigMap["FormatDoubleNumber"] = "FormatDoubleNumber";
  FastTrackHelperConfigMap["FormatIntegerNumber"] = "FormatIntegerNumber";
  FastTrackHelperConfigMap["GetSystemTimeInMs"] = "GetSystemTimeInMs";
  FastTrackHelperConfigMap["GetUsableURN"] = "GetUsableURN";
  FastTrackHelperConfigMap["Greater"] = "Greater";
  FastTrackHelperConfigMap["GreaterAndEquals"] = "GreaterAndEquals";
  FastTrackHelperConfigMap["HTTPLink"] = "HTTPLink";
  FastTrackHelperConfigMap["ImpressionAnalyticsPayload"] = "ImpressionAnalyticsPayload";
  FastTrackHelperConfigMap["IsNotNullOrEmpty"] = "IsNotNullOrEmpty";
  FastTrackHelperConfigMap["IsQualifiedVrboProperty"] = "IsQualifiedVrboProperty";
  FastTrackHelperConfigMap["Join"] = "Join";
  FastTrackHelperConfigMap["Limit"] = "Limit";
  FastTrackHelperConfigMap["Localize"] = "Localize";
  FastTrackHelperConfigMap["Not"] = "Not";
  FastTrackHelperConfigMap["Or"] = "Or";
  FastTrackHelperConfigMap["Substring"] = "Substring";
})(FastTrackHelperConfigMap || (FastTrackHelperConfigMap = {}));
export var FastTrackImageFit;
(function (FastTrackImageFit) {
  FastTrackImageFit["CONTAIN"] = "CONTAIN";
  FastTrackImageFit["COVER"] = "COVER";
})(FastTrackImageFit || (FastTrackImageFit = {}));
export var FastTrackImageLazyload;
(function (FastTrackImageLazyload) {
  FastTrackImageLazyload["EAGER"] = "EAGER";
  FastTrackImageLazyload["LAZY"] = "LAZY";
  FastTrackImageLazyload["OFF"] = "OFF";
})(FastTrackImageLazyload || (FastTrackImageLazyload = {}));
export var FastTrackImageRounded;
(function (FastTrackImageRounded) {
  FastTrackImageRounded["ALL"] = "ALL";
  FastTrackImageRounded["END_END"] = "END_END";
  FastTrackImageRounded["END_START"] = "END_START";
  FastTrackImageRounded["FALSE"] = "FALSE";
  FastTrackImageRounded["MAX"] = "MAX";
  FastTrackImageRounded["START_END"] = "START_END";
  FastTrackImageRounded["START_START"] = "START_START";
  FastTrackImageRounded["TRUE"] = "TRUE";
})(FastTrackImageRounded || (FastTrackImageRounded = {}));
export var FastTrackModalNavType;
(function (FastTrackModalNavType) {
  FastTrackModalNavType["BACK"] = "BACK";
  FastTrackModalNavType["CLOSE"] = "CLOSE";
  FastTrackModalNavType["EXPAND_DOWN"] = "EXPAND_DOWN";
  FastTrackModalNavType["EXPAND_UP"] = "EXPAND_UP";
})(FastTrackModalNavType || (FastTrackModalNavType = {}));
export var FastTrackModalPosition;
(function (FastTrackModalPosition) {
  FastTrackModalPosition["LEADING"] = "LEADING";
  FastTrackModalPosition["TRAILING"] = "TRAILING";
})(FastTrackModalPosition || (FastTrackModalPosition = {}));
export var FastTrackModalSideSheetLayout;
(function (FastTrackModalSideSheetLayout) {
  FastTrackModalSideSheetLayout["EIGHT"] = "EIGHT";
  FastTrackModalSideSheetLayout["FOUR"] = "FOUR";
  FastTrackModalSideSheetLayout["ONE"] = "ONE";
  FastTrackModalSideSheetLayout["SIX"] = "SIX";
  FastTrackModalSideSheetLayout["THREE"] = "THREE";
  FastTrackModalSideSheetLayout["TWELVE"] = "TWELVE";
  FastTrackModalSideSheetLayout["TWO"] = "TWO";
})(FastTrackModalSideSheetLayout || (FastTrackModalSideSheetLayout = {}));
export var FastTrackModalType;
(function (FastTrackModalType) {
  FastTrackModalType["BOTTOM"] = "BOTTOM";
  FastTrackModalType["CENTERED"] = "CENTERED";
  FastTrackModalType["FULL"] = "FULL";
  FastTrackModalType["POPOVER"] = "POPOVER";
  FastTrackModalType["SIDE"] = "SIDE";
})(FastTrackModalType || (FastTrackModalType = {}));
export var FastTrackOverflow;
(function (FastTrackOverflow) {
  FastTrackOverflow["TRUNCATE"] = "TRUNCATE";
  FastTrackOverflow["TRUNCATE_FOUR_LINES"] = "TRUNCATE_FOUR_LINES";
  FastTrackOverflow["TRUNCATE_THREE_LINES"] = "TRUNCATE_THREE_LINES";
  FastTrackOverflow["TRUNCATE_TWO_LINES"] = "TRUNCATE_TWO_LINES";
  FastTrackOverflow["WRAP"] = "WRAP";
})(FastTrackOverflow || (FastTrackOverflow = {}));
export var FastTrackProductEntityAssetSelection;
(function (FastTrackProductEntityAssetSelection) {
  FastTrackProductEntityAssetSelection["ACCESSIBILITY_CONTENT"] = "ACCESSIBILITY_CONTENT";
  FastTrackProductEntityAssetSelection["AMENITY"] = "AMENITY";
  FastTrackProductEntityAssetSelection["EXPERIENCE_SCORE"] = "EXPERIENCE_SCORE";
  FastTrackProductEntityAssetSelection["EXPERIENCE_SCORE_WITH_TOP_RATED_ATTRIBUTES"] = "EXPERIENCE_SCORE_WITH_TOP_RATED_ATTRIBUTES";
  FastTrackProductEntityAssetSelection["FAMILY_HIGHLIGHTS"] = "FAMILY_HIGHLIGHTS";
  FastTrackProductEntityAssetSelection["HEADLINE"] = "HEADLINE";
  FastTrackProductEntityAssetSelection["IMAGE"] = "IMAGE";
  FastTrackProductEntityAssetSelection["LOCATION_DESCRIPTION"] = "LOCATION_DESCRIPTION";
  FastTrackProductEntityAssetSelection["LUXURY_STAYS"] = "LUXURY_STAYS";
  FastTrackProductEntityAssetSelection["PLACE"] = "PLACE";
  FastTrackProductEntityAssetSelection["POLICY"] = "POLICY";
  FastTrackProductEntityAssetSelection["PRODUCT_ATTRIBUTE"] = "PRODUCT_ATTRIBUTE";
  FastTrackProductEntityAssetSelection["PRODUCT_HIGHLIGHTS"] = "PRODUCT_HIGHLIGHTS";
  FastTrackProductEntityAssetSelection["PRODUCT_UNIQUENESS_HIGHLIGHTS"] = "PRODUCT_UNIQUENESS_HIGHLIGHTS";
  FastTrackProductEntityAssetSelection["PROPERTY_AMENITY_GROUPS"] = "PROPERTY_AMENITY_GROUPS";
  FastTrackProductEntityAssetSelection["RELEVANT_AMENITY_DETAILS"] = "RELEVANT_AMENITY_DETAILS";
  FastTrackProductEntityAssetSelection["REVIEW_DETAILS"] = "REVIEW_DETAILS";
  FastTrackProductEntityAssetSelection["REVIEW_IMAGES"] = "REVIEW_IMAGES";
  FastTrackProductEntityAssetSelection["REVIEW_SUMMARY"] = "REVIEW_SUMMARY";
  FastTrackProductEntityAssetSelection["REVIEW_SUMMARY_HIGHLIGHTS"] = "REVIEW_SUMMARY_HIGHLIGHTS";
  FastTrackProductEntityAssetSelection["SUPPLY_SCORE"] = "SUPPLY_SCORE";
  FastTrackProductEntityAssetSelection["TOP_RATED_DETAILS"] = "TOP_RATED_DETAILS";
  FastTrackProductEntityAssetSelection["VIRTUAL_TOUR"] = "VIRTUAL_TOUR";
})(FastTrackProductEntityAssetSelection || (FastTrackProductEntityAssetSelection = {}));
export var FastTrackProductEntityRefinementSelection;
(function (FastTrackProductEntityRefinementSelection) {
  FastTrackProductEntityRefinementSelection["EMBARGO"] = "EMBARGO";
})(FastTrackProductEntityRefinementSelection || (FastTrackProductEntityRefinementSelection = {}));
export var FastTrackSpace;
(function (FastTrackSpace) {
  FastTrackSpace["four"] = "four";
  FastTrackSpace["half"] = "half";
  FastTrackSpace["one"] = "one";
  FastTrackSpace["six"] = "six";
  FastTrackSpace["three"] = "three";
  FastTrackSpace["twelve"] = "twelve";
  FastTrackSpace["two"] = "two";
})(FastTrackSpace || (FastTrackSpace = {}));
export var FastTrackSpacingOptions;
(function (FastTrackSpacingOptions) {
  FastTrackSpacingOptions["FOUR"] = "FOUR";
  FastTrackSpacingOptions["HALF"] = "HALF";
  FastTrackSpacingOptions["ONE"] = "ONE";
  FastTrackSpacingOptions["SIX"] = "SIX";
  FastTrackSpacingOptions["SIXTEEN"] = "SIXTEEN";
  FastTrackSpacingOptions["THREE"] = "THREE";
  FastTrackSpacingOptions["TWELVE"] = "TWELVE";
  FastTrackSpacingOptions["TWO"] = "TWO";
  FastTrackSpacingOptions["UNSET"] = "UNSET";
})(FastTrackSpacingOptions || (FastTrackSpacingOptions = {}));
export var FastTrackTabType;
(function (FastTrackTabType) {
  FastTrackTabType["EQUAL_WIDTH"] = "EQUAL_WIDTH";
  FastTrackTabType["NATURAL"] = "NATURAL";
})(FastTrackTabType || (FastTrackTabType = {}));
export var FeeType;
(function (FeeType) {
  FeeType["CARD_ON_FILE_DAMAGE_LIABILITY"] = "CARD_ON_FILE_DAMAGE_LIABILITY";
  FeeType["DEFAULT"] = "DEFAULT";
  FeeType["DEFAULT_SUPPLIER_COLLECTED"] = "DEFAULT_SUPPLIER_COLLECTED";
  FeeType["GROUPED_MANDATORY_FEES"] = "GROUPED_MANDATORY_FEES";
  FeeType["L26_TAX_BREAKOUT"] = "L26_TAX_BREAKOUT";
  FeeType["NORMAL"] = "NORMAL";
  FeeType["OTHER"] = "OTHER";
  FeeType["PER_PERSON_PER_NIGHT"] = "PER_PERSON_PER_NIGHT";
  FeeType["PROPERTY"] = "PROPERTY";
  FeeType["REFUNDABLE_DAMAGE_DEPOSIT"] = "REFUNDABLE_DAMAGE_DEPOSIT";
  FeeType["RESORT"] = "RESORT";
})(FeeType || (FeeType = {}));
export var FilterPlacement;
(function (FilterPlacement) {
  FilterPlacement["OVERLAY_BOTTOM"] = "OVERLAY_BOTTOM";
  FilterPlacement["OVERLAY_TOP"] = "OVERLAY_TOP";
  FilterPlacement["TOOLBAR"] = "TOOLBAR";
})(FilterPlacement || (FilterPlacement = {}));
export var FilterPolicyType;
(function (FilterPolicyType) {
  FilterPolicyType["NO_CHANGE_FEE"] = "NO_CHANGE_FEE";
})(FilterPolicyType || (FilterPolicyType = {}));
export var FilterTravelAndBaggageType;
(function (FilterTravelAndBaggageType) {
  FilterTravelAndBaggageType["CARRY_ON_BAG"] = "CARRY_ON_BAG";
  FilterTravelAndBaggageType["FIRST_CHECKED_BAG"] = "FIRST_CHECKED_BAG";
  FilterTravelAndBaggageType["NO_CANCEL_FEE"] = "NO_CANCEL_FEE";
  FilterTravelAndBaggageType["NO_CHANGE_FEE"] = "NO_CHANGE_FEE";
  FilterTravelAndBaggageType["SEAT_CHOICE"] = "SEAT_CHOICE";
})(FilterTravelAndBaggageType || (FilterTravelAndBaggageType = {}));
export var FlavorType;
(function (FlavorType) {
  FlavorType["DEMO"] = "DEMO";
  FlavorType["INT"] = "INT";
  FlavorType["LAB"] = "LAB";
  FlavorType["PROD"] = "PROD";
  FlavorType["SANDBOX"] = "SANDBOX";
})(FlavorType || (FlavorType = {}));
export var FlexAlignItems;
(function (FlexAlignItems) {
  FlexAlignItems["CENTER"] = "CENTER";
  FlexAlignItems["END"] = "END";
  FlexAlignItems["START"] = "START";
  FlexAlignItems["STRETCH"] = "STRETCH";
})(FlexAlignItems || (FlexAlignItems = {}));
export var FlexDirection;
(function (FlexDirection) {
  FlexDirection["ROW"] = "ROW";
})(FlexDirection || (FlexDirection = {}));
export var FlexJustifyContent;
(function (FlexJustifyContent) {
  FlexJustifyContent["CENTER"] = "CENTER";
  FlexJustifyContent["END"] = "END";
  FlexJustifyContent["SPACE_AROUND"] = "SPACE_AROUND";
  FlexJustifyContent["SPACE_BETWEEN"] = "SPACE_BETWEEN";
  FlexJustifyContent["START"] = "START";
})(FlexJustifyContent || (FlexJustifyContent = {}));
export var FlexWrap;
(function (FlexWrap) {
  FlexWrap["NOWRAP"] = "NOWRAP";
})(FlexWrap || (FlexWrap = {}));
export var FlexibleSearchTripDurationType;
(function (FlexibleSearchTripDurationType) {
  FlexibleSearchTripDurationType["MONTH"] = "MONTH";
  FlexibleSearchTripDurationType["WEEK"] = "WEEK";
  FlexibleSearchTripDurationType["WEEKEND"] = "WEEKEND";
})(FlexibleSearchTripDurationType || (FlexibleSearchTripDurationType = {}));
export var FlightCabinClassInfo;
(function (FlightCabinClassInfo) {
  FlightCabinClassInfo["BUSINESS"] = "BUSINESS";
  FlightCabinClassInfo["COACH"] = "COACH";
  FlightCabinClassInfo["FIRST"] = "FIRST";
  FlightCabinClassInfo["PREMIUM_ECONOMY"] = "PREMIUM_ECONOMY";
})(FlightCabinClassInfo || (FlightCabinClassInfo = {}));
export var FlightCrossSellProductType;
(function (FlightCrossSellProductType) {
  FlightCrossSellProductType["CAR"] = "CAR";
  FlightCrossSellProductType["HOTEL"] = "HOTEL";
})(FlightCrossSellProductType || (FlightCrossSellProductType = {}));
export var FlightJourneyStatus;
(function (FlightJourneyStatus) {
  FlightJourneyStatus["FAILURE"] = "FAILURE";
  FlightJourneyStatus["SUCCESS"] = "SUCCESS";
})(FlightJourneyStatus || (FlightJourneyStatus = {}));
export var FlightPage;
(function (FlightPage) {
  FlightPage["MULTIDEST_LEG_0"] = "MULTIDEST_LEG_0";
  FlightPage["MULTIDEST_LEG_1"] = "MULTIDEST_LEG_1";
  FlightPage["MULTIDEST_LEG_2"] = "MULTIDEST_LEG_2";
  FlightPage["MULTIDEST_LEG_3"] = "MULTIDEST_LEG_3";
  FlightPage["MULTIDEST_LEG_4"] = "MULTIDEST_LEG_4";
  FlightPage["ONE_WAY"] = "ONE_WAY";
  FlightPage["ROUNDTRIP_IN"] = "ROUNDTRIP_IN";
  FlightPage["ROUNDTRIP_OUT"] = "ROUNDTRIP_OUT";
})(FlightPage || (FlightPage = {}));
export var FlightSelectableAncillaryOptionValuesType;
(function (FlightSelectableAncillaryOptionValuesType) {
  FlightSelectableAncillaryOptionValuesType["NONE"] = "NONE";
  FlightSelectableAncillaryOptionValuesType["VALID"] = "VALID";
})(FlightSelectableAncillaryOptionValuesType || (FlightSelectableAncillaryOptionValuesType = {}));
export var FlightShoppingCardDurationTimelineTheme;
(function (FlightShoppingCardDurationTimelineTheme) {
  FlightShoppingCardDurationTimelineTheme["DEFAULT"] = "DEFAULT";
  FlightShoppingCardDurationTimelineTheme["MUTE"] = "MUTE";
  FlightShoppingCardDurationTimelineTheme["SUCCESS"] = "SUCCESS";
})(FlightShoppingCardDurationTimelineTheme || (FlightShoppingCardDurationTimelineTheme = {}));
export var FlightsActionDisplayType;
(function (FlightsActionDisplayType) {
  FlightsActionDisplayType["CHECKBOX"] = "CHECKBOX";
  FlightsActionDisplayType["PRIMARY"] = "PRIMARY";
  FlightsActionDisplayType["RADIO_BUTTON"] = "RADIO_BUTTON";
  FlightsActionDisplayType["SECONDARY"] = "SECONDARY";
  FlightsActionDisplayType["SWITCH"] = "SWITCH";
  FlightsActionDisplayType["TERTIARY"] = "TERTIARY";
  FlightsActionDisplayType["TOGGLE"] = "TOGGLE";
})(FlightsActionDisplayType || (FlightsActionDisplayType = {}));
export var FlightsActionType;
(function (FlightsActionType) {
  FlightsActionType["ADD_PRICE_MATCH_PROMISE"] = "ADD_PRICE_MATCH_PROMISE";
  FlightsActionType["ALL_SEATS_SELECTED_FOR_A_LEG"] = "ALL_SEATS_SELECTED_FOR_A_LEG";
  FlightsActionType["APPLY_FARE"] = "APPLY_FARE";
  FlightsActionType["CHOOSE_FARE"] = "CHOOSE_FARE";
  FlightsActionType["CLICKING_NEXT_FLIGHT_BEFORE_FINISHING_CURRENT_FLIGHT_SEAT_SELECTION_DIALOG"] = "CLICKING_NEXT_FLIGHT_BEFORE_FINISHING_CURRENT_FLIGHT_SEAT_SELECTION_DIALOG";
  FlightsActionType["CONTINUE_BOOKING_WITHOUT_SELECTING_SEATS_DIALOG"] = "CONTINUE_BOOKING_WITHOUT_SELECTING_SEATS_DIALOG";
  FlightsActionType["COUPON_CREDIT"] = "COUPON_CREDIT";
  FlightsActionType["DISMISS"] = "DISMISS";
  FlightsActionType["ENABLE_SUGGESTED_FILTER"] = "ENABLE_SUGGESTED_FILTER";
  FlightsActionType["FETCH_SEARCH_LISTINGS"] = "FETCH_SEARCH_LISTINGS";
  FlightsActionType["GO_TO_AUTH"] = "GO_TO_AUTH";
  FlightsActionType["GO_TO_CHECKOUT"] = "GO_TO_CHECKOUT";
  FlightsActionType["GO_TO_CONFIRMATION"] = "GO_TO_CONFIRMATION";
  FlightsActionType["GO_TO_DETAILS"] = "GO_TO_DETAILS";
  FlightsActionType["GO_TO_MERCH_HUB"] = "GO_TO_MERCH_HUB";
  FlightsActionType["GO_TO_NEXT_JOURNEY"] = "GO_TO_NEXT_JOURNEY";
  FlightsActionType["GO_TO_PACKAGES"] = "GO_TO_PACKAGES";
  FlightsActionType["GO_TO_SEARCH"] = "GO_TO_SEARCH";
  FlightsActionType["GO_TO_STOREFRONT"] = "GO_TO_STOREFRONT";
  FlightsActionType["GO_TO_TRIPS"] = "GO_TO_TRIPS";
  FlightsActionType["LOAD_CROSS_SELL_DIALOG"] = "LOAD_CROSS_SELL_DIALOG";
  FlightsActionType["LOAD_STANDARD_OFFER_FARE_RULE_MODULE"] = "LOAD_STANDARD_OFFER_FARE_RULE_MODULE";
  FlightsActionType["LOAD_TAX_BREAKDOWN_DIALOG"] = "LOAD_TAX_BREAKDOWN_DIALOG";
  FlightsActionType["MEMBER_SIGNIN"] = "MEMBER_SIGNIN";
  FlightsActionType["NO_SEATS_SELECTED_FOR_A_LEG"] = "NO_SEATS_SELECTED_FOR_A_LEG";
  FlightsActionType["OPEN_BAGS_SELECTION_ALERT_DIALOG_ON_CHECKOUT"] = "OPEN_BAGS_SELECTION_ALERT_DIALOG_ON_CHECKOUT";
  FlightsActionType["OPEN_BAGS_SELECTION_SHEET"] = "OPEN_BAGS_SELECTION_SHEET";
  FlightsActionType["OPEN_BOOK_DIRECTLY_WITH_AIRLINES_DIALOG"] = "OPEN_BOOK_DIRECTLY_WITH_AIRLINES_DIALOG";
  FlightsActionType["OPEN_CREDIT_DIALOG"] = "OPEN_CREDIT_DIALOG";
  FlightsActionType["OPEN_FARE_SEAT_MAP_SEAT_SHEET"] = "OPEN_FARE_SEAT_MAP_SEAT_SHEET";
  FlightsActionType["OPEN_FARE_SEAT_MAP_VIEW"] = "OPEN_FARE_SEAT_MAP_VIEW";
  FlightsActionType["OPEN_FARE_SEAT_SELECT_VIEW"] = "OPEN_FARE_SEAT_SELECT_VIEW";
  FlightsActionType["OPEN_FLIGHT_LEG_DROP_DOWN_IN_SEAT_MAP_DIALOG"] = "OPEN_FLIGHT_LEG_DROP_DOWN_IN_SEAT_MAP_DIALOG";
  FlightsActionType["OPEN_LEGEND_INFO_SHEET_MOBILE"] = "OPEN_LEGEND_INFO_SHEET_MOBILE";
  FlightsActionType["OPEN_PRICE_SUMMARY"] = "OPEN_PRICE_SUMMARY";
  FlightsActionType["OPEN_SEAT_SELECTION_ALERT_DIALOG_ON_CHECKOUT"] = "OPEN_SEAT_SELECTION_ALERT_DIALOG_ON_CHECKOUT";
  FlightsActionType["REDIRECT_TO_AIRLINES_FOR_BOOKING"] = "REDIRECT_TO_AIRLINES_FOR_BOOKING";
  FlightsActionType["REFRESH"] = "REFRESH";
  FlightsActionType["SEARCH_FORM_ADD_JOURNEY"] = "SEARCH_FORM_ADD_JOURNEY";
  FlightsActionType["SEARCH_FORM_CLOSE_BUTTON"] = "SEARCH_FORM_CLOSE_BUTTON";
  FlightsActionType["SEARCH_FORM_REMOVE_JOURNEY"] = "SEARCH_FORM_REMOVE_JOURNEY";
  FlightsActionType["SEAT_MAP_CLOSE_WITHOUT_SELECTING_SEATS_DIALOG"] = "SEAT_MAP_CLOSE_WITHOUT_SELECTING_SEATS_DIALOG";
  FlightsActionType["SEAT_MAP_CONTINUE_BOOKING"] = "SEAT_MAP_CONTINUE_BOOKING";
  FlightsActionType["SEAT_MAP_GO_TO_NEXT_FLIGHT"] = "SEAT_MAP_GO_TO_NEXT_FLIGHT";
  FlightsActionType["SEAT_MAP_NEXT_FLIGHT"] = "SEAT_MAP_NEXT_FLIGHT";
  FlightsActionType["SEAT_MAP_SAVE_AND_EXIT"] = "SEAT_MAP_SAVE_AND_EXIT";
  FlightsActionType["SEAT_MAP_STAY_HERE"] = "SEAT_MAP_STAY_HERE";
  FlightsActionType["SEAT_MAP_STAY_ON_THIS_PAGE"] = "SEAT_MAP_STAY_ON_THIS_PAGE";
  FlightsActionType["SEAT_SELECT_DIALOG_CONTINUE_BOOKING"] = "SEAT_SELECT_DIALOG_CONTINUE_BOOKING";
  FlightsActionType["SEAT_SELECT_PREVIEW_FOR_TRAVELER"] = "SEAT_SELECT_PREVIEW_FOR_TRAVELER";
  FlightsActionType["SELECT_FLIGHT_LEG_IN_SEAT_MAP_DIALOG"] = "SELECT_FLIGHT_LEG_IN_SEAT_MAP_DIALOG";
  FlightsActionType["SELECT_SEAT"] = "SELECT_SEAT";
  FlightsActionType["SHARE_FEEDBACK"] = "SHARE_FEEDBACK";
  FlightsActionType["SHOW_FREE_PRICE_MATCH_PROMISE_DETAILS"] = "SHOW_FREE_PRICE_MATCH_PROMISE_DETAILS";
  FlightsActionType["SHOW_PRICE_MATCH_PROMISE_DETAILS"] = "SHOW_PRICE_MATCH_PROMISE_DETAILS";
  FlightsActionType["SOME_SEATS_SELECTED_FOR_A_LEG"] = "SOME_SEATS_SELECTED_FOR_A_LEG";
  FlightsActionType["SUBSCRIBE_TO_EMAILS"] = "SUBSCRIBE_TO_EMAILS";
  FlightsActionType["TOGGLE_PRICE_ALERTS"] = "TOGGLE_PRICE_ALERTS";
  FlightsActionType["UNSELECT_SEAT"] = "UNSELECT_SEAT";
  FlightsActionType["UPGRADE_TO_NEXT_FARE"] = "UPGRADE_TO_NEXT_FARE";
})(FlightsActionType || (FlightsActionType = {}));
export var FlightsAdvancedFilterType;
(function (FlightsAdvancedFilterType) {
  FlightsAdvancedFilterType["NONSTOP"] = "NONSTOP";
  FlightsAdvancedFilterType["REFUNDABLE"] = "REFUNDABLE";
})(FlightsAdvancedFilterType || (FlightsAdvancedFilterType = {}));
export var FlightsAncillaryDisplayAction;
(function (FlightsAncillaryDisplayAction) {
  FlightsAncillaryDisplayAction["NONE"] = "NONE";
  FlightsAncillaryDisplayAction["PREFETCH"] = "PREFETCH";
})(FlightsAncillaryDisplayAction || (FlightsAncillaryDisplayAction = {}));
export var FlightsAncillarySelectionAccessibilityMsgType;
(function (FlightsAncillarySelectionAccessibilityMsgType) {
  FlightsAncillarySelectionAccessibilityMsgType["SELECTION_FAILED"] = "SELECTION_FAILED";
  FlightsAncillarySelectionAccessibilityMsgType["SELECTION_SUCCESS"] = "SELECTION_SUCCESS";
})(FlightsAncillarySelectionAccessibilityMsgType || (FlightsAncillarySelectionAccessibilityMsgType = {}));
export var FlightsAncillarySelectionErrorType;
(function (FlightsAncillarySelectionErrorType) {
  FlightsAncillarySelectionErrorType["BAGGAGE_DETAILS_LOADED_FAILED"] = "BAGGAGE_DETAILS_LOADED_FAILED";
  FlightsAncillarySelectionErrorType["DETAILS_LOADING_FAILED"] = "DETAILS_LOADING_FAILED";
  FlightsAncillarySelectionErrorType["PRE_SELECTION_FAILED"] = "PRE_SELECTION_FAILED";
  FlightsAncillarySelectionErrorType["SELECTION_FAILED"] = "SELECTION_FAILED";
  FlightsAncillarySelectionErrorType["SELECTION_UNAVAILABLE"] = "SELECTION_UNAVAILABLE";
})(FlightsAncillarySelectionErrorType || (FlightsAncillarySelectionErrorType = {}));
export var FlightsAncillaryType;
(function (FlightsAncillaryType) {
  FlightsAncillaryType["BAGS"] = "BAGS";
  FlightsAncillaryType["BAGS_V2"] = "BAGS_V2";
  FlightsAncillaryType["SEATS"] = "SEATS";
})(FlightsAncillaryType || (FlightsAncillaryType = {}));
export var FlightsBadgeTheme;
(function (FlightsBadgeTheme) {
  FlightsBadgeTheme["BRAND"] = "BRAND";
  FlightsBadgeTheme["DEAL_BUNDLED"] = "DEAL_BUNDLED";
  FlightsBadgeTheme["DEAL_GENERIC"] = "DEAL_GENERIC";
  FlightsBadgeTheme["DEAL_MEMBER"] = "DEAL_MEMBER";
  FlightsBadgeTheme["FAMILY_FRIENDLY"] = "FAMILY_FRIENDLY";
  FlightsBadgeTheme["NOTIFICATION"] = "NOTIFICATION";
  FlightsBadgeTheme["SAVED"] = "SAVED";
  FlightsBadgeTheme["SPONSORED"] = "SPONSORED";
  FlightsBadgeTheme["SUPPLIER_PROMO"] = "SUPPLIER_PROMO";
  FlightsBadgeTheme["VIEWED"] = "VIEWED";
  FlightsBadgeTheme["VIP"] = "VIP";
})(FlightsBadgeTheme || (FlightsBadgeTheme = {}));
export var FlightsButtonWidth;
(function (FlightsButtonWidth) {
  FlightsButtonWidth["DEFAULT"] = "DEFAULT";
  FlightsButtonWidth["FULL_WIDTH"] = "FULL_WIDTH";
})(FlightsButtonWidth || (FlightsButtonWidth = {}));
export var FlightsCabinClass;
(function (FlightsCabinClass) {
  FlightsCabinClass["BUSINESS"] = "BUSINESS";
  FlightsCabinClass["COACH"] = "COACH";
  FlightsCabinClass["FIRST"] = "FIRST";
  FlightsCabinClass["PREMIUM_ECONOMY"] = "PREMIUM_ECONOMY";
})(FlightsCabinClass || (FlightsCabinClass = {}));
export var FlightsCardBackgroundTheme;
(function (FlightsCardBackgroundTheme) {
  FlightsCardBackgroundTheme["HIGHLIGHT"] = "HIGHLIGHT";
  FlightsCardBackgroundTheme["PRIMARY"] = "PRIMARY";
  FlightsCardBackgroundTheme["SECONDARY"] = "SECONDARY";
})(FlightsCardBackgroundTheme || (FlightsCardBackgroundTheme = {}));
export var FlightsCategorizedListSectionType;
(function (FlightsCategorizedListSectionType) {
  FlightsCategorizedListSectionType["BAG"] = "BAG";
  FlightsCategorizedListSectionType["SEAT"] = "SEAT";
})(FlightsCategorizedListSectionType || (FlightsCategorizedListSectionType = {}));
export var FlightsCrossSortType;
(function (FlightsCrossSortType) {
  FlightsCrossSortType["CABIN_CLASS"] = "CABIN_CLASS";
  FlightsCrossSortType["CANCELLATION"] = "CANCELLATION";
  FlightsCrossSortType["CARRY_ON_BAG"] = "CARRY_ON_BAG";
  FlightsCrossSortType["CHANGE_ALLOWED"] = "CHANGE_ALLOWED";
  FlightsCrossSortType["CHECKED_BAG"] = "CHECKED_BAG";
  FlightsCrossSortType["DURATION"] = "DURATION";
  FlightsCrossSortType["NUM_OF_STOPS"] = "NUM_OF_STOPS";
  FlightsCrossSortType["PRICE"] = "PRICE";
  FlightsCrossSortType["SEAT_CHOICE"] = "SEAT_CHOICE";
})(FlightsCrossSortType || (FlightsCrossSortType = {}));
export var FlightsDialogAction;
(function (FlightsDialogAction) {
  FlightsDialogAction["CLOSE"] = "CLOSE";
  FlightsDialogAction["OPEN"] = "OPEN";
  FlightsDialogAction["TOGGLE"] = "TOGGLE";
})(FlightsDialogAction || (FlightsDialogAction = {}));
export var FlightsDialogAnimation;
(function (FlightsDialogAnimation) {
  FlightsDialogAnimation["FADE"] = "FADE";
  FlightsDialogAnimation["NONE"] = "NONE";
})(FlightsDialogAnimation || (FlightsDialogAnimation = {}));
export var FlightsDialogId;
(function (FlightsDialogId) {
  FlightsDialogId["AIRLINE_CREDITS"] = "AIRLINE_CREDITS";
  FlightsDialogId["BAGGAGE_CLOSE_COMPLETE_DIALOG"] = "BAGGAGE_CLOSE_COMPLETE_DIALOG";
  /** @deprecated Use baggage close complete/incomplete dialogs */
  FlightsDialogId["BAGGAGE_CLOSE_DIALOG"] = "BAGGAGE_CLOSE_DIALOG";
  FlightsDialogId["BAGGAGE_CLOSE_INCOMPLETE_DIALOG"] = "BAGGAGE_CLOSE_INCOMPLETE_DIALOG";
  FlightsDialogId["BAGGAGE_CONTINUE_BOOKING"] = "BAGGAGE_CONTINUE_BOOKING";
  FlightsDialogId["BAGGAGE_DETAILS_DIALOG"] = "BAGGAGE_DETAILS_DIALOG";
  FlightsDialogId["BAGS_POPUP_ON_CHECKOUT"] = "BAGS_POPUP_ON_CHECKOUT";
  FlightsDialogId["CHECK_CREDITS_DIALOG"] = "CHECK_CREDITS_DIALOG";
  FlightsDialogId["CKO_REASSURANCE_DISCLAIMER_DIALOG"] = "CKO_REASSURANCE_DISCLAIMER_DIALOG";
  FlightsDialogId["FLIGHTS_DETAILS_AND_FARES"] = "FLIGHTS_DETAILS_AND_FARES";
  FlightsDialogId["FLIGHTS_DETAILS_DIALOG"] = "FLIGHTS_DETAILS_DIALOG";
  FlightsDialogId["FORCED_CHOICE_MODAL"] = "FORCED_CHOICE_MODAL";
  FlightsDialogId["FSR_EXPIRED_DEALS_DIALOG"] = "FSR_EXPIRED_DEALS_DIALOG";
  FlightsDialogId["FSR_HOW_DEALS_WORK_SHEET"] = "FSR_HOW_DEALS_WORK_SHEET";
  FlightsDialogId["FSR_OUT_DISCLAIMER_DIALOG"] = "FSR_OUT_DISCLAIMER_DIALOG";
  FlightsDialogId["HOW_CREDITS_WORK"] = "HOW_CREDITS_WORK";
  FlightsDialogId["LEAVE_TO_COUPONS"] = "LEAVE_TO_COUPONS";
  FlightsDialogId["MEMBER_SIGN_IN_DIALOG"] = "MEMBER_SIGN_IN_DIALOG";
  FlightsDialogId["MERCHANDISING_SELECTIONS_BOOKING_LOADING"] = "MERCHANDISING_SELECTIONS_BOOKING_LOADING";
  FlightsDialogId["MERCHANDISING_SELECTIONS_EXPIRY"] = "MERCHANDISING_SELECTIONS_EXPIRY";
  FlightsDialogId["MOBILE_APP_DOWNLOAD_SHEET"] = "MOBILE_APP_DOWNLOAD_SHEET";
  FlightsDialogId["ONE_CLICK_FARE_DOWNGRADE_DIALOG"] = "ONE_CLICK_FARE_DOWNGRADE_DIALOG";
  FlightsDialogId["PAID_BAGS_DIALOG"] = "PAID_BAGS_DIALOG";
  FlightsDialogId["PMP_INFO_DIALOG"] = "PMP_INFO_DIALOG";
  FlightsDialogId["PMP_ONE_KEY_WEB_DIALOG"] = "PMP_ONE_KEY_WEB_DIALOG";
  FlightsDialogId["PMP_REMOVE_DIALOG"] = "PMP_REMOVE_DIALOG";
  FlightsDialogId["SEATS_POPUP_ON_CHECKOUT"] = "SEATS_POPUP_ON_CHECKOUT";
  FlightsDialogId["SEAT_CELL_DETAILS_DIALOG"] = "SEAT_CELL_DETAILS_DIALOG";
  FlightsDialogId["SEAT_CLOSE_COMPLETE_DIALOG"] = "SEAT_CLOSE_COMPLETE_DIALOG";
  FlightsDialogId["SEAT_CONTINUE_BOOKING"] = "SEAT_CONTINUE_BOOKING";
  FlightsDialogId["SEAT_LEGEND_DIALOG"] = "SEAT_LEGEND_DIALOG";
  FlightsDialogId["SEAT_SELECTION_DIALOG"] = "SEAT_SELECTION_DIALOG";
  FlightsDialogId["TRAVELER_MISMATCH_DIALOG"] = "TRAVELER_MISMATCH_DIALOG";
})(FlightsDialogId || (FlightsDialogId = {}));
export var FlightsDialogPreAction;
(function (FlightsDialogPreAction) {
  FlightsDialogPreAction["CLOSE_ALL_DIALOGS"] = "CLOSE_ALL_DIALOGS";
})(FlightsDialogPreAction || (FlightsDialogPreAction = {}));
export var FlightsDisplayComponent;
(function (FlightsDisplayComponent) {
  FlightsDisplayComponent["SMART_NFF_ALTERNATE_SUGGESTION"] = "SMART_NFF_ALTERNATE_SUGGESTION";
})(FlightsDisplayComponent || (FlightsDisplayComponent = {}));
export var FlightsFareCalendarPriceTheme;
(function (FlightsFareCalendarPriceTheme) {
  FlightsFareCalendarPriceTheme["NEGATIVE"] = "NEGATIVE";
  FlightsFareCalendarPriceTheme["POSITIVE"] = "POSITIVE";
  FlightsFareCalendarPriceTheme["UNDEFINED"] = "UNDEFINED";
})(FlightsFareCalendarPriceTheme || (FlightsFareCalendarPriceTheme = {}));
export var FlightsFaresScrollDirection;
(function (FlightsFaresScrollDirection) {
  FlightsFaresScrollDirection["HORIZONTAL"] = "HORIZONTAL";
  FlightsFaresScrollDirection["VERTICAL"] = "VERTICAL";
})(FlightsFaresScrollDirection || (FlightsFaresScrollDirection = {}));
export var FlightsFaresSeparationType;
(function (FlightsFaresSeparationType) {
  FlightsFaresSeparationType["BASE_AND_UPSELL"] = "BASE_AND_UPSELL";
  FlightsFaresSeparationType["NONSTOP_BASE_AND_UPSELL"] = "NONSTOP_BASE_AND_UPSELL";
  FlightsFaresSeparationType["NONSTOP_ONLY_BASE"] = "NONSTOP_ONLY_BASE";
  FlightsFaresSeparationType["NONSTOP_ONLY_UPSELL"] = "NONSTOP_ONLY_UPSELL";
  FlightsFaresSeparationType["ONLY_BASE"] = "ONLY_BASE";
  FlightsFaresSeparationType["ONLY_UPSELL"] = "ONLY_UPSELL";
})(FlightsFaresSeparationType || (FlightsFaresSeparationType = {}));
export var FlightsFilterType;
(function (FlightsFilterType) {
  FlightsFilterType["ARRIVAL_AIRPORT"] = "ARRIVAL_AIRPORT";
  FlightsFilterType["ARRIVAL_TIME"] = "ARRIVAL_TIME";
  FlightsFilterType["ARRIVAL_TIME_SLIDER"] = "ARRIVAL_TIME_SLIDER";
  FlightsFilterType["CARRY_ON_BAG"] = "CARRY_ON_BAG";
  FlightsFilterType["CHECKED_BAG"] = "CHECKED_BAG";
  FlightsFilterType["CREDIT_ELIGIBLE"] = "CREDIT_ELIGIBLE";
  FlightsFilterType["DEPARTURE_AIRPORT"] = "DEPARTURE_AIRPORT";
  FlightsFilterType["DEPARTURE_TIME"] = "DEPARTURE_TIME";
  FlightsFilterType["DEPARTURE_TIME_SLIDER"] = "DEPARTURE_TIME_SLIDER";
  FlightsFilterType["DURATION"] = "DURATION";
  FlightsFilterType["FLEXIBLE_CHANGE_POLICIES"] = "FLEXIBLE_CHANGE_POLICIES";
  FlightsFilterType["LAYOVER_AIRPORT"] = "LAYOVER_AIRPORT";
  FlightsFilterType["LAYOVER_TIME_SLIDER"] = "LAYOVER_TIME_SLIDER";
  FlightsFilterType["NO_CANCEL_FEE"] = "NO_CANCEL_FEE";
  FlightsFilterType["NO_CHANGE_FEE"] = "NO_CHANGE_FEE";
  FlightsFilterType["NUM_OF_STOPS"] = "NUM_OF_STOPS";
  FlightsFilterType["PREFERRED_AIRLINE"] = "PREFERRED_AIRLINE";
  FlightsFilterType["PRICE"] = "PRICE";
  FlightsFilterType["SEAT_CHOICE"] = "SEAT_CHOICE";
  FlightsFilterType["TRAVEL_AND_BAGGAGE"] = "TRAVEL_AND_BAGGAGE";
  FlightsFilterType["TRAVEL_TIME_SLIDER"] = "TRAVEL_TIME_SLIDER";
})(FlightsFilterType || (FlightsFilterType = {}));
export var FlightsFlexibleGridType;
(function (FlightsFlexibleGridType) {
  FlightsFlexibleGridType["FLEX_SEARCH"] = "FLEX_SEARCH";
  FlightsFlexibleGridType["FLEX_SEARCH_GRID"] = "FLEX_SEARCH_GRID";
  FlightsFlexibleGridType["FLEX_SEARCH_NOT_APPLICABLE"] = "FLEX_SEARCH_NOT_APPLICABLE";
})(FlightsFlexibleGridType || (FlightsFlexibleGridType = {}));
export var FlightsFlexibleSearchHeaderCellTheme;
(function (FlightsFlexibleSearchHeaderCellTheme) {
  FlightsFlexibleSearchHeaderCellTheme["DEFAULT"] = "DEFAULT";
  FlightsFlexibleSearchHeaderCellTheme["SEARCHED_DATE_HEADER"] = "SEARCHED_DATE_HEADER";
})(FlightsFlexibleSearchHeaderCellTheme || (FlightsFlexibleSearchHeaderCellTheme = {}));
export var FlightsFlexibleSearchLegendCellTheme;
(function (FlightsFlexibleSearchLegendCellTheme) {
  FlightsFlexibleSearchLegendCellTheme["DEFAULT"] = "DEFAULT";
})(FlightsFlexibleSearchLegendCellTheme || (FlightsFlexibleSearchLegendCellTheme = {}));
export var FlightsFlexibleSearchResultCellTheme;
(function (FlightsFlexibleSearchResultCellTheme) {
  FlightsFlexibleSearchResultCellTheme["DEFAULT_PRICE"] = "DEFAULT_PRICE";
  FlightsFlexibleSearchResultCellTheme["HIGHEST_PRICE"] = "HIGHEST_PRICE";
  FlightsFlexibleSearchResultCellTheme["LOWEST_PRICE"] = "LOWEST_PRICE";
  FlightsFlexibleSearchResultCellTheme["NO_PRICE"] = "NO_PRICE";
  FlightsFlexibleSearchResultCellTheme["SEARCHED_DATE"] = "SEARCHED_DATE";
  FlightsFlexibleSearchResultCellTheme["SEARCHED_DATE_LOWEST_PRICE"] = "SEARCHED_DATE_LOWEST_PRICE";
  FlightsFlexibleSearchResultCellTheme["SEARCHED_DATE_NO_PRICE"] = "SEARCHED_DATE_NO_PRICE";
  FlightsFlexibleSearchResultCellTheme["SEARCHED_DATE_UNAVAILABLE_FLIGHTS"] = "SEARCHED_DATE_UNAVAILABLE_FLIGHTS";
  FlightsFlexibleSearchResultCellTheme["UNAVAILABLE_FLIGHTS"] = "UNAVAILABLE_FLIGHTS";
})(FlightsFlexibleSearchResultCellTheme || (FlightsFlexibleSearchResultCellTheme = {}));
export var FlightsHeadingSize;
(function (FlightsHeadingSize) {
  FlightsHeadingSize["EIGHT"] = "EIGHT";
  FlightsHeadingSize["FIVE"] = "FIVE";
  FlightsHeadingSize["FOUR"] = "FOUR";
  FlightsHeadingSize["ONE"] = "ONE";
  FlightsHeadingSize["SEVEN"] = "SEVEN";
  FlightsHeadingSize["SIX"] = "SIX";
  FlightsHeadingSize["THREE"] = "THREE";
})(FlightsHeadingSize || (FlightsHeadingSize = {}));
export var FlightsIconPosition;
(function (FlightsIconPosition) {
  FlightsIconPosition["LEADING"] = "LEADING";
  FlightsIconPosition["TRAILING"] = "TRAILING";
})(FlightsIconPosition || (FlightsIconPosition = {}));
export var FlightsLinkTarget;
(function (FlightsLinkTarget) {
  FlightsLinkTarget["EXTERNAL"] = "EXTERNAL";
  FlightsLinkTarget["INTERNAL"] = "INTERNAL";
})(FlightsLinkTarget || (FlightsLinkTarget = {}));
export var FlightsListingsViewType;
(function (FlightsListingsViewType) {
  FlightsListingsViewType["MIXED"] = "MIXED";
  FlightsListingsViewType["SINGLE"] = "SINGLE";
})(FlightsListingsViewType || (FlightsListingsViewType = {}));
export var FlightsMetricName;
(function (FlightsMetricName) {
  FlightsMetricName["DETAILS_JOURNEY_SUMMARY_SUPPLY_RESPONSE_TIME"] = "DETAILS_JOURNEY_SUMMARY_SUPPLY_RESPONSE_TIME";
  FlightsMetricName["DETAILS_PRICE_SUMMARY_SUPPLY_RESPONSE_TIME"] = "DETAILS_PRICE_SUMMARY_SUPPLY_RESPONSE_TIME";
  FlightsMetricName["LISTINGS_SUPPLY_RESPONSE_TIME"] = "LISTINGS_SUPPLY_RESPONSE_TIME";
})(FlightsMetricName || (FlightsMetricName = {}));
export var FlightsOneClickFareUpgradeCardType;
(function (FlightsOneClickFareUpgradeCardType) {
  FlightsOneClickFareUpgradeCardType["OCU"] = "OCU";
  FlightsOneClickFareUpgradeCardType["REFUNDABILITY"] = "REFUNDABILITY";
})(FlightsOneClickFareUpgradeCardType || (FlightsOneClickFareUpgradeCardType = {}));
export var FlightsPhrasePartStyle;
(function (FlightsPhrasePartStyle) {
  FlightsPhrasePartStyle["EMPHASIS"] = "EMPHASIS";
  FlightsPhrasePartStyle["IMPORTANT"] = "IMPORTANT";
  FlightsPhrasePartStyle["STANDARD"] = "STANDARD";
})(FlightsPhrasePartStyle || (FlightsPhrasePartStyle = {}));
export var FlightsPlacardDisplayType;
(function (FlightsPlacardDisplayType) {
  FlightsPlacardDisplayType["IMMEDIATE"] = "IMMEDIATE";
  FlightsPlacardDisplayType["SORT_FILTERS_SERVER_ERROR"] = "SORT_FILTERS_SERVER_ERROR";
})(FlightsPlacardDisplayType || (FlightsPlacardDisplayType = {}));
export var FlightsPlacardTheme;
(function (FlightsPlacardTheme) {
  FlightsPlacardTheme["HIGHLIGHT"] = "HIGHLIGHT";
  FlightsPlacardTheme["PRIMARY"] = "PRIMARY";
  FlightsPlacardTheme["SECONDARY"] = "SECONDARY";
  FlightsPlacardTheme["STANDARD"] = "STANDARD";
})(FlightsPlacardTheme || (FlightsPlacardTheme = {}));
export var FlightsPlacardType;
(function (FlightsPlacardType) {
  FlightsPlacardType["FREE_CANCELLATION"] = "FREE_CANCELLATION";
  FlightsPlacardType["HOTEL_ATTACH"] = "HOTEL_ATTACH";
  FlightsPlacardType["LOYALTY_REWARD"] = "LOYALTY_REWARD";
  FlightsPlacardType["NO_CHANGE_FEES"] = "NO_CHANGE_FEES";
  FlightsPlacardType["PASSPORT"] = "PASSPORT";
  FlightsPlacardType["PRICE_CHANGE"] = "PRICE_CHANGE";
  FlightsPlacardType["SPLIT_TICKET"] = "SPLIT_TICKET";
})(FlightsPlacardType || (FlightsPlacardType = {}));
export var FlightsProgressBarDisplay;
(function (FlightsProgressBarDisplay) {
  FlightsProgressBarDisplay["DISABLED"] = "DISABLED";
  FlightsProgressBarDisplay["ENABLED"] = "ENABLED";
})(FlightsProgressBarDisplay || (FlightsProgressBarDisplay = {}));
export var FlightsQueryState;
(function (FlightsQueryState) {
  FlightsQueryState["LOADED"] = "LOADED";
  FlightsQueryState["LOADING"] = "LOADING";
})(FlightsQueryState || (FlightsQueryState = {}));
export var FlightsRewardsOptionId;
(function (FlightsRewardsOptionId) {
  FlightsRewardsOptionId["ALTAIR"] = "ALTAIR";
  FlightsRewardsOptionId["AVION"] = "AVION";
  FlightsRewardsOptionId["NONAVION"] = "NONAVION";
})(FlightsRewardsOptionId || (FlightsRewardsOptionId = {}));
export var FlightsSearchCriteriaMismatch;
(function (FlightsSearchCriteriaMismatch) {
  FlightsSearchCriteriaMismatch["TRAVELER"] = "TRAVELER";
})(FlightsSearchCriteriaMismatch || (FlightsSearchCriteriaMismatch = {}));
export var FlightsSearchExperienceType;
(function (FlightsSearchExperienceType) {
  FlightsSearchExperienceType["CODESHARE"] = "CODESHARE";
  FlightsSearchExperienceType["FLIGHT_DETAILS"] = "FLIGHT_DETAILS";
  FlightsSearchExperienceType["OFFER_LISTING"] = "OFFER_LISTING";
})(FlightsSearchExperienceType || (FlightsSearchExperienceType = {}));
export var FlightsSearchFormErrorType;
(function (FlightsSearchFormErrorType) {
  FlightsSearchFormErrorType["CHILD_AGE_MISSING"] = "CHILD_AGE_MISSING";
  FlightsSearchFormErrorType["DATE_BEFORE_PREVIOUS_DATE"] = "DATE_BEFORE_PREVIOUS_DATE";
  FlightsSearchFormErrorType["DATE_NOT_IN_VALID_RANGE"] = "DATE_NOT_IN_VALID_RANGE";
  FlightsSearchFormErrorType["DATE_NOT_IN_VALID_RANGE_TO_DEFAULT_DATE"] = "DATE_NOT_IN_VALID_RANGE_TO_DEFAULT_DATE";
  FlightsSearchFormErrorType["INFANT_AGE_MISSING"] = "INFANT_AGE_MISSING";
  FlightsSearchFormErrorType["INVALID_DATE_FORMAT"] = "INVALID_DATE_FORMAT";
  FlightsSearchFormErrorType["INVALID_TRAVELER_COUNT"] = "INVALID_TRAVELER_COUNT";
  FlightsSearchFormErrorType["LESS_THAN_MINIMUM_ADULT"] = "LESS_THAN_MINIMUM_ADULT";
  FlightsSearchFormErrorType["LOCATION_EMPTY"] = "LOCATION_EMPTY";
  FlightsSearchFormErrorType["LOCATION_SAME"] = "LOCATION_SAME";
  FlightsSearchFormErrorType["MAX_STAY_DURATION_ERROR"] = "MAX_STAY_DURATION_ERROR";
  FlightsSearchFormErrorType["SAME_CITY"] = "SAME_CITY";
  FlightsSearchFormErrorType["SPECIAL_CHARACTER_NOT_ALLOWED"] = "SPECIAL_CHARACTER_NOT_ALLOWED";
  FlightsSearchFormErrorType["TOO_SHORT_AIRPORT_STRING"] = "TOO_SHORT_AIRPORT_STRING";
  FlightsSearchFormErrorType["UNATTENDED_INFANT_IN_LAP"] = "UNATTENDED_INFANT_IN_LAP";
  FlightsSearchFormErrorType["UNATTENDED_INFANT_IN_SEAT"] = "UNATTENDED_INFANT_IN_SEAT";
})(FlightsSearchFormErrorType || (FlightsSearchFormErrorType = {}));
export var FlightsSearchListingHeaderContentType;
(function (FlightsSearchListingHeaderContentType) {
  FlightsSearchListingHeaderContentType["ONE_KEY"] = "ONE_KEY";
})(FlightsSearchListingHeaderContentType || (FlightsSearchListingHeaderContentType = {}));
export var FlightsSeatCardStyles;
(function (FlightsSeatCardStyles) {
  FlightsSeatCardStyles["BORDER"] = "BORDER";
  FlightsSeatCardStyles["WITHOUT_BORDER"] = "WITHOUT_BORDER";
})(FlightsSeatCardStyles || (FlightsSeatCardStyles = {}));
export var FlightsSeatCellState;
(function (FlightsSeatCellState) {
  FlightsSeatCellState["DEFAULT"] = "DEFAULT";
  FlightsSeatCellState["HIGHLIGHTED"] = "HIGHLIGHTED";
})(FlightsSeatCellState || (FlightsSeatCellState = {}));
export var FlightsSeatCellTheme;
(function (FlightsSeatCellTheme) {
  FlightsSeatCellTheme["HIGHLIGHTED"] = "HIGHLIGHTED";
  FlightsSeatCellTheme["STANDARD"] = "STANDARD";
})(FlightsSeatCellTheme || (FlightsSeatCellTheme = {}));
export var FlightsSeatSelectionErrorType;
(function (FlightsSeatSelectionErrorType) {
  FlightsSeatSelectionErrorType["SELECTION_FAILED"] = "SELECTION_FAILED";
  FlightsSeatSelectionErrorType["SELECTION_UNAVAILABLE"] = "SELECTION_UNAVAILABLE";
})(FlightsSeatSelectionErrorType || (FlightsSeatSelectionErrorType = {}));
export var FlightsSeatsExperienceType;
(function (FlightsSeatsExperienceType) {
  FlightsSeatsExperienceType["CURRENT_SEATS_EXPERIENCE"] = "CURRENT_SEATS_EXPERIENCE";
  FlightsSeatsExperienceType["NEW_SEATS_EXPERIENCE"] = "NEW_SEATS_EXPERIENCE";
  FlightsSeatsExperienceType["SEATS_RICH_CONTENT_EXPERIENCE"] = "SEATS_RICH_CONTENT_EXPERIENCE";
})(FlightsSeatsExperienceType || (FlightsSeatsExperienceType = {}));
export var FlightsSmartShoppingButtonWidth;
(function (FlightsSmartShoppingButtonWidth) {
  FlightsSmartShoppingButtonWidth["DEFAULT"] = "DEFAULT";
  FlightsSmartShoppingButtonWidth["FULL_WIDTH"] = "FULL_WIDTH";
})(FlightsSmartShoppingButtonWidth || (FlightsSmartShoppingButtonWidth = {}));
export var FlightsSmartShoppingDialogAction;
(function (FlightsSmartShoppingDialogAction) {
  FlightsSmartShoppingDialogAction["CLOSE"] = "CLOSE";
  FlightsSmartShoppingDialogAction["OPEN"] = "OPEN";
  FlightsSmartShoppingDialogAction["TOGGLE"] = "TOGGLE";
})(FlightsSmartShoppingDialogAction || (FlightsSmartShoppingDialogAction = {}));
export var FlightsSmartShoppingDialogId;
(function (FlightsSmartShoppingDialogId) {
  FlightsSmartShoppingDialogId["AIRLINE_CREDITS"] = "AIRLINE_CREDITS";
  FlightsSmartShoppingDialogId["BAGGAGE_CLOSE_COMPLETE_DIALOG"] = "BAGGAGE_CLOSE_COMPLETE_DIALOG";
  FlightsSmartShoppingDialogId["BAGGAGE_CLOSE_INCOMPLETE_DIALOG"] = "BAGGAGE_CLOSE_INCOMPLETE_DIALOG";
  FlightsSmartShoppingDialogId["BAGGAGE_CONTINUE_BOOKING"] = "BAGGAGE_CONTINUE_BOOKING";
  FlightsSmartShoppingDialogId["BAGGAGE_DETAILS_DIALOG"] = "BAGGAGE_DETAILS_DIALOG";
  FlightsSmartShoppingDialogId["BAGS_POPUP_ON_CHECKOUT"] = "BAGS_POPUP_ON_CHECKOUT";
  FlightsSmartShoppingDialogId["CHECK_CREDITS_DIALOG"] = "CHECK_CREDITS_DIALOG";
  FlightsSmartShoppingDialogId["FLIGHTS_DETAILS_AND_FARES"] = "FLIGHTS_DETAILS_AND_FARES";
  FlightsSmartShoppingDialogId["FORCED_CHOICE_MODAL"] = "FORCED_CHOICE_MODAL";
  FlightsSmartShoppingDialogId["HOW_CREDITS_WORK"] = "HOW_CREDITS_WORK";
  FlightsSmartShoppingDialogId["LEAVE_TO_COUPONS"] = "LEAVE_TO_COUPONS";
  FlightsSmartShoppingDialogId["PAID_BAGS_DIALOG"] = "PAID_BAGS_DIALOG";
  FlightsSmartShoppingDialogId["PMP_INFO_DIALOG"] = "PMP_INFO_DIALOG";
  FlightsSmartShoppingDialogId["PMP_REMOVE_DIALOG"] = "PMP_REMOVE_DIALOG";
  FlightsSmartShoppingDialogId["SEATS_POPUP_ON_CHECKOUT"] = "SEATS_POPUP_ON_CHECKOUT";
  FlightsSmartShoppingDialogId["SEAT_CLOSE_COMPLETE_DIALOG"] = "SEAT_CLOSE_COMPLETE_DIALOG";
  FlightsSmartShoppingDialogId["SEAT_CONTINUE_BOOKING"] = "SEAT_CONTINUE_BOOKING";
  FlightsSmartShoppingDialogId["SEAT_SELECTION_DIALOG"] = "SEAT_SELECTION_DIALOG";
  FlightsSmartShoppingDialogId["TRAVELER_MISMATCH_DIALOG"] = "TRAVELER_MISMATCH_DIALOG";
})(FlightsSmartShoppingDialogId || (FlightsSmartShoppingDialogId = {}));
export var FlightsSmartShoppingIconPosition;
(function (FlightsSmartShoppingIconPosition) {
  FlightsSmartShoppingIconPosition["LEADING"] = "LEADING";
  FlightsSmartShoppingIconPosition["TRAILING"] = "TRAILING";
})(FlightsSmartShoppingIconPosition || (FlightsSmartShoppingIconPosition = {}));
export var FlightsSmartShoppingLinkTarget;
(function (FlightsSmartShoppingLinkTarget) {
  FlightsSmartShoppingLinkTarget["EXTERNAL"] = "EXTERNAL";
  FlightsSmartShoppingLinkTarget["INTERNAL"] = "INTERNAL";
})(FlightsSmartShoppingLinkTarget || (FlightsSmartShoppingLinkTarget = {}));
export var FlightsSortOrder;
(function (FlightsSortOrder) {
  FlightsSortOrder["DECREASING"] = "DECREASING";
  FlightsSortOrder["INCREASING"] = "INCREASING";
})(FlightsSortOrder || (FlightsSortOrder = {}));
export var FlightsSortType;
(function (FlightsSortType) {
  FlightsSortType["ARRIVAL"] = "ARRIVAL";
  FlightsSortType["BEST_BETS"] = "BEST_BETS";
  FlightsSortType["BEST_SORT"] = "BEST_SORT";
  FlightsSortType["CROSS_SORT"] = "CROSS_SORT";
  FlightsSortType["DEPARTURE"] = "DEPARTURE";
  FlightsSortType["DURATION"] = "DURATION";
  FlightsSortType["NONSTOP"] = "NONSTOP";
  FlightsSortType["PRICE"] = "PRICE";
  FlightsSortType["RECOMMENDED"] = "RECOMMENDED";
})(FlightsSortType || (FlightsSortType = {}));
export var FlightsStep;
(function (FlightsStep) {
  FlightsStep["BAGS"] = "BAGS";
  FlightsStep["CHECKOUT"] = "CHECKOUT";
  FlightsStep["FLIGHT_DETAILS"] = "FLIGHT_DETAILS";
  FlightsStep["SEATS"] = "SEATS";
})(FlightsStep || (FlightsStep = {}));
export var FlightsStopFilterOperation;
(function (FlightsStopFilterOperation) {
  FlightsStopFilterOperation["EQUAL"] = "EQUAL";
  FlightsStopFilterOperation["GREATER_THAN_EQUAL"] = "GREATER_THAN_EQUAL";
  FlightsStopFilterOperation["LESS_THAN_EQUAL"] = "LESS_THAN_EQUAL";
})(FlightsStopFilterOperation || (FlightsStopFilterOperation = {}));
export var FlightsTabSelectionState;
(function (FlightsTabSelectionState) {
  FlightsTabSelectionState["CURRENT"] = "CURRENT";
  FlightsTabSelectionState["SELECTED"] = "SELECTED";
  FlightsTabSelectionState["UNSELECTED"] = "UNSELECTED";
})(FlightsTabSelectionState || (FlightsTabSelectionState = {}));
export var FlightsTextSectionDecorator;
(function (FlightsTextSectionDecorator) {
  FlightsTextSectionDecorator["BOTTOMSEPARATOR"] = "BOTTOMSEPARATOR";
  FlightsTextSectionDecorator["TOPSEPARATOR"] = "TOPSEPARATOR";
})(FlightsTextSectionDecorator || (FlightsTextSectionDecorator = {}));
export var FlightsTimeOfDay;
(function (FlightsTimeOfDay) {
  FlightsTimeOfDay["AFTERNOON"] = "AFTERNOON";
  FlightsTimeOfDay["EARLY_MORNING"] = "EARLY_MORNING";
  FlightsTimeOfDay["EVENING"] = "EVENING";
  FlightsTimeOfDay["MORNING"] = "MORNING";
})(FlightsTimeOfDay || (FlightsTimeOfDay = {}));
export var FlightsTimePeriodOfDay;
(function (FlightsTimePeriodOfDay) {
  FlightsTimePeriodOfDay["AFTERNOON"] = "AFTERNOON";
  FlightsTimePeriodOfDay["EARLY_MORNING"] = "EARLY_MORNING";
  FlightsTimePeriodOfDay["EVENING"] = "EVENING";
  FlightsTimePeriodOfDay["MORNING"] = "MORNING";
})(FlightsTimePeriodOfDay || (FlightsTimePeriodOfDay = {}));
export var FlightsTravelerType;
(function (FlightsTravelerType) {
  FlightsTravelerType["ADULT"] = "ADULT";
  FlightsTravelerType["CHILD"] = "CHILD";
  FlightsTravelerType["INFANT_IN_LAP"] = "INFANT_IN_LAP";
  FlightsTravelerType["INFANT_IN_SEAT"] = "INFANT_IN_SEAT";
})(FlightsTravelerType || (FlightsTravelerType = {}));
export var FlightsTripType;
(function (FlightsTripType) {
  FlightsTripType["MULTI_DESTINATION"] = "MULTI_DESTINATION";
  FlightsTripType["ONE_WAY"] = "ONE_WAY";
  FlightsTripType["ROUND_TRIP"] = "ROUND_TRIP";
})(FlightsTripType || (FlightsTripType = {}));
export var Flow;
(function (Flow) {
  Flow["AUTOMATED_MANUAL"] = "AUTOMATED_MANUAL";
  Flow["MANUAL"] = "MANUAL";
})(Flow || (Flow = {}));
export var FlowEntrypointIdentifier;
(function (FlowEntrypointIdentifier) {
  FlowEntrypointIdentifier["PROFILE"] = "PROFILE";
  FlowEntrypointIdentifier["SETTINGS"] = "SETTINGS";
})(FlowEntrypointIdentifier || (FlowEntrypointIdentifier = {}));
export var FlowType;
(function (FlowType) {
  FlowType["AUTOMATED"] = "AUTOMATED";
  FlowType["AUTOMATED_MANUAL"] = "AUTOMATED_MANUAL";
  FlowType["MANUAL"] = "MANUAL";
})(FlowType || (FlowType = {}));
export var FollowUpActionLabels;
(function (FollowUpActionLabels) {
  FollowUpActionLabels["ACCOUNT_INFO"] = "ACCOUNT_INFO";
  FollowUpActionLabels["AGENT"] = "AGENT";
  FollowUpActionLabels["CANCEL"] = "CANCEL";
  FollowUpActionLabels["CHANGE"] = "CHANGE";
  FollowUpActionLabels["CHITCHAT"] = "CHITCHAT";
  FollowUpActionLabels["NO_BOOKING"] = "NO_BOOKING";
  FollowUpActionLabels["VIEW_BOOKING"] = "VIEW_BOOKING";
  FollowUpActionLabels["VIRTUAL_AGENT"] = "VIRTUAL_AGENT";
})(FollowUpActionLabels || (FollowUpActionLabels = {}));
export var FooterButtonType;
(function (FooterButtonType) {
  FooterButtonType["PRIMARY"] = "PRIMARY";
  FooterButtonType["SECONDARY"] = "SECONDARY";
  FooterButtonType["TERTIARY"] = "TERTIARY";
})(FooterButtonType || (FooterButtonType = {}));
export var FooterSeparatorType;
(function (FooterSeparatorType) {
  FooterSeparatorType["HORIZONTAL_RULE"] = "HORIZONTAL_RULE";
})(FooterSeparatorType || (FooterSeparatorType = {}));
export var FreeCancellationApplicability;
(function (FreeCancellationApplicability) {
  FreeCancellationApplicability["ALL_JOURNEYS"] = "ALL_JOURNEYS";
  FreeCancellationApplicability["CURRENT_JOUNRNEY"] = "CURRENT_JOUNRNEY";
  FreeCancellationApplicability["NOT_APPLICABLE"] = "NOT_APPLICABLE";
})(FreeCancellationApplicability || (FreeCancellationApplicability = {}));
export var FunnelLocation;
(function (FunnelLocation) {
  FunnelLocation["CHECKOUT"] = "CHECKOUT";
  FunnelLocation["CHECKOUT_ERROR"] = "CHECKOUT_ERROR";
  FunnelLocation["CONFIRMATION"] = "CONFIRMATION";
  FunnelLocation["DETAILS"] = "DETAILS";
  FunnelLocation["ENTRY"] = "ENTRY";
  FunnelLocation["FEATURES"] = "FEATURES";
  FunnelLocation["PAYMENT_ERROR"] = "PAYMENT_ERROR";
  FunnelLocation["POST_BOOKING"] = "POST_BOOKING";
  FunnelLocation["SEARCH_RESULTS"] = "SEARCH_RESULTS";
  FunnelLocation["SYSTEM"] = "SYSTEM";
  FunnelLocation["USER"] = "USER";
})(FunnelLocation || (FunnelLocation = {}));
export var GeoType;
(function (GeoType) {
  GeoType["AIRPORT"] = "AIRPORT";
  GeoType["DESTINATION"] = "DESTINATION";
  GeoType["PROPERTY"] = "PROPERTY";
})(GeoType || (GeoType = {}));
export var GlobalNavigationLineOfBusiness;
(function (GlobalNavigationLineOfBusiness) {
  GlobalNavigationLineOfBusiness["ACTIVITY"] = "ACTIVITY";
  GlobalNavigationLineOfBusiness["CAR"] = "CAR";
  GlobalNavigationLineOfBusiness["CRUISE"] = "CRUISE";
  GlobalNavigationLineOfBusiness["FLIGHT"] = "FLIGHT";
  GlobalNavigationLineOfBusiness["HOTEL"] = "HOTEL";
  GlobalNavigationLineOfBusiness["PACKAGE"] = "PACKAGE";
  GlobalNavigationLineOfBusiness["UNKNOWN"] = "UNKNOWN";
})(GlobalNavigationLineOfBusiness || (GlobalNavigationLineOfBusiness = {}));
export var GroundTransfersDirection;
(function (GroundTransfersDirection) {
  GroundTransfersDirection["FROM_AIRPORT"] = "FROM_AIRPORT";
  GroundTransfersDirection["ROUNDTRIP"] = "ROUNDTRIP";
  GroundTransfersDirection["TO_AIRPORT"] = "TO_AIRPORT";
})(GroundTransfersDirection || (GroundTransfersDirection = {}));
export var GroundTransfersTravelerDetailsType;
(function (GroundTransfersTravelerDetailsType) {
  GroundTransfersTravelerDetailsType["ADULT"] = "ADULT";
  GroundTransfersTravelerDetailsType["CHILD"] = "CHILD";
  GroundTransfersTravelerDetailsType["INFANT"] = "INFANT";
})(GroundTransfersTravelerDetailsType || (GroundTransfersTravelerDetailsType = {}));
export var HeaderAlignment;
(function (HeaderAlignment) {
  HeaderAlignment["END"] = "END";
  HeaderAlignment["START"] = "START";
})(HeaderAlignment || (HeaderAlignment = {}));
export var HeaderButtonType;
(function (HeaderButtonType) {
  HeaderButtonType["PRIMARY"] = "PRIMARY";
  HeaderButtonType["SECONDARY"] = "SECONDARY";
  HeaderButtonType["TERTIARY"] = "TERTIARY";
})(HeaderButtonType || (HeaderButtonType = {}));
export var HeaderItemPosition;
(function (HeaderItemPosition) {
  HeaderItemPosition["LEFT"] = "LEFT";
  HeaderItemPosition["RIGHT"] = "RIGHT";
})(HeaderItemPosition || (HeaderItemPosition = {}));
export var HeaderLayout;
(function (HeaderLayout) {
  HeaderLayout["DEFAULT"] = "DEFAULT";
  HeaderLayout["MINIMAL"] = "MINIMAL";
  HeaderLayout["SIMPLIFIED"] = "SIMPLIFIED";
})(HeaderLayout || (HeaderLayout = {}));
export var HeaderSeparatorType;
(function (HeaderSeparatorType) {
  HeaderSeparatorType["HORIZONTAL_RULE"] = "HORIZONTAL_RULE";
})(HeaderSeparatorType || (HeaderSeparatorType = {}));
export var HeaderTextSize;
(function (HeaderTextSize) {
  HeaderTextSize["LARGE"] = "LARGE";
  HeaderTextSize["SMALL"] = "SMALL";
})(HeaderTextSize || (HeaderTextSize = {}));
export var HeaderTextType;
(function (HeaderTextType) {
  HeaderTextType["HEADING"] = "HEADING";
  HeaderTextType["SUBHEADING"] = "SUBHEADING";
})(HeaderTextType || (HeaderTextType = {}));
export var HeaderViewSize;
(function (HeaderViewSize) {
  HeaderViewSize["EXTRA_LARGE"] = "EXTRA_LARGE";
  HeaderViewSize["LARGE"] = "LARGE";
  HeaderViewSize["MEDIUM"] = "MEDIUM";
  HeaderViewSize["SMALL"] = "SMALL";
})(HeaderViewSize || (HeaderViewSize = {}));
export var HeartOutlineType;
(function (HeartOutlineType) {
  HeartOutlineType["NORMAL_OUTLINED"] = "NORMAL_OUTLINED";
  HeartOutlineType["RED_OUTLINED"] = "RED_OUTLINED";
})(HeartOutlineType || (HeartOutlineType = {}));
export var HelpAndFeedbackHelpCenterOptionsType;
(function (HelpAndFeedbackHelpCenterOptionsType) {
  HelpAndFeedbackHelpCenterOptionsType["OWNER"] = "OWNER";
  HelpAndFeedbackHelpCenterOptionsType["PROPERTY_MANAGER"] = "PROPERTY_MANAGER";
  HelpAndFeedbackHelpCenterOptionsType["TRAVELER"] = "TRAVELER";
  HelpAndFeedbackHelpCenterOptionsType["TRUST_AND_SAFETY"] = "TRUST_AND_SAFETY";
})(HelpAndFeedbackHelpCenterOptionsType || (HelpAndFeedbackHelpCenterOptionsType = {}));
export var HelpAndFeedbackOptionsType;
(function (HelpAndFeedbackOptionsType) {
  HelpAndFeedbackOptionsType["BRAND_CALL"] = "BRAND_CALL";
  HelpAndFeedbackOptionsType["CHAT_NOW"] = "CHAT_NOW";
  HelpAndFeedbackOptionsType["CREDIT_CARD"] = "CREDIT_CARD";
  HelpAndFeedbackOptionsType["RATE_OUR_APP"] = "RATE_OUR_APP";
  HelpAndFeedbackOptionsType["SHARE_YOUR_FEEDBACK"] = "SHARE_YOUR_FEEDBACK";
  HelpAndFeedbackOptionsType["VISIT_HELP_CENTER"] = "VISIT_HELP_CENTER";
})(HelpAndFeedbackOptionsType || (HelpAndFeedbackOptionsType = {}));
export var HelperConfigMap;
(function (HelperConfigMap) {
  HelperConfigMap["And"] = "And";
  HelperConfigMap["CapitalizeFirst"] = "CapitalizeFirst";
  HelperConfigMap["DeviceTypeEvaluator"] = "DeviceTypeEvaluator";
  HelperConfigMap["Equals"] = "Equals";
  HelperConfigMap["EvaluateExperiment"] = "EvaluateExperiment";
  HelperConfigMap["EvaluateExperimentAndLog"] = "EvaluateExperimentAndLog";
  HelperConfigMap["FormatDoubleNumber"] = "FormatDoubleNumber";
  HelperConfigMap["FormatIntegerNumber"] = "FormatIntegerNumber";
  HelperConfigMap["GetSystemTimeInMs"] = "GetSystemTimeInMs";
  HelperConfigMap["GetUsableURN"] = "GetUsableURN";
  HelperConfigMap["Greater"] = "Greater";
  HelperConfigMap["GreaterAndEquals"] = "GreaterAndEquals";
  HelperConfigMap["HTTPLink"] = "HTTPLink";
  HelperConfigMap["ImpressionAnalyticsPayload"] = "ImpressionAnalyticsPayload";
  HelperConfigMap["IsNotNullOrEmpty"] = "IsNotNullOrEmpty";
  HelperConfigMap["IsQualifiedVrboProperty"] = "IsQualifiedVrboProperty";
  HelperConfigMap["Join"] = "Join";
  HelperConfigMap["Limit"] = "Limit";
  HelperConfigMap["Localize"] = "Localize";
  HelperConfigMap["Not"] = "Not";
  HelperConfigMap["NotEqual"] = "NotEqual";
  HelperConfigMap["Or"] = "Or";
  HelperConfigMap["Substring"] = "Substring";
})(HelperConfigMap || (HelperConfigMap = {}));
export var HistoryType;
(function (HistoryType) {
  HistoryType["ACTIVITY_DETAIL"] = "ACTIVITY_DETAIL";
  HistoryType["ACTIVITY_SEARCH"] = "ACTIVITY_SEARCH";
  HistoryType["CAR_DETAIL"] = "CAR_DETAIL";
  HistoryType["CAR_SEARCH"] = "CAR_SEARCH";
  HistoryType["CRUISE_SEARCH"] = "CRUISE_SEARCH";
  HistoryType["FLIGHT_DETAIL"] = "FLIGHT_DETAIL";
  HistoryType["FLIGHT_SEARCH"] = "FLIGHT_SEARCH";
  HistoryType["PACKAGE_SEARCH"] = "PACKAGE_SEARCH";
  HistoryType["PROPERTY_DETAIL"] = "PROPERTY_DETAIL";
  HistoryType["PROPERTY_SEARCH"] = "PROPERTY_SEARCH";
})(HistoryType || (HistoryType = {}));
export var HttpStatus;
(function (HttpStatus) {
  HttpStatus["ACCEPTED"] = "ACCEPTED";
  HttpStatus["ALREADY_REPORTED"] = "ALREADY_REPORTED";
  HttpStatus["BAD_GATEWAY"] = "BAD_GATEWAY";
  HttpStatus["BAD_REQUEST"] = "BAD_REQUEST";
  HttpStatus["BANDWIDTH_LIMIT_EXCEEDED"] = "BANDWIDTH_LIMIT_EXCEEDED";
  HttpStatus["CHECKPOINT"] = "CHECKPOINT";
  HttpStatus["CONFLICT"] = "CONFLICT";
  HttpStatus["CONTINUE"] = "CONTINUE";
  HttpStatus["CREATED"] = "CREATED";
  HttpStatus["DESTINATION_LOCKED"] = "DESTINATION_LOCKED";
  HttpStatus["EXPECTATION_FAILED"] = "EXPECTATION_FAILED";
  HttpStatus["FAILED_DEPENDENCY"] = "FAILED_DEPENDENCY";
  HttpStatus["FORBIDDEN"] = "FORBIDDEN";
  HttpStatus["FOUND"] = "FOUND";
  HttpStatus["GATEWAY_TIMEOUT"] = "GATEWAY_TIMEOUT";
  HttpStatus["GONE"] = "GONE";
  HttpStatus["HTTP_VERSION_NOT_SUPPORTED"] = "HTTP_VERSION_NOT_SUPPORTED";
  HttpStatus["IM_USED"] = "IM_USED";
  HttpStatus["INSUFFICIENT_SPACE_ON_RESOURCE"] = "INSUFFICIENT_SPACE_ON_RESOURCE";
  HttpStatus["INSUFFICIENT_STORAGE"] = "INSUFFICIENT_STORAGE";
  HttpStatus["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
  HttpStatus["I_AM_A_TEAPOT"] = "I_AM_A_TEAPOT";
  HttpStatus["LENGTH_REQUIRED"] = "LENGTH_REQUIRED";
  HttpStatus["LOCKED"] = "LOCKED";
  HttpStatus["LOOP_DETECTED"] = "LOOP_DETECTED";
  HttpStatus["METHOD_FAILURE"] = "METHOD_FAILURE";
  HttpStatus["METHOD_NOT_ALLOWED"] = "METHOD_NOT_ALLOWED";
  HttpStatus["MOVED_PERMANENTLY"] = "MOVED_PERMANENTLY";
  HttpStatus["MOVED_TEMPORARILY"] = "MOVED_TEMPORARILY";
  HttpStatus["MULTIPLE_CHOICES"] = "MULTIPLE_CHOICES";
  HttpStatus["MULTI_STATUS"] = "MULTI_STATUS";
  HttpStatus["NETWORK_AUTHENTICATION_REQUIRED"] = "NETWORK_AUTHENTICATION_REQUIRED";
  HttpStatus["NON_AUTHORITATIVE_INFORMATION"] = "NON_AUTHORITATIVE_INFORMATION";
  HttpStatus["NOT_ACCEPTABLE"] = "NOT_ACCEPTABLE";
  HttpStatus["NOT_EXTENDED"] = "NOT_EXTENDED";
  HttpStatus["NOT_FOUND"] = "NOT_FOUND";
  HttpStatus["NOT_IMPLEMENTED"] = "NOT_IMPLEMENTED";
  HttpStatus["NOT_MODIFIED"] = "NOT_MODIFIED";
  HttpStatus["NO_CONTENT"] = "NO_CONTENT";
  HttpStatus["OK"] = "OK";
  HttpStatus["PARTIAL_CONTENT"] = "PARTIAL_CONTENT";
  HttpStatus["PAYLOAD_TOO_LARGE"] = "PAYLOAD_TOO_LARGE";
  HttpStatus["PAYMENT_REQUIRED"] = "PAYMENT_REQUIRED";
  HttpStatus["PERMANENT_REDIRECT"] = "PERMANENT_REDIRECT";
  HttpStatus["PRECONDITION_FAILED"] = "PRECONDITION_FAILED";
  HttpStatus["PRECONDITION_REQUIRED"] = "PRECONDITION_REQUIRED";
  HttpStatus["PROCESSING"] = "PROCESSING";
  HttpStatus["PROXY_AUTHENTICATION_REQUIRED"] = "PROXY_AUTHENTICATION_REQUIRED";
  HttpStatus["REQUESTED_RANGE_NOT_SATISFIABLE"] = "REQUESTED_RANGE_NOT_SATISFIABLE";
  HttpStatus["REQUEST_ENTITY_TOO_LARGE"] = "REQUEST_ENTITY_TOO_LARGE";
  HttpStatus["REQUEST_HEADER_FIELDS_TOO_LARGE"] = "REQUEST_HEADER_FIELDS_TOO_LARGE";
  HttpStatus["REQUEST_TIMEOUT"] = "REQUEST_TIMEOUT";
  HttpStatus["REQUEST_URI_TOO_LONG"] = "REQUEST_URI_TOO_LONG";
  HttpStatus["RESET_CONTENT"] = "RESET_CONTENT";
  HttpStatus["SEE_OTHER"] = "SEE_OTHER";
  HttpStatus["SERVICE_UNAVAILABLE"] = "SERVICE_UNAVAILABLE";
  HttpStatus["SWITCHING_PROTOCOLS"] = "SWITCHING_PROTOCOLS";
  HttpStatus["TEMPORARY_REDIRECT"] = "TEMPORARY_REDIRECT";
  HttpStatus["TOO_EARLY"] = "TOO_EARLY";
  HttpStatus["TOO_MANY_REQUESTS"] = "TOO_MANY_REQUESTS";
  HttpStatus["UNAUTHORIZED"] = "UNAUTHORIZED";
  HttpStatus["UNAVAILABLE_FOR_LEGAL_REASONS"] = "UNAVAILABLE_FOR_LEGAL_REASONS";
  HttpStatus["UNPROCESSABLE_ENTITY"] = "UNPROCESSABLE_ENTITY";
  HttpStatus["UNSUPPORTED_MEDIA_TYPE"] = "UNSUPPORTED_MEDIA_TYPE";
  HttpStatus["UPGRADE_REQUIRED"] = "UPGRADE_REQUIRED";
  HttpStatus["URI_TOO_LONG"] = "URI_TOO_LONG";
  HttpStatus["USE_PROXY"] = "USE_PROXY";
  HttpStatus["VARIANT_ALSO_NEGOTIATES"] = "VARIANT_ALSO_NEGOTIATES";
})(HttpStatus || (HttpStatus = {}));
export var ISViewType;
(function (ISViewType) {
  ISViewType["BOOKING_CONFIRM"] = "BOOKING_CONFIRM";
  ISViewType["BOOKING_SKIP"] = "BOOKING_SKIP";
  ISViewType["SEARCH"] = "SEARCH";
})(ISViewType || (ISViewType = {}));
export var IconSize;
(function (IconSize) {
  IconSize["EXTRA_SMALL"] = "EXTRA_SMALL";
  IconSize["LARGE"] = "LARGE";
  IconSize["MEDIUM"] = "MEDIUM";
  /** @deprecated LARGE icon size changed from 48px to 32px. Use LARGE instead of MEDIUM_LARGE */
  IconSize["MEDIUM_LARGE"] = "MEDIUM_LARGE";
  IconSize["SMALL"] = "SMALL";
  IconSize["XLARGE"] = "XLARGE";
})(IconSize || (IconSize = {}));
export var IconSpotLight;
(function (IconSpotLight) {
  IconSpotLight["INFO"] = "INFO";
  IconSpotLight["POSITIVE"] = "POSITIVE";
  IconSpotLight["STANDARD"] = "STANDARD";
  IconSpotLight["WARNING"] = "WARNING";
})(IconSpotLight || (IconSpotLight = {}));
export var IconTheme;
(function (IconTheme) {
  IconTheme["default"] = "default";
  IconTheme["emphasis"] = "emphasis";
  IconTheme["inverse"] = "inverse";
  IconTheme["negative"] = "negative";
  IconTheme["positive"] = "positive";
})(IconTheme || (IconTheme = {}));
export var IdentifierType;
(function (IdentifierType) {
  IdentifierType["ACTIVITY_ID"] = "ACTIVITY_ID";
  /** @deprecated Use CHECKOUT_SESSION_ID instead */
  IdentifierType["CART_ID"] = "CART_ID";
  IdentifierType["CHECKOUT_SESSION_ID"] = "CHECKOUT_SESSION_ID";
  /** @deprecated This has been deprecated */
  IdentifierType["ENTITY_ID"] = "ENTITY_ID";
  IdentifierType["LEGACY_ORDER_ID"] = "LEGACY_ORDER_ID";
  /** @deprecated Use CHECKOUT_SESSION_ID instead */
  IdentifierType["OFFER_ID"] = "OFFER_ID";
  IdentifierType["ORDER_ID"] = "ORDER_ID";
  IdentifierType["PROPERTY_ID"] = "PROPERTY_ID";
  IdentifierType["SAILING_TOKEN"] = "SAILING_TOKEN";
})(IdentifierType || (IdentifierType = {}));
export var IdentityAccountCreationState;
(function (IdentityAccountCreationState) {
  IdentityAccountCreationState["INVALID"] = "INVALID";
  IdentityAccountCreationState["NOT_UPGRADED"] = "NOT_UPGRADED";
  IdentityAccountCreationState["UPGRADED"] = "UPGRADED";
})(IdentityAccountCreationState || (IdentityAccountCreationState = {}));
export var IdentityAccountCreationType;
(function (IdentityAccountCreationType) {
  IdentityAccountCreationType["INVALID"] = "INVALID";
  IdentityAccountCreationType["REGISTERED"] = "REGISTERED";
  IdentityAccountCreationType["UNREGISTERED"] = "UNREGISTERED";
})(IdentityAccountCreationType || (IdentityAccountCreationType = {}));
export var IdentityAccountTakeOverDeviceType;
(function (IdentityAccountTakeOverDeviceType) {
  IdentityAccountTakeOverDeviceType["TRUST_WIDGET"] = "TRUST_WIDGET";
})(IdentityAccountTakeOverDeviceType || (IdentityAccountTakeOverDeviceType = {}));
export var IdentityAccountTakeOverType;
(function (IdentityAccountTakeOverType) {
  IdentityAccountTakeOverType["CAPTCHA_ARKOSE_MOBILE_APP"] = "CAPTCHA_ARKOSE_MOBILE_APP";
  IdentityAccountTakeOverType["CAPTCHA_SAFETYNET_ANDROID"] = "CAPTCHA_SAFETYNET_ANDROID";
  IdentityAccountTakeOverType["CAPTCHA_WEB"] = "CAPTCHA_WEB";
  IdentityAccountTakeOverType["CSRF"] = "CSRF";
  IdentityAccountTakeOverType["DEVICE_CHECK"] = "DEVICE_CHECK";
  IdentityAccountTakeOverType["TRUST_WIDGET"] = "TRUST_WIDGET";
})(IdentityAccountTakeOverType || (IdentityAccountTakeOverType = {}));
export var IdentityAnalyticsPayloadType;
(function (IdentityAnalyticsPayloadType) {
  IdentityAnalyticsPayloadType["EG_CLICK_STREAM"] = "EG_CLICK_STREAM";
  IdentityAnalyticsPayloadType["UIS_PRIME"] = "UIS_PRIME";
})(IdentityAnalyticsPayloadType || (IdentityAnalyticsPayloadType = {}));
export var IdentityAuthenticationScenario;
(function (IdentityAuthenticationScenario) {
  IdentityAuthenticationScenario["CHECKOUT_SIGNIN"] = "CHECKOUT_SIGNIN";
  IdentityAuthenticationScenario["CHECKOUT_SIGNUP"] = "CHECKOUT_SIGNUP";
  IdentityAuthenticationScenario["PROFILE"] = "PROFILE";
  IdentityAuthenticationScenario["SIGNIN"] = "SIGNIN";
  IdentityAuthenticationScenario["SIGNUP"] = "SIGNUP";
})(IdentityAuthenticationScenario || (IdentityAuthenticationScenario = {}));
export var IdentityChannelType;
(function (IdentityChannelType) {
  IdentityChannelType["ANDROID"] = "ANDROID";
  IdentityChannelType["DESKTOP"] = "DESKTOP";
  IdentityChannelType["IOS"] = "IOS";
})(IdentityChannelType || (IdentityChannelType = {}));
export var IdentityLayout;
(function (IdentityLayout) {
  IdentityLayout["APP_ONBOARDING"] = "APP_ONBOARDING";
  IdentityLayout["FORGOT_PASSWORD"] = "FORGOT_PASSWORD";
  IdentityLayout["GET_RESENT_OTP_ATO"] = "GET_RESENT_OTP_ATO";
  IdentityLayout["GET_SEND_OTP_ATO"] = "GET_SEND_OTP_ATO";
  IdentityLayout["GET_SET_PASSWORD_ATO"] = "GET_SET_PASSWORD_ATO";
  IdentityLayout["GET_SIGN_IN_ATO"] = "GET_SIGN_IN_ATO";
  IdentityLayout["GET_SIGN_IN_VERIFY_OTP_ATO"] = "GET_SIGN_IN_VERIFY_OTP_ATO";
  IdentityLayout["GET_SIGN_UP_VERIFY_OTP_ATO"] = "GET_SIGN_UP_VERIFY_OTP_ATO";
  IdentityLayout["GET_SOCIAL_LOGIN_APPLE_ATO"] = "GET_SOCIAL_LOGIN_APPLE_ATO";
  IdentityLayout["GET_SOCIAL_LOGIN_FACEBOOK_ATO"] = "GET_SOCIAL_LOGIN_FACEBOOK_ATO";
  IdentityLayout["GET_SOCIAL_LOGIN_GOOGLE_ATO"] = "GET_SOCIAL_LOGIN_GOOGLE_ATO";
  IdentityLayout["GET_USER_DETAILS_ATO"] = "GET_USER_DETAILS_ATO";
  IdentityLayout["LAUNCH_ONE_IDENTITY"] = "LAUNCH_ONE_IDENTITY";
  IdentityLayout["RESET_PASSWORD"] = "RESET_PASSWORD";
  IdentityLayout["SIGNIN"] = "SIGNIN";
  IdentityLayout["SIGNUP"] = "SIGNUP";
})(IdentityLayout || (IdentityLayout = {}));
export var IdentityLoginScenario;
(function (IdentityLoginScenario) {
  IdentityLoginScenario["SIGN_IN"] = "SIGN_IN";
  IdentityLoginScenario["SIGN_UP"] = "SIGN_UP";
})(IdentityLoginScenario || (IdentityLoginScenario = {}));
export var IdentityLoginType;
(function (IdentityLoginType) {
  IdentityLoginType["SIGN_IN"] = "SIGN_IN";
  IdentityLoginType["SIGN_UP"] = "SIGN_UP";
})(IdentityLoginType || (IdentityLoginType = {}));
export var IdentitySocialButtonType;
(function (IdentitySocialButtonType) {
  IdentitySocialButtonType["PRIMARY"] = "PRIMARY";
  IdentitySocialButtonType["SECONDARY"] = "SECONDARY";
})(IdentitySocialButtonType || (IdentitySocialButtonType = {}));
export var IdentitySocialTokenType;
(function (IdentitySocialTokenType) {
  IdentitySocialTokenType["Access"] = "Access";
  IdentitySocialTokenType["AuthCode"] = "AuthCode";
  IdentitySocialTokenType["ID"] = "ID";
})(IdentitySocialTokenType || (IdentitySocialTokenType = {}));
export var IdentitySocialType;
(function (IdentitySocialType) {
  IdentitySocialType["APPLE"] = "APPLE";
  IdentitySocialType["FACEBOOK"] = "FACEBOOK";
  IdentitySocialType["GOOGLE"] = "GOOGLE";
})(IdentitySocialType || (IdentitySocialType = {}));
export var IdentitySpaceSize;
(function (IdentitySpaceSize) {
  IdentitySpaceSize["S0X"] = "S0X";
  IdentitySpaceSize["S0XH"] = "S0XH";
  IdentitySpaceSize["S1X"] = "S1X";
  IdentitySpaceSize["S2X"] = "S2X";
  IdentitySpaceSize["S3X"] = "S3X";
  IdentitySpaceSize["S4X"] = "S4X";
  IdentitySpaceSize["S5X"] = "S5X";
  IdentitySpaceSize["S6X"] = "S6X";
  IdentitySpaceSize["S8X"] = "S8X";
  IdentitySpaceSize["S10X"] = "S10X";
  IdentitySpaceSize["S11X"] = "S11X";
  IdentitySpaceSize["S12X"] = "S12X";
  IdentitySpaceSize["S14X"] = "S14X";
  IdentitySpaceSize["S16X"] = "S16X";
  IdentitySpaceSize["S18X"] = "S18X";
})(IdentitySpaceSize || (IdentitySpaceSize = {}));
export var IdentitySuccessType;
(function (IdentitySuccessType) {
  IdentitySuccessType["ONE_KEY_ONBOARDING"] = "ONE_KEY_ONBOARDING";
  IdentitySuccessType["ORIGIN"] = "ORIGIN";
})(IdentitySuccessType || (IdentitySuccessType = {}));
export var ImagePosition;
(function (ImagePosition) {
  ImagePosition["LEFT"] = "LEFT";
  ImagePosition["TOP"] = "TOP";
})(ImagePosition || (ImagePosition = {}));
export var ImmersiveViewNavigationType;
(function (ImmersiveViewNavigationType) {
  ImmersiveViewNavigationType["SWIPE_HORIZONTAL"] = "SWIPE_HORIZONTAL";
  ImmersiveViewNavigationType["SWIPE_VERTICAL"] = "SWIPE_VERTICAL";
})(ImmersiveViewNavigationType || (ImmersiveViewNavigationType = {}));
export var InlineJustify;
(function (InlineJustify) {
  InlineJustify["END"] = "END";
  InlineJustify["SPACE"] = "SPACE";
  InlineJustify["START"] = "START";
})(InlineJustify || (InlineJustify = {}));
export var InquiryProminence;
(function (InquiryProminence) {
  InquiryProminence["HIGH"] = "HIGH";
  InquiryProminence["LOW"] = "LOW";
  InquiryProminence["MEDIUM"] = "MEDIUM";
})(InquiryProminence || (InquiryProminence = {}));
export var InsurTechDialogActionType;
(function (InsurTechDialogActionType) {
  InsurTechDialogActionType["CLOSE"] = "CLOSE";
  InsurTechDialogActionType["OPEN"] = "OPEN";
  InsurTechDialogActionType["OPEN_QUERY"] = "OPEN_QUERY";
})(InsurTechDialogActionType || (InsurTechDialogActionType = {}));
export var InsurTechExperienceDialogTriggerType;
(function (InsurTechExperienceDialogTriggerType) {
  InsurTechExperienceDialogTriggerType["BUTTON"] = "BUTTON";
  InsurTechExperienceDialogTriggerType["LINK"] = "LINK";
})(InsurTechExperienceDialogTriggerType || (InsurTechExperienceDialogTriggerType = {}));
export var InsurTechPagePlacementType;
(function (InsurTechPagePlacementType) {
  InsurTechPagePlacementType["LISTING"] = "LISTING";
  InsurTechPagePlacementType["LISTING_ONE_WAY"] = "LISTING_ONE_WAY";
})(InsurTechPagePlacementType || (InsurTechPagePlacementType = {}));
export var InsuranceMessageType;
(function (InsuranceMessageType) {
  InsuranceMessageType["ERROR"] = "ERROR";
  InsuranceMessageType["INFO"] = "INFO";
  InsuranceMessageType["WARNING"] = "WARNING";
})(InsuranceMessageType || (InsuranceMessageType = {}));
export var InsuranceRewardsPointSelectionEnum;
(function (InsuranceRewardsPointSelectionEnum) {
  InsuranceRewardsPointSelectionEnum["APPLY_REWARD_POINTS"] = "APPLY_REWARD_POINTS";
  InsuranceRewardsPointSelectionEnum["DO_NOT_APPLY_REWARD_POINTS"] = "DO_NOT_APPLY_REWARD_POINTS";
})(InsuranceRewardsPointSelectionEnum || (InsuranceRewardsPointSelectionEnum = {}));
export var InsurtechAlertTheme;
(function (InsurtechAlertTheme) {
  InsurtechAlertTheme["INFO"] = "INFO";
  InsurtechAlertTheme["SUCCESS"] = "SUCCESS";
  InsurtechAlertTheme["WARNING"] = "WARNING";
})(InsurtechAlertTheme || (InsurtechAlertTheme = {}));
export var InsurtechClientId;
(function (InsurtechClientId) {
  InsurtechClientId["CAR_INFO_SITE"] = "CAR_INFO_SITE";
  InsurtechClientId["EG_CHECKOUT"] = "EG_CHECKOUT";
  InsurtechClientId["FCR_CHECKOUT"] = "FCR_CHECKOUT";
})(InsurtechClientId || (InsurtechClientId = {}));
export var InsurtechCommonErrorType;
(function (InsurtechCommonErrorType) {
  InsurtechCommonErrorType["ANONYMOUS_TRIP_USER"] = "ANONYMOUS_TRIP_USER";
  InsurtechCommonErrorType["BOOKING_CANCELLED"] = "BOOKING_CANCELLED";
  InsurtechCommonErrorType["CHANGE_RESIDENCY_FAILURE"] = "CHANGE_RESIDENCY_FAILURE";
  InsurtechCommonErrorType["ERROR_CARD_LIMIT"] = "ERROR_CARD_LIMIT";
  InsurtechCommonErrorType["ERROR_MAPPING_PAYMENT_INSTRUMENT_DETAILS"] = "ERROR_MAPPING_PAYMENT_INSTRUMENT_DETAILS";
  InsurtechCommonErrorType["ERROR_OMS_RECOVERABLE"] = "ERROR_OMS_RECOVERABLE";
  InsurtechCommonErrorType["FAILURE_SELECT_INSURANCE_PRODUCT"] = "FAILURE_SELECT_INSURANCE_PRODUCT";
  InsurtechCommonErrorType["INSURANCE_ADD_FAILURE"] = "INSURANCE_ADD_FAILURE";
  InsurtechCommonErrorType["INSURANCE_ALREADY_PURCHASED"] = "INSURANCE_ALREADY_PURCHASED";
  InsurtechCommonErrorType["INSURANCE_OFFER_EXPIRED"] = "INSURANCE_OFFER_EXPIRED";
  InsurtechCommonErrorType["INVALID_ACCOUNT_NUMBER"] = "INVALID_ACCOUNT_NUMBER";
  InsurtechCommonErrorType["INVALID_EDGE_TOKEN"] = "INVALID_EDGE_TOKEN";
  InsurtechCommonErrorType["INVALID_EXPIRATION_DATE"] = "INVALID_EXPIRATION_DATE";
  InsurtechCommonErrorType["INVALID_FORM"] = "INVALID_FORM";
  InsurtechCommonErrorType["PAYMENT_CARD_ISSUE"] = "PAYMENT_CARD_ISSUE";
  InsurtechCommonErrorType["PAYMENT_SESSION_SERVICE_ERROR"] = "PAYMENT_SESSION_SERVICE_ERROR";
  InsurtechCommonErrorType["RESPONSE_ID_NOT_FOUND_IN_RECORD"] = "RESPONSE_ID_NOT_FOUND_IN_RECORD";
  InsurtechCommonErrorType["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
  InsurtechCommonErrorType["USER_ACCOUNT_MISMATCH"] = "USER_ACCOUNT_MISMATCH";
})(InsurtechCommonErrorType || (InsurtechCommonErrorType = {}));
export var InsurtechCustomErrorType;
(function (InsurtechCustomErrorType) {
  InsurtechCustomErrorType["CACHED_PRODUCT_UNAVAILABLE"] = "CACHED_PRODUCT_UNAVAILABLE";
  InsurtechCustomErrorType["GENERIC_ERROR"] = "GENERIC_ERROR";
  InsurtechCustomErrorType["INVALID_USER"] = "INVALID_USER";
})(InsurtechCustomErrorType || (InsurtechCustomErrorType = {}));
export var InsurtechGenericActionType;
(function (InsurtechGenericActionType) {
  InsurtechGenericActionType["REDIRECT_CHECKOUT_URL"] = "REDIRECT_CHECKOUT_URL";
  InsurtechGenericActionType["RELOAD_PAGE"] = "RELOAD_PAGE";
})(InsurtechGenericActionType || (InsurtechGenericActionType = {}));
export var InsurtechLinkType;
(function (InsurtechLinkType) {
  InsurtechLinkType["MORE_INFO"] = "MORE_INFO";
  InsurtechLinkType["STANDARD_LINK"] = "STANDARD_LINK";
})(InsurtechLinkType || (InsurtechLinkType = {}));
export var InsurtechListItemTheme;
(function (InsurtechListItemTheme) {
  InsurtechListItemTheme["HEADED_LIST_ITEM"] = "HEADED_LIST_ITEM";
  InsurtechListItemTheme["LIST_ITEM"] = "LIST_ITEM";
})(InsurtechListItemTheme || (InsurtechListItemTheme = {}));
export var InsurtechModuleHeadingViewType;
(function (InsurtechModuleHeadingViewType) {
  InsurtechModuleHeadingViewType["INLINE_BADGE"] = "INLINE_BADGE";
  InsurtechModuleHeadingViewType["PRIMARY"] = "PRIMARY";
})(InsurtechModuleHeadingViewType || (InsurtechModuleHeadingViewType = {}));
export var InsurtechMutationStatus;
(function (InsurtechMutationStatus) {
  InsurtechMutationStatus["FAILED"] = "FAILED";
  InsurtechMutationStatus["SUCCESS"] = "SUCCESS";
})(InsurtechMutationStatus || (InsurtechMutationStatus = {}));
export var InsurtechOfferState;
(function (InsurtechOfferState) {
  InsurtechOfferState["CHANGED_OFFER"] = "CHANGED_OFFER";
  InsurtechOfferState["NOT_SELECTED"] = "NOT_SELECTED";
  InsurtechOfferState["UNCHANGED_OFFER"] = "UNCHANGED_OFFER";
})(InsurtechOfferState || (InsurtechOfferState = {}));
export var InsurtechPrimingCardViewType;
(function (InsurtechPrimingCardViewType) {
  InsurtechPrimingCardViewType["BASIC_PRIMING_CARD"] = "BASIC_PRIMING_CARD";
})(InsurtechPrimingCardViewType || (InsurtechPrimingCardViewType = {}));
export var InsurtechShoppingIdentifierType;
(function (InsurtechShoppingIdentifierType) {
  InsurtechShoppingIdentifierType["CHECKOUT_SESSION_ID"] = "CHECKOUT_SESSION_ID";
  InsurtechShoppingIdentifierType["JCID"] = "JCID";
  InsurtechShoppingIdentifierType["OFFER_TOKEN"] = "OFFER_TOKEN";
  InsurtechShoppingIdentifierType["RESPONSE_ID"] = "RESPONSE_ID";
  InsurtechShoppingIdentifierType["TRIP_ID"] = "TRIP_ID";
})(InsurtechShoppingIdentifierType || (InsurtechShoppingIdentifierType = {}));
export var InsurtechShoppingViewType;
(function (InsurtechShoppingViewType) {
  InsurtechShoppingViewType["CHECKOUT_SHOPPING"] = "CHECKOUT_SHOPPING";
  InsurtechShoppingViewType["DETAILS_SHOPPING"] = "DETAILS_SHOPPING";
  InsurtechShoppingViewType["EXPWEB_CHECKOUT_SHOPPING"] = "EXPWEB_CHECKOUT_SHOPPING";
  InsurtechShoppingViewType["POST_PURCHASE"] = "POST_PURCHASE";
  InsurtechShoppingViewType["SUMMARY"] = "SUMMARY";
})(InsurtechShoppingViewType || (InsurtechShoppingViewType = {}));
export var InsurtechTransactionIdentifierType;
(function (InsurtechTransactionIdentifierType) {
  InsurtechTransactionIdentifierType["RESPONSE_ID"] = "RESPONSE_ID";
  InsurtechTransactionIdentifierType["TRIP_ID"] = "TRIP_ID";
})(InsurtechTransactionIdentifierType || (InsurtechTransactionIdentifierType = {}));
export var InsurtechUpdateOperationType;
(function (InsurtechUpdateOperationType) {
  InsurtechUpdateOperationType["ADD"] = "ADD";
  InsurtechUpdateOperationType["MODAL"] = "MODAL";
  InsurtechUpdateOperationType["REMOVE"] = "REMOVE";
})(InsurtechUpdateOperationType || (InsurtechUpdateOperationType = {}));
export var InteractionEventName;
(function (InteractionEventName) {
  InteractionEventName["BOOK"] = "BOOK";
  InteractionEventName["CHECKOUT_PAGE_LOAD"] = "CHECKOUT_PAGE_LOAD";
  InteractionEventName["CONFIRMATION_PAGE_LOAD"] = "CONFIRMATION_PAGE_LOAD";
})(InteractionEventName || (InteractionEventName = {}));
export var InteractionMode;
(function (InteractionMode) {
  InteractionMode["FREE"] = "FREE";
  InteractionMode["RESTRICTED"] = "RESTRICTED";
})(InteractionMode || (InteractionMode = {}));
export var InventoryType;
(function (InventoryType) {
  InventoryType["AICTRAVELGROUP"] = "AICTRAVELGROUP";
  InventoryType["AMOMA"] = "AMOMA";
  InventoryType["ANA"] = "ANA";
  InventoryType["BESTBUYHOTEL"] = "BESTBUYHOTEL";
  InventoryType["BONOTEL"] = "BONOTEL";
  InventoryType["CNTRAVEL"] = "CNTRAVEL";
  /** @deprecated Replaced by TRIPCOM */
  InventoryType["CTRIP"] = "CTRIP";
  InventoryType["DESPEGAR"] = "DESPEGAR";
  InventoryType["DIDATRAVEL"] = "DIDATRAVEL";
  InventoryType["DIRECT_AGENCY"] = "DIRECT_AGENCY";
  InventoryType["EUROPLAYAS"] = "EUROPLAYAS";
  InventoryType["EXCITEHOLIDAYS"] = "EXCITEHOLIDAYS";
  InventoryType["EZZEGO_1"] = "EZZEGO_1";
  InventoryType["GETAROOM"] = "GETAROOM";
  InventoryType["HOME_AWAY"] = "HOME_AWAY";
  InventoryType["HOTELDOBESTDAYTRAVEL"] = "HOTELDOBESTDAYTRAVEL";
  InventoryType["HOTEL_BEDS"] = "HOTEL_BEDS";
  /** @deprecated Replaced by THOMASCOOK_EUR */
  InventoryType["HRS"] = "HRS";
  InventoryType["JAC_TRAVEL"] = "JAC_TRAVEL";
  InventoryType["JAL"] = "JAL";
  InventoryType["JALAN"] = "JALAN";
  InventoryType["JTB"] = "JTB";
  InventoryType["JUMBOTOURS"] = "JUMBOTOURS";
  InventoryType["MAGICROOMS"] = "MAGICROOMS";
  InventoryType["MERCHANT"] = "MERCHANT";
  InventoryType["MGBEDBANK"] = "MGBEDBANK";
  InventoryType["MIKI"] = "MIKI";
  InventoryType["MTS"] = "MTS";
  InventoryType["NTA"] = "NTA";
  InventoryType["NUITEE"] = "NUITEE";
  InventoryType["OPAQUE"] = "OPAQUE";
  InventoryType["OSTROVOK"] = "OSTROVOK";
  InventoryType["PEGASUS"] = "PEGASUS";
  InventoryType["RAKUTEN"] = "RAKUTEN";
  InventoryType["RESTEL"] = "RESTEL";
  InventoryType["ROOMER"] = "ROOMER";
  InventoryType["SMYROOMS"] = "SMYROOMS";
  InventoryType["SUNHOTELS"] = "SUNHOTELS";
  InventoryType["THOMASCOOK_EUR"] = "THOMASCOOK_EUR";
  InventoryType["THOMASCOOK_UK"] = "THOMASCOOK_UK";
  InventoryType["TOURICO"] = "TOURICO";
  InventoryType["TRAVELLANDA"] = "TRAVELLANDA";
  InventoryType["TRAVELREPUBLIC"] = "TRAVELREPUBLIC";
  InventoryType["TRIPCOM"] = "TRIPCOM";
  InventoryType["VENERE"] = "VENERE";
  InventoryType["VRBO"] = "VRBO";
  InventoryType["VRBO_MUVR"] = "VRBO_MUVR";
  InventoryType["WELCOME_BEDS"] = "WELCOME_BEDS";
  InventoryType["WOORI"] = "WOORI";
  InventoryType["WORLDHOTELLINK"] = "WORLDHOTELLINK";
  InventoryType["WORLDSPAN"] = "WORLDSPAN";
  InventoryType["WOTIF"] = "WOTIF";
  InventoryType["YOUTRAVEL"] = "YOUTRAVEL";
  InventoryType["ZUMATA"] = "ZUMATA";
})(InventoryType || (InventoryType = {}));
export var JourneyExperience;
(function (JourneyExperience) {
  JourneyExperience["NEW_JOURNEY_PLAYBACK"] = "NEW_JOURNEY_PLAYBACK";
  JourneyExperience["OLD_JOURNEY_PLAYBACK"] = "OLD_JOURNEY_PLAYBACK";
})(JourneyExperience || (JourneyExperience = {}));
export var KeyPhrasesClientType;
(function (KeyPhrasesClientType) {
  KeyPhrasesClientType["DEFAULT"] = "DEFAULT";
  KeyPhrasesClientType["FILTER"] = "FILTER";
  KeyPhrasesClientType["SNIPPET"] = "SNIPPET";
})(KeyPhrasesClientType || (KeyPhrasesClientType = {}));
export var LayoutPosition;
(function (LayoutPosition) {
  LayoutPosition["CENTER"] = "CENTER";
  LayoutPosition["LEFT"] = "LEFT";
  LayoutPosition["TOP"] = "TOP";
})(LayoutPosition || (LayoutPosition = {}));
export var LegalConcept;
(function (LegalConcept) {
  LegalConcept["LEGAL_CONCEPT_COOKIES"] = "LEGAL_CONCEPT_COOKIES";
  LegalConcept["LEGAL_CONCEPT_INVALID"] = "LEGAL_CONCEPT_INVALID";
  LegalConcept["LEGAL_CONCEPT_PRIVACY"] = "LEGAL_CONCEPT_PRIVACY";
  LegalConcept["LEGAL_CONCEPT_SORT_AND_FILTER"] = "LEGAL_CONCEPT_SORT_AND_FILTER";
  LegalConcept["LEGAL_CONCEPT_TERMS_AND_CONDITIONS"] = "LEGAL_CONCEPT_TERMS_AND_CONDITIONS";
})(LegalConcept || (LegalConcept = {}));
export var LineOfBusinessDomain;
(function (LineOfBusinessDomain) {
  LineOfBusinessDomain["ACTIVITIES"] = "ACTIVITIES";
  LineOfBusinessDomain["CAR"] = "CAR";
  LineOfBusinessDomain["CRUISES"] = "CRUISES";
  LineOfBusinessDomain["FLIGHT"] = "FLIGHT";
  LineOfBusinessDomain["GROUNDTRANSFERS"] = "GROUNDTRANSFERS";
  LineOfBusinessDomain["LODGING"] = "LODGING";
  LineOfBusinessDomain["PACKAGES"] = "PACKAGES";
  LineOfBusinessDomain["RAILS"] = "RAILS";
  LineOfBusinessDomain["UNCLASSIFIED"] = "UNCLASSIFIED";
})(LineOfBusinessDomain || (LineOfBusinessDomain = {}));
export var LineOfBusinessType;
(function (LineOfBusinessType) {
  LineOfBusinessType["ACTIVITY"] = "ACTIVITY";
  LineOfBusinessType["AIR"] = "AIR";
  LineOfBusinessType["CAR"] = "CAR";
  LineOfBusinessType["CRUISE"] = "CRUISE";
  LineOfBusinessType["FEE"] = "FEE";
  LineOfBusinessType["INSURANCE"] = "INSURANCE";
  LineOfBusinessType["ITINERARY"] = "ITINERARY";
  LineOfBusinessType["LODGING"] = "LODGING";
  LineOfBusinessType["TRAIN"] = "TRAIN";
})(LineOfBusinessType || (LineOfBusinessType = {}));
export var Listings;
(function (Listings) {
  Listings["ADS_ONLY"] = "ADS_ONLY";
})(Listings || (Listings = {}));
export var LiveAnnouncePoliteness;
(function (LiveAnnouncePoliteness) {
  LiveAnnouncePoliteness["ASSERTIVE"] = "ASSERTIVE";
  LiveAnnouncePoliteness["OFF"] = "OFF";
  LiveAnnouncePoliteness["POLITE"] = "POLITE";
})(LiveAnnouncePoliteness || (LiveAnnouncePoliteness = {}));
export var LoaderSize;
(function (LoaderSize) {
  LoaderSize["LARGE"] = "LARGE";
  LoaderSize["MEDIUM"] = "MEDIUM";
  LoaderSize["SMALL"] = "SMALL";
})(LoaderSize || (LoaderSize = {}));
export var Location;
(function (Location) {
  Location["CHECKOUT_RIGHT_SIDE_PAGE"] = "CHECKOUT_RIGHT_SIDE_PAGE";
  Location["CHECKOUT_TOP_OF_PAGE"] = "CHECKOUT_TOP_OF_PAGE";
})(Location || (Location = {}));
export var LocationScoutProductId;
(function (LocationScoutProductId) {
  LocationScoutProductId["SRP"] = "SRP";
  LocationScoutProductId["TRAVEL_SHOP"] = "TRAVEL_SHOP";
})(LocationScoutProductId || (LocationScoutProductId = {}));
export var LocationServiceType;
(function (LocationServiceType) {
  LocationServiceType["RECOMMENDATIONS"] = "RECOMMENDATIONS";
  LocationServiceType["SUGGESTIONS"] = "SUGGESTIONS";
})(LocationServiceType || (LocationServiceType = {}));
export var LodgingBookingPlaybackType;
(function (LodgingBookingPlaybackType) {
  LodgingBookingPlaybackType["COMBINED"] = "COMBINED";
  LodgingBookingPlaybackType["NEW"] = "NEW";
  LodgingBookingPlaybackType["ORIGINAL"] = "ORIGINAL";
})(LodgingBookingPlaybackType || (LodgingBookingPlaybackType = {}));
export var LodgingCancelStatus;
(function (LodgingCancelStatus) {
  LodgingCancelStatus["Failure"] = "Failure";
  LodgingCancelStatus["Success"] = "Success";
})(LodgingCancelStatus || (LodgingCancelStatus = {}));
export var LodgingChangeStatus;
(function (LodgingChangeStatus) {
  LodgingChangeStatus["Failure"] = "Failure";
  LodgingChangeStatus["Pending"] = "Pending";
  LodgingChangeStatus["Success"] = "Success";
})(LodgingChangeStatus || (LodgingChangeStatus = {}));
export var LodgingCompareRowTheme;
(function (LodgingCompareRowTheme) {
  LodgingCompareRowTheme["COMBINE_NEXT"] = "COMBINE_NEXT";
  LodgingCompareRowTheme["SPOTLIGHT"] = "SPOTLIGHT";
  LodgingCompareRowTheme["STANDARD"] = "STANDARD";
})(LodgingCompareRowTheme || (LodgingCompareRowTheme = {}));
export var LodgingCrossSellProductType;
(function (LodgingCrossSellProductType) {
  LodgingCrossSellProductType["CAR"] = "CAR";
})(LodgingCrossSellProductType || (LodgingCrossSellProductType = {}));
export var LodgingFormMethod;
(function (LodgingFormMethod) {
  LodgingFormMethod["GET"] = "GET";
  LodgingFormMethod["POST"] = "POST";
})(LodgingFormMethod || (LodgingFormMethod = {}));
export var LodgingInputType;
(function (LodgingInputType) {
  LodgingInputType["EMAIL"] = "EMAIL";
  LodgingInputType["HIDDEN"] = "HIDDEN";
  LodgingInputType["PASSWORD"] = "PASSWORD";
  LodgingInputType["TEL"] = "TEL";
  LodgingInputType["TEXT"] = "TEXT";
  LodgingInputType["URL"] = "URL";
})(LodgingInputType || (LodgingInputType = {}));
export var LodgingMessageTheme;
(function (LodgingMessageTheme) {
  LodgingMessageTheme["AGGRESSIVE"] = "AGGRESSIVE";
  LodgingMessageTheme["EMPHASIS"] = "EMPHASIS";
  LodgingMessageTheme["HIGHLIGHT"] = "HIGHLIGHT";
  LodgingMessageTheme["STRONG"] = "STRONG";
})(LodgingMessageTheme || (LodgingMessageTheme = {}));
export var LodgingStepperState;
(function (LodgingStepperState) {
  LodgingStepperState["COMPLETE"] = "COMPLETE";
  LodgingStepperState["CURRENT"] = "CURRENT";
  LodgingStepperState["INCOMPLETE"] = "INCOMPLETE";
})(LodgingStepperState || (LodgingStepperState = {}));
export var LodgingSupplierRemittanceType;
(function (LodgingSupplierRemittanceType) {
  LodgingSupplierRemittanceType["GROSS"] = "GROSS";
  LodgingSupplierRemittanceType["INVALID"] = "INVALID";
  LodgingSupplierRemittanceType["NET"] = "NET";
})(LodgingSupplierRemittanceType || (LodgingSupplierRemittanceType = {}));
export var LodgingTrackPosition;
(function (LodgingTrackPosition) {
  LodgingTrackPosition["END"] = "END";
  LodgingTrackPosition["STANDARD"] = "STANDARD";
  LodgingTrackPosition["START"] = "START";
})(LodgingTrackPosition || (LodgingTrackPosition = {}));
export var LogoutStatus;
(function (LogoutStatus) {
  LogoutStatus["FAILURE"] = "FAILURE";
  LogoutStatus["SUCCESS"] = "SUCCESS";
})(LogoutStatus || (LogoutStatus = {}));
export var LoyaltyExperienceStrategy;
(function (LoyaltyExperienceStrategy) {
  LoyaltyExperienceStrategy["HIDE_TRAVELER_INFO_AND_REWARDS_LINK"] = "HIDE_TRAVELER_INFO_AND_REWARDS_LINK";
  LoyaltyExperienceStrategy["SHOW_TRAVELER_INFO_AND_REWARDS_LINK"] = "SHOW_TRAVELER_INFO_AND_REWARDS_LINK";
})(LoyaltyExperienceStrategy || (LoyaltyExperienceStrategy = {}));
export var MFAVerificationMethod;
(function (MFAVerificationMethod) {
  MFAVerificationMethod["CALL"] = "CALL";
  MFAVerificationMethod["SMS"] = "SMS";
})(MFAVerificationMethod || (MFAVerificationMethod = {}));
export var MODERATION_RULE;
(function (MODERATION_RULE) {
  MODERATION_RULE["ADVISE"] = "ADVISE";
  MODERATION_RULE["BLOCK"] = "BLOCK";
})(MODERATION_RULE || (MODERATION_RULE = {}));
export var MODERATION_TYPE;
(function (MODERATION_TYPE) {
  MODERATION_TYPE["CHARACTER"] = "CHARACTER";
  MODERATION_TYPE["WORD"] = "WORD";
})(MODERATION_TYPE || (MODERATION_TYPE = {}));
export var MapIconType;
(function (MapIconType) {
  MapIconType["AIRPORT"] = "AIRPORT";
  MapIconType["CIRCLE"] = "CIRCLE";
  MapIconType["GROCERY"] = "GROCERY";
  MapIconType["HOTEL"] = "HOTEL";
  MapIconType["LANDMARK"] = "LANDMARK";
  MapIconType["METRO"] = "METRO";
  MapIconType["RESTAURANT"] = "RESTAURANT";
  MapIconType["SOLD_OUT"] = "SOLD_OUT";
  MapIconType["TRAIN"] = "TRAIN";
  MapIconType["VACATION_RENTAL"] = "VACATION_RENTAL";
})(MapIconType || (MapIconType = {}));
export var MapToolbarActionsType;
(function (MapToolbarActionsType) {
  MapToolbarActionsType["FILTERS"] = "FILTERS";
})(MapToolbarActionsType || (MapToolbarActionsType = {}));
export var MarkItemPosition;
(function (MarkItemPosition) {
  MarkItemPosition["LEFT"] = "LEFT";
  MarkItemPosition["RIGHT"] = "RIGHT";
})(MarkItemPosition || (MarkItemPosition = {}));
export var MarkerType;
(function (MarkerType) {
  MarkerType["HOTEL"] = "HOTEL";
  MarkerType["POI"] = "POI";
})(MarkerType || (MarkerType = {}));
export var MarketingFeedCardStatus;
(function (MarketingFeedCardStatus) {
  MarketingFeedCardStatus["ACTIVE"] = "ACTIVE";
  MarketingFeedCardStatus["DELETED"] = "DELETED";
  MarketingFeedCardStatus["INACTIVE"] = "INACTIVE";
})(MarketingFeedCardStatus || (MarketingFeedCardStatus = {}));
export var MarkupTextType;
(function (MarkupTextType) {
  MarkupTextType["HTML"] = "HTML";
})(MarkupTextType || (MarkupTextType = {}));
export var MarqueeCardType;
(function (MarqueeCardType) {
  MarqueeCardType["CENTER_STAGE"] = "CENTER_STAGE";
  MarqueeCardType["COLLECTION"] = "COLLECTION";
  MarqueeCardType["FULL_BLEED"] = "FULL_BLEED";
})(MarqueeCardType || (MarqueeCardType = {}));
export var MealPlanFilterAmenity;
(function (MealPlanFilterAmenity) {
  MealPlanFilterAmenity["ALL_INCLUSIVE"] = "ALL_INCLUSIVE";
  MealPlanFilterAmenity["FREE_BREAKFAST"] = "FREE_BREAKFAST";
  MealPlanFilterAmenity["FULL_BOARD"] = "FULL_BOARD";
  MealPlanFilterAmenity["HALF_BOARD"] = "HALF_BOARD";
})(MealPlanFilterAmenity || (MealPlanFilterAmenity = {}));
export var MediaContentType;
(function (MediaContentType) {
  MediaContentType["IMAGE"] = "IMAGE";
  MediaContentType["VIDEO"] = "VIDEO";
})(MediaContentType || (MediaContentType = {}));
export var MediaType;
(function (MediaType) {
  MediaType["IMAGE"] = "IMAGE";
  MediaType["VIDEO"] = "VIDEO";
})(MediaType || (MediaType = {}));
export var MemberWalletBadgeTheme;
(function (MemberWalletBadgeTheme) {
  MemberWalletBadgeTheme["LOYALTY_HIGH_TIER"] = "LOYALTY_HIGH_TIER";
  MemberWalletBadgeTheme["LOYALTY_LOW_TIER"] = "LOYALTY_LOW_TIER";
  MemberWalletBadgeTheme["LOYALTY_MIDDLE_TIER"] = "LOYALTY_MIDDLE_TIER";
})(MemberWalletBadgeTheme || (MemberWalletBadgeTheme = {}));
export var MerchandisingDetailsCardPath;
(function (MerchandisingDetailsCardPath) {
  MerchandisingDetailsCardPath["CHECKOUT"] = "CHECKOUT";
  MerchandisingDetailsCardPath["CONFIRMATION"] = "CONFIRMATION";
})(MerchandisingDetailsCardPath || (MerchandisingDetailsCardPath = {}));
export var MessageContentTheme;
(function (MessageContentTheme) {
  MessageContentTheme["CRITICAL"] = "CRITICAL";
  MessageContentTheme["ERROR"] = "ERROR";
})(MessageContentTheme || (MessageContentTheme = {}));
export var MessageType;
(function (MessageType) {
  MessageType["ADDON"] = "ADDON";
  MessageType["BRANDED_THEME_ACCENT"] = "BRANDED_THEME_ACCENT";
  MessageType["BRANDED_THEME_DARK"] = "BRANDED_THEME_DARK";
  MessageType["BRANDED_THEME_LIGHT"] = "BRANDED_THEME_LIGHT";
  MessageType["COMPRESSION"] = "COMPRESSION";
  MessageType["COUPON"] = "COUPON";
  MessageType["DOUBLE_POINTS"] = "DOUBLE_POINTS";
  MessageType["FREE_CANCELLATION"] = "FREE_CANCELLATION";
  MessageType["FREE_LX"] = "FREE_LX";
  MessageType["HOTRATE"] = "HOTRATE";
  MessageType["LEGAL"] = "LEGAL";
  MessageType["LOGIN"] = "LOGIN";
  MessageType["LOYALTY"] = "LOYALTY";
  MessageType["NO_CC"] = "NO_CC";
  MessageType["OVERFILTERED"] = "OVERFILTERED";
  MessageType["PARTNER_LINKOFF"] = "PARTNER_LINKOFF";
  MessageType["VACATION_RENTALS"] = "VACATION_RENTALS";
})(MessageType || (MessageType = {}));
export var MessagingCardTheme;
(function (MessagingCardTheme) {
  MessagingCardTheme["HIGHLIGHT"] = "HIGHLIGHT";
  MessagingCardTheme["PRIMARY"] = "PRIMARY";
  MessagingCardTheme["SECONDARY"] = "SECONDARY";
})(MessagingCardTheme || (MessagingCardTheme = {}));
export var MigrationFlightsQueryState;
(function (MigrationFlightsQueryState) {
  MigrationFlightsQueryState["LOADED"] = "LOADED";
  MigrationFlightsQueryState["LOADING"] = "LOADING";
})(MigrationFlightsQueryState || (MigrationFlightsQueryState = {}));
export var MishopUIDialogButtonType;
(function (MishopUIDialogButtonType) {
  MishopUIDialogButtonType["PRIMARY"] = "PRIMARY";
  MishopUIDialogButtonType["TERTIARY"] = "TERTIARY";
})(MishopUIDialogButtonType || (MishopUIDialogButtonType = {}));
export var MishopUIPlacardType;
(function (MishopUIPlacardType) {
  MishopUIPlacardType["PRICE_CHANGE"] = "PRICE_CHANGE";
})(MishopUIPlacardType || (MishopUIPlacardType = {}));
export var MishopUIProductType;
(function (MishopUIProductType) {
  MishopUIProductType["ACTIVITY"] = "ACTIVITY";
  MishopUIProductType["CAR"] = "CAR";
  MishopUIProductType["FLIGHT"] = "FLIGHT";
  MishopUIProductType["GROUND_TRANSFER"] = "GROUND_TRANSFER";
  MishopUIProductType["PROPERTY"] = "PROPERTY";
})(MishopUIProductType || (MishopUIProductType = {}));
export var ModalNavType;
(function (ModalNavType) {
  ModalNavType["BACK"] = "BACK";
  ModalNavType["CLOSE"] = "CLOSE";
  ModalNavType["EXPAND_DOWN"] = "EXPAND_DOWN";
  ModalNavType["EXPAND_UP"] = "EXPAND_UP";
})(ModalNavType || (ModalNavType = {}));
export var ModalSideSheetLayout;
(function (ModalSideSheetLayout) {
  ModalSideSheetLayout["EIGHT"] = "EIGHT";
  ModalSideSheetLayout["FOUR"] = "FOUR";
  ModalSideSheetLayout["ONE"] = "ONE";
  ModalSideSheetLayout["SIX"] = "SIX";
  ModalSideSheetLayout["THREE"] = "THREE";
  ModalSideSheetLayout["TWELVE"] = "TWELVE";
  ModalSideSheetLayout["TWO"] = "TWO";
})(ModalSideSheetLayout || (ModalSideSheetLayout = {}));
export var ModuleName;
(function (ModuleName) {
  ModuleName["COUPON_MODULE"] = "COUPON_MODULE";
})(ModuleName || (ModuleName = {}));
export var ModuleNames;
(function (ModuleNames) {
  ModuleNames["DISCOVER"] = "DISCOVER";
  ModuleNames["HOME"] = "HOME";
})(ModuleNames || (ModuleNames = {}));
export var MonthId;
(function (MonthId) {
  MonthId["APRIL"] = "APRIL";
  MonthId["AUGUST"] = "AUGUST";
  MonthId["DECEMBER"] = "DECEMBER";
  MonthId["FEBRUARY"] = "FEBRUARY";
  MonthId["JANUARY"] = "JANUARY";
  MonthId["JULY"] = "JULY";
  MonthId["JUNE"] = "JUNE";
  MonthId["MARCH"] = "MARCH";
  MonthId["MAY"] = "MAY";
  MonthId["NOVEMBER"] = "NOVEMBER";
  MonthId["OCTOBER"] = "OCTOBER";
  MonthId["SEPTEMBER"] = "SEPTEMBER";
})(MonthId || (MonthId = {}));
export var MultiItemShoppingActionType;
(function (MultiItemShoppingActionType) {
  MultiItemShoppingActionType["CAR_SEARCH"] = "CAR_SEARCH";
  MultiItemShoppingActionType["FLIGHT_SEARCH"] = "FLIGHT_SEARCH";
  MultiItemShoppingActionType["PROPERTY_SEARCH"] = "PROPERTY_SEARCH";
})(MultiItemShoppingActionType || (MultiItemShoppingActionType = {}));
export var NavigationType;
(function (NavigationType) {
  NavigationType["COLLAPSE"] = "COLLAPSE";
  NavigationType["EXPAND"] = "EXPAND";
})(NavigationType || (NavigationType = {}));
export var NoCancelFeeType;
(function (NoCancelFeeType) {
  NoCancelFeeType["NO_CANCEL_FEE"] = "NO_CANCEL_FEE";
})(NoCancelFeeType || (NoCancelFeeType = {}));
export var NoChangeFeeType;
(function (NoChangeFeeType) {
  NoChangeFeeType["NO_CHANGE_FEE"] = "NO_CHANGE_FEE";
})(NoChangeFeeType || (NoChangeFeeType = {}));
export var NotificationFormButtonType;
(function (NotificationFormButtonType) {
  NotificationFormButtonType["FLOATING_ACTION_BUTTON"] = "FLOATING_ACTION_BUTTON";
  NotificationFormButtonType["PRIMARY_BUTTON"] = "PRIMARY_BUTTON";
  NotificationFormButtonType["SECONDARY_BUTTON"] = "SECONDARY_BUTTON";
  NotificationFormButtonType["TERTIARY_BUTTON"] = "TERTIARY_BUTTON";
})(NotificationFormButtonType || (NotificationFormButtonType = {}));
export var NotificationInModuleMessageName;
(function (NotificationInModuleMessageName) {
  NotificationInModuleMessageName["COUPON_REMOVAL_FAILED_MESSAGE"] = "COUPON_REMOVAL_FAILED_MESSAGE";
  NotificationInModuleMessageName["CPM_CC_ON_FILE_MESSAGE"] = "CPM_CC_ON_FILE_MESSAGE";
  NotificationInModuleMessageName["CPM_CC_ON_FILE_ONLY_INSURANCE_PAYMENT_MESSAGE"] = "CPM_CC_ON_FILE_ONLY_INSURANCE_PAYMENT_MESSAGE";
  NotificationInModuleMessageName["CPM_NO_PAYMENT_DUE_MESSAGE"] = "CPM_NO_PAYMENT_DUE_MESSAGE";
  NotificationInModuleMessageName["CPM_ONLY_INSURANCE_PAYMENT_DUE_MESSAGE"] = "CPM_ONLY_INSURANCE_PAYMENT_DUE_MESSAGE";
  NotificationInModuleMessageName["OKC_ADD_FAILED_MESSAGE"] = "OKC_ADD_FAILED_MESSAGE";
  NotificationInModuleMessageName["OKC_REMOVAL_FAILED_MESSAGE"] = "OKC_REMOVAL_FAILED_MESSAGE";
  NotificationInModuleMessageName["PAY_NOW_CC_ON_FILE_GIFT_CARD_COUPON_TOTAL_AMOUNT"] = "PAY_NOW_CC_ON_FILE_GIFT_CARD_COUPON_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PAY_NOW_CC_ON_FILE_GIFT_CARD_TOTAL_AMOUNT"] = "PAY_NOW_CC_ON_FILE_GIFT_CARD_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PAY_NOW_CC_ON_FILE_OKC_COUPON_TOTAL_AMOUNT"] = "PAY_NOW_CC_ON_FILE_OKC_COUPON_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PAY_NOW_CC_ON_FILE_OKC_GIFT_CARD_COUPON_TOTAL_AMOUNT"] = "PAY_NOW_CC_ON_FILE_OKC_GIFT_CARD_COUPON_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PAY_NOW_CC_ON_FILE_OKC_GIFT_CARD_TOTAL_AMOUNT"] = "PAY_NOW_CC_ON_FILE_OKC_GIFT_CARD_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PAY_NOW_CC_ON_FILE_OKC_TOTAL_AMOUNT"] = "PAY_NOW_CC_ON_FILE_OKC_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PAY_NOW_GIFT_CARD_COUPON_TOTAL_AMOUNT"] = "PAY_NOW_GIFT_CARD_COUPON_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PAY_NOW_GIFT_CARD_TOTAL_AMOUNT"] = "PAY_NOW_GIFT_CARD_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PAY_NOW_OKC_COUPON_TOTAL_AMOUNT"] = "PAY_NOW_OKC_COUPON_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PAY_NOW_OKC_GIFT_CARD_COUPON_TOTAL_AMOUNT"] = "PAY_NOW_OKC_GIFT_CARD_COUPON_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PAY_NOW_OKC_GIFT_CARD_TOTAL_AMOUNT"] = "PAY_NOW_OKC_GIFT_CARD_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PAY_NOW_OKC_TOTAL_AMOUNT"] = "PAY_NOW_OKC_TOTAL_AMOUNT";
  NotificationInModuleMessageName["PROMOTION_CHANGED_MESSAGE"] = "PROMOTION_CHANGED_MESSAGE";
})(NotificationInModuleMessageName || (NotificationInModuleMessageName = {}));
export var NotificationInModuleModuleName;
(function (NotificationInModuleModuleName) {
  NotificationInModuleModuleName["COMMON_PAYMENT_MODULE"] = "COMMON_PAYMENT_MODULE";
  NotificationInModuleModuleName["COUPON_MODULE"] = "COUPON_MODULE";
  NotificationInModuleModuleName["LOYALTY_BURN_MODULE"] = "LOYALTY_BURN_MODULE";
  NotificationInModuleModuleName["PROMOTION_CHANGED_MODULE"] = "PROMOTION_CHANGED_MODULE";
})(NotificationInModuleModuleName || (NotificationInModuleModuleName = {}));
export var NotificationLinkTarget;
(function (NotificationLinkTarget) {
  NotificationLinkTarget["NEW_TAB"] = "NEW_TAB";
  NotificationLinkTarget["SAME_TAB"] = "SAME_TAB";
})(NotificationLinkTarget || (NotificationLinkTarget = {}));
export var NotificationLinkType;
(function (NotificationLinkType) {
  NotificationLinkType["ANCHOR"] = "ANCHOR";
  NotificationLinkType["APP_DOWNLOAD_LINK"] = "APP_DOWNLOAD_LINK";
  NotificationLinkType["BUTTON"] = "BUTTON";
  NotificationLinkType["FLOATING_ACTION_BUTTON"] = "FLOATING_ACTION_BUTTON";
  NotificationLinkType["PRIMARY_BUTTON"] = "PRIMARY_BUTTON";
  NotificationLinkType["SECONDARY_BUTTON"] = "SECONDARY_BUTTON";
  NotificationLinkType["TERTIARY_BUTTON"] = "TERTIARY_BUTTON";
})(NotificationLinkType || (NotificationLinkType = {}));
export var NotificationLocation;
(function (NotificationLocation) {
  NotificationLocation["AFTER_HEADER"] = "AFTER_HEADER";
  NotificationLocation["AFTER_WIZARD"] = "AFTER_WIZARD";
  NotificationLocation["FOOTER"] = "FOOTER";
  NotificationLocation["IN_PAGE"] = "IN_PAGE";
  NotificationLocation["IN_PAGE_AD"] = "IN_PAGE_AD";
  NotificationLocation["LEGAL"] = "LEGAL";
  NotificationLocation["POPUP"] = "POPUP";
  NotificationLocation["PRE_HEADER"] = "PRE_HEADER";
})(NotificationLocation || (NotificationLocation = {}));
export var NotificationLocationOnPage;
(function (NotificationLocationOnPage) {
  NotificationLocationOnPage["AFTER_HEADER"] = "AFTER_HEADER";
  NotificationLocationOnPage["AFTER_WIZARD"] = "AFTER_WIZARD";
  NotificationLocationOnPage["ERROR"] = "ERROR";
  NotificationLocationOnPage["FOOTER"] = "FOOTER";
  NotificationLocationOnPage["FULL_PAGE"] = "FULL_PAGE";
  NotificationLocationOnPage["IN_MODAL"] = "IN_MODAL";
  NotificationLocationOnPage["IN_MODULE"] = "IN_MODULE";
  NotificationLocationOnPage["IN_PAGE"] = "IN_PAGE";
  NotificationLocationOnPage["IN_PAGE_AD"] = "IN_PAGE_AD";
  NotificationLocationOnPage["LEGAL"] = "LEGAL";
  NotificationLocationOnPage["POPUP"] = "POPUP";
  NotificationLocationOnPage["PRE_HEADER"] = "PRE_HEADER";
})(NotificationLocationOnPage || (NotificationLocationOnPage = {}));
export var NotificationMessageCardLayout;
(function (NotificationMessageCardLayout) {
  NotificationMessageCardLayout["ICON_ON_SIDE"] = "ICON_ON_SIDE";
  NotificationMessageCardLayout["ICON_ON_TOP"] = "ICON_ON_TOP";
  NotificationMessageCardLayout["WITH_BORDER"] = "WITH_BORDER";
})(NotificationMessageCardLayout || (NotificationMessageCardLayout = {}));
export var NotificationMessageDisplayType;
(function (NotificationMessageDisplayType) {
  NotificationMessageDisplayType["FORM"] = "FORM";
  NotificationMessageDisplayType["PAGE"] = "PAGE";
})(NotificationMessageDisplayType || (NotificationMessageDisplayType = {}));
export var NotificationMessageLevel;
(function (NotificationMessageLevel) {
  NotificationMessageLevel["ERROR"] = "ERROR";
  NotificationMessageLevel["INFO"] = "INFO";
  NotificationMessageLevel["SUCCESS"] = "SUCCESS";
  NotificationMessageLevel["WARNING"] = "WARNING";
})(NotificationMessageLevel || (NotificationMessageLevel = {}));
export var NotificationMessageType;
(function (NotificationMessageType) {
  NotificationMessageType["INVALID_INPUT"] = "INVALID_INPUT";
  NotificationMessageType["SERVICE_ERROR"] = "SERVICE_ERROR";
  NotificationMessageType["SUCCESS"] = "SUCCESS";
})(NotificationMessageType || (NotificationMessageType = {}));
export var NotificationPhraseLinkStyle;
(function (NotificationPhraseLinkStyle) {
  NotificationPhraseLinkStyle["FLOATING_ACTION_BUTTON"] = "FLOATING_ACTION_BUTTON";
  NotificationPhraseLinkStyle["PRIMARY_BUTTON"] = "PRIMARY_BUTTON";
  NotificationPhraseLinkStyle["SECONDARY_BUTTON"] = "SECONDARY_BUTTON";
  NotificationPhraseLinkStyle["TERTIARY_BUTTON"] = "TERTIARY_BUTTON";
})(NotificationPhraseLinkStyle || (NotificationPhraseLinkStyle = {}));
export var NotificationPhraseLinkTheme;
(function (NotificationPhraseLinkTheme) {
  NotificationPhraseLinkTheme["DEFAULT"] = "DEFAULT";
  NotificationPhraseLinkTheme["INVERSE"] = "INVERSE";
})(NotificationPhraseLinkTheme || (NotificationPhraseLinkTheme = {}));
export var NotificationPhrasePartStyle;
(function (NotificationPhrasePartStyle) {
  NotificationPhrasePartStyle["EMPHASIS"] = "EMPHASIS";
  NotificationPhrasePartStyle["IMPORTANT"] = "IMPORTANT";
  NotificationPhrasePartStyle["MEDIUM"] = "MEDIUM";
  NotificationPhrasePartStyle["STANDARD"] = "STANDARD";
})(NotificationPhrasePartStyle || (NotificationPhrasePartStyle = {}));
export var NotificationPreferenceType;
(function (NotificationPreferenceType) {
  NotificationPreferenceType["EMAIL"] = "EMAIL";
  NotificationPreferenceType["PUSH"] = "PUSH";
})(NotificationPreferenceType || (NotificationPreferenceType = {}));
export var NotificationState;
(function (NotificationState) {
  NotificationState["READ"] = "READ";
  NotificationState["SEEN"] = "SEEN";
  NotificationState["UNKNOWN"] = "UNKNOWN";
  NotificationState["UNSEEN"] = "UNSEEN";
})(NotificationState || (NotificationState = {}));
export var NotificationSubType;
(function (NotificationSubType) {
  NotificationSubType["COUPON_CAMPAIGN"] = "COUPON_CAMPAIGN";
  NotificationSubType["DEFAULT"] = "DEFAULT";
})(NotificationSubType || (NotificationSubType = {}));
export var NotificationType;
(function (NotificationType) {
  NotificationType["ALERT"] = "ALERT";
  NotificationType["APP_DOWNLOAD"] = "APP_DOWNLOAD";
  NotificationType["APP_HERO"] = "APP_HERO";
  NotificationType["BOTTOM_SHEET"] = "BOTTOM_SHEET";
  NotificationType["CENTERED_SHEET"] = "CENTERED_SHEET";
  NotificationType["CITI_COBRAND"] = "CITI_COBRAND";
  NotificationType["CITI_COBRAND_VERTICAL"] = "CITI_COBRAND_VERTICAL";
  NotificationType["CLEANLINESS"] = "CLEANLINESS";
  NotificationType["CONFIGURABLE"] = "CONFIGURABLE";
  NotificationType["ERROR"] = "ERROR";
  NotificationType["FULL_PAGE"] = "FULL_PAGE";
  NotificationType["FULL_SHEET"] = "FULL_SHEET";
  NotificationType["FULL_SHEET_MEDIA"] = "FULL_SHEET_MEDIA";
  NotificationType["GENERIC"] = "GENERIC";
  NotificationType["HEADER"] = "HEADER";
  NotificationType["INBOX"] = "INBOX";
  NotificationType["MAD_MARQUEE"] = "MAD_MARQUEE";
  NotificationType["NONE"] = "NONE";
  NotificationType["ONETRUST"] = "ONETRUST";
  NotificationType["ONE_KEY"] = "ONE_KEY";
  NotificationType["OSANO"] = "OSANO";
  NotificationType["POPOVER"] = "POPOVER";
  NotificationType["RAF_BANNER"] = "RAF_BANNER";
  NotificationType["TAKEOVER"] = "TAKEOVER";
  NotificationType["TRAVEL_ADVISORY"] = "TRAVEL_ADVISORY";
  NotificationType["TRUST_MSG_BANNER"] = "TRUST_MSG_BANNER";
  NotificationType["UNRECOVERABLE_ERROR"] = "UNRECOVERABLE_ERROR";
})(NotificationType || (NotificationType = {}));
export var NotificationUserActionType;
(function (NotificationUserActionType) {
  NotificationUserActionType["DELETE"] = "DELETE";
  NotificationUserActionType["MARK_AS_READ"] = "MARK_AS_READ";
  NotificationUserActionType["MARK_AS_UNREAD"] = "MARK_AS_UNREAD";
  NotificationUserActionType["UNKNOWN"] = "UNKNOWN";
})(NotificationUserActionType || (NotificationUserActionType = {}));
export var NotificationView;
(function (NotificationView) {
  NotificationView["CAROUSEL"] = "CAROUSEL";
  NotificationView["GRID"] = "GRID";
})(NotificationView || (NotificationView = {}));
export var NotificationsType;
(function (NotificationsType) {
  NotificationsType["ALL"] = "ALL";
})(NotificationsType || (NotificationsType = {}));
export var OfferAttributeType;
(function (OfferAttributeType) {
  OfferAttributeType["FREE_BREAKFAST"] = "FREE_BREAKFAST";
  OfferAttributeType["FREE_CANCELLATION"] = "FREE_CANCELLATION";
  OfferAttributeType["NO_CC_REQUIRED"] = "NO_CC_REQUIRED";
  OfferAttributeType["PAYMENT_CHOICE"] = "PAYMENT_CHOICE";
  OfferAttributeType["VIP"] = "VIP";
})(OfferAttributeType || (OfferAttributeType = {}));
export var OfferIdentityType;
(function (OfferIdentityType) {
  OfferIdentityType["EG_OFFER_ID"] = "EG_OFFER_ID";
  OfferIdentityType["EG_OFFER_TOKEN"] = "EG_OFFER_TOKEN";
})(OfferIdentityType || (OfferIdentityType = {}));
export var OffersInquiryProminence;
(function (OffersInquiryProminence) {
  OffersInquiryProminence["HIGH"] = "HIGH";
  OffersInquiryProminence["LOW"] = "LOW";
  OffersInquiryProminence["MEDIUM"] = "MEDIUM";
})(OffersInquiryProminence || (OffersInquiryProminence = {}));
export var OffersRecommendationContainerType;
(function (OffersRecommendationContainerType) {
  OffersRecommendationContainerType["CAROUSEL"] = "CAROUSEL";
  OffersRecommendationContainerType["GRID"] = "GRID";
})(OffersRecommendationContainerType || (OffersRecommendationContainerType = {}));
export var OffersRecommendationStrategy;
(function (OffersRecommendationStrategy) {
  OffersRecommendationStrategy["ALTERNATE"] = "ALTERNATE";
  OffersRecommendationStrategy["ATTACH"] = "ATTACH";
  OffersRecommendationStrategy["CO_CLICK"] = "CO_CLICK";
  OffersRecommendationStrategy["NEARBY"] = "NEARBY";
  OffersRecommendationStrategy["POPULAR"] = "POPULAR";
  OffersRecommendationStrategy["TRENDING"] = "TRENDING";
})(OffersRecommendationStrategy || (OffersRecommendationStrategy = {}));
export var OneIdentityOnboardingClientAction;
(function (OneIdentityOnboardingClientAction) {
  OneIdentityOnboardingClientAction["IDENTITY_MERGE"] = "IDENTITY_MERGE";
  OneIdentityOnboardingClientAction["LOGOUT"] = "LOGOUT";
  OneIdentityOnboardingClientAction["NONE"] = "NONE";
  OneIdentityOnboardingClientAction["NONE_JOURNEY_IS_COMPLETE"] = "NONE_JOURNEY_IS_COMPLETE";
})(OneIdentityOnboardingClientAction || (OneIdentityOnboardingClientAction = {}));
export var OneKeyOnboardingOperationStatus;
(function (OneKeyOnboardingOperationStatus) {
  OneKeyOnboardingOperationStatus["FAILED"] = "FAILED";
  OneKeyOnboardingOperationStatus["SUCCEEDED"] = "SUCCEEDED";
  OneKeyOnboardingOperationStatus["TIMED_OUT"] = "TIMED_OUT";
})(OneKeyOnboardingOperationStatus || (OneKeyOnboardingOperationStatus = {}));
export var OneKeyUniversalOnboardingOperationStatus;
(function (OneKeyUniversalOnboardingOperationStatus) {
  OneKeyUniversalOnboardingOperationStatus["FAILED"] = "FAILED";
  OneKeyUniversalOnboardingOperationStatus["FAILED_BLOCKING"] = "FAILED_BLOCKING";
  OneKeyUniversalOnboardingOperationStatus["SUCCEEDED"] = "SUCCEEDED";
  OneKeyUniversalOnboardingOperationStatus["UNKNOWN_OPERATION"] = "UNKNOWN_OPERATION";
})(OneKeyUniversalOnboardingOperationStatus || (OneKeyUniversalOnboardingOperationStatus = {}));
export var Operation;
(function (Operation) {
  Operation["ADDITION"] = "ADDITION";
  Operation["MULTIPLICATION"] = "MULTIPLICATION";
})(Operation || (Operation = {}));
export var OperationStatus;
(function (OperationStatus) {
  OperationStatus["FAILURE"] = "FAILURE";
  OperationStatus["INVALID_INPUT"] = "INVALID_INPUT";
  OperationStatus["SUCCESS"] = "SUCCESS";
})(OperationStatus || (OperationStatus = {}));
export var OperationType;
(function (OperationType) {
  OperationType["SMART_FORM_LOAD"] = "SMART_FORM_LOAD";
  OperationType["SMART_FORM_SUBMIT"] = "SMART_FORM_SUBMIT";
})(OperationType || (OperationType = {}));
export var Orientation;
(function (Orientation) {
  Orientation["LANDSCAPE"] = "LANDSCAPE";
  Orientation["PORTRAIT"] = "PORTRAIT";
})(Orientation || (Orientation = {}));
export var Overflow;
(function (Overflow) {
  Overflow["TRUNCATE"] = "TRUNCATE";
  Overflow["TRUNCATE_FOUR_LINES"] = "TRUNCATE_FOUR_LINES";
  Overflow["TRUNCATE_THREE_LINES"] = "TRUNCATE_THREE_LINES";
  Overflow["TRUNCATE_TWO_LINES"] = "TRUNCATE_TWO_LINES";
  Overflow["WRAP"] = "WRAP";
})(Overflow || (Overflow = {}));
export var PackageOfferingType;
(function (PackageOfferingType) {
  PackageOfferingType["FLIGHT_CAR"] = "FLIGHT_CAR";
  PackageOfferingType["FLIGHT_HOTEL"] = "FLIGHT_HOTEL";
  PackageOfferingType["FLIGHT_HOTEL_CAR"] = "FLIGHT_HOTEL_CAR";
  PackageOfferingType["HOTEL_CAR"] = "HOTEL_CAR";
})(PackageOfferingType || (PackageOfferingType = {}));
export var PackageSort;
(function (PackageSort) {
  PackageSort["PRICE_HIGH_TO_LOW"] = "PRICE_HIGH_TO_LOW";
  PackageSort["PRICE_LOW_TO_HIGH"] = "PRICE_LOW_TO_HIGH";
  PackageSort["RECOMMENDED"] = "RECOMMENDED";
})(PackageSort || (PackageSort = {}));
export var PackageType;
(function (PackageType) {
  PackageType["FLIGHT_CAR"] = "FLIGHT_CAR";
  PackageType["FLIGHT_HOTEL"] = "FLIGHT_HOTEL";
  PackageType["FLIGHT_HOTEL_CAR"] = "FLIGHT_HOTEL_CAR";
  PackageType["HOTEL_ACTIVITY"] = "HOTEL_ACTIVITY";
  PackageType["HOTEL_CAR"] = "HOTEL_CAR";
})(PackageType || (PackageType = {}));
export var PackageUIMessagingCardType;
(function (PackageUIMessagingCardType) {
  PackageUIMessagingCardType["GT_JUMP_LINK"] = "GT_JUMP_LINK";
  PackageUIMessagingCardType["INVALID"] = "INVALID";
})(PackageUIMessagingCardType || (PackageUIMessagingCardType = {}));
export var PageLocation;
(function (PageLocation) {
  PageLocation["CFAR_DETAILS"] = "CFAR_DETAILS";
  PageLocation["CHECKOUT"] = "CHECKOUT";
  PageLocation["CHECKOUT_ERROR"] = "CHECKOUT_ERROR";
  PageLocation["CONFIRMATION"] = "CONFIRMATION";
  PageLocation["DETAILS"] = "DETAILS";
  PageLocation["ENTRY"] = "ENTRY";
  PageLocation["FEATURES"] = "FEATURES";
  PageLocation["INSURANCE_DETAILS"] = "INSURANCE_DETAILS";
  PageLocation["ITINERARY"] = "ITINERARY";
  PageLocation["PAYMENT_ERROR"] = "PAYMENT_ERROR";
  PageLocation["POST_BOOKING"] = "POST_BOOKING";
  PageLocation["SEARCH_RESULTS"] = "SEARCH_RESULTS";
  PageLocation["SYSTEM"] = "SYSTEM";
  PageLocation["USER"] = "USER";
})(PageLocation || (PageLocation = {}));
export var PageType;
(function (PageType) {
  PageType["DISCOVER_TAB"] = "DISCOVER_TAB";
  PageType["HOME_PAGE"] = "HOME_PAGE";
  PageType["TRAVEL_GUIDE"] = "TRAVEL_GUIDE";
  PageType["TRIP_OVERVIEW"] = "TRIP_OVERVIEW";
  PageType["WISH_LIST"] = "WISH_LIST";
})(PageType || (PageType = {}));
export var Partner;
(function (Partner) {
  Partner["AGENCY"] = "AGENCY";
  Partner["MERCHANT"] = "MERCHANT";
  Partner["MERCHANT_AGENCY"] = "MERCHANT_AGENCY";
})(Partner || (Partner = {}));
export var PartnerType;
(function (PartnerType) {
  PartnerType["AGENCY"] = "AGENCY";
  PartnerType["MERCHANT"] = "MERCHANT";
  PartnerType["MERCHANT_AGENCY"] = "MERCHANT_AGENCY";
})(PartnerType || (PartnerType = {}));
export var PasswordWithStrengthMeterInputStrength;
(function (PasswordWithStrengthMeterInputStrength) {
  PasswordWithStrengthMeterInputStrength["STRONG"] = "STRONG";
  PasswordWithStrengthMeterInputStrength["VERY_STRONG"] = "VERY_STRONG";
  PasswordWithStrengthMeterInputStrength["WEAK"] = "WEAK";
})(PasswordWithStrengthMeterInputStrength || (PasswordWithStrengthMeterInputStrength = {}));
export var PasswordWithStrengthMeterProgressBarTheme;
(function (PasswordWithStrengthMeterProgressBarTheme) {
  PasswordWithStrengthMeterProgressBarTheme["MUTED"] = "MUTED";
  PasswordWithStrengthMeterProgressBarTheme["POSITIVE"] = "POSITIVE";
})(PasswordWithStrengthMeterProgressBarTheme || (PasswordWithStrengthMeterProgressBarTheme = {}));
export var PaymentAssistCardTypeInput;
(function (PaymentAssistCardTypeInput) {
  PaymentAssistCardTypeInput["NEW"] = "NEW";
  PaymentAssistCardTypeInput["ORIGINAL"] = "ORIGINAL";
  PaymentAssistCardTypeInput["SAVED"] = "SAVED";
})(PaymentAssistCardTypeInput || (PaymentAssistCardTypeInput = {}));
export var PaymentAssistTransactionTypeInput;
(function (PaymentAssistTransactionTypeInput) {
  PaymentAssistTransactionTypeInput["CHARGE"] = "CHARGE";
  PaymentAssistTransactionTypeInput["REFUND"] = "REFUND";
})(PaymentAssistTransactionTypeInput || (PaymentAssistTransactionTypeInput = {}));
export var PaymentAssistTransactionTypeOutput;
(function (PaymentAssistTransactionTypeOutput) {
  PaymentAssistTransactionTypeOutput["CHARGE"] = "CHARGE";
  PaymentAssistTransactionTypeOutput["REFUND"] = "REFUND";
})(PaymentAssistTransactionTypeOutput || (PaymentAssistTransactionTypeOutput = {}));
export var PaymentDeviceType;
(function (PaymentDeviceType) {
  PaymentDeviceType["APP_PHONE"] = "APP_PHONE";
  PaymentDeviceType["APP_TABLET"] = "APP_TABLET";
  PaymentDeviceType["DESKTOP"] = "DESKTOP";
  PaymentDeviceType["DEVICE_TYPE_INVALID"] = "DEVICE_TYPE_INVALID";
  PaymentDeviceType["MOBILE_PHONE"] = "MOBILE_PHONE";
  PaymentDeviceType["MOBILE_TABLET"] = "MOBILE_TABLET";
})(PaymentDeviceType || (PaymentDeviceType = {}));
export var PaymentEntryURN;
(function (PaymentEntryURN) {
  PaymentEntryURN["PAYMENT_APPLE_PAY_COUNTRY_CODE"] = "PAYMENT_APPLE_PAY_COUNTRY_CODE";
  PaymentEntryURN["PAYMENT_APPLE_PAY_EMAIL_ADDRESS"] = "PAYMENT_APPLE_PAY_EMAIL_ADDRESS";
  PaymentEntryURN["PAYMENT_APPLE_PAY_FIRST_NAME"] = "PAYMENT_APPLE_PAY_FIRST_NAME";
  PaymentEntryURN["PAYMENT_APPLE_PAY_LAST_NAME"] = "PAYMENT_APPLE_PAY_LAST_NAME";
  PaymentEntryURN["PAYMENT_APPLE_PAY_PHONE_NUMBER"] = "PAYMENT_APPLE_PAY_PHONE_NUMBER";
  PaymentEntryURN["PAYMENT_APPLE_PAY_POSTAL_CODE"] = "PAYMENT_APPLE_PAY_POSTAL_CODE";
  PaymentEntryURN["PAYMENT_BILLING_ZIPCODE"] = "PAYMENT_BILLING_ZIPCODE";
  PaymentEntryURN["UNKNOWN"] = "UNKNOWN";
})(PaymentEntryURN || (PaymentEntryURN = {}));
export var PaymentMethodType;
(function (PaymentMethodType) {
  PaymentMethodType["AFFIRM"] = "AFFIRM";
  PaymentMethodType["APPLE_PAY"] = "APPLE_PAY";
  PaymentMethodType["CREDIT_CARD"] = "CREDIT_CARD";
  PaymentMethodType["GIFT_CARD"] = "GIFT_CARD";
  PaymentMethodType["INVALID"] = "INVALID";
  PaymentMethodType["LOYALTY"] = "LOYALTY";
  PaymentMethodType["NAVER_PAY"] = "NAVER_PAY";
  PaymentMethodType["UNRECOGNIZED"] = "UNRECOGNIZED";
})(PaymentMethodType || (PaymentMethodType = {}));
export var PaymentNotificationLocation;
(function (PaymentNotificationLocation) {
  PaymentNotificationLocation["IN_MODULE"] = "IN_MODULE";
})(PaymentNotificationLocation || (PaymentNotificationLocation = {}));
export var PaymentNotificationMessageName;
(function (PaymentNotificationMessageName) {
  PaymentNotificationMessageName["CPM_CC_ON_FILE_MESSAGE"] = "CPM_CC_ON_FILE_MESSAGE";
  PaymentNotificationMessageName["CPM_CC_ON_FILE_ONLY_INSURANCE_PAYMENT_MESSAGE"] = "CPM_CC_ON_FILE_ONLY_INSURANCE_PAYMENT_MESSAGE";
  PaymentNotificationMessageName["CPM_NO_PAYMENT_DUE_MESSAGE"] = "CPM_NO_PAYMENT_DUE_MESSAGE";
  PaymentNotificationMessageName["CPM_ONLY_INSURANCE_PAYMENT_DUE_MESSAGE"] = "CPM_ONLY_INSURANCE_PAYMENT_DUE_MESSAGE";
  PaymentNotificationMessageName["INVALID_MESSAGE"] = "INVALID_MESSAGE";
  PaymentNotificationMessageName["PAY_NOW_GIFT_CARD_TOTAL_AMOUNT"] = "PAY_NOW_GIFT_CARD_TOTAL_AMOUNT";
  PaymentNotificationMessageName["PAY_NOW_OKC_TOTAL_AMOUNT"] = "PAY_NOW_OKC_TOTAL_AMOUNT";
})(PaymentNotificationMessageName || (PaymentNotificationMessageName = {}));
export var PaymentNotificationModuleName;
(function (PaymentNotificationModuleName) {
  PaymentNotificationModuleName["COMMON_PAYMENT_MODULE"] = "COMMON_PAYMENT_MODULE";
})(PaymentNotificationModuleName || (PaymentNotificationModuleName = {}));
export var PaymentPayType;
(function (PaymentPayType) {
  PaymentPayType["PAY_LATER"] = "PAY_LATER";
  PaymentPayType["PAY_NOW"] = "PAY_NOW";
})(PaymentPayType || (PaymentPayType = {}));
export var PaymentState;
(function (PaymentState) {
  PaymentState["STATE_CANCELLED"] = "STATE_CANCELLED";
  PaymentState["STATE_DISABLED"] = "STATE_DISABLED";
  PaymentState["STATE_INVALID"] = "STATE_INVALID";
  PaymentState["STATE_REMOVED"] = "STATE_REMOVED";
  PaymentState["STATE_SELECTED"] = "STATE_SELECTED";
  PaymentState["STATE_UNSELECTED"] = "STATE_UNSELECTED";
  PaymentState["UNRECOGNIZED"] = "UNRECOGNIZED";
})(PaymentState || (PaymentState = {}));
export var PaymentType;
(function (PaymentType) {
  PaymentType["COUPON_ELIGIBLE"] = "COUPON_ELIGIBLE";
  PaymentType["FREE_CANCELLATION"] = "FREE_CANCELLATION";
  PaymentType["GIFT_CARD"] = "GIFT_CARD";
  PaymentType["PAY_LATER"] = "PAY_LATER";
  PaymentType["PAY_LATER_WITH_DEPOSIT"] = "PAY_LATER_WITH_DEPOSIT";
  PaymentType["PAY_WITHOUT_CREDITCARD"] = "PAY_WITHOUT_CREDITCARD";
  PaymentType["PAY_WITH_AFFIRM"] = "PAY_WITH_AFFIRM";
  PaymentType["PAY_WITH_AFTERPAY"] = "PAY_WITH_AFTERPAY";
  PaymentType["PAY_WITH_KLARNA"] = "PAY_WITH_KLARNA";
})(PaymentType || (PaymentType = {}));
export var PaymentTypeLogoSize;
(function (PaymentTypeLogoSize) {
  PaymentTypeLogoSize["LARGE"] = "LARGE";
  PaymentTypeLogoSize["SMALL"] = "SMALL";
})(PaymentTypeLogoSize || (PaymentTypeLogoSize = {}));
export var PersonalizedTheme;
(function (PersonalizedTheme) {
  PersonalizedTheme["BRIGHT_1"] = "BRIGHT_1";
  PersonalizedTheme["DARK_1"] = "DARK_1";
  PersonalizedTheme["GLOBAL_LOYALTY_EXTRA_HIGH"] = "GLOBAL_LOYALTY_EXTRA_HIGH";
  PersonalizedTheme["GLOBAL_LOYALTY_HIGH"] = "GLOBAL_LOYALTY_HIGH";
  PersonalizedTheme["GLOBAL_LOYALTY_LOW"] = "GLOBAL_LOYALTY_LOW";
  PersonalizedTheme["GLOBAL_LOYALTY_MID"] = "GLOBAL_LOYALTY_MID";
  PersonalizedTheme["LIGHT_1"] = "LIGHT_1";
})(PersonalizedTheme || (PersonalizedTheme = {}));
export var PickerTriggerDisplayType;
(function (PickerTriggerDisplayType) {
  PickerTriggerDisplayType["GLOBAL_NAV_BUTTON"] = "GLOBAL_NAV_BUTTON";
  PickerTriggerDisplayType["GLOBAL_NAV_LINK"] = "GLOBAL_NAV_LINK";
})(PickerTriggerDisplayType || (PickerTriggerDisplayType = {}));
export var PickerTriggerPlacementType;
(function (PickerTriggerPlacementType) {
  PickerTriggerPlacementType["MENU_ITEM"] = "MENU_ITEM";
  PickerTriggerPlacementType["NAV_LINK"] = "NAV_LINK";
})(PickerTriggerPlacementType || (PickerTriggerPlacementType = {}));
export var Placement;
(function (Placement) {
  Placement["Horizontal"] = "Horizontal";
  Placement["Vertical"] = "Vertical";
})(Placement || (Placement = {}));
export var PlatformType;
(function (PlatformType) {
  PlatformType["ANDROID"] = "ANDROID";
  PlatformType["IOS"] = "IOS";
  PlatformType["WEB"] = "WEB";
})(PlatformType || (PlatformType = {}));
export var PluralCategory;
(function (PluralCategory) {
  PluralCategory["FEW"] = "FEW";
  PluralCategory["MANY"] = "MANY";
  PluralCategory["ONE"] = "ONE";
  PluralCategory["OTHER"] = "OTHER";
  PluralCategory["TWO"] = "TWO";
  PluralCategory["ZERO"] = "ZERO";
})(PluralCategory || (PluralCategory = {}));
export var Points;
(function (Points) {
  Points["SHOP_WITHOUT_POINTS"] = "SHOP_WITHOUT_POINTS";
  Points["SHOP_WITH_POINTS"] = "SHOP_WITH_POINTS";
})(Points || (Points = {}));
export var PrepareCheckoutResponseOption;
(function (PrepareCheckoutResponseOption) {
  PrepareCheckoutResponseOption["ALTERNATIVE_ACTION_ON_FAILURE"] = "ALTERNATIVE_ACTION_ON_FAILURE";
})(PrepareCheckoutResponseOption || (PrepareCheckoutResponseOption = {}));
export var PriceMatchPromiseChartType;
(function (PriceMatchPromiseChartType) {
  PriceMatchPromiseChartType["BAR"] = "BAR";
  PriceMatchPromiseChartType["LINE"] = "LINE";
})(PriceMatchPromiseChartType || (PriceMatchPromiseChartType = {}));
export var PriceMatchPromiseScaleType;
(function (PriceMatchPromiseScaleType) {
  PriceMatchPromiseScaleType["DATE"] = "DATE";
  PriceMatchPromiseScaleType["LABEL"] = "LABEL";
  PriceMatchPromiseScaleType["TIME"] = "TIME";
})(PriceMatchPromiseScaleType || (PriceMatchPromiseScaleType = {}));
export var PriceMatchPromiseState;
(function (PriceMatchPromiseState) {
  PriceMatchPromiseState["REMOVABLE"] = "REMOVABLE";
  PriceMatchPromiseState["SELECTABLE"] = "SELECTABLE";
  PriceMatchPromiseState["STATIC"] = "STATIC";
  PriceMatchPromiseState["TOGGLEABLE"] = "TOGGLEABLE";
})(PriceMatchPromiseState || (PriceMatchPromiseState = {}));
export var PricingSchemeType;
(function (PricingSchemeType) {
  PricingSchemeType["DAILY_RATE"] = "DAILY_RATE";
  PricingSchemeType["TOTAL_RATE"] = "TOTAL_RATE";
})(PricingSchemeType || (PricingSchemeType = {}));
export var PricingType;
(function (PricingType) {
  PricingType["OccupancyBasedPricing"] = "OccupancyBasedPricing";
  PricingType["PerDayPricing"] = "PerDayPricing";
})(PricingType || (PricingType = {}));
export var PrivacySellState;
(function (PrivacySellState) {
  PrivacySellState["CAN_NOT_SELL"] = "CAN_NOT_SELL";
  PrivacySellState["CAN_SELL"] = "CAN_SELL";
})(PrivacySellState || (PrivacySellState = {}));
export var PrivacyTrackingState;
(function (PrivacyTrackingState) {
  PrivacyTrackingState["CAN_NOT_TRACK"] = "CAN_NOT_TRACK";
  PrivacyTrackingState["CAN_TRACK"] = "CAN_TRACK";
})(PrivacyTrackingState || (PrivacyTrackingState = {}));
export var ProductContext;
(function (ProductContext) {
  ProductContext["BRAND_RESULT_LISTING"] = "BRAND_RESULT_LISTING";
  ProductContext["FLIGHT_SPONSORED_LISTING"] = "FLIGHT_SPONSORED_LISTING";
  ProductContext["LODGING_SPONSORED_LISTING"] = "LODGING_SPONSORED_LISTING";
})(ProductContext || (ProductContext = {}));
export var ProductEntityAssetSelection;
(function (ProductEntityAssetSelection) {
  ProductEntityAssetSelection["ABOUT_PROPERTY"] = "ABOUT_PROPERTY";
  ProductEntityAssetSelection["ACCESSIBILITY_CONTENT"] = "ACCESSIBILITY_CONTENT";
  ProductEntityAssetSelection["AMENITY"] = "AMENITY";
  ProductEntityAssetSelection["AT_A_GLANCE_AMENITIES"] = "AT_A_GLANCE_AMENITIES";
  ProductEntityAssetSelection["EXPERIENCE_SCORE"] = "EXPERIENCE_SCORE";
  ProductEntityAssetSelection["EXPERIENCE_SCORE_WITH_TOP_RATED_ATTRIBUTES"] = "EXPERIENCE_SCORE_WITH_TOP_RATED_ATTRIBUTES";
  ProductEntityAssetSelection["FAMILY_HIGHLIGHTS"] = "FAMILY_HIGHLIGHTS";
  ProductEntityAssetSelection["GUEST_CHOICE"] = "GUEST_CHOICE";
  ProductEntityAssetSelection["HEADLINE"] = "HEADLINE";
  ProductEntityAssetSelection["IMAGE"] = "IMAGE";
  ProductEntityAssetSelection["IMAGE_RICH_AMENITY"] = "IMAGE_RICH_AMENITY";
  ProductEntityAssetSelection["LOCATION_DESCRIPTION"] = "LOCATION_DESCRIPTION";
  ProductEntityAssetSelection["LUXURY_STAYS"] = "LUXURY_STAYS";
  ProductEntityAssetSelection["PLACE"] = "PLACE";
  ProductEntityAssetSelection["POLICY"] = "POLICY";
  ProductEntityAssetSelection["PRODUCT_ATTRIBUTE"] = "PRODUCT_ATTRIBUTE";
  ProductEntityAssetSelection["PRODUCT_HIGHLIGHTS"] = "PRODUCT_HIGHLIGHTS";
  ProductEntityAssetSelection["PRODUCT_UNIQUENESS_HIGHLIGHTS"] = "PRODUCT_UNIQUENESS_HIGHLIGHTS";
  ProductEntityAssetSelection["PROPERTY_AMENITY_GROUPS"] = "PROPERTY_AMENITY_GROUPS";
  ProductEntityAssetSelection["RELEVANT_AMENITY_DETAILS"] = "RELEVANT_AMENITY_DETAILS";
  ProductEntityAssetSelection["REVIEW_DETAILS"] = "REVIEW_DETAILS";
  ProductEntityAssetSelection["REVIEW_IMAGES"] = "REVIEW_IMAGES";
  ProductEntityAssetSelection["REVIEW_SUMMARY"] = "REVIEW_SUMMARY";
  ProductEntityAssetSelection["REVIEW_SUMMARY_HIGHLIGHTS"] = "REVIEW_SUMMARY_HIGHLIGHTS";
  ProductEntityAssetSelection["SUPPLY_SCORE"] = "SUPPLY_SCORE";
  ProductEntityAssetSelection["TOP_RATED_DETAILS"] = "TOP_RATED_DETAILS";
  ProductEntityAssetSelection["VIRTUAL_TOUR"] = "VIRTUAL_TOUR";
})(ProductEntityAssetSelection || (ProductEntityAssetSelection = {}));
export var ProductEntityRefinementSelection;
(function (ProductEntityRefinementSelection) {
  ProductEntityRefinementSelection["EMBARGO"] = "EMBARGO";
})(ProductEntityRefinementSelection || (ProductEntityRefinementSelection = {}));
export var ProductImageFitment;
(function (ProductImageFitment) {
  ProductImageFitment["CONTAIN"] = "CONTAIN";
  ProductImageFitment["COVER"] = "COVER";
})(ProductImageFitment || (ProductImageFitment = {}));
export var ProductImageWidth;
(function (ProductImageWidth) {
  ProductImageWidth["FULL_WIDTH"] = "FULL_WIDTH";
  ProductImageWidth["HALF_WIDTH"] = "HALF_WIDTH";
  ProductImageWidth["QUARTER_WIDTH"] = "QUARTER_WIDTH";
  ProductImageWidth["THIRD_WIDTH"] = "THIRD_WIDTH";
})(ProductImageWidth || (ProductImageWidth = {}));
export var ProductLine;
(function (ProductLine) {
  ProductLine["ACTIVITY"] = "ACTIVITY";
  ProductLine["CAR"] = "CAR";
  ProductLine["CRUISE"] = "CRUISE";
  ProductLine["FLIGHT"] = "FLIGHT";
  ProductLine["GROUND_TRANSFER"] = "GROUND_TRANSFER";
  ProductLine["INSURANCE"] = "INSURANCE";
  ProductLine["LODGING"] = "LODGING";
  ProductLine["PACKAGE"] = "PACKAGE";
  ProductLine["PRODUCT_LINE_INVALID"] = "PRODUCT_LINE_INVALID";
  ProductLine["RAIL"] = "RAIL";
  ProductLine["UNRECOGNIZED"] = "UNRECOGNIZED";
})(ProductLine || (ProductLine = {}));
export var ProductQuestionTopic;
(function (ProductQuestionTopic) {
  ProductQuestionTopic["HOTEL"] = "HOTEL";
})(ProductQuestionTopic || (ProductQuestionTopic = {}));
export var ProductType;
(function (ProductType) {
  ProductType["ARTICLE"] = "ARTICLE";
  ProductType["CAR_DETAILS"] = "CAR_DETAILS";
  ProductType["DESTINATION"] = "DESTINATION";
  ProductType["DESTINATION_EXPERIENCE"] = "DESTINATION_EXPERIENCE";
  ProductType["EXPERIENCE"] = "EXPERIENCE";
  ProductType["EXPERT_TIP"] = "EXPERT_TIP";
  ProductType["FLIGHT_SEARCH"] = "FLIGHT_SEARCH";
  ProductType["LX_DETAILS"] = "LX_DETAILS";
  ProductType["NEIGHBORHOOD"] = "NEIGHBORHOOD";
  ProductType["POI_GAIA"] = "POI_GAIA";
  ProductType["PROPERTY_DETAILS"] = "PROPERTY_DETAILS";
})(ProductType || (ProductType = {}));
export var ProfileButtonLayout;
(function (ProfileButtonLayout) {
  ProfileButtonLayout["INLINE"] = "INLINE";
  ProfileButtonLayout["STACKED"] = "STACKED";
})(ProfileButtonLayout || (ProfileButtonLayout = {}));
export var ProfileLinkTarget;
(function (ProfileLinkTarget) {
  ProfileLinkTarget["EXTERNAL"] = "EXTERNAL";
  ProfileLinkTarget["INTERNAL"] = "INTERNAL";
})(ProfileLinkTarget || (ProfileLinkTarget = {}));
export var ProgressionBarAction;
(function (ProgressionBarAction) {
  ProgressionBarAction["PDP"] = "PDP";
  ProgressionBarAction["REVIEWS"] = "REVIEWS";
  ProgressionBarAction["TRAVEL_GUIDE"] = "TRAVEL_GUIDE";
})(ProgressionBarAction || (ProgressionBarAction = {}));
export var PropertyActivitiesCrossSellActionType;
(function (PropertyActivitiesCrossSellActionType) {
  PropertyActivitiesCrossSellActionType["DISMISS"] = "DISMISS";
  PropertyActivitiesCrossSellActionType["EDIT"] = "EDIT";
  PropertyActivitiesCrossSellActionType["OPEN"] = "OPEN";
  PropertyActivitiesCrossSellActionType["REMOVE"] = "REMOVE";
})(PropertyActivitiesCrossSellActionType || (PropertyActivitiesCrossSellActionType = {}));
export var PropertyAmenityCategoryType;
(function (PropertyAmenityCategoryType) {
  PropertyAmenityCategoryType["ACCESSIBLE_ROLL_IN_SHOWER"] = "ACCESSIBLE_ROLL_IN_SHOWER";
  PropertyAmenityCategoryType["AIR_CONDITIONING"] = "AIR_CONDITIONING";
  PropertyAmenityCategoryType["ALL_INCLUSIVE"] = "ALL_INCLUSIVE";
  PropertyAmenityCategoryType["ARCADE"] = "ARCADE";
  PropertyAmenityCategoryType["BABYSITTING"] = "BABYSITTING";
  PropertyAmenityCategoryType["BAR"] = "BAR";
  PropertyAmenityCategoryType["BOWLING"] = "BOWLING";
  PropertyAmenityCategoryType["BREAKFAST_AVAILABLE"] = "BREAKFAST_AVAILABLE";
  PropertyAmenityCategoryType["BUSINESS_SERVICES"] = "BUSINESS_SERVICES";
  PropertyAmenityCategoryType["CHILDREN_CLUB"] = "CHILDREN_CLUB";
  PropertyAmenityCategoryType["DAILY_HOUSEKEEPING"] = "DAILY_HOUSEKEEPING";
  PropertyAmenityCategoryType["EXTRA_BED"] = "EXTRA_BED";
  PropertyAmenityCategoryType["FITNESS_EQUIPMENT"] = "FITNESS_EQUIPMENT";
  PropertyAmenityCategoryType["FREE_AIRPORT_TRANSPORTATION"] = "FREE_AIRPORT_TRANSPORTATION";
  PropertyAmenityCategoryType["FREE_BREAKFAST"] = "FREE_BREAKFAST";
  PropertyAmenityCategoryType["FREE_PARKING"] = "FREE_PARKING";
  PropertyAmenityCategoryType["FREE_WELCOME_DRINK"] = "FREE_WELCOME_DRINK";
  PropertyAmenityCategoryType["FRONT_DESK_24_HOURS"] = "FRONT_DESK_24_HOURS";
  PropertyAmenityCategoryType["GROCERY"] = "GROCERY";
  PropertyAmenityCategoryType["HIGH_SPEED_INTERNET"] = "HIGH_SPEED_INTERNET";
  PropertyAmenityCategoryType["HOT_TUB"] = "HOT_TUB";
  PropertyAmenityCategoryType["IN_ROOM_INTERNET"] = "IN_ROOM_INTERNET";
  PropertyAmenityCategoryType["KIDS_POOL"] = "KIDS_POOL";
  PropertyAmenityCategoryType["KITCHEN"] = "KITCHEN";
  PropertyAmenityCategoryType["LAUNDRY"] = "LAUNDRY";
  PropertyAmenityCategoryType["LIVING_AREA"] = "LIVING_AREA";
  PropertyAmenityCategoryType["MICROWAVE"] = "MICROWAVE";
  PropertyAmenityCategoryType["MINI_GOLF"] = "MINI_GOLF";
  PropertyAmenityCategoryType["PARKING"] = "PARKING";
  PropertyAmenityCategoryType["PETS_ALLOWED"] = "PETS_ALLOWED";
  PropertyAmenityCategoryType["PLAYGROUND"] = "PLAYGROUND";
  PropertyAmenityCategoryType["POOL"] = "POOL";
  PropertyAmenityCategoryType["RESTAURANT_IN_HOTEL"] = "RESTAURANT_IN_HOTEL";
  PropertyAmenityCategoryType["ROOM_SERVICE"] = "ROOM_SERVICE";
  PropertyAmenityCategoryType["SMOKE_FREE"] = "SMOKE_FREE";
  PropertyAmenityCategoryType["SOUNDPROOF_ROOM"] = "SOUNDPROOF_ROOM";
  PropertyAmenityCategoryType["SPA_SERVICES_ON_SITE"] = "SPA_SERVICES_ON_SITE";
  PropertyAmenityCategoryType["SPECIAL_DEAL"] = "SPECIAL_DEAL";
  PropertyAmenityCategoryType["TENNIS_COURT"] = "TENNIS_COURT";
  PropertyAmenityCategoryType["TOYS"] = "TOYS";
  PropertyAmenityCategoryType["WATERSLIDE"] = "WATERSLIDE";
})(PropertyAmenityCategoryType || (PropertyAmenityCategoryType = {}));
export var PropertyAmenityType;
(function (PropertyAmenityType) {
  PropertyAmenityType["ACCESSIBILITY"] = "ACCESSIBILITY";
  PropertyAmenityType["BATHROOM"] = "BATHROOM";
  PropertyAmenityType["BEDROOM"] = "BEDROOM";
  PropertyAmenityType["BUSINESS_SERVICES"] = "BUSINESS_SERVICES";
  PropertyAmenityType["CONVENIENCES"] = "CONVENIENCES";
  PropertyAmenityType["ENTERTAINMENT"] = "ENTERTAINMENT";
  PropertyAmenityType["FAMILY_FRIENDLY"] = "FAMILY_FRIENDLY";
  PropertyAmenityType["FOOD_DRINK"] = "FOOD_DRINK";
  PropertyAmenityType["GUEST_SERVICES"] = "GUEST_SERVICES";
  PropertyAmenityType["INTERNET"] = "INTERNET";
  PropertyAmenityType["MORE"] = "MORE";
  PropertyAmenityType["OUTDOOR"] = "OUTDOOR";
  PropertyAmenityType["OUTDOOR_SPACE"] = "OUTDOOR_SPACE";
  PropertyAmenityType["PARKING"] = "PARKING";
  PropertyAmenityType["PROPERTY"] = "PROPERTY";
  PropertyAmenityType["ROOM"] = "ROOM";
  PropertyAmenityType["SPA"] = "SPA";
  PropertyAmenityType["THINGS_TO_DO"] = "THINGS_TO_DO";
})(PropertyAmenityType || (PropertyAmenityType = {}));
export var PropertyCalendarPriceTrend;
(function (PropertyCalendarPriceTrend) {
  PropertyCalendarPriceTrend["NEGATIVE"] = "NEGATIVE";
  PropertyCalendarPriceTrend["POSITIVE"] = "POSITIVE";
})(PropertyCalendarPriceTrend || (PropertyCalendarPriceTrend = {}));
export var PropertyCancellationPolicyType;
(function (PropertyCancellationPolicyType) {
  PropertyCancellationPolicyType["NON_REFUNDABLE"] = "NON_REFUNDABLE";
  PropertyCancellationPolicyType["REFUNDABLE_WITH_NO_PENALTY"] = "REFUNDABLE_WITH_NO_PENALTY";
  PropertyCancellationPolicyType["REFUNDABLE_WITH_PENALTY"] = "REFUNDABLE_WITH_PENALTY";
})(PropertyCancellationPolicyType || (PropertyCancellationPolicyType = {}));
export var PropertyContentSectionRef;
(function (PropertyContentSectionRef) {
  PropertyContentSectionRef["AVAILABILITY_CALENDAR_START_DATE"] = "AVAILABILITY_CALENDAR_START_DATE";
  PropertyContentSectionRef["PRICE_DISPLAY_SERVICE_RESPONSE"] = "PRICE_DISPLAY_SERVICE_RESPONSE";
  PropertyContentSectionRef["RECOMMENDED_OFFER"] = "RECOMMENDED_OFFER";
  PropertyContentSectionRef["ROOMS_AND_RATES"] = "ROOMS_AND_RATES";
})(PropertyContentSectionRef || (PropertyContentSectionRef = {}));
export var PropertyContentType;
(function (PropertyContentType) {
  PropertyContentType["DETAILED"] = "DETAILED";
  PropertyContentType["SUMMARY"] = "SUMMARY";
})(PropertyContentType || (PropertyContentType = {}));
export var PropertyDetailsPropertyType;
(function (PropertyDetailsPropertyType) {
  PropertyDetailsPropertyType["HOTEL"] = "HOTEL";
  PropertyDetailsPropertyType["VACATION_RENTAL"] = "VACATION_RENTAL";
})(PropertyDetailsPropertyType || (PropertyDetailsPropertyType = {}));
export var PropertyFilterAccessibility;
(function (PropertyFilterAccessibility) {
  PropertyFilterAccessibility["ACCESSIBLE_BATHROOM"] = "ACCESSIBLE_BATHROOM";
  PropertyFilterAccessibility["ACCESSIBLE_PARKING"] = "ACCESSIBLE_PARKING";
  PropertyFilterAccessibility["ELEVATOR"] = "ELEVATOR";
  PropertyFilterAccessibility["IN_ROOM_ACCESSIBLE"] = "IN_ROOM_ACCESSIBLE";
  PropertyFilterAccessibility["ROLL_IN_SHOWER"] = "ROLL_IN_SHOWER";
  PropertyFilterAccessibility["SERVICE_ANIMAL"] = "SERVICE_ANIMAL";
  PropertyFilterAccessibility["SIGN_LANGUAGE_INTERPRETER"] = "SIGN_LANGUAGE_INTERPRETER";
  PropertyFilterAccessibility["STAIR_FREE_PATH"] = "STAIR_FREE_PATH";
})(PropertyFilterAccessibility || (PropertyFilterAccessibility = {}));
export var PropertyFilterAmenity;
(function (PropertyFilterAmenity) {
  PropertyFilterAmenity["AIR_CONDITIONING"] = "AIR_CONDITIONING";
  PropertyFilterAmenity["ALL_INCLUSIVE"] = "ALL_INCLUSIVE";
  PropertyFilterAmenity["BALCONY_OR_TERRACE"] = "BALCONY_OR_TERRACE";
  PropertyFilterAmenity["BAR"] = "BAR";
  PropertyFilterAmenity["BEACH"] = "BEACH";
  PropertyFilterAmenity["BEACH_FRONT"] = "BEACH_FRONT";
  PropertyFilterAmenity["BED_LINENS"] = "BED_LINENS";
  PropertyFilterAmenity["CASINO"] = "CASINO";
  PropertyFilterAmenity["CRIB"] = "CRIB";
  PropertyFilterAmenity["DISHWASHER"] = "DISHWASHER";
  PropertyFilterAmenity["DRYER"] = "DRYER";
  PropertyFilterAmenity["ELECTRIC_CAR"] = "ELECTRIC_CAR";
  PropertyFilterAmenity["FIREPLACE"] = "FIREPLACE";
  PropertyFilterAmenity["FREE_AIRPORT_TRANSPORTATION"] = "FREE_AIRPORT_TRANSPORTATION";
  PropertyFilterAmenity["FREE_BREAKFAST"] = "FREE_BREAKFAST";
  PropertyFilterAmenity["FREE_PARKING"] = "FREE_PARKING";
  PropertyFilterAmenity["GOLF"] = "GOLF";
  PropertyFilterAmenity["GOLF_COURSE"] = "GOLF_COURSE";
  PropertyFilterAmenity["GYM"] = "GYM";
  PropertyFilterAmenity["HOT_SPRING"] = "HOT_SPRING";
  PropertyFilterAmenity["HOT_TUB"] = "HOT_TUB";
  PropertyFilterAmenity["KITCHEN_KITCHEN"] = "KITCHEN_KITCHEN";
  PropertyFilterAmenity["KITCHEN_KITCHENETTE"] = "KITCHEN_KITCHENETTE";
  PropertyFilterAmenity["OCEAN_VIEW"] = "OCEAN_VIEW";
  PropertyFilterAmenity["PARKING"] = "PARKING";
  PropertyFilterAmenity["PETS"] = "PETS";
  PropertyFilterAmenity["POOL"] = "POOL";
  PropertyFilterAmenity["RESTAURANT_IN_HOTEL"] = "RESTAURANT_IN_HOTEL";
  PropertyFilterAmenity["SPA_ON_SITE"] = "SPA_ON_SITE";
  PropertyFilterAmenity["TELEVISION"] = "TELEVISION";
  PropertyFilterAmenity["WASHER"] = "WASHER";
  PropertyFilterAmenity["WASHER_DRYER"] = "WASHER_DRYER";
  PropertyFilterAmenity["WATER_PARK"] = "WATER_PARK";
  PropertyFilterAmenity["WIFI"] = "WIFI";
})(PropertyFilterAmenity || (PropertyFilterAmenity = {}));
export var PropertyInfoOptions;
(function (PropertyInfoOptions) {
  PropertyInfoOptions["SHOP_WITH_POINTS"] = "SHOP_WITH_POINTS";
})(PropertyInfoOptions || (PropertyInfoOptions = {}));
export var PropertyPaymentFlags;
(function (PropertyPaymentFlags) {
  PropertyPaymentFlags["ARTIFICIALLY_FENCED_RATE"] = "ARTIFICIALLY_FENCED_RATE";
  PropertyPaymentFlags["MIXED_RATE_PLAN"] = "MIXED_RATE_PLAN";
  PropertyPaymentFlags["NO_CREDIT_CARD"] = "NO_CREDIT_CARD";
})(PropertyPaymentFlags || (PropertyPaymentFlags = {}));
export var PropertyPaymentModel;
(function (PropertyPaymentModel) {
  PropertyPaymentModel["PAY_LATER"] = "PAY_LATER";
  PropertyPaymentModel["PAY_LATER_WITH_DEPOSIT"] = "PAY_LATER_WITH_DEPOSIT";
  PropertyPaymentModel["PAY_NOW"] = "PAY_NOW";
})(PropertyPaymentModel || (PropertyPaymentModel = {}));
export var PropertyPaymentType;
(function (PropertyPaymentType) {
  PropertyPaymentType["PAY_LATER"] = "PAY_LATER";
  PropertyPaymentType["PAY_LATER_WITH_DEPOSIT"] = "PAY_LATER_WITH_DEPOSIT";
  PropertyPaymentType["PAY_NOW"] = "PAY_NOW";
})(PropertyPaymentType || (PropertyPaymentType = {}));
export var PropertyRecommendationStrategy;
(function (PropertyRecommendationStrategy) {
  PropertyRecommendationStrategy["NEARBY_PROPERTIES"] = "NEARBY_PROPERTIES";
  PropertyRecommendationStrategy["SIMILAR_PROPERTIES"] = "SIMILAR_PROPERTIES";
})(PropertyRecommendationStrategy || (PropertyRecommendationStrategy = {}));
export var PropertyReviewCategory;
(function (PropertyReviewCategory) {
  PropertyReviewCategory["BUSINESS_TRAVELERS"] = "BUSINESS_TRAVELERS";
  PropertyReviewCategory["COUPLES"] = "COUPLES";
  PropertyReviewCategory["EVERYONE"] = "EVERYONE";
  PropertyReviewCategory["FAMILIES"] = "FAMILIES";
  PropertyReviewCategory["GETAWAY_WITH_FRIENDS"] = "GETAWAY_WITH_FRIENDS";
  PropertyReviewCategory["GOLF"] = "GOLF";
  PropertyReviewCategory["OTHER"] = "OTHER";
  PropertyReviewCategory["OVERNIGHT_STAY"] = "OVERNIGHT_STAY";
  PropertyReviewCategory["PERSONAL_EVENT"] = "PERSONAL_EVENT";
  PropertyReviewCategory["SHOPPING"] = "SHOPPING";
  PropertyReviewCategory["SPA"] = "SPA";
  PropertyReviewCategory["STUDENTS"] = "STUDENTS";
})(PropertyReviewCategory || (PropertyReviewCategory = {}));
export var PropertyReviewSort;
(function (PropertyReviewSort) {
  PropertyReviewSort["HIGHEST_TO_LOWEST_RATED"] = "HIGHEST_TO_LOWEST_RATED";
  PropertyReviewSort["LOWEST_TO_HIGHEST_RATED"] = "LOWEST_TO_HIGHEST_RATED";
  PropertyReviewSort["NEWEST_TO_OLDEST"] = "NEWEST_TO_OLDEST";
  PropertyReviewSort["NEWEST_TO_OLDEST_BY_LANGUAGE"] = "NEWEST_TO_OLDEST_BY_LANGUAGE";
  PropertyReviewSort["NEWEST_TO_OLDEST_BY_RELEVANCY"] = "NEWEST_TO_OLDEST_BY_RELEVANCY";
})(PropertyReviewSort || (PropertyReviewSort = {}));
export var PropertyReviewType;
(function (PropertyReviewType) {
  PropertyReviewType["CRITICAL"] = "CRITICAL";
  PropertyReviewType["POSITIVE"] = "POSITIVE";
  PropertyReviewType["RECENT"] = "RECENT";
})(PropertyReviewType || (PropertyReviewType = {}));
export var PropertySearchOptions;
(function (PropertySearchOptions) {
  /** @deprecated Use PropertyShopOptions.listings */
  PropertySearchOptions["ADS_ONLY"] = "ADS_ONLY";
  /** @deprecated Use PropertyShopOptions.points */
  PropertySearchOptions["SHOP_WITH_POINTS"] = "SHOP_WITH_POINTS";
})(PropertySearchOptions || (PropertySearchOptions = {}));
export var PropertySectionRef;
(function (PropertySectionRef) {
  /** @deprecated Replace with PropertyContentSectionRef.AVAILABILITY_CALENDAR_START_DATE */
  PropertySectionRef["AVAILABILITY_CALENDAR_START_DATE"] = "AVAILABILITY_CALENDAR_START_DATE";
  /** @deprecated Replace with PropertyContentSectionRef.PRICE_DISPLAY_SERVICE_RESPONSE */
  PropertySectionRef["PRICE_DISPLAY_SERVICE_RESPONSE"] = "PRICE_DISPLAY_SERVICE_RESPONSE";
  /** @deprecated Replace with PropertyContentSectionRef.RECOMMENDED_OFFER */
  PropertySectionRef["RECOMMENDED_OFFER"] = "RECOMMENDED_OFFER";
  /** @deprecated Replace with PropertyContentSectionRef.ROOMS_AND_RATES */
  PropertySectionRef["ROOMS_AND_RATES"] = "ROOMS_AND_RATES";
})(PropertySectionRef || (PropertySectionRef = {}));
export var PropertySort;
(function (PropertySort) {
  PropertySort["AARP_MEMBER_DISCOUNT"] = "AARP_MEMBER_DISCOUNT";
  PropertySort["BEST_DEAL"] = "BEST_DEAL";
  PropertySort["DEPARTURE_EARLY_TO_LATE"] = "DEPARTURE_EARLY_TO_LATE";
  PropertySort["DEPARTURE_LATE_TO_EARLY"] = "DEPARTURE_LATE_TO_EARLY";
  PropertySort["DISTANCE"] = "DISTANCE";
  PropertySort["DURATION_LONG_TO_SHORT"] = "DURATION_LONG_TO_SHORT";
  PropertySort["DURATION_SHORT_TO_LONG"] = "DURATION_SHORT_TO_LONG";
  /** @deprecated Hackathon 2022-07 */
  PropertySort["MAXIMUM_SAVINGS"] = "MAXIMUM_SAVINGS";
  /** @deprecated Hackathon 2022-07 */
  PropertySort["MAXIMUM_SAVINGS_PERCENT"] = "MAXIMUM_SAVINGS_PERCENT";
  PropertySort["MEMBER_PRICING"] = "MEMBER_PRICING";
  PropertySort["PACKAGE_SAVINGS"] = "PACKAGE_SAVINGS";
  PropertySort["PRICE_HIGH_TO_LOW"] = "PRICE_HIGH_TO_LOW";
  PropertySort["PRICE_LOW_TO_HIGH"] = "PRICE_LOW_TO_HIGH";
  PropertySort["PRICE_RELEVANT"] = "PRICE_RELEVANT";
  PropertySort["PROPERTY_CLASS"] = "PROPERTY_CLASS";
  PropertySort["PROPERTY_NAME"] = "PROPERTY_NAME";
  PropertySort["RECOMMENDED"] = "RECOMMENDED";
  PropertySort["REVIEW"] = "REVIEW";
  PropertySort["REVIEW_COUNT"] = "REVIEW_COUNT";
  /** @deprecated tnl 49730 reverted to control */
  PropertySort["REVIEW_PRICE"] = "REVIEW_PRICE";
  PropertySort["REVIEW_RELEVANT"] = "REVIEW_RELEVANT";
  PropertySort["VACATION_RENTAL"] = "VACATION_RENTAL";
})(PropertySort || (PropertySort = {}));
export var PropertyStyle;
(function (PropertyStyle) {
  PropertyStyle["BOUTIQUE"] = "BOUTIQUE";
  PropertyStyle["HOMES"] = "HOMES";
  PropertyStyle["HOTELS"] = "HOTELS";
  PropertyStyle["LGBT"] = "LGBT";
  PropertyStyle["LUXURY"] = "LUXURY";
  PropertyStyle["OUTDOOR"] = "OUTDOOR";
  PropertyStyle["UNIQUE"] = "UNIQUE";
})(PropertyStyle || (PropertyStyle = {}));
export var QuestionSortType;
(function (QuestionSortType) {
  QuestionSortType["NEWEST_TO_OLDEST"] = "NEWEST_TO_OLDEST";
  QuestionSortType["OLDEST_TO_NEWEST"] = "OLDEST_TO_NEWEST";
})(QuestionSortType || (QuestionSortType = {}));
export var QuoteBuilderType;
(function (QuoteBuilderType) {
  QuoteBuilderType["BOTTOM"] = "BOTTOM";
  QuoteBuilderType["RIGHT_SIDE"] = "RIGHT_SIDE";
  QuoteBuilderType["SIDE"] = "SIDE";
})(QuoteBuilderType || (QuoteBuilderType = {}));
export var RangeIndicatorSegmentStyle;
(function (RangeIndicatorSegmentStyle) {
  RangeIndicatorSegmentStyle["EMPHASIS"] = "EMPHASIS";
})(RangeIndicatorSegmentStyle || (RangeIndicatorSegmentStyle = {}));
export var RangeIndicatorSegmentTheme;
(function (RangeIndicatorSegmentTheme) {
  RangeIndicatorSegmentTheme["NEGATIVE"] = "NEGATIVE";
  RangeIndicatorSegmentTheme["NEUTRAL"] = "NEUTRAL";
  RangeIndicatorSegmentTheme["POSITIVE"] = "POSITIVE";
  RangeIndicatorSegmentTheme["VERY_POSITIVE"] = "VERY_POSITIVE";
})(RangeIndicatorSegmentTheme || (RangeIndicatorSegmentTheme = {}));
export var RateAdjustmentType;
(function (RateAdjustmentType) {
  RateAdjustmentType["Amount"] = "Amount";
  RateAdjustmentType["Percent"] = "Percent";
})(RateAdjustmentType || (RateAdjustmentType = {}));
export var RateDiscountType;
(function (RateDiscountType) {
  RateDiscountType["MEMBER_DEAL"] = "MEMBER_DEAL";
  RateDiscountType["MOBILE_EXCLUSIVE"] = "MOBILE_EXCLUSIVE";
  RateDiscountType["REGULAR"] = "REGULAR";
  RateDiscountType["TONIGHT_ONLY"] = "TONIGHT_ONLY";
})(RateDiscountType || (RateDiscountType = {}));
export var ReasonCategory;
(function (ReasonCategory) {
  ReasonCategory["ACCOUNT_UPDATE"] = "ACCOUNT_UPDATE";
  ReasonCategory["CANCEL"] = "CANCEL";
  ReasonCategory["CANCEL_OPAQUE"] = "CANCEL_OPAQUE";
  ReasonCategory["CHANGE"] = "CHANGE";
  ReasonCategory["CHARGE"] = "CHARGE";
  ReasonCategory["FINANCIAL_ADJUSTMENT"] = "FINANCIAL_ADJUSTMENT";
  ReasonCategory["POINTS_ADJUSTMENT"] = "POINTS_ADJUSTMENT";
  ReasonCategory["PRICE_MATCH"] = "PRICE_MATCH";
})(ReasonCategory || (ReasonCategory = {}));
export var RecentActivityType;
(function (RecentActivityType) {
  RecentActivityType["BROWSING_HISTORY"] = "BROWSING_HISTORY";
  RecentActivityType["RECENT_SEARCHES"] = "RECENT_SEARCHES";
  RecentActivityType["VIEWED_OFFERS"] = "VIEWED_OFFERS";
  RecentActivityType["VIEWED_PRODUCTS"] = "VIEWED_PRODUCTS";
})(RecentActivityType || (RecentActivityType = {}));
export var RecommendedType;
(function (RecommendedType) {
  RecommendedType["BESPOKE"] = "BESPOKE";
  RecommendedType["DESTINATIONS"] = "DESTINATIONS";
  RecommendedType["OFFERS"] = "OFFERS";
})(RecommendedType || (RecommendedType = {}));
export var RefreshAction;
(function (RefreshAction) {
  RefreshAction["LOGOUT"] = "LOGOUT";
  RefreshAction["NONE"] = "NONE";
})(RefreshAction || (RefreshAction = {}));
export var RefundMode;
(function (RefundMode) {
  RefundMode["FTC"] = "FTC";
  RefundMode["NO_VALUE"] = "NO_VALUE";
  RefundMode["REFUND"] = "REFUND";
})(RefundMode || (RefundMode = {}));
export var RefundStatus;
(function (RefundStatus) {
  RefundStatus["Failure"] = "Failure";
  RefundStatus["Success"] = "Success";
})(RefundStatus || (RefundStatus = {}));
export var RefundType;
(function (RefundType) {
  RefundType["FTC"] = "FTC";
  RefundType["NO_VALUE"] = "NO_VALUE";
  RefundType["REFUND"] = "REFUND";
})(RefundType || (RefundType = {}));
export var RefundableType;
(function (RefundableType) {
  RefundableType["FULLY"] = "FULLY";
  RefundableType["NON"] = "NON";
  RefundableType["PARTIALLY"] = "PARTIALLY";
  RefundableType["UNKNOWN"] = "UNKNOWN";
})(RefundableType || (RefundableType = {}));
export var RegionType;
(function (RegionType) {
  RegionType["ADDRESS"] = "ADDRESS";
  RegionType["AIRPORT"] = "AIRPORT";
  RegionType["CITY"] = "CITY";
  RegionType["CONTINENT"] = "CONTINENT";
  RegionType["COUNTRY"] = "COUNTRY";
  RegionType["HIGH_LEVEL_REGION"] = "HIGH_LEVEL_REGION";
  RegionType["METRO_STATION"] = "METRO_STATION";
  RegionType["MULTI_CITY_VICINITY"] = "MULTI_CITY_VICINITY";
  RegionType["NEIGHBORHOOD"] = "NEIGHBORHOOD";
  RegionType["POINT_OF_INTEREST"] = "POINT_OF_INTEREST";
  RegionType["PROVINCE_STATE"] = "PROVINCE_STATE";
  RegionType["TRAIN_STATION"] = "TRAIN_STATION";
  RegionType["UNKNOWN"] = "UNKNOWN";
})(RegionType || (RegionType = {}));
export var ReservationModifiersCancellationFeeOptionType;
(function (ReservationModifiersCancellationFeeOptionType) {
  ReservationModifiersCancellationFeeOptionType["CUSTOM_FEE"] = "CUSTOM_FEE";
  ReservationModifiersCancellationFeeOptionType["FULL_FEE"] = "FULL_FEE";
  ReservationModifiersCancellationFeeOptionType["NO_FEE"] = "NO_FEE";
})(ReservationModifiersCancellationFeeOptionType || (ReservationModifiersCancellationFeeOptionType = {}));
export var ReservationModifiersRefundAmountOptionType;
(function (ReservationModifiersRefundAmountOptionType) {
  ReservationModifiersRefundAmountOptionType["CUSTOM_AMOUNT"] = "CUSTOM_AMOUNT";
  ReservationModifiersRefundAmountOptionType["PERCENTAGE_AMOUNT"] = "PERCENTAGE_AMOUNT";
})(ReservationModifiersRefundAmountOptionType || (ReservationModifiersRefundAmountOptionType = {}));
export var ReservationModifiersScreenType;
(function (ReservationModifiersScreenType) {
  ReservationModifiersScreenType["CONFIRM_DETAILS"] = "CONFIRM_DETAILS";
  ReservationModifiersScreenType["EMPATHY"] = "EMPATHY";
  ReservationModifiersScreenType["LINK_TO_LEGACY"] = "LINK_TO_LEGACY";
  ReservationModifiersScreenType["MENU"] = "MENU";
  ReservationModifiersScreenType["REVIEW_CHANGES"] = "REVIEW_CHANGES";
  ReservationModifiersScreenType["REVIEW_TERMS"] = "REVIEW_TERMS";
  ReservationModifiersScreenType["SET_CANCELLATION_FEE"] = "SET_CANCELLATION_FEE";
  ReservationModifiersScreenType["SET_CANCELLATION_REASON"] = "SET_CANCELLATION_REASON";
  ReservationModifiersScreenType["SET_MESSAGE_TO_GUEST"] = "SET_MESSAGE_TO_GUEST";
  ReservationModifiersScreenType["SET_REFUND_AMOUNT"] = "SET_REFUND_AMOUNT";
  ReservationModifiersScreenType["SET_REFUND_REASON"] = "SET_REFUND_REASON";
  ReservationModifiersScreenType["SET_STAY_DATES"] = "SET_STAY_DATES";
  ReservationModifiersScreenType["SUCCESS"] = "SUCCESS";
  ReservationModifiersScreenType["VR_SET_REFUND_AMOUNT"] = "VR_SET_REFUND_AMOUNT";
})(ReservationModifiersScreenType || (ReservationModifiersScreenType = {}));
export var ReservationModifiersType;
(function (ReservationModifiersType) {
  ReservationModifiersType["CANCEL"] = "CANCEL";
  ReservationModifiersType["CHANGE_DATES"] = "CHANGE_DATES";
  ReservationModifiersType["GUEST_SATISFACTION_REFUND"] = "GUEST_SATISFACTION_REFUND";
  ReservationModifiersType["NO_SHOW"] = "NO_SHOW";
})(ReservationModifiersType || (ReservationModifiersType = {}));
export var ReservationModifiersVRCancelRefundLevel;
(function (ReservationModifiersVRCancelRefundLevel) {
  ReservationModifiersVRCancelRefundLevel["FULL"] = "FULL";
  ReservationModifiersVRCancelRefundLevel["HALF"] = "HALF";
  ReservationModifiersVRCancelRefundLevel["ZERO"] = "ZERO";
})(ReservationModifiersVRCancelRefundLevel || (ReservationModifiersVRCancelRefundLevel = {}));
export var ResidencyState;
(function (ResidencyState) {
  ResidencyState["APPLIED"] = "APPLIED";
  ResidencyState["AVAILABLE"] = "AVAILABLE";
})(ResidencyState || (ResidencyState = {}));
export var ResponseStatus;
(function (ResponseStatus) {
  ResponseStatus["ERROR"] = "ERROR";
  ResponseStatus["SUCCESS"] = "SUCCESS";
})(ResponseStatus || (ResponseStatus = {}));
export var ResponseTagType;
(function (ResponseTagType) {
  ResponseTagType["CACHE_SOURCE_AIRBLAZE"] = "CACHE_SOURCE_AIRBLAZE";
  ResponseTagType["CACHE_SOURCE_BYOT_AIRBLAZE"] = "CACHE_SOURCE_BYOT_AIRBLAZE";
  ResponseTagType["EXPERIENCE_SEARCH_CACHE_HYDRATOR"] = "EXPERIENCE_SEARCH_CACHE_HYDRATOR";
  ResponseTagType["LISTINGS_PAGINATED_SLICED"] = "LISTINGS_PAGINATED_SLICED";
  ResponseTagType["LISTING_CACHE_SOURCE"] = "LISTING_CACHE_SOURCE";
  ResponseTagType["LISTING_DOWNLEVEL_SOURCE"] = "LISTING_DOWNLEVEL_SOURCE";
  ResponseTagType["MAIN_SEARCH_STACK"] = "MAIN_SEARCH_STACK";
  ResponseTagType["RESPONSE_SUMMARY_CACHED_CONFIG"] = "RESPONSE_SUMMARY_CACHED_CONFIG";
  ResponseTagType["RESPONSE_SUMMARY_CACHED_ML"] = "RESPONSE_SUMMARY_CACHED_ML";
  ResponseTagType["RESPONSE_SUMMARY_HYBRID"] = "RESPONSE_SUMMARY_HYBRID";
  ResponseTagType["RESPONSE_SUMMARY_LIVE"] = "RESPONSE_SUMMARY_LIVE";
  ResponseTagType["RESPONSE_SUMMARY_REFINEMENTS_CACHE_LIVE"] = "RESPONSE_SUMMARY_REFINEMENTS_CACHE_LIVE";
  ResponseTagType["RESPONSE_SUMMARY_REFINEMENTS_CACHE_REDIS"] = "RESPONSE_SUMMARY_REFINEMENTS_CACHE_REDIS";
  ResponseTagType["RESPONSE_SUMMARY_REFINEMENTS_USER_CACHE"] = "RESPONSE_SUMMARY_REFINEMENTS_USER_CACHE";
  ResponseTagType["TEST_SEARCH_STACK"] = "TEST_SEARCH_STACK";
  ResponseTagType["UNRECOGNIZED"] = "UNRECOGNIZED";
})(ResponseTagType || (ResponseTagType = {}));
export var Result;
(function (Result) {
  Result["ERROR"] = "ERROR";
  Result["SUCCESS"] = "SUCCESS";
})(Result || (Result = {}));
export var ReviewInteractionType;
(function (ReviewInteractionType) {
  ReviewInteractionType["HELPFUL_REVIEW"] = "HELPFUL_REVIEW";
  ReviewInteractionType["REVIEW_COLLAPSE_LABEL"] = "REVIEW_COLLAPSE_LABEL";
  ReviewInteractionType["REVIEW_EXPAND_LABEL"] = "REVIEW_EXPAND_LABEL";
  ReviewInteractionType["REVIEW_REPORT_FLAG"] = "REVIEW_REPORT_FLAG";
})(ReviewInteractionType || (ReviewInteractionType = {}));
export var ReviewSource;
(function (ReviewSource) {
  ReviewSource["EXTERNAL_REVIEWS"] = "EXTERNAL_REVIEWS";
  ReviewSource["INTERNAL_REVIEWS"] = "INTERNAL_REVIEWS";
})(ReviewSource || (ReviewSource = {}));
export var ReviewsCarouselCTALocation;
(function (ReviewsCarouselCTALocation) {
  ReviewsCarouselCTALocation["TRIPS_PAGE"] = "TRIPS_PAGE";
  ReviewsCarouselCTALocation["UNKNOWN"] = "UNKNOWN";
})(ReviewsCarouselCTALocation || (ReviewsCarouselCTALocation = {}));
export var RewardsOption;
(function (RewardsOption) {
  RewardsOption["ALTAIR"] = "ALTAIR";
  RewardsOption["AVION"] = "AVION";
  RewardsOption["NONAVION"] = "NONAVION";
})(RewardsOption || (RewardsOption = {}));
export var RewardsPointSelectionEnum;
(function (RewardsPointSelectionEnum) {
  RewardsPointSelectionEnum["APPLY_REWARD_POINTS"] = "APPLY_REWARD_POINTS";
  RewardsPointSelectionEnum["DO_NOT_APPLY_REWARD_POINTS"] = "DO_NOT_APPLY_REWARD_POINTS";
})(RewardsPointSelectionEnum || (RewardsPointSelectionEnum = {}));
export var RewardsProgramState;
(function (RewardsProgramState) {
  RewardsProgramState["ENROLLED"] = "ENROLLED";
  RewardsProgramState["NOT_ENROLLED"] = "NOT_ENROLLED";
})(RewardsProgramState || (RewardsProgramState = {}));
export var RouteType;
(function (RouteType) {
  RouteType["MULTI_DESTINATION"] = "MULTI_DESTINATION";
  RouteType["ONE_WAY"] = "ONE_WAY";
  RouteType["ROUND_TRIP"] = "ROUND_TRIP";
})(RouteType || (RouteType = {}));
export var ScreenType;
(function (ScreenType) {
  ScreenType["ADJUSTMENT"] = "ADJUSTMENT";
  ScreenType["CANCEL"] = "CANCEL";
  ScreenType["REVIEW"] = "REVIEW";
})(ScreenType || (ScreenType = {}));
export var SearchFormProductType;
(function (SearchFormProductType) {
  SearchFormProductType["ACTIVITIES"] = "ACTIVITIES";
  SearchFormProductType["CARS"] = "CARS";
  SearchFormProductType["CRUISES"] = "CRUISES";
  SearchFormProductType["FLIGHTS"] = "FLIGHTS";
  SearchFormProductType["LODGING"] = "LODGING";
  SearchFormProductType["PACKAGES"] = "PACKAGES";
})(SearchFormProductType || (SearchFormProductType = {}));
export var SearchHistoryDayRange;
(function (SearchHistoryDayRange) {
  SearchHistoryDayRange["SEVEN_DAYS"] = "SEVEN_DAYS";
  SearchHistoryDayRange["THREE_DAYS"] = "THREE_DAYS";
})(SearchHistoryDayRange || (SearchHistoryDayRange = {}));
export var SearchHistoryDestinationType;
(function (SearchHistoryDestinationType) {
  SearchHistoryDestinationType["METRO_CITY"] = "METRO_CITY";
})(SearchHistoryDestinationType || (SearchHistoryDestinationType = {}));
export var SearchLocationActionStatus;
(function (SearchLocationActionStatus) {
  SearchLocationActionStatus["FAIL"] = "FAIL";
  SearchLocationActionStatus["SUCCESS"] = "SUCCESS";
})(SearchLocationActionStatus || (SearchLocationActionStatus = {}));
export var SearchLocationSource;
(function (SearchLocationSource) {
  SearchLocationSource["EGTYPEAHEAD"] = "EGTYPEAHEAD";
  SearchLocationSource["GOOGLE"] = "GOOGLE";
  SearchLocationSource["RECOMMENDATIONS"] = "RECOMMENDATIONS";
})(SearchLocationSource || (SearchLocationSource = {}));
export var SearchViews;
(function (SearchViews) {
  SearchViews["BOOKING_VIEW"] = "BOOKING_VIEW";
  SearchViews["SUMMARIZATION_VIEW"] = "SUMMARIZATION_VIEW";
})(SearchViews || (SearchViews = {}));
export var SeatChoiceType;
(function (SeatChoiceType) {
  SeatChoiceType["SEAT_CHOICE"] = "SEAT_CHOICE";
})(SeatChoiceType || (SeatChoiceType = {}));
export var SeatVariant;
(function (SeatVariant) {
  SeatVariant["ACCESSIBLE"] = "ACCESSIBLE";
  SeatVariant["AISLE"] = "AISLE";
  SeatVariant["AVAILABLE"] = "AVAILABLE";
  SeatVariant["COLUMN"] = "COLUMN";
  SeatVariant["EXIT"] = "EXIT";
  SeatVariant["GALLEY"] = "GALLEY";
  SeatVariant["PREFERRED"] = "PREFERRED";
  SeatVariant["RESTROOM"] = "RESTROOM";
  SeatVariant["ROW"] = "ROW";
  SeatVariant["SELECTED"] = "SELECTED";
  SeatVariant["UNAVAILABLE"] = "UNAVAILABLE";
})(SeatVariant || (SeatVariant = {}));
export var SectionType;
(function (SectionType) {
  SectionType["ORDERED_LIST"] = "ORDERED_LIST";
  SectionType["PARAGRAPH"] = "PARAGRAPH";
  SectionType["UNORDERED_LIST"] = "UNORDERED_LIST";
})(SectionType || (SectionType = {}));
export var SelectedAncillaryTemplateType;
(function (SelectedAncillaryTemplateType) {
  SelectedAncillaryTemplateType["MULTIPLE"] = "MULTIPLE";
  SelectedAncillaryTemplateType["NONE"] = "NONE";
  SelectedAncillaryTemplateType["ONE"] = "ONE";
})(SelectedAncillaryTemplateType || (SelectedAncillaryTemplateType = {}));
export var ServiceActionType;
(function (ServiceActionType) {
  ServiceActionType["CONNECT"] = "CONNECT";
  ServiceActionType["DISCONNECT"] = "DISCONNECT";
})(ServiceActionType || (ServiceActionType = {}));
export var ServiceAutomationToolbarElevation;
(function (ServiceAutomationToolbarElevation) {
  ServiceAutomationToolbarElevation["FLAT"] = "FLAT";
  ServiceAutomationToolbarElevation["FLOATING"] = "FLOATING";
})(ServiceAutomationToolbarElevation || (ServiceAutomationToolbarElevation = {}));
export var ServiceAutomationToolbarNavType;
(function (ServiceAutomationToolbarNavType) {
  ServiceAutomationToolbarNavType["BACK"] = "BACK";
  ServiceAutomationToolbarNavType["CLOSE"] = "CLOSE";
  ServiceAutomationToolbarNavType["EXPAND_UP"] = "EXPAND_UP";
})(ServiceAutomationToolbarNavType || (ServiceAutomationToolbarNavType = {}));
export var SheetState;
(function (SheetState) {
  SheetState["COLLAPSED"] = "COLLAPSED";
  SheetState["FULL"] = "FULL";
  SheetState["HALF"] = "HALF";
})(SheetState || (SheetState = {}));
export var ShoppingButtonType;
(function (ShoppingButtonType) {
  ShoppingButtonType["PRIMARY"] = "PRIMARY";
  ShoppingButtonType["SECONDARY"] = "SECONDARY";
  ShoppingButtonType["TERTIARY"] = "TERTIARY";
})(ShoppingButtonType || (ShoppingButtonType = {}));
export var ShoppingFunctionType;
(function (ShoppingFunctionType) {
  ShoppingFunctionType["CALLBACK"] = "CALLBACK";
  ShoppingFunctionType["SHARED_UI"] = "SHARED_UI";
})(ShoppingFunctionType || (ShoppingFunctionType = {}));
export var ShoppingPathType;
(function (ShoppingPathType) {
  ShoppingPathType["ATTACH"] = "ATTACH";
  ShoppingPathType["PACKAGE"] = "PACKAGE";
  ShoppingPathType["STANDALONE"] = "STANDALONE";
})(ShoppingPathType || (ShoppingPathType = {}));
export var ShoppingSelectableCardState;
(function (ShoppingSelectableCardState) {
  ShoppingSelectableCardState["DISABLED"] = "DISABLED";
  ShoppingSelectableCardState["SELECTED"] = "SELECTED";
  ShoppingSelectableCardState["UNSELECTED"] = "UNSELECTED";
})(ShoppingSelectableCardState || (ShoppingSelectableCardState = {}));
export var ShoppingSortAndFilterActionType;
(function (ShoppingSortAndFilterActionType) {
  ShoppingSortAndFilterActionType["CLEAR_DATA"] = "CLEAR_DATA";
  ShoppingSortAndFilterActionType["CLOSE_AND_APPLY"] = "CLOSE_AND_APPLY";
  ShoppingSortAndFilterActionType["CLOSE_AND_DO_NOT_APPLY"] = "CLOSE_AND_DO_NOT_APPLY";
  ShoppingSortAndFilterActionType["OPEN_MODAL"] = "OPEN_MODAL";
})(ShoppingSortAndFilterActionType || (ShoppingSortAndFilterActionType = {}));
export var ShoppingSortAndFilterSliderType;
(function (ShoppingSortAndFilterSliderType) {
  ShoppingSortAndFilterSliderType["DUAL"] = "DUAL";
  ShoppingSortAndFilterSliderType["SINGLE"] = "SINGLE";
})(ShoppingSortAndFilterSliderType || (ShoppingSortAndFilterSliderType = {}));
export var ShoppingTabsNavType;
(function (ShoppingTabsNavType) {
  ShoppingTabsNavType["JUMPLINK"] = "JUMPLINK";
  ShoppingTabsNavType["NATURAL"] = "NATURAL";
})(ShoppingTabsNavType || (ShoppingTabsNavType = {}));
export var ShoppingTriggerType;
(function (ShoppingTriggerType) {
  ShoppingTriggerType["CLICK"] = "CLICK";
  ShoppingTriggerType["HOVER"] = "HOVER";
  ShoppingTriggerType["LOAD"] = "LOAD";
})(ShoppingTriggerType || (ShoppingTriggerType = {}));
export var SideSheetType;
(function (SideSheetType) {
  SideSheetType["BREAKFAST"] = "BREAKFAST";
  SideSheetType["NONREFUNDABLE"] = "NONREFUNDABLE";
  SideSheetType["PACKAGE"] = "PACKAGE";
  SideSheetType["REFUNDABLE"] = "REFUNDABLE";
})(SideSheetType || (SideSheetType = {}));
export var SignalType;
(function (SignalType) {
  SignalType["BOOKING_SUCCESS"] = "BOOKING_SUCCESS";
  SignalType["CONFIGURATION_ADDED"] = "CONFIGURATION_ADDED";
  SignalType["DUPLICATE_REQUEST"] = "DUPLICATE_REQUEST";
  SignalType["INVALID_EMAIL"] = "INVALID_EMAIL";
  SignalType["OFFER_ADDED"] = "OFFER_ADDED";
  SignalType["OFFER_CHANGED"] = "OFFER_CHANGED";
  SignalType["OFFER_REMOVED"] = "OFFER_REMOVED";
  SignalType["OFFER_UNAVAILABLE"] = "OFFER_UNAVAILABLE";
  SignalType["OPERATION_FAILED"] = "OPERATION_FAILED";
  SignalType["PAYMENT_ERROR"] = "PAYMENT_ERROR";
  SignalType["PAYMENT_METHODS_APPLIED"] = "PAYMENT_METHODS_APPLIED";
  SignalType["PAYMENT_METHOD_UPDATED"] = "PAYMENT_METHOD_UPDATED";
  SignalType["PRICE_ADJUSTED"] = "PRICE_ADJUSTED";
  SignalType["PRICE_CHANGED"] = "PRICE_CHANGED";
  SignalType["REQUEST_CONFLICT"] = "REQUEST_CONFLICT";
  SignalType["SESSION_EXPIRED"] = "SESSION_EXPIRED";
  SignalType["SESSION_TOKEN_UPDATED"] = "SESSION_TOKEN_UPDATED";
  SignalType["SIGNAL_TYPE_INVALID"] = "SIGNAL_TYPE_INVALID";
  SignalType["UNRECOGNIZED"] = "UNRECOGNIZED";
  SignalType["UNRECOVERABLE_FAILURE"] = "UNRECOVERABLE_FAILURE";
})(SignalType || (SignalType = {}));
export var SmartFormHeadingSizeInput;
(function (SmartFormHeadingSizeInput) {
  SmartFormHeadingSizeInput["EIGHT"] = "EIGHT";
  SmartFormHeadingSizeInput["FIVE"] = "FIVE";
  SmartFormHeadingSizeInput["FOUR"] = "FOUR";
  SmartFormHeadingSizeInput["ONE"] = "ONE";
  SmartFormHeadingSizeInput["SEVEN"] = "SEVEN";
  SmartFormHeadingSizeInput["SIX"] = "SIX";
  SmartFormHeadingSizeInput["THREE"] = "THREE";
  SmartFormHeadingSizeInput["TWO"] = "TWO";
})(SmartFormHeadingSizeInput || (SmartFormHeadingSizeInput = {}));
export var SmartFormHeadingTagInput;
(function (SmartFormHeadingTagInput) {
  SmartFormHeadingTagInput["H1"] = "H1";
  SmartFormHeadingTagInput["H2"] = "H2";
  SmartFormHeadingTagInput["H3"] = "H3";
  SmartFormHeadingTagInput["H4"] = "H4";
  SmartFormHeadingTagInput["H5"] = "H5";
  SmartFormHeadingTagInput["H6"] = "H6";
})(SmartFormHeadingTagInput || (SmartFormHeadingTagInput = {}));
export var SmartFormTextInputVariant;
(function (SmartFormTextInputVariant) {
  SmartFormTextInputVariant["EMAIL"] = "EMAIL";
  SmartFormTextInputVariant["NUMERIC"] = "NUMERIC";
  SmartFormTextInputVariant["TEL"] = "TEL";
  SmartFormTextInputVariant["TEXT"] = "TEXT";
  SmartFormTextInputVariant["TEXTAREA"] = "TEXTAREA";
})(SmartFormTextInputVariant || (SmartFormTextInputVariant = {}));
export var SmartFormType;
(function (SmartFormType) {
  SmartFormType["BILLING_ADDRESS"] = "BILLING_ADDRESS";
  SmartFormType["CONTACT_INFORMATION"] = "CONTACT_INFORMATION";
})(SmartFormType || (SmartFormType = {}));
export var SocialConnectionsManageConnectionRequestType;
(function (SocialConnectionsManageConnectionRequestType) {
  SocialConnectionsManageConnectionRequestType["ACCEPT"] = "ACCEPT";
  SocialConnectionsManageConnectionRequestType["REJECT"] = "REJECT";
  SocialConnectionsManageConnectionRequestType["REMOVE"] = "REMOVE";
  SocialConnectionsManageConnectionRequestType["REQUEST"] = "REQUEST";
})(SocialConnectionsManageConnectionRequestType || (SocialConnectionsManageConnectionRequestType = {}));
export var SocialShareStrategy;
(function (SocialShareStrategy) {
  SocialShareStrategy["ACTIVITY"] = "ACTIVITY";
  SocialShareStrategy["FLIGHT"] = "FLIGHT";
  SocialShareStrategy["FLIGHT_SEARCH"] = "FLIGHT_SEARCH";
  SocialShareStrategy["PROPERTY"] = "PROPERTY";
  SocialShareStrategy["PROPERTY_SEARCH"] = "PROPERTY_SEARCH";
  SocialShareStrategy["REQUEST_TO_JOIN_TRIP"] = "REQUEST_TO_JOIN_TRIP";
  SocialShareStrategy["TRIP_INVITE"] = "TRIP_INVITE";
})(SocialShareStrategy || (SocialShareStrategy = {}));
export var SortAndFilterBasicFilterPayloadType;
(function (SortAndFilterBasicFilterPayloadType) {
  SortAndFilterBasicFilterPayloadType["OPTION"] = "OPTION";
  SortAndFilterBasicFilterPayloadType["REMOVE"] = "REMOVE";
})(SortAndFilterBasicFilterPayloadType || (SortAndFilterBasicFilterPayloadType = {}));
export var SortAndFilterSignalCondition;
(function (SortAndFilterSignalCondition) {
  SortAndFilterSignalCondition["DEFAULT_TO_NON_DEFAULT"] = "DEFAULT_TO_NON_DEFAULT";
  SortAndFilterSignalCondition["NON_DEFAULT_TO_DEFAULT"] = "NON_DEFAULT_TO_DEFAULT";
  SortAndFilterSignalCondition["NON_DEFAULT_TO_NON_DEFAULT"] = "NON_DEFAULT_TO_NON_DEFAULT";
  SortAndFilterSignalCondition["SELECTED"] = "SELECTED";
  SortAndFilterSignalCondition["UNSELECTED"] = "UNSELECTED";
})(SortAndFilterSignalCondition || (SortAndFilterSignalCondition = {}));
export var SortAndFiltersViewType;
(function (SortAndFiltersViewType) {
  SortAndFiltersViewType["APPLIED_FILTERS"] = "APPLIED_FILTERS";
  SortAndFiltersViewType["FILTERS"] = "FILTERS";
  SortAndFiltersViewType["FILTERS_PILLS"] = "FILTERS_PILLS";
  SortAndFiltersViewType["FILTER_BAR"] = "FILTER_BAR";
  SortAndFiltersViewType["FILTER_DIALOG"] = "FILTER_DIALOG";
  SortAndFiltersViewType["SORT"] = "SORT";
  SortAndFiltersViewType["SORT_AND_FILTERS"] = "SORT_AND_FILTERS";
  SortAndFiltersViewType["SORT_TABS"] = "SORT_TABS";
  SortAndFiltersViewType["USE_REWARDS"] = "USE_REWARDS";
})(SortAndFiltersViewType || (SortAndFiltersViewType = {}));
export var SourceType;
(function (SourceType) {
  SourceType["ESR"] = "ESR";
  SourceType["GDS"] = "GDS";
})(SourceType || (SourceType = {}));
export var Space;
(function (Space) {
  Space["four"] = "four";
  Space["half"] = "half";
  Space["one"] = "one";
  Space["six"] = "six";
  Space["three"] = "three";
  Space["twelve"] = "twelve";
  Space["two"] = "two";
})(Space || (Space = {}));
export var Spacing;
(function (Spacing) {
  Spacing["four"] = "four";
  Spacing["half"] = "half";
  Spacing["one"] = "one";
  Spacing["six"] = "six";
  Spacing["sixteen"] = "sixteen";
  Spacing["three"] = "three";
  Spacing["twelve"] = "twelve";
  Spacing["two"] = "two";
  Spacing["unset"] = "unset";
})(Spacing || (Spacing = {}));
export var SpacingOptions;
(function (SpacingOptions) {
  SpacingOptions["FOUR"] = "FOUR";
  SpacingOptions["HALF"] = "HALF";
  SpacingOptions["ONE"] = "ONE";
  SpacingOptions["SIX"] = "SIX";
  SpacingOptions["SIXTEEN"] = "SIXTEEN";
  SpacingOptions["THREE"] = "THREE";
  SpacingOptions["TWELVE"] = "TWELVE";
  SpacingOptions["TWO"] = "TWO";
  SpacingOptions["UNSET"] = "UNSET";
})(SpacingOptions || (SpacingOptions = {}));
export var SpecialEquipment;
(function (SpecialEquipment) {
  SpecialEquipment["ADDITIONAL_DRIVER"] = "ADDITIONAL_DRIVER";
  SpecialEquipment["BICYCLE_RACK"] = "BICYCLE_RACK";
  SpecialEquipment["BOOSTER_SEAT"] = "BOOSTER_SEAT";
  SpecialEquipment["INFANT_SEAT"] = "INFANT_SEAT";
  SpecialEquipment["LEFT_HAND_CONTROL"] = "LEFT_HAND_CONTROL";
  SpecialEquipment["LUGGAGE_RACK"] = "LUGGAGE_RACK";
  SpecialEquipment["NAV_SYSTEM"] = "NAV_SYSTEM";
  SpecialEquipment["RIGHT_HAND_CONTROL"] = "RIGHT_HAND_CONTROL";
  SpecialEquipment["ROAD_CONGESTION_PAYMENT_SCHEME"] = "ROAD_CONGESTION_PAYMENT_SCHEME";
  SpecialEquipment["SATELLITE_RADIO"] = "SATELLITE_RADIO";
  SpecialEquipment["SKI_RACK"] = "SKI_RACK";
  SpecialEquipment["SNOW_CHAINS"] = "SNOW_CHAINS";
  SpecialEquipment["SNOW_TIRES"] = "SNOW_TIRES";
  SpecialEquipment["TODDLER_SEAT"] = "TODDLER_SEAT";
  SpecialEquipment["TOLL_PAYMENT_TAG_PASS"] = "TOLL_PAYMENT_TAG_PASS";
  SpecialEquipment["TRAILER_HITCH"] = "TRAILER_HITCH";
  SpecialEquipment["WIFI_ACCESS"] = "WIFI_ACCESS";
  SpecialEquipment["WINTER_PACKAGE"] = "WINTER_PACKAGE";
})(SpecialEquipment || (SpecialEquipment = {}));
export var SponsoredContentLocationResolver;
(function (SponsoredContentLocationResolver) {
  SponsoredContentLocationResolver["GAIA"] = "GAIA";
  SponsoredContentLocationResolver["TLA"] = "TLA";
})(SponsoredContentLocationResolver || (SponsoredContentLocationResolver = {}));
export var SponsoredContentLocationResolverInput;
(function (SponsoredContentLocationResolverInput) {
  SponsoredContentLocationResolverInput["GAIA"] = "GAIA";
  SponsoredContentLocationResolverInput["TLA"] = "TLA";
})(SponsoredContentLocationResolverInput || (SponsoredContentLocationResolverInput = {}));
export var SponsoredContentProductType;
(function (SponsoredContentProductType) {
  SponsoredContentProductType["ALTERNATE_DESTINATIONS_CAROUSEL"] = "ALTERNATE_DESTINATIONS_CAROUSEL";
  SponsoredContentProductType["BRAND_RESULT_LISTING"] = "BRAND_RESULT_LISTING";
  SponsoredContentProductType["BRAND_RESULT_LISTING_CARS"] = "BRAND_RESULT_LISTING_CARS";
  SponsoredContentProductType["BRAND_RESULT_LISTING_CRUISES"] = "BRAND_RESULT_LISTING_CRUISES";
  SponsoredContentProductType["BRAND_RESULT_LISTING_FLIGHTS"] = "BRAND_RESULT_LISTING_FLIGHTS";
  SponsoredContentProductType["BRAND_RESULT_LISTING_LODGING"] = "BRAND_RESULT_LISTING_LODGING";
  SponsoredContentProductType["BRAND_RESULT_LISTING_LX"] = "BRAND_RESULT_LISTING_LX";
  SponsoredContentProductType["DESTINATION_EXPERIENCE"] = "DESTINATION_EXPERIENCE";
  SponsoredContentProductType["MULTI_LISTING_AD"] = "MULTI_LISTING_AD";
  SponsoredContentProductType["SPONSORED_PROPERTIES_CAROUSEL"] = "SPONSORED_PROPERTIES_CAROUSEL";
})(SponsoredContentProductType || (SponsoredContentProductType = {}));
export var StayType;
(function (StayType) {
  StayType["CONVENTIONAL"] = "CONVENTIONAL";
  StayType["VACATION_RENTALS"] = "VACATION_RENTALS";
})(StayType || (StayType = {}));
export var StepReviewInlineEditingSheetButtonType;
(function (StepReviewInlineEditingSheetButtonType) {
  StepReviewInlineEditingSheetButtonType["PRIMARY"] = "PRIMARY";
  StepReviewInlineEditingSheetButtonType["SECONDARY"] = "SECONDARY";
})(StepReviewInlineEditingSheetButtonType || (StepReviewInlineEditingSheetButtonType = {}));
export var StrikeOutType;
(function (StrikeOutType) {
  StrikeOutType["ATTACH"] = "ATTACH";
  StrikeOutType["INVALID"] = "INVALID";
  StrikeOutType["LOYALTY"] = "LOYALTY";
  StrikeOutType["PACKAGE"] = "PACKAGE";
  StrikeOutType["PRICE_SURVEY"] = "PRICE_SURVEY";
  StrikeOutType["STANDALONE"] = "STANDALONE";
})(StrikeOutType || (StrikeOutType = {}));
export var StructureType;
(function (StructureType) {
  StructureType["AGRITOURISM"] = "AGRITOURISM";
  StructureType["ALL_INCLUSIVE"] = "ALL_INCLUSIVE";
  StructureType["APARTMENT"] = "APARTMENT";
  StructureType["APART_HOTEL"] = "APART_HOTEL";
  StructureType["BED_AND_BREAKFAST"] = "BED_AND_BREAKFAST";
  StructureType["CABIN"] = "CABIN";
  StructureType["CAPSULE_HOTEL"] = "CAPSULE_HOTEL";
  StructureType["CARAVAN_PARK"] = "CARAVAN_PARK";
  StructureType["CASTLE"] = "CASTLE";
  StructureType["CHALET"] = "CHALET";
  StructureType["CONDO"] = "CONDO";
  StructureType["CONDO_RESORT"] = "CONDO_RESORT";
  StructureType["COTTAGE"] = "COTTAGE";
  StructureType["COUNTRY_HOUSE"] = "COUNTRY_HOUSE";
  StructureType["CRUISE"] = "CRUISE";
  StructureType["ECO_HOTEL"] = "ECO_HOTEL";
  StructureType["GUEST_HOUSE"] = "GUEST_HOUSE";
  StructureType["HOLIDAY_PARK"] = "HOLIDAY_PARK";
  StructureType["HOSTAL"] = "HOSTAL";
  StructureType["HOSTEL"] = "HOSTEL";
  StructureType["HOTEL"] = "HOTEL";
  StructureType["HOTEL_RESORT"] = "HOTEL_RESORT";
  StructureType["HOUSE_BOAT"] = "HOUSE_BOAT";
  StructureType["INN"] = "INN";
  StructureType["LODGE"] = "LODGE";
  StructureType["LONGHOUSE"] = "LONGHOUSE";
  StructureType["MOBILE_HOME"] = "MOBILE_HOME";
  StructureType["MOTEL"] = "MOTEL";
  StructureType["OVERWATER"] = "OVERWATER";
  StructureType["PALACE"] = "PALACE";
  StructureType["PENSION"] = "PENSION";
  StructureType["POUSADA_BRAZIL"] = "POUSADA_BRAZIL";
  StructureType["POUSADA_PORTUGAL"] = "POUSADA_PORTUGAL";
  StructureType["RANCH"] = "RANCH";
  StructureType["RESIDENCE"] = "RESIDENCE";
  StructureType["RIAD"] = "RIAD";
  StructureType["RYOKAN"] = "RYOKAN";
  StructureType["SAFARI"] = "SAFARI";
  StructureType["SERVICED_APARTMENT"] = "SERVICED_APARTMENT";
  StructureType["TOWNHOUSE"] = "TOWNHOUSE";
  StructureType["TRADITIONAL_BUILDING"] = "TRADITIONAL_BUILDING";
  StructureType["TREE_HOUSE"] = "TREE_HOUSE";
  StructureType["UNKNOWN"] = "UNKNOWN";
  StructureType["VACATION_HOME"] = "VACATION_HOME";
  StructureType["VACATION_RENTAL"] = "VACATION_RENTAL";
  StructureType["VACATION_RENTALS"] = "VACATION_RENTALS";
  StructureType["VILLA"] = "VILLA";
  StructureType["VIP"] = "VIP";
})(StructureType || (StructureType = {}));
export var SupplyContentDescriptionSectionName;
(function (SupplyContentDescriptionSectionName) {
  SupplyContentDescriptionSectionName["ABOUT_YOU"] = "ABOUT_YOU";
  SupplyContentDescriptionSectionName["BATHROOMS_ADDITIONAL_INFO"] = "BATHROOMS_ADDITIONAL_INFO";
  SupplyContentDescriptionSectionName["BEDROOMS_ADDITIONAL_INFO"] = "BEDROOMS_ADDITIONAL_INFO";
  SupplyContentDescriptionSectionName["DESCRIPTION"] = "DESCRIPTION";
  SupplyContentDescriptionSectionName["HEADLINE"] = "HEADLINE";
  SupplyContentDescriptionSectionName["INTERIOR_SIZE"] = "INTERIOR_SIZE";
  SupplyContentDescriptionSectionName["MANUAL_TRANSLATION"] = "MANUAL_TRANSLATION";
  SupplyContentDescriptionSectionName["PROPERTY_ADDITIONAL_INFO"] = "PROPERTY_ADDITIONAL_INFO";
  SupplyContentDescriptionSectionName["PROPERTY_NAME"] = "PROPERTY_NAME";
  SupplyContentDescriptionSectionName["PROPERTY_TYPE"] = "PROPERTY_TYPE";
  SupplyContentDescriptionSectionName["SUITABILITY_AMENITY"] = "SUITABILITY_AMENITY";
  SupplyContentDescriptionSectionName["UNIQUE_BENEFITS"] = "UNIQUE_BENEFITS";
  SupplyContentDescriptionSectionName["WHY_THIS_PROPERTY"] = "WHY_THIS_PROPERTY";
})(SupplyContentDescriptionSectionName || (SupplyContentDescriptionSectionName = {}));
export var SupplyContentManualTranslationOperation;
(function (SupplyContentManualTranslationOperation) {
  SupplyContentManualTranslationOperation["ADD"] = "ADD";
  SupplyContentManualTranslationOperation["DELETE"] = "DELETE";
  SupplyContentManualTranslationOperation["UPDATE"] = "UPDATE";
})(SupplyContentManualTranslationOperation || (SupplyContentManualTranslationOperation = {}));
export var SupplyContractingAlignment;
(function (SupplyContractingAlignment) {
  SupplyContractingAlignment["CENTER"] = "CENTER";
  SupplyContractingAlignment["LEFT"] = "LEFT";
})(SupplyContractingAlignment || (SupplyContractingAlignment = {}));
export var SupplyContractingResponseStatus;
(function (SupplyContractingResponseStatus) {
  SupplyContractingResponseStatus["FAIL"] = "FAIL";
  SupplyContractingResponseStatus["SUCCESS"] = "SUCCESS";
})(SupplyContractingResponseStatus || (SupplyContractingResponseStatus = {}));
export var SupplyContractingToolbarNavType;
(function (SupplyContractingToolbarNavType) {
  SupplyContractingToolbarNavType["BACK"] = "BACK";
  SupplyContractingToolbarNavType["CLOSE"] = "CLOSE";
})(SupplyContractingToolbarNavType || (SupplyContractingToolbarNavType = {}));
export var SupplyDashboardType;
(function (SupplyDashboardType) {
  SupplyDashboardType["DASHBOARD"] = "DASHBOARD";
  SupplyDashboardType["LEGACY"] = "LEGACY";
})(SupplyDashboardType || (SupplyDashboardType = {}));
export var SupplyLoyaltyActionId;
(function (SupplyLoyaltyActionId) {
  SupplyLoyaltyActionId["VIP_APPLICATION_REVIEW_SHEET"] = "VIP_APPLICATION_REVIEW_SHEET";
  SupplyLoyaltyActionId["VIP_BENEFITS_SHEET"] = "VIP_BENEFITS_SHEET";
  SupplyLoyaltyActionId["VIP_CONTACTS_SHEET"] = "VIP_CONTACTS_SHEET";
  SupplyLoyaltyActionId["VIP_CONTRACT_SHEET"] = "VIP_CONTRACT_SHEET";
  SupplyLoyaltyActionId["VIP_CONTRACT_VIEW_SHEET"] = "VIP_CONTRACT_VIEW_SHEET";
  SupplyLoyaltyActionId["VIP_MEMBERS_ONLY_DISCOUNT_SHEET"] = "VIP_MEMBERS_ONLY_DISCOUNT_SHEET";
  SupplyLoyaltyActionId["VIP_ONBOARDING_ACTIVATION_SUBMIT"] = "VIP_ONBOARDING_ACTIVATION_SUBMIT";
  SupplyLoyaltyActionId["VIP_PROGRAM_PROGRESS_SHEET"] = "VIP_PROGRAM_PROGRESS_SHEET";
  SupplyLoyaltyActionId["VIP_PROGRAM_SUBMIT"] = "VIP_PROGRAM_SUBMIT";
  SupplyLoyaltyActionId["VIP_VALUE_ADD_SHEET"] = "VIP_VALUE_ADD_SHEET";
})(SupplyLoyaltyActionId || (SupplyLoyaltyActionId = {}));
export var SupplyLoyaltyCallToActionCardVariants;
(function (SupplyLoyaltyCallToActionCardVariants) {
  SupplyLoyaltyCallToActionCardVariants["PRIMARY"] = "PRIMARY";
  SupplyLoyaltyCallToActionCardVariants["SECONDARY"] = "SECONDARY";
})(SupplyLoyaltyCallToActionCardVariants || (SupplyLoyaltyCallToActionCardVariants = {}));
export var SupplyLoyaltyCardVariants;
(function (SupplyLoyaltyCardVariants) {
  SupplyLoyaltyCardVariants["VIP_ACCESS_BENEFIT"] = "VIP_ACCESS_BENEFIT";
  SupplyLoyaltyCardVariants["VIP_HOW_IT_WORKS_REQUIREMENT"] = "VIP_HOW_IT_WORKS_REQUIREMENT";
  SupplyLoyaltyCardVariants["VIP_MESSAGE_HORIZONTAL"] = "VIP_MESSAGE_HORIZONTAL";
  SupplyLoyaltyCardVariants["VIP_TRAINING"] = "VIP_TRAINING";
})(SupplyLoyaltyCardVariants || (SupplyLoyaltyCardVariants = {}));
export var SupplyLoyaltyResponseStatus;
(function (SupplyLoyaltyResponseStatus) {
  SupplyLoyaltyResponseStatus["FAIL"] = "FAIL";
  SupplyLoyaltyResponseStatus["SUCCESS"] = "SUCCESS";
})(SupplyLoyaltyResponseStatus || (SupplyLoyaltyResponseStatus = {}));
export var SupplyLoyaltySectionVariant;
(function (SupplyLoyaltySectionVariant) {
  SupplyLoyaltySectionVariant["PRIMARY"] = "PRIMARY";
  SupplyLoyaltySectionVariant["SECONDARY"] = "SECONDARY";
})(SupplyLoyaltySectionVariant || (SupplyLoyaltySectionVariant = {}));
export var SupplyLoyaltyVipProgramApprovalDecisionInput;
(function (SupplyLoyaltyVipProgramApprovalDecisionInput) {
  SupplyLoyaltyVipProgramApprovalDecisionInput["APPROVE"] = "APPROVE";
  SupplyLoyaltyVipProgramApprovalDecisionInput["REJECT"] = "REJECT";
})(SupplyLoyaltyVipProgramApprovalDecisionInput || (SupplyLoyaltyVipProgramApprovalDecisionInput = {}));
export var SupplyNavigationHeaderMenuType;
(function (SupplyNavigationHeaderMenuType) {
  SupplyNavigationHeaderMenuType["NOTIFICATIONS"] = "NOTIFICATIONS";
  SupplyNavigationHeaderMenuType["PORTFOLIO_PROPERTY_FILTER"] = "PORTFOLIO_PROPERTY_FILTER";
  SupplyNavigationHeaderMenuType["PROPERTY_DETAILS"] = "PROPERTY_DETAILS";
  SupplyNavigationHeaderMenuType["PROPERTY_FILTER"] = "PROPERTY_FILTER";
  SupplyNavigationHeaderMenuType["SEARCH"] = "SEARCH";
})(SupplyNavigationHeaderMenuType || (SupplyNavigationHeaderMenuType = {}));
export var SupplyOnboardingAvailabilityType;
(function (SupplyOnboardingAvailabilityType) {
  SupplyOnboardingAvailabilityType["BLOCK"] = "BLOCK";
  SupplyOnboardingAvailabilityType["OPEN"] = "OPEN";
})(SupplyOnboardingAvailabilityType || (SupplyOnboardingAvailabilityType = {}));
export var SupplyOnboardingButtonType;
(function (SupplyOnboardingButtonType) {
  SupplyOnboardingButtonType["BACK"] = "BACK";
  SupplyOnboardingButtonType["DONE"] = "DONE";
  SupplyOnboardingButtonType["LINK"] = "LINK";
  SupplyOnboardingButtonType["NEXT"] = "NEXT";
  SupplyOnboardingButtonType["SKIP"] = "SKIP";
})(SupplyOnboardingButtonType || (SupplyOnboardingButtonType = {}));
export var SupplyOnboardingTipCardId;
(function (SupplyOnboardingTipCardId) {
  SupplyOnboardingTipCardId["AMENITIES"] = "AMENITIES";
  SupplyOnboardingTipCardId["DESCRIPTION"] = "DESCRIPTION";
  SupplyOnboardingTipCardId["HEADLINE"] = "HEADLINE";
  SupplyOnboardingTipCardId["PHOTOS"] = "PHOTOS";
  SupplyOnboardingTipCardId["REGULATORY"] = "REGULATORY";
})(SupplyOnboardingTipCardId || (SupplyOnboardingTipCardId = {}));
export var SupplyPartnerLifeCycleStage;
(function (SupplyPartnerLifeCycleStage) {
  SupplyPartnerLifeCycleStage["LIFE_CYCLE_STAGE_ALL_GROWN_UP"] = "LIFE_CYCLE_STAGE_ALL_GROWN_UP";
  SupplyPartnerLifeCycleStage["LIFE_CYCLE_STAGE_BABY"] = "LIFE_CYCLE_STAGE_BABY";
  SupplyPartnerLifeCycleStage["LIFE_CYCLE_STAGE_KID"] = "LIFE_CYCLE_STAGE_KID";
  SupplyPartnerLifeCycleStage["LIFE_CYCLE_STAGE_TEENAGER"] = "LIFE_CYCLE_STAGE_TEENAGER";
  SupplyPartnerLifeCycleStage["LIFE_CYCLE_STAGE_YOUNG_ADULT"] = "LIFE_CYCLE_STAGE_YOUNG_ADULT";
  SupplyPartnerLifeCycleStage["UNRECOGNIZED"] = "UNRECOGNIZED";
})(SupplyPartnerLifeCycleStage || (SupplyPartnerLifeCycleStage = {}));
export var SupplyPaymentDetailsExpandoPositioning;
(function (SupplyPaymentDetailsExpandoPositioning) {
  SupplyPaymentDetailsExpandoPositioning["LEADING"] = "LEADING";
  SupplyPaymentDetailsExpandoPositioning["TRAILING"] = "TRAILING";
})(SupplyPaymentDetailsExpandoPositioning || (SupplyPaymentDetailsExpandoPositioning = {}));
export var SupplyPaymentDisplayToolbarIcon;
(function (SupplyPaymentDisplayToolbarIcon) {
  SupplyPaymentDisplayToolbarIcon["BACK"] = "BACK";
  SupplyPaymentDisplayToolbarIcon["CLOSE"] = "CLOSE";
})(SupplyPaymentDisplayToolbarIcon || (SupplyPaymentDisplayToolbarIcon = {}));
export var SupplyPhotosActionType;
(function (SupplyPhotosActionType) {
  SupplyPhotosActionType["CARD_BUTTON"] = "CARD_BUTTON";
  SupplyPhotosActionType["LINK"] = "LINK";
  SupplyPhotosActionType["LINK_BUTTON"] = "LINK_BUTTON";
  SupplyPhotosActionType["PRIMARY_BUTTON"] = "PRIMARY_BUTTON";
  SupplyPhotosActionType["SECONDARY_BUTTON"] = "SECONDARY_BUTTON";
  SupplyPhotosActionType["TERTIARY_BUTTON"] = "TERTIARY_BUTTON";
  SupplyPhotosActionType["TOOL_BAR_BUTTON"] = "TOOL_BAR_BUTTON";
})(SupplyPhotosActionType || (SupplyPhotosActionType = {}));
export var SupplyPhotosPhotoStatus;
(function (SupplyPhotosPhotoStatus) {
  SupplyPhotosPhotoStatus["ACTIVE"] = "ACTIVE";
  SupplyPhotosPhotoStatus["PROCESSING"] = "PROCESSING";
})(SupplyPhotosPhotoStatus || (SupplyPhotosPhotoStatus = {}));
export var SupplyPremierHostDownloadType;
(function (SupplyPremierHostDownloadType) {
  SupplyPremierHostDownloadType["BOOKING_DETAILS"] = "BOOKING_DETAILS";
  SupplyPremierHostDownloadType["LISTINGS_SUMMARY"] = "LISTINGS_SUMMARY";
})(SupplyPremierHostDownloadType || (SupplyPremierHostDownloadType = {}));
export var SupplyProductCreationNavigationType;
(function (SupplyProductCreationNavigationType) {
  SupplyProductCreationNavigationType["BACK"] = "BACK";
  SupplyProductCreationNavigationType["NEXT"] = "NEXT";
})(SupplyProductCreationNavigationType || (SupplyProductCreationNavigationType = {}));
export var SupplyProductCreationPopularRateType;
(function (SupplyProductCreationPopularRateType) {
  SupplyProductCreationPopularRateType["BREAKFAST"] = "BREAKFAST";
  SupplyProductCreationPopularRateType["MOD"] = "MOD";
  SupplyProductCreationPopularRateType["NONREFUNDABLE"] = "NONREFUNDABLE";
  SupplyProductCreationPopularRateType["PACKAGE"] = "PACKAGE";
  SupplyProductCreationPopularRateType["REFUNDABLE"] = "REFUNDABLE";
})(SupplyProductCreationPopularRateType || (SupplyProductCreationPopularRateType = {}));
export var SupplyProductCreationRoomNameAttributeCategoryName;
(function (SupplyProductCreationRoomNameAttributeCategoryName) {
  SupplyProductCreationRoomNameAttributeCategoryName["ACCESSIBILITY"] = "ACCESSIBILITY";
  SupplyProductCreationRoomNameAttributeCategoryName["AREA"] = "AREA";
  SupplyProductCreationRoomNameAttributeCategoryName["BEDROOM_DETAILS"] = "BEDROOM_DETAILS";
  SupplyProductCreationRoomNameAttributeCategoryName["FEATURE_AMENITY"] = "FEATURE_AMENITY";
  SupplyProductCreationRoomNameAttributeCategoryName["OTHER_BEDROOM_DETAILS"] = "OTHER_BEDROOM_DETAILS";
  SupplyProductCreationRoomNameAttributeCategoryName["OUTDOOR_SPACE"] = "OUTDOOR_SPACE";
  SupplyProductCreationRoomNameAttributeCategoryName["ROOM_CLASS"] = "ROOM_CLASS";
  SupplyProductCreationRoomNameAttributeCategoryName["ROOM_TYPE"] = "ROOM_TYPE";
  SupplyProductCreationRoomNameAttributeCategoryName["SMOKING_POLICY"] = "SMOKING_POLICY";
  SupplyProductCreationRoomNameAttributeCategoryName["VIEW"] = "VIEW";
})(SupplyProductCreationRoomNameAttributeCategoryName || (SupplyProductCreationRoomNameAttributeCategoryName = {}));
export var SupplyProductCreationRoomOverviewTabName;
(function (SupplyProductCreationRoomOverviewTabName) {
  SupplyProductCreationRoomOverviewTabName["FINISHED_ROOMS"] = "FINISHED_ROOMS";
  SupplyProductCreationRoomOverviewTabName["UNFINISHED_ROOMS"] = "UNFINISHED_ROOMS";
})(SupplyProductCreationRoomOverviewTabName || (SupplyProductCreationRoomOverviewTabName = {}));
export var SupplyProgramsAlignment;
(function (SupplyProgramsAlignment) {
  SupplyProgramsAlignment["CENTER"] = "CENTER";
  SupplyProgramsAlignment["LEFT"] = "LEFT";
})(SupplyProgramsAlignment || (SupplyProgramsAlignment = {}));
export var SupplyProgramsEnrollmentStatus;
(function (SupplyProgramsEnrollmentStatus) {
  SupplyProgramsEnrollmentStatus["ACCEPTED_TERMS"] = "ACCEPTED_TERMS";
  SupplyProgramsEnrollmentStatus["ACTIVE_ENROLLMENT"] = "ACTIVE_ENROLLMENT";
  SupplyProgramsEnrollmentStatus["ELIGIBLE_TO_ENROLL"] = "ELIGIBLE_TO_ENROLL";
  SupplyProgramsEnrollmentStatus["NOT_ELIGIBLE"] = "NOT_ELIGIBLE";
})(SupplyProgramsEnrollmentStatus || (SupplyProgramsEnrollmentStatus = {}));
export var SupplyProgramsResponseStatus;
(function (SupplyProgramsResponseStatus) {
  SupplyProgramsResponseStatus["FAILURE"] = "FAILURE";
  SupplyProgramsResponseStatus["SUCCESS"] = "SUCCESS";
})(SupplyProgramsResponseStatus || (SupplyProgramsResponseStatus = {}));
export var SupplyProgramsSheetType;
(function (SupplyProgramsSheetType) {
  SupplyProgramsSheetType["ENROLLMENT"] = "ENROLLMENT";
  SupplyProgramsSheetType["HOW_IT_WORKS_POPOVER"] = "HOW_IT_WORKS_POPOVER";
  SupplyProgramsSheetType["VIEW_TERMS_AND_CONDITIONS"] = "VIEW_TERMS_AND_CONDITIONS";
  SupplyProgramsSheetType["VIEW_TERMS_AND_CONDITIONS_ONLY"] = "VIEW_TERMS_AND_CONDITIONS_ONLY";
})(SupplyProgramsSheetType || (SupplyProgramsSheetType = {}));
export var SupplyProgramsToolbarNavType;
(function (SupplyProgramsToolbarNavType) {
  SupplyProgramsToolbarNavType["BACK"] = "BACK";
  SupplyProgramsToolbarNavType["CLOSE"] = "CLOSE";
})(SupplyProgramsToolbarNavType || (SupplyProgramsToolbarNavType = {}));
export var SupplyPromotionNotificationType;
(function (SupplyPromotionNotificationType) {
  SupplyPromotionNotificationType["ERROR"] = "ERROR";
  SupplyPromotionNotificationType["SUCCESS"] = "SUCCESS";
})(SupplyPromotionNotificationType || (SupplyPromotionNotificationType = {}));
export var SupplyPromotionReservationMode;
(function (SupplyPromotionReservationMode) {
  SupplyPromotionReservationMode["ADVANCED"] = "ADVANCED";
  SupplyPromotionReservationMode["STANDARD"] = "STANDARD";
})(SupplyPromotionReservationMode || (SupplyPromotionReservationMode = {}));
export var SupplyPromotionResponseStatus;
(function (SupplyPromotionResponseStatus) {
  SupplyPromotionResponseStatus["FAIL"] = "FAIL";
  SupplyPromotionResponseStatus["SUCCESS"] = "SUCCESS";
})(SupplyPromotionResponseStatus || (SupplyPromotionResponseStatus = {}));
export var SupplyQuickActionComparisonType;
(function (SupplyQuickActionComparisonType) {
  SupplyQuickActionComparisonType["GREATER_THAN"] = "GREATER_THAN";
  SupplyQuickActionComparisonType["LESSER_THAN"] = "LESSER_THAN";
})(SupplyQuickActionComparisonType || (SupplyQuickActionComparisonType = {}));
export var SupplyQuickActionElementVisibility;
(function (SupplyQuickActionElementVisibility) {
  SupplyQuickActionElementVisibility["HIDE_SELF"] = "HIDE_SELF";
  SupplyQuickActionElementVisibility["SHOW_SELF"] = "SHOW_SELF";
})(SupplyQuickActionElementVisibility || (SupplyQuickActionElementVisibility = {}));
export var SupplyQuickActionFieldProcessorOperation;
(function (SupplyQuickActionFieldProcessorOperation) {
  SupplyQuickActionFieldProcessorOperation["PERCENT_CALC_AND_REPLACE"] = "PERCENT_CALC_AND_REPLACE";
  SupplyQuickActionFieldProcessorOperation["PLAIN_REPLACE"] = "PLAIN_REPLACE";
  SupplyQuickActionFieldProcessorOperation["SUBTRACT_AND_REPLACE"] = "SUBTRACT_AND_REPLACE";
})(SupplyQuickActionFieldProcessorOperation || (SupplyQuickActionFieldProcessorOperation = {}));
export var SupplyQuickActionFieldSetDirection;
(function (SupplyQuickActionFieldSetDirection) {
  SupplyQuickActionFieldSetDirection["HORIZONTAL"] = "HORIZONTAL";
  SupplyQuickActionFieldSetDirection["VERTICAL"] = "VERTICAL";
})(SupplyQuickActionFieldSetDirection || (SupplyQuickActionFieldSetDirection = {}));
export var SupplyQuickActionSpacingSize;
(function (SupplyQuickActionSpacingSize) {
  SupplyQuickActionSpacingSize["SPACING__0X__HALF"] = "SPACING__0X__HALF";
  SupplyQuickActionSpacingSize["SPACING__1X"] = "SPACING__1X";
  SupplyQuickActionSpacingSize["SPACING__2X"] = "SPACING__2X";
  SupplyQuickActionSpacingSize["SPACING__3X"] = "SPACING__3X";
  SupplyQuickActionSpacingSize["SPACING__4X"] = "SPACING__4X";
  SupplyQuickActionSpacingSize["SPACING__5X"] = "SPACING__5X";
  SupplyQuickActionSpacingSize["SPACING__6X"] = "SPACING__6X";
  SupplyQuickActionSpacingSize["SPACING__8X"] = "SPACING__8X";
  SupplyQuickActionSpacingSize["SPACING__10X"] = "SPACING__10X";
  SupplyQuickActionSpacingSize["SPACING__11X"] = "SPACING__11X";
  SupplyQuickActionSpacingSize["SPACING__12X"] = "SPACING__12X";
  SupplyQuickActionSpacingSize["SPACING__14X"] = "SPACING__14X";
  SupplyQuickActionSpacingSize["SPACING__16X"] = "SPACING__16X";
})(SupplyQuickActionSpacingSize || (SupplyQuickActionSpacingSize = {}));
export var SupplyQuickActionTableItemCellType;
(function (SupplyQuickActionTableItemCellType) {
  SupplyQuickActionTableItemCellType["NORMAL"] = "NORMAL";
  SupplyQuickActionTableItemCellType["STRIKETHROUGH"] = "STRIKETHROUGH";
})(SupplyQuickActionTableItemCellType || (SupplyQuickActionTableItemCellType = {}));
export var SupplyRateAvailabilityObservationSpacingSize;
(function (SupplyRateAvailabilityObservationSpacingSize) {
  SupplyRateAvailabilityObservationSpacingSize["SPACING__0X__HALF"] = "SPACING__0X__HALF";
  SupplyRateAvailabilityObservationSpacingSize["SPACING__1X"] = "SPACING__1X";
  SupplyRateAvailabilityObservationSpacingSize["SPACING__2X"] = "SPACING__2X";
  SupplyRateAvailabilityObservationSpacingSize["SPACING__3X"] = "SPACING__3X";
  SupplyRateAvailabilityObservationSpacingSize["SPACING__4X"] = "SPACING__4X";
  SupplyRateAvailabilityObservationSpacingSize["SPACING__5X"] = "SPACING__5X";
  SupplyRateAvailabilityObservationSpacingSize["SPACING__6X"] = "SPACING__6X";
  SupplyRateAvailabilityObservationSpacingSize["SPACING__8X"] = "SPACING__8X";
})(SupplyRateAvailabilityObservationSpacingSize || (SupplyRateAvailabilityObservationSpacingSize = {}));
export var SupplyRatePlanCreationOption;
(function (SupplyRatePlanCreationOption) {
  SupplyRatePlanCreationOption["B2B_DISTRIBUTION_CUSTOM"] = "B2B_DISTRIBUTION_CUSTOM";
  SupplyRatePlanCreationOption["BUSINESS_CUSTOM"] = "BUSINESS_CUSTOM";
  SupplyRatePlanCreationOption["STANDARD_CUSTOM"] = "STANDARD_CUSTOM";
})(SupplyRatePlanCreationOption || (SupplyRatePlanCreationOption = {}));
export var SupplyRatePlanEditOption;
(function (SupplyRatePlanEditOption) {
  SupplyRatePlanEditOption["REACTIVATE"] = "REACTIVATE";
})(SupplyRatePlanEditOption || (SupplyRatePlanEditOption = {}));
export var SupplyRatePlanOperationType;
(function (SupplyRatePlanOperationType) {
  SupplyRatePlanOperationType["COPY"] = "COPY";
  SupplyRatePlanOperationType["CREATE"] = "CREATE";
  SupplyRatePlanOperationType["EDIT"] = "EDIT";
})(SupplyRatePlanOperationType || (SupplyRatePlanOperationType = {}));
export var SupplyRegulatoryStatus;
(function (SupplyRegulatoryStatus) {
  SupplyRegulatoryStatus["COMPLIANT"] = "COMPLIANT";
  SupplyRegulatoryStatus["NONE"] = "NONE";
  SupplyRegulatoryStatus["NOT_COMPLIANT"] = "NOT_COMPLIANT";
})(SupplyRegulatoryStatus || (SupplyRegulatoryStatus = {}));
export var SupplyReservationDetailsComponentType;
(function (SupplyReservationDetailsComponentType) {
  SupplyReservationDetailsComponentType["ACCESS_INSTRUCTIONS"] = "ACCESS_INSTRUCTIONS";
  SupplyReservationDetailsComponentType["AUTOMATED_PAYOUT_STATUSES"] = "AUTOMATED_PAYOUT_STATUSES";
  SupplyReservationDetailsComponentType["CANCELLATION_POLICY"] = "CANCELLATION_POLICY";
  SupplyReservationDetailsComponentType["DAMAGE_DEPOSIT"] = "DAMAGE_DEPOSIT";
  SupplyReservationDetailsComponentType["INVOICE_STATUSES"] = "INVOICE_STATUSES";
  SupplyReservationDetailsComponentType["MINI_AVAILABILITY_CALENDAR"] = "MINI_AVAILABILITY_CALENDAR";
  SupplyReservationDetailsComponentType["PARTNER_NOTES"] = "PARTNER_NOTES";
  SupplyReservationDetailsComponentType["PAYMENT_DETAILS"] = "PAYMENT_DETAILS";
  SupplyReservationDetailsComponentType["PAYMENT_REQUEST_STATUSES"] = "PAYMENT_REQUEST_STATUSES";
  SupplyReservationDetailsComponentType["PAYMENT_SCHEDULE"] = "PAYMENT_SCHEDULE";
  SupplyReservationDetailsComponentType["PAYMENT_SUMMARY"] = "PAYMENT_SUMMARY";
  SupplyReservationDetailsComponentType["RENTAL_AGREEMENT"] = "RENTAL_AGREEMENT";
  SupplyReservationDetailsComponentType["STAY_DETAILS"] = "STAY_DETAILS";
  SupplyReservationDetailsComponentType["TRAVELER_INFO"] = "TRAVELER_INFO";
})(SupplyReservationDetailsComponentType || (SupplyReservationDetailsComponentType = {}));
export var SupplyReservationStatusType;
(function (SupplyReservationStatusType) {
  SupplyReservationStatusType["ARRIVING_TODAY"] = "ARRIVING_TODAY";
  SupplyReservationStatusType["BOOKED"] = "BOOKED";
  SupplyReservationStatusType["BOOKING_REQUEST"] = "BOOKING_REQUEST";
  SupplyReservationStatusType["BOOKING_REQUEST_DECLINED"] = "BOOKING_REQUEST_DECLINED";
  SupplyReservationStatusType["BOOKING_REQUEST_EXPIRED"] = "BOOKING_REQUEST_EXPIRED";
  SupplyReservationStatusType["CANCELED"] = "CANCELED";
  SupplyReservationStatusType["CANCELLATION_REQUEST"] = "CANCELLATION_REQUEST";
  SupplyReservationStatusType["CURRENT_STAY"] = "CURRENT_STAY";
  SupplyReservationStatusType["INQUIRY"] = "INQUIRY";
  SupplyReservationStatusType["POST_STAY"] = "POST_STAY";
  SupplyReservationStatusType["PRE_APPROVAL_EXPIRED"] = "PRE_APPROVAL_EXPIRED";
  SupplyReservationStatusType["PRE_APPROVED"] = "PRE_APPROVED";
  SupplyReservationStatusType["REQUEST_WITHDRAWN"] = "REQUEST_WITHDRAWN";
  SupplyReservationStatusType["UNKNOWN"] = "UNKNOWN";
})(SupplyReservationStatusType || (SupplyReservationStatusType = {}));
export var SupplyReservationsAccessTypes;
(function (SupplyReservationsAccessTypes) {
  SupplyReservationsAccessTypes["DOORMAN"] = "DOORMAN";
  SupplyReservationsAccessTypes["KEYPAD"] = "KEYPAD";
  SupplyReservationsAccessTypes["LOCKBOX"] = "LOCKBOX";
  SupplyReservationsAccessTypes["OTHER"] = "OTHER";
  SupplyReservationsAccessTypes["PICKUP"] = "PICKUP";
  SupplyReservationsAccessTypes["SMART_LOCK"] = "SMART_LOCK";
})(SupplyReservationsAccessTypes || (SupplyReservationsAccessTypes = {}));
export var SupplyReservationsActionType;
(function (SupplyReservationsActionType) {
  SupplyReservationsActionType["ACCESS_INSTRUCTIONS_ADD"] = "ACCESS_INSTRUCTIONS_ADD";
  SupplyReservationsActionType["ACCESS_INSTRUCTIONS_EDIT"] = "ACCESS_INSTRUCTIONS_EDIT";
  SupplyReservationsActionType["AUTOMATED_PAYOUT_STATUS_ACTION"] = "AUTOMATED_PAYOUT_STATUS_ACTION";
  SupplyReservationsActionType["AUTO_CANCEL_WAIVERS_CANCEL_CHANGES"] = "AUTO_CANCEL_WAIVERS_CANCEL_CHANGES";
  SupplyReservationsActionType["AUTO_CANCEL_WAIVERS_SAVE_CHANGES"] = "AUTO_CANCEL_WAIVERS_SAVE_CHANGES";
  SupplyReservationsActionType["DAMAGE_PROTECTION_REFUND_DEPOSIT"] = "DAMAGE_PROTECTION_REFUND_DEPOSIT";
  SupplyReservationsActionType["DAMAGE_PROTECTION_REPORT_DAMAGE"] = "DAMAGE_PROTECTION_REPORT_DAMAGE";
  SupplyReservationsActionType["DAMAGE_PROTECTION_REPORT_DAMAGE_EXTERNAL"] = "DAMAGE_PROTECTION_REPORT_DAMAGE_EXTERNAL";
  SupplyReservationsActionType["DAMAGE_PROTECTION_SEE_DAMAGE_REPORT"] = "DAMAGE_PROTECTION_SEE_DAMAGE_REPORT";
  SupplyReservationsActionType["DAMAGE_PROTECTION_SEE_POLICY"] = "DAMAGE_PROTECTION_SEE_POLICY";
  SupplyReservationsActionType["EVC_MANAGE_CARD_MORE_INFO"] = "EVC_MANAGE_CARD_MORE_INFO";
  SupplyReservationsActionType["EVC_MANAGE_CARD_SEARCH"] = "EVC_MANAGE_CARD_SEARCH";
  SupplyReservationsActionType["EVC_MANAGE_FEEDBACK"] = "EVC_MANAGE_FEEDBACK";
  SupplyReservationsActionType["EVC_MANAGE_RESERVATION_DETAILS"] = "EVC_MANAGE_RESERVATION_DETAILS";
  SupplyReservationsActionType["GUEST_INFO_EMAIL"] = "GUEST_INFO_EMAIL";
  SupplyReservationsActionType["GUEST_INFO_PHONE"] = "GUEST_INFO_PHONE";
  SupplyReservationsActionType["GUEST_INFO_VIEW_PROFILE"] = "GUEST_INFO_VIEW_PROFILE";
  SupplyReservationsActionType["HEADER_ARCHIVE"] = "HEADER_ARCHIVE";
  SupplyReservationsActionType["HEADER_DETAILS"] = "HEADER_DETAILS";
  SupplyReservationsActionType["HEADER_FEEDBACK"] = "HEADER_FEEDBACK";
  SupplyReservationsActionType["HEADER_HELP_CENTER"] = "HEADER_HELP_CENTER";
  SupplyReservationsActionType["HEADER_MARK_AS_READ"] = "HEADER_MARK_AS_READ";
  SupplyReservationsActionType["HEADER_MARK_AS_SPAM"] = "HEADER_MARK_AS_SPAM";
  SupplyReservationsActionType["HEADER_MARK_AS_UNREAD"] = "HEADER_MARK_AS_UNREAD";
  SupplyReservationsActionType["HEADER_PRINT"] = "HEADER_PRINT";
  SupplyReservationsActionType["HEADER_SHARE"] = "HEADER_SHARE";
  SupplyReservationsActionType["HEADER_UNARCHIVE"] = "HEADER_UNARCHIVE";
  SupplyReservationsActionType["INVOICE_STATUS_ACTION"] = "INVOICE_STATUS_ACTION";
  SupplyReservationsActionType["NOTES_ADD"] = "NOTES_ADD";
  SupplyReservationsActionType["NOTES_CANCEL"] = "NOTES_CANCEL";
  SupplyReservationsActionType["NOTES_EDIT"] = "NOTES_EDIT";
  SupplyReservationsActionType["NOTES_SAVE"] = "NOTES_SAVE";
  SupplyReservationsActionType["PAYMENT_REQUEST_STATUS_ACTION"] = "PAYMENT_REQUEST_STATUS_ACTION";
  SupplyReservationsActionType["PAYMENT_SCHEDULE_ADD_EXTRA_CHARGE"] = "PAYMENT_SCHEDULE_ADD_EXTRA_CHARGE";
  SupplyReservationsActionType["PAYMENT_SCHEDULE_CANCEL_PAYMENT_REQUEST"] = "PAYMENT_SCHEDULE_CANCEL_PAYMENT_REQUEST";
  SupplyReservationsActionType["PAYMENT_SCHEDULE_EDIT_PAYMENT_SCHEDULE"] = "PAYMENT_SCHEDULE_EDIT_PAYMENT_SCHEDULE";
  SupplyReservationsActionType["PAYMENT_SCHEDULE_MARK_PAID"] = "PAYMENT_SCHEDULE_MARK_PAID";
  SupplyReservationsActionType["PAYMENT_SCHEDULE_RESEND_REQUEST"] = "PAYMENT_SCHEDULE_RESEND_REQUEST";
  SupplyReservationsActionType["PAYMENT_SCHEDULE_SEND_A_REFUND"] = "PAYMENT_SCHEDULE_SEND_A_REFUND";
  SupplyReservationsActionType["PAYMENT_SUMMARY_VIEW"] = "PAYMENT_SUMMARY_VIEW";
  SupplyReservationsActionType["RENTAL_AGREEMENT_VIEW"] = "RENTAL_AGREEMENT_VIEW";
  SupplyReservationsActionType["RESERVATION_MODIFIERS_BACK"] = "RESERVATION_MODIFIERS_BACK";
  SupplyReservationsActionType["RESERVATION_MODIFIERS_CLOSE"] = "RESERVATION_MODIFIERS_CLOSE";
  SupplyReservationsActionType["RESERVATION_MODIFIERS_NEXT"] = "RESERVATION_MODIFIERS_NEXT";
  SupplyReservationsActionType["RESERVATION_MODIFIERS_SAVE_CANCEL"] = "RESERVATION_MODIFIERS_SAVE_CANCEL";
  SupplyReservationsActionType["RESERVATION_MODIFIERS_SAVE_CHANGE_DATES"] = "RESERVATION_MODIFIERS_SAVE_CHANGE_DATES";
  SupplyReservationsActionType["RESERVATION_MODIFIERS_SAVE_GUEST_SATISFACTION_REFUND"] = "RESERVATION_MODIFIERS_SAVE_GUEST_SATISFACTION_REFUND";
  SupplyReservationsActionType["RESERVATION_MODIFIERS_SAVE_NO_SHOW"] = "RESERVATION_MODIFIERS_SAVE_NO_SHOW";
  SupplyReservationsActionType["STAY_DETAILS_ACCEPT"] = "STAY_DETAILS_ACCEPT";
  SupplyReservationsActionType["STAY_DETAILS_ADD_REMOVE_GUESTS"] = "STAY_DETAILS_ADD_REMOVE_GUESTS";
  SupplyReservationsActionType["STAY_DETAILS_CANCEL_PRE_APPROVAL"] = "STAY_DETAILS_CANCEL_PRE_APPROVAL";
  SupplyReservationsActionType["STAY_DETAILS_CANCEL_RESERVATION"] = "STAY_DETAILS_CANCEL_RESERVATION";
  SupplyReservationsActionType["STAY_DETAILS_CHANGE_DATES"] = "STAY_DETAILS_CHANGE_DATES";
  SupplyReservationsActionType["STAY_DETAILS_CHANGE_TIME"] = "STAY_DETAILS_CHANGE_TIME";
  SupplyReservationsActionType["STAY_DETAILS_CONFIRM_CANCELLATION"] = "STAY_DETAILS_CONFIRM_CANCELLATION";
  SupplyReservationsActionType["STAY_DETAILS_DECLINE"] = "STAY_DETAILS_DECLINE";
  SupplyReservationsActionType["STAY_DETAILS_EDIT_DATES"] = "STAY_DETAILS_EDIT_DATES";
  SupplyReservationsActionType["STAY_DETAILS_EDIT_QUOTE"] = "STAY_DETAILS_EDIT_QUOTE";
  SupplyReservationsActionType["STAY_DETAILS_EDIT_RESERVATION"] = "STAY_DETAILS_EDIT_RESERVATION";
  SupplyReservationsActionType["STAY_DETAILS_SEND_PRE_APPROVAL"] = "STAY_DETAILS_SEND_PRE_APPROVAL";
})(SupplyReservationsActionType || (SupplyReservationsActionType = {}));
export var SupplyReservationsDeclineOption;
(function (SupplyReservationsDeclineOption) {
  SupplyReservationsDeclineOption["BLOCK_DATES"] = "BLOCK_DATES";
  SupplyReservationsDeclineOption["LEAVE_DATES"] = "LEAVE_DATES";
})(SupplyReservationsDeclineOption || (SupplyReservationsDeclineOption = {}));
export var SupplyReservationsDeclineStep;
(function (SupplyReservationsDeclineStep) {
  SupplyReservationsDeclineStep["CONFIRM"] = "CONFIRM";
  SupplyReservationsDeclineStep["REASON_AND_MESSAGE"] = "REASON_AND_MESSAGE";
})(SupplyReservationsDeclineStep || (SupplyReservationsDeclineStep = {}));
export var SupplyReservationsEvcManagerTabType;
(function (SupplyReservationsEvcManagerTabType) {
  SupplyReservationsEvcManagerTabType["EVC_LOOKUP"] = "EVC_LOOKUP";
  SupplyReservationsEvcManagerTabType["EVC_MANAGE"] = "EVC_MANAGE";
})(SupplyReservationsEvcManagerTabType || (SupplyReservationsEvcManagerTabType = {}));
export var SupplyReservationsEvcManagerTableType;
(function (SupplyReservationsEvcManagerTableType) {
  SupplyReservationsEvcManagerTableType["EVC_CARDS_TO_CHARGE"] = "EVC_CARDS_TO_CHARGE";
  SupplyReservationsEvcManagerTableType["EVC_CARDS_TO_REFUND"] = "EVC_CARDS_TO_REFUND";
})(SupplyReservationsEvcManagerTableType || (SupplyReservationsEvcManagerTableType = {}));
export var SupplyReservationsEvcSearchInputType;
(function (SupplyReservationsEvcSearchInputType) {
  SupplyReservationsEvcSearchInputType["EVC_NUMBER"] = "EVC_NUMBER";
  SupplyReservationsEvcSearchInputType["RESERVATION_ID"] = "RESERVATION_ID";
})(SupplyReservationsEvcSearchInputType || (SupplyReservationsEvcSearchInputType = {}));
export var SupplyReservationsInteractionBannerTheme;
(function (SupplyReservationsInteractionBannerTheme) {
  SupplyReservationsInteractionBannerTheme["CRITICAL"] = "CRITICAL";
  SupplyReservationsInteractionBannerTheme["ERROR"] = "ERROR";
})(SupplyReservationsInteractionBannerTheme || (SupplyReservationsInteractionBannerTheme = {}));
export var SupplyReservationsInteractionChatImageSize;
(function (SupplyReservationsInteractionChatImageSize) {
  SupplyReservationsInteractionChatImageSize["LARGE"] = "LARGE";
  SupplyReservationsInteractionChatImageSize["SMALL"] = "SMALL";
})(SupplyReservationsInteractionChatImageSize || (SupplyReservationsInteractionChatImageSize = {}));
export var SupplyReservationsInteractionListActionDialogFooterActionType;
(function (SupplyReservationsInteractionListActionDialogFooterActionType) {
  SupplyReservationsInteractionListActionDialogFooterActionType["PRIMARY"] = "PRIMARY";
  SupplyReservationsInteractionListActionDialogFooterActionType["TERTIARY"] = "TERTIARY";
})(SupplyReservationsInteractionListActionDialogFooterActionType || (SupplyReservationsInteractionListActionDialogFooterActionType = {}));
export var SupplyReservationsInteractionListColumnHorizontalAlign;
(function (SupplyReservationsInteractionListColumnHorizontalAlign) {
  SupplyReservationsInteractionListColumnHorizontalAlign["CENTER"] = "CENTER";
  SupplyReservationsInteractionListColumnHorizontalAlign["END"] = "END";
  SupplyReservationsInteractionListColumnHorizontalAlign["JUSTIFY"] = "JUSTIFY";
  SupplyReservationsInteractionListColumnHorizontalAlign["JUSTIFY_ALL"] = "JUSTIFY_ALL";
  SupplyReservationsInteractionListColumnHorizontalAlign["LEFT"] = "LEFT";
  SupplyReservationsInteractionListColumnHorizontalAlign["MATCH_PARENT"] = "MATCH_PARENT";
  SupplyReservationsInteractionListColumnHorizontalAlign["RIGHT"] = "RIGHT";
  SupplyReservationsInteractionListColumnHorizontalAlign["START"] = "START";
})(SupplyReservationsInteractionListColumnHorizontalAlign || (SupplyReservationsInteractionListColumnHorizontalAlign = {}));
export var SupplyReservationsInteractionListColumnVerticalAlign;
(function (SupplyReservationsInteractionListColumnVerticalAlign) {
  SupplyReservationsInteractionListColumnVerticalAlign["BASELINE"] = "BASELINE";
  SupplyReservationsInteractionListColumnVerticalAlign["BOTTOM"] = "BOTTOM";
  SupplyReservationsInteractionListColumnVerticalAlign["MIDDLE"] = "MIDDLE";
  SupplyReservationsInteractionListColumnVerticalAlign["SUB"] = "SUB";
  SupplyReservationsInteractionListColumnVerticalAlign["SUPER"] = "SUPER";
  SupplyReservationsInteractionListColumnVerticalAlign["TEXT_BOTTOM"] = "TEXT_BOTTOM";
  SupplyReservationsInteractionListColumnVerticalAlign["TEXT_TOP"] = "TEXT_TOP";
  SupplyReservationsInteractionListColumnVerticalAlign["TOP"] = "TOP";
})(SupplyReservationsInteractionListColumnVerticalAlign || (SupplyReservationsInteractionListColumnVerticalAlign = {}));
export var SupplyReservationsInteractionListItemAction;
(function (SupplyReservationsInteractionListItemAction) {
  SupplyReservationsInteractionListItemAction["ARCHIVE"] = "ARCHIVE";
  SupplyReservationsInteractionListItemAction["READ"] = "READ";
  SupplyReservationsInteractionListItemAction["SPAM"] = "SPAM";
  SupplyReservationsInteractionListItemAction["UNARCHIVE"] = "UNARCHIVE";
  SupplyReservationsInteractionListItemAction["UNREAD"] = "UNREAD";
})(SupplyReservationsInteractionListItemAction || (SupplyReservationsInteractionListItemAction = {}));
export var SupplyReservationsInteractionListSwipeActionEdge;
(function (SupplyReservationsInteractionListSwipeActionEdge) {
  SupplyReservationsInteractionListSwipeActionEdge["LEADING"] = "LEADING";
  SupplyReservationsInteractionListSwipeActionEdge["TRAILING"] = "TRAILING";
})(SupplyReservationsInteractionListSwipeActionEdge || (SupplyReservationsInteractionListSwipeActionEdge = {}));
export var SupplyReservationsInteractionMessageMode;
(function (SupplyReservationsInteractionMessageMode) {
  SupplyReservationsInteractionMessageMode["INCOMING"] = "INCOMING";
  SupplyReservationsInteractionMessageMode["OUTGOING"] = "OUTGOING";
})(SupplyReservationsInteractionMessageMode || (SupplyReservationsInteractionMessageMode = {}));
export var SupplyReservationsInteractionMessagesDetailsLinkType;
(function (SupplyReservationsInteractionMessagesDetailsLinkType) {
  SupplyReservationsInteractionMessagesDetailsLinkType["CHARGEBACKS"] = "CHARGEBACKS";
  SupplyReservationsInteractionMessagesDetailsLinkType["SEE_DAMAGE_REPORT"] = "SEE_DAMAGE_REPORT";
  SupplyReservationsInteractionMessagesDetailsLinkType["VIEW_CSA_CLAIM"] = "VIEW_CSA_CLAIM";
})(SupplyReservationsInteractionMessagesDetailsLinkType || (SupplyReservationsInteractionMessagesDetailsLinkType = {}));
export var SupplyReservationsInteractionMessagesDialogFooterActionType;
(function (SupplyReservationsInteractionMessagesDialogFooterActionType) {
  SupplyReservationsInteractionMessagesDialogFooterActionType["PRIMARY"] = "PRIMARY";
  SupplyReservationsInteractionMessagesDialogFooterActionType["TERTIARY"] = "TERTIARY";
})(SupplyReservationsInteractionMessagesDialogFooterActionType || (SupplyReservationsInteractionMessagesDialogFooterActionType = {}));
export var SupplyReservationsInteractionViewType;
(function (SupplyReservationsInteractionViewType) {
  SupplyReservationsInteractionViewType["CAROUSEL_VIEW"] = "CAROUSEL_VIEW";
  SupplyReservationsInteractionViewType["LIST_VIEW"] = "LIST_VIEW";
})(SupplyReservationsInteractionViewType || (SupplyReservationsInteractionViewType = {}));
export var SupplyReservationsInteractionsSort;
(function (SupplyReservationsInteractionsSort) {
  SupplyReservationsInteractionsSort["CHECKIN_DATE_ASC"] = "CHECKIN_DATE_ASC";
  SupplyReservationsInteractionsSort["CHECKIN_DATE_DESC"] = "CHECKIN_DATE_DESC";
  SupplyReservationsInteractionsSort["GUEST_ASC"] = "GUEST_ASC";
  SupplyReservationsInteractionsSort["GUEST_DESC"] = "GUEST_DESC";
  SupplyReservationsInteractionsSort["RECEIVED_ON_ASC"] = "RECEIVED_ON_ASC";
  SupplyReservationsInteractionsSort["RECEIVED_ON_DESC"] = "RECEIVED_ON_DESC";
})(SupplyReservationsInteractionsSort || (SupplyReservationsInteractionsSort = {}));
export var SupplyReservationsPayment;
(function (SupplyReservationsPayment) {
  SupplyReservationsPayment["OFFLINE"] = "OFFLINE";
  SupplyReservationsPayment["ONLINE"] = "ONLINE";
})(SupplyReservationsPayment || (SupplyReservationsPayment = {}));
export var SupplyReservationsPaymentDetailsExpandoPositioning;
(function (SupplyReservationsPaymentDetailsExpandoPositioning) {
  SupplyReservationsPaymentDetailsExpandoPositioning["LEADING"] = "LEADING";
  SupplyReservationsPaymentDetailsExpandoPositioning["TRAILING"] = "TRAILING";
})(SupplyReservationsPaymentDetailsExpandoPositioning || (SupplyReservationsPaymentDetailsExpandoPositioning = {}));
export var SupplyReservationsPaymentDisplayToolbarIcon;
(function (SupplyReservationsPaymentDisplayToolbarIcon) {
  SupplyReservationsPaymentDisplayToolbarIcon["BACK"] = "BACK";
  SupplyReservationsPaymentDisplayToolbarIcon["CLOSE"] = "CLOSE";
})(SupplyReservationsPaymentDisplayToolbarIcon || (SupplyReservationsPaymentDisplayToolbarIcon = {}));
export var SupplyReservationsRenderHint;
(function (SupplyReservationsRenderHint) {
  SupplyReservationsRenderHint["COLLAPSED"] = "COLLAPSED";
  SupplyReservationsRenderHint["LIST"] = "LIST";
})(SupplyReservationsRenderHint || (SupplyReservationsRenderHint = {}));
export var SupplyReservationsStayDetailsMessageTimerType;
(function (SupplyReservationsStayDetailsMessageTimerType) {
  SupplyReservationsStayDetailsMessageTimerType["COUNT_DOWN"] = "COUNT_DOWN";
  SupplyReservationsStayDetailsMessageTimerType["COUNT_UP"] = "COUNT_UP";
})(SupplyReservationsStayDetailsMessageTimerType || (SupplyReservationsStayDetailsMessageTimerType = {}));
export var SupplyReservationsTimerBadgeType;
(function (SupplyReservationsTimerBadgeType) {
  SupplyReservationsTimerBadgeType["COUNT_DOWN"] = "COUNT_DOWN";
  SupplyReservationsTimerBadgeType["COUNT_UP"] = "COUNT_UP";
})(SupplyReservationsTimerBadgeType || (SupplyReservationsTimerBadgeType = {}));
export var SupplyReviewsActionId;
(function (SupplyReviewsActionId) {
  SupplyReviewsActionId["REVIEWS_DOWNLOAD_SHEET"] = "REVIEWS_DOWNLOAD_SHEET";
  SupplyReviewsActionId["REVIEWS_FILTERS_SHEET"] = "REVIEWS_FILTERS_SHEET";
  SupplyReviewsActionId["REVIEWS_RESPOND_SHEET"] = "REVIEWS_RESPOND_SHEET";
  SupplyReviewsActionId["REVIEWS_SHARE_SHEET"] = "REVIEWS_SHARE_SHEET";
})(SupplyReviewsActionId || (SupplyReviewsActionId = {}));
export var SupplyRoomTypeMappingSpacingSize;
(function (SupplyRoomTypeMappingSpacingSize) {
  SupplyRoomTypeMappingSpacingSize["SPACING__0X__HALF"] = "SPACING__0X__HALF";
  SupplyRoomTypeMappingSpacingSize["SPACING__1X"] = "SPACING__1X";
  SupplyRoomTypeMappingSpacingSize["SPACING__2X"] = "SPACING__2X";
  SupplyRoomTypeMappingSpacingSize["SPACING__3X"] = "SPACING__3X";
  SupplyRoomTypeMappingSpacingSize["SPACING__4X"] = "SPACING__4X";
  SupplyRoomTypeMappingSpacingSize["SPACING__5X"] = "SPACING__5X";
  SupplyRoomTypeMappingSpacingSize["SPACING__6X"] = "SPACING__6X";
  SupplyRoomTypeMappingSpacingSize["SPACING__8X"] = "SPACING__8X";
})(SupplyRoomTypeMappingSpacingSize || (SupplyRoomTypeMappingSpacingSize = {}));
export var SupplyRoomTypeMappingUserAction;
(function (SupplyRoomTypeMappingUserAction) {
  SupplyRoomTypeMappingUserAction["CONFIRM"] = "CONFIRM";
  SupplyRoomTypeMappingUserAction["REJECT"] = "REJECT";
})(SupplyRoomTypeMappingUserAction || (SupplyRoomTypeMappingUserAction = {}));
export var SupplyRoomTypeMappingViewPhase;
(function (SupplyRoomTypeMappingViewPhase) {
  SupplyRoomTypeMappingViewPhase["VIEW_PROPERTY_MAPPING"] = "VIEW_PROPERTY_MAPPING";
  SupplyRoomTypeMappingViewPhase["VIEW_PROPERTY_MAPPING_INPUT"] = "VIEW_PROPERTY_MAPPING_INPUT";
  SupplyRoomTypeMappingViewPhase["VIEW_ROOM_TYPE_MAPPING"] = "VIEW_ROOM_TYPE_MAPPING";
})(SupplyRoomTypeMappingViewPhase || (SupplyRoomTypeMappingViewPhase = {}));
export var SupplyRoomTypeMappingViewType;
(function (SupplyRoomTypeMappingViewType) {
  SupplyRoomTypeMappingViewType["DIALOG"] = "DIALOG";
})(SupplyRoomTypeMappingViewType || (SupplyRoomTypeMappingViewType = {}));
export var SupplyScoreBenefitsLadderSpacingSize;
(function (SupplyScoreBenefitsLadderSpacingSize) {
  SupplyScoreBenefitsLadderSpacingSize["SPACING__0X__HALF"] = "SPACING__0X__HALF";
  SupplyScoreBenefitsLadderSpacingSize["SPACING__1X"] = "SPACING__1X";
  SupplyScoreBenefitsLadderSpacingSize["SPACING__2X"] = "SPACING__2X";
  SupplyScoreBenefitsLadderSpacingSize["SPACING__3X"] = "SPACING__3X";
  SupplyScoreBenefitsLadderSpacingSize["SPACING__4X"] = "SPACING__4X";
  SupplyScoreBenefitsLadderSpacingSize["SPACING__5X"] = "SPACING__5X";
  SupplyScoreBenefitsLadderSpacingSize["SPACING__6X"] = "SPACING__6X";
  SupplyScoreBenefitsLadderSpacingSize["SPACING__8X"] = "SPACING__8X";
})(SupplyScoreBenefitsLadderSpacingSize || (SupplyScoreBenefitsLadderSpacingSize = {}));
export var SupplyScoreFactorBmlAuditItemObervationType;
(function (SupplyScoreFactorBmlAuditItemObervationType) {
  SupplyScoreFactorBmlAuditItemObervationType["AVAILABILITY"] = "AVAILABILITY";
  SupplyScoreFactorBmlAuditItemObervationType["RATE"] = "RATE";
})(SupplyScoreFactorBmlAuditItemObervationType || (SupplyScoreFactorBmlAuditItemObervationType = {}));
export var SupplyScoreFactorNotificationType;
(function (SupplyScoreFactorNotificationType) {
  SupplyScoreFactorNotificationType["DOING_GREAT"] = "DOING_GREAT";
  SupplyScoreFactorNotificationType["NEEDS_ATTENTION"] = "NEEDS_ATTENTION";
  SupplyScoreFactorNotificationType["NEXT_BEST_FACTOR"] = "NEXT_BEST_FACTOR";
})(SupplyScoreFactorNotificationType || (SupplyScoreFactorNotificationType = {}));
export var SupplyScoreMessagingTheme;
(function (SupplyScoreMessagingTheme) {
  SupplyScoreMessagingTheme["INFO"] = "INFO";
  SupplyScoreMessagingTheme["MESSAGE"] = "MESSAGE";
  SupplyScoreMessagingTheme["WARNING"] = "WARNING";
})(SupplyScoreMessagingTheme || (SupplyScoreMessagingTheme = {}));
export var SupplyTaxesType;
(function (SupplyTaxesType) {
  SupplyTaxesType["AMOUNT"] = "AMOUNT";
  SupplyTaxesType["NOT_INCLUDED"] = "NOT_INCLUDED";
  SupplyTaxesType["PERCENT"] = "PERCENT";
})(SupplyTaxesType || (SupplyTaxesType = {}));
export var TabStatus;
(function (TabStatus) {
  TabStatus["NOT_SELECTED"] = "NOT_SELECTED";
  TabStatus["SELECTED"] = "SELECTED";
})(TabStatus || (TabStatus = {}));
export var TabType;
(function (TabType) {
  TabType["EQUAL_WIDTH"] = "EQUAL_WIDTH";
  TabType["NATURAL"] = "NATURAL";
})(TabType || (TabType = {}));
export var TextDirection;
(function (TextDirection) {
  TextDirection["LTR"] = "LTR";
  TextDirection["RTL"] = "RTL";
})(TextDirection || (TextDirection = {}));
export var TextTheme;
(function (TextTheme) {
  TextTheme["EMPHASIS"] = "EMPHASIS";
  TextTheme["INVERSE"] = "INVERSE";
  TextTheme["LOYALTY"] = "LOYALTY";
  TextTheme["NEGATIVE"] = "NEGATIVE";
  TextTheme["POSITIVE"] = "POSITIVE";
  TextTheme["PRIMARY"] = "PRIMARY";
  TextTheme["SECONDARY"] = "SECONDARY";
})(TextTheme || (TextTheme = {}));
export var TextWeight;
(function (TextWeight) {
  TextWeight["BOLD"] = "BOLD";
  TextWeight["MEDIUM"] = "MEDIUM";
  TextWeight["REGULAR"] = "REGULAR";
})(TextWeight || (TextWeight = {}));
export var Theme;
(function (Theme) {
  Theme["BRAND"] = "BRAND";
  Theme["BRANDED_DEAL"] = "BRANDED_DEAL";
  Theme["DEAL_ADD_ON"] = "DEAL_ADD_ON";
  Theme["DEAL_BUNDLED"] = "DEAL_BUNDLED";
  Theme["DEAL_GENERIC"] = "DEAL_GENERIC";
  Theme["DEAL_MEMBER"] = "DEAL_MEMBER";
  Theme["FAMILY_FRIENDLY"] = "FAMILY_FRIENDLY";
  Theme["INFO"] = "INFO";
  Theme["LINK"] = "LINK";
  Theme["LOYALTY"] = "LOYALTY";
  Theme["LOYALTY_EXTRA_HIGH_TIER"] = "LOYALTY_EXTRA_HIGH_TIER";
  Theme["LOYALTY_GLOBAL_EXTRA_HIGH_TIER"] = "LOYALTY_GLOBAL_EXTRA_HIGH_TIER";
  Theme["LOYALTY_GLOBAL_HIGH_TIER"] = "LOYALTY_GLOBAL_HIGH_TIER";
  Theme["LOYALTY_GLOBAL_LOW_TIER"] = "LOYALTY_GLOBAL_LOW_TIER";
  Theme["LOYALTY_GLOBAL_MIDDLE_TIER"] = "LOYALTY_GLOBAL_MIDDLE_TIER";
  Theme["LOYALTY_HIGH_TIER"] = "LOYALTY_HIGH_TIER";
  Theme["LOYALTY_LOW_TIER"] = "LOYALTY_LOW_TIER";
  Theme["LOYALTY_MIDDLE_TIER"] = "LOYALTY_MIDDLE_TIER";
  Theme["NOTIFICATION"] = "NOTIFICATION";
  Theme["PROMOTED"] = "PROMOTED";
  Theme["SAVED"] = "SAVED";
  Theme["SPONSORED"] = "SPONSORED";
  Theme["STANDARD"] = "STANDARD";
  Theme["SUCCESS"] = "SUCCESS";
  Theme["SUCCESS_MEDIUM"] = "SUCCESS_MEDIUM";
  Theme["SUPPLIER_PROMO"] = "SUPPLIER_PROMO";
  Theme["URGENT"] = "URGENT";
  Theme["VIEWED"] = "VIEWED";
  Theme["VIP"] = "VIP";
  Theme["VIP_ACCESS"] = "VIP_ACCESS";
})(Theme || (Theme = {}));
export var ToastDismissDuration;
(function (ToastDismissDuration) {
  ToastDismissDuration["INDEFINITE"] = "INDEFINITE";
  ToastDismissDuration["LONG"] = "LONG";
  ToastDismissDuration["SHORT"] = "SHORT";
})(ToastDismissDuration || (ToastDismissDuration = {}));
export var ToolbarNavType;
(function (ToolbarNavType) {
  ToolbarNavType["CLOSE"] = "CLOSE";
  ToolbarNavType["EXPAND_DOWN"] = "EXPAND_DOWN";
  ToolbarNavType["EXPAND_UP"] = "EXPAND_UP";
})(ToolbarNavType || (ToolbarNavType = {}));
export var TopTaskTypes;
(function (TopTaskTypes) {
  TopTaskTypes["ACCELERATOR_OPPORTUNITY"] = "ACCELERATOR_OPPORTUNITY";
  TopTaskTypes["ADD_AMENITIES"] = "ADD_AMENITIES";
  TopTaskTypes["ADD_MINIMUM_STAY"] = "ADD_MINIMUM_STAY";
  TopTaskTypes["ADD_PHOTOS"] = "ADD_PHOTOS";
  TopTaskTypes["ADD_SAFETY_DEVICES_AMENITIES"] = "ADD_SAFETY_DEVICES_AMENITIES";
  TopTaskTypes["ARRIVING"] = "ARRIVING";
  TopTaskTypes["AUTORATEMATCH_OPPORTUNITY"] = "AUTORATEMATCH_OPPORTUNITY";
  TopTaskTypes["BANK_DETAILS"] = "BANK_DETAILS";
  TopTaskTypes["BOOKING_REQUEST"] = "BOOKING_REQUEST";
  TopTaskTypes["BOOKING_SMS_OPT_IN"] = "BOOKING_SMS_OPT_IN";
  TopTaskTypes["CANCELLATION"] = "CANCELLATION";
  TopTaskTypes["CANCELLATION_REQUEST"] = "CANCELLATION_REQUEST";
  TopTaskTypes["CONFIRM_CONTACT"] = "CONFIRM_CONTACT";
  TopTaskTypes["CONFIRM_IDENTITY"] = "CONFIRM_IDENTITY";
  TopTaskTypes["CURRENT_STAY"] = "CURRENT_STAY";
  TopTaskTypes["DIRECT_DEBIT"] = "DIRECT_DEBIT";
  TopTaskTypes["INQUIRY"] = "INQUIRY";
  TopTaskTypes["LOWER_FEES"] = "LOWER_FEES";
  TopTaskTypes["MINIMUMINVENTORY_OPPORTUNITY"] = "MINIMUMINVENTORY_OPPORTUNITY";
  TopTaskTypes["MOBILEDRR_OPPORTUNITY"] = "MOBILEDRR_OPPORTUNITY";
  TopTaskTypes["OPPORTUNITY"] = "OPPORTUNITY";
  TopTaskTypes["RATE_TRAVELER"] = "RATE_TRAVELER";
  TopTaskTypes["TAX_FORM"] = "TAX_FORM";
  TopTaskTypes["TMODDRR_OPPORTUNITY"] = "TMODDRR_OPPORTUNITY";
  TopTaskTypes["UNKNOWN"] = "UNKNOWN";
  TopTaskTypes["UNKNOWN__"] = "UNKNOWN__";
  TopTaskTypes["UPDATE_TAX_INFORMATION"] = "UPDATE_TAX_INFORMATION";
  TopTaskTypes["VRPAYMENTS_TIP"] = "VRPAYMENTS_TIP";
})(TopTaskTypes || (TopTaskTypes = {}));
export var TravelShopType;
(function (TravelShopType) {
  TravelShopType["COLLECTIONS"] = "COLLECTIONS";
  TravelShopType["SHOPS"] = "SHOPS";
})(TravelShopType || (TravelShopType = {}));
export var TravelerDetailsType;
(function (TravelerDetailsType) {
  TravelerDetailsType["ADULT"] = "ADULT";
  TravelerDetailsType["CHILD"] = "CHILD";
  TravelerDetailsType["INFANT_IN_LAP"] = "INFANT_IN_LAP";
  TravelerDetailsType["INFANT_IN_SEAT"] = "INFANT_IN_SEAT";
})(TravelerDetailsType || (TravelerDetailsType = {}));
export var TravelerType;
(function (TravelerType) {
  TravelerType["ADULTS_ONLY"] = "ADULTS_ONLY";
  TravelerType["BUSINESS"] = "BUSINESS";
  TravelerType["ECO_CERTIFIED"] = "ECO_CERTIFIED";
  TravelerType["FAMILY"] = "FAMILY";
  TravelerType["LGBT"] = "LGBT";
  TravelerType["ROMANTIC"] = "ROMANTIC";
})(TravelerType || (TravelerType = {}));
export var TripAssistGoalType;
(function (TripAssistGoalType) {
  TripAssistGoalType["BOOK"] = "BOOK";
  TripAssistGoalType["PLAN"] = "PLAN";
})(TripAssistGoalType || (TripAssistGoalType = {}));
export var TripCareButtonAction;
(function (TripCareButtonAction) {
  TripCareButtonAction["BACK"] = "BACK";
  TripCareButtonAction["CANCEL_POLICY"] = "CANCEL_POLICY";
  TripCareButtonAction["DISMISS_POPUP"] = "DISMISS_POPUP";
  TripCareButtonAction["POPUP"] = "POPUP";
  TripCareButtonAction["RELOAD"] = "RELOAD";
})(TripCareButtonAction || (TripCareButtonAction = {}));
export var TripCareButtonState;
(function (TripCareButtonState) {
  TripCareButtonState["DISABLED"] = "DISABLED";
  TripCareButtonState["ENABLED"] = "ENABLED";
})(TripCareButtonState || (TripCareButtonState = {}));
export var TripCareLayoutStyle;
(function (TripCareLayoutStyle) {
  TripCareLayoutStyle["CARD_BORDER_TOP"] = "CARD_BORDER_TOP";
})(TripCareLayoutStyle || (TripCareLayoutStyle = {}));
export var TripCareListType;
(function (TripCareListType) {
  TripCareListType["BULLET"] = "BULLET";
  TripCareListType["NO_BULLET"] = "NO_BULLET";
  TripCareListType["NUMBERED"] = "NUMBERED";
})(TripCareListType || (TripCareListType = {}));
export var TripCarePhraseStyle;
(function (TripCarePhraseStyle) {
  TripCarePhraseStyle["BOLD"] = "BOLD";
  TripCarePhraseStyle["HR_DIVIDER_BOTTOM"] = "HR_DIVIDER_BOTTOM";
  TripCarePhraseStyle["HR_DIVIDER_TOP"] = "HR_DIVIDER_TOP";
  TripCarePhraseStyle["SIZE_100"] = "SIZE_100";
  TripCarePhraseStyle["SIZE_200"] = "SIZE_200";
  TripCarePhraseStyle["SIZE_300"] = "SIZE_300";
  TripCarePhraseStyle["SIZE_400"] = "SIZE_400";
  TripCarePhraseStyle["SIZE_500"] = "SIZE_500";
  TripCarePhraseStyle["SIZE_600"] = "SIZE_600";
  TripCarePhraseStyle["SIZE_700"] = "SIZE_700";
  TripCarePhraseStyle["SIZE_800"] = "SIZE_800";
  TripCarePhraseStyle["SIZE_900"] = "SIZE_900";
  TripCarePhraseStyle["SMALL"] = "SMALL";
  TripCarePhraseStyle["SPACING_FOUR"] = "SPACING_FOUR";
  TripCarePhraseStyle["SPACING_HALF"] = "SPACING_HALF";
  TripCarePhraseStyle["SPACING_ONE"] = "SPACING_ONE";
  TripCarePhraseStyle["SPACING_SIX"] = "SPACING_SIX";
  TripCarePhraseStyle["SPACING_THREE"] = "SPACING_THREE";
  TripCarePhraseStyle["SPACING_TWO"] = "SPACING_TWO";
  TripCarePhraseStyle["THEME_EMPHASIS"] = "THEME_EMPHASIS";
  TripCarePhraseStyle["THEME_NEGATIVE"] = "THEME_NEGATIVE";
  TripCarePhraseStyle["THEME_POSITIVE"] = "THEME_POSITIVE";
  TripCarePhraseStyle["WEIGHT_BOLD"] = "WEIGHT_BOLD";
  TripCarePhraseStyle["WEIGHT_MEDIUM"] = "WEIGHT_MEDIUM";
  TripCarePhraseStyle["WEIGHT_REGULAR"] = "WEIGHT_REGULAR";
})(TripCarePhraseStyle || (TripCarePhraseStyle = {}));
export var TripCarePhraseType;
(function (TripCarePhraseType) {
  TripCarePhraseType["BUTTON"] = "BUTTON";
  TripCarePhraseType["LINK"] = "LINK";
  TripCarePhraseType["TEXT"] = "TEXT";
})(TripCarePhraseType || (TripCarePhraseType = {}));
export var TripCreationEntryPoint;
(function (TripCreationEntryPoint) {
  TripCreationEntryPoint["HOTEL_SEARCH_RESULTS"] = "HOTEL_SEARCH_RESULTS";
  TripCreationEntryPoint["TRIPS_LIST"] = "TRIPS_LIST";
  TripCreationEntryPoint["TRIP_OVERVIEW"] = "TRIP_OVERVIEW";
})(TripCreationEntryPoint || (TripCreationEntryPoint = {}));
export var TripEntity;
(function (TripEntity) {
  TripEntity["ACTIVITY_PRODUCT"] = "ACTIVITY_PRODUCT";
  TripEntity["CAR_PRODUCT"] = "CAR_PRODUCT";
  TripEntity["FLIGHT_SEARCH"] = "FLIGHT_SEARCH";
  TripEntity["LODGING_PRODUCT"] = "LODGING_PRODUCT";
  TripEntity["PACKAGE_PRODUCT"] = "PACKAGE_PRODUCT";
})(TripEntity || (TripEntity = {}));
export var TripIntentPersonType;
(function (TripIntentPersonType) {
  TripIntentPersonType["ADULT"] = "ADULT";
  TripIntentPersonType["CHILD"] = "CHILD";
})(TripIntentPersonType || (TripIntentPersonType = {}));
export var TripScheduleChangeStatus;
(function (TripScheduleChangeStatus) {
  TripScheduleChangeStatus["FLIGHT_SCHEDULE_CHANGE"] = "FLIGHT_SCHEDULE_CHANGE";
  TripScheduleChangeStatus["NO_CHANGE"] = "NO_CHANGE";
})(TripScheduleChangeStatus || (TripScheduleChangeStatus = {}));
export var TripStage;
(function (TripStage) {
  TripStage["MID_TRAVEL"] = "MID_TRAVEL";
  TripStage["POST_TRAVEL"] = "POST_TRAVEL";
  TripStage["PRE_TRAVEL"] = "PRE_TRAVEL";
})(TripStage || (TripStage = {}));
export var TripState;
(function (TripState) {
  TripState["CURRENT"] = "CURRENT";
  TripState["PAST"] = "PAST";
  TripState["POTENTIAL"] = "POTENTIAL";
  TripState["UPCOMING"] = "UPCOMING";
})(TripState || (TripState = {}));
export var TripsAvatarSize;
(function (TripsAvatarSize) {
  TripsAvatarSize["EXTRA_LARGE"] = "EXTRA_LARGE";
  TripsAvatarSize["LARGE"] = "LARGE";
  TripsAvatarSize["MEDIUM"] = "MEDIUM";
  TripsAvatarSize["SMALL"] = "SMALL";
})(TripsAvatarSize || (TripsAvatarSize = {}));
export var TripsBadgeTheme;
(function (TripsBadgeTheme) {
  TripsBadgeTheme["LOYALTY_HIGH_TIER"] = "LOYALTY_HIGH_TIER";
  TripsBadgeTheme["LOYALTY_LOW_TIER"] = "LOYALTY_LOW_TIER";
  TripsBadgeTheme["LOYALTY_MIDDLE_TIER"] = "LOYALTY_MIDDLE_TIER";
  TripsBadgeTheme["SAVED"] = "SAVED";
  TripsBadgeTheme["STATUS_NEGATIVE"] = "STATUS_NEGATIVE";
  TripsBadgeTheme["STATUS_POSITIVE"] = "STATUS_POSITIVE";
  TripsBadgeTheme["UTILITY_STANDARD"] = "UTILITY_STANDARD";
  TripsBadgeTheme["VIP"] = "VIP";
})(TripsBadgeTheme || (TripsBadgeTheme = {}));
export var TripsButtonWidth;
(function (TripsButtonWidth) {
  TripsButtonWidth["MATCH_PARENT_CONTAINER"] = "MATCH_PARENT_CONTAINER";
  TripsButtonWidth["WRAP_CONTENT"] = "WRAP_CONTENT";
})(TripsButtonWidth || (TripsButtonWidth = {}));
export var TripsCalendarSelectionType;
(function (TripsCalendarSelectionType) {
  TripsCalendarSelectionType["SINGLE"] = "SINGLE";
  TripsCalendarSelectionType["START_AND_END"] = "START_AND_END";
  TripsCalendarSelectionType["START_AND_END_WITH_SAME_DATE"] = "START_AND_END_WITH_SAME_DATE";
})(TripsCalendarSelectionType || (TripsCalendarSelectionType = {}));
export var TripsCancellationTimelineIdType;
(function (TripsCancellationTimelineIdType) {
  TripsCancellationTimelineIdType["TRIP_ITEM_ID"] = "TRIP_ITEM_ID";
})(TripsCancellationTimelineIdType || (TripsCancellationTimelineIdType = {}));
export var TripsCancellationType;
(function (TripsCancellationType) {
  TripsCancellationType["ACTIVITY"] = "ACTIVITY";
  TripsCancellationType["CAR"] = "CAR";
  TripsCancellationType["CRUISE"] = "CRUISE";
  TripsCancellationType["HOTEL"] = "HOTEL";
  TripsCancellationType["INSURANCE"] = "INSURANCE";
})(TripsCancellationType || (TripsCancellationType = {}));
export var TripsCardState;
(function (TripsCardState) {
  TripsCardState["SELECTED"] = "SELECTED";
  TripsCardState["UNSELECTED"] = "UNSELECTED";
})(TripsCardState || (TripsCardState = {}));
export var TripsComposableDrawerType;
(function (TripsComposableDrawerType) {
  TripsComposableDrawerType["BOTTOM"] = "BOTTOM";
  TripsComposableDrawerType["CENTER"] = "CENTER";
  TripsComposableDrawerType["FULL"] = "FULL";
  TripsComposableDrawerType["SIDE"] = "SIDE";
})(TripsComposableDrawerType || (TripsComposableDrawerType = {}));
export var TripsContentTheme;
(function (TripsContentTheme) {
  TripsContentTheme["BULLETS"] = "BULLETS";
  TripsContentTheme["NO_BULLETS"] = "NO_BULLETS";
  TripsContentTheme["ORDERED"] = "ORDERED";
  TripsContentTheme["PARAGRAPH"] = "PARAGRAPH";
})(TripsContentTheme || (TripsContentTheme = {}));
export var TripsContentType;
(function (TripsContentType) {
  TripsContentType["PDF"] = "PDF";
})(TripsContentType || (TripsContentType = {}));
export var TripsContentUnorderedListTheme;
(function (TripsContentUnorderedListTheme) {
  TripsContentUnorderedListTheme["BULLETS"] = "BULLETS";
  TripsContentUnorderedListTheme["NO_BULLETS"] = "NO_BULLETS";
})(TripsContentUnorderedListTheme || (TripsContentUnorderedListTheme = {}));
export var TripsCustomerNotificationTripScheduleChangeStatus;
(function (TripsCustomerNotificationTripScheduleChangeStatus) {
  TripsCustomerNotificationTripScheduleChangeStatus["FLIGHT_SCHEDULE_CHANGE"] = "FLIGHT_SCHEDULE_CHANGE";
  TripsCustomerNotificationTripScheduleChangeStatus["NO_CHANGE"] = "NO_CHANGE";
})(TripsCustomerNotificationTripScheduleChangeStatus || (TripsCustomerNotificationTripScheduleChangeStatus = {}));
export var TripsDialogButtonLayout;
(function (TripsDialogButtonLayout) {
  TripsDialogButtonLayout["INLINE"] = "INLINE";
  TripsDialogButtonLayout["STACKED"] = "STACKED";
})(TripsDialogButtonLayout || (TripsDialogButtonLayout = {}));
export var TripsDisplayMode;
(function (TripsDisplayMode) {
  TripsDisplayMode["DEFAULT"] = "DEFAULT";
  TripsDisplayMode["MODAL"] = "MODAL";
  TripsDisplayMode["NEW_TAB"] = "NEW_TAB";
})(TripsDisplayMode || (TripsDisplayMode = {}));
export var TripsDivider;
(function (TripsDivider) {
  TripsDivider["NARROW"] = "NARROW";
  TripsDivider["WIDE"] = "WIDE";
})(TripsDivider || (TripsDivider = {}));
export var TripsFindItineraryNumberView;
(function (TripsFindItineraryNumberView) {
  TripsFindItineraryNumberView["FIND_ITINERARY_OTHER_EMAIL"] = "FIND_ITINERARY_OTHER_EMAIL";
  TripsFindItineraryNumberView["FIND_ITINERARY_USER_EMAIL"] = "FIND_ITINERARY_USER_EMAIL";
  TripsFindItineraryNumberView["RESULT"] = "RESULT";
})(TripsFindItineraryNumberView || (TripsFindItineraryNumberView = {}));
export var TripsFittedImageType;
(function (TripsFittedImageType) {
  TripsFittedImageType["BARCODE"] = "BARCODE";
  TripsFittedImageType["E_FRANKING_STAMP"] = "E_FRANKING_STAMP";
  TripsFittedImageType["QR_CODE"] = "QR_CODE";
})(TripsFittedImageType || (TripsFittedImageType = {}));
export var TripsFormContainerTheme;
(function (TripsFormContainerTheme) {
  TripsFormContainerTheme["MATCH_PARENT"] = "MATCH_PARENT";
  TripsFormContainerTheme["WRAP_CONTENT"] = "WRAP_CONTENT";
})(TripsFormContainerTheme || (TripsFormContainerTheme = {}));
export var TripsFormType;
(function (TripsFormType) {
  TripsFormType["CREATE_NEW_TRIP_FROM_ITEM"] = "CREATE_NEW_TRIP_FROM_ITEM";
  TripsFormType["FIND_ITINERARY_NUMBER"] = "FIND_ITINERARY_NUMBER";
  TripsFormType["INVITE"] = "INVITE";
  TripsFormType["SAVE_NEW_TRIP"] = "SAVE_NEW_TRIP";
  TripsFormType["SEARCH_BOOKING"] = "SEARCH_BOOKING";
  TripsFormType["SEND_ITINERARY_EMAIL"] = "SEND_ITINERARY_EMAIL";
  TripsFormType["UPDATE_TRIP"] = "UPDATE_TRIP";
})(TripsFormType || (TripsFormType = {}));
export var TripsInputType;
(function (TripsInputType) {
  TripsInputType["MULTIPLE"] = "MULTIPLE";
  TripsInputType["SINGLE"] = "SINGLE";
})(TripsInputType || (TripsInputType = {}));
export var TripsLinkTarget;
(function (TripsLinkTarget) {
  TripsLinkTarget["EXTERNAL"] = "EXTERNAL";
  TripsLinkTarget["INTERNAL"] = "INTERNAL";
})(TripsLinkTarget || (TripsLinkTarget = {}));
export var TripsMarkerType;
(function (TripsMarkerType) {
  TripsMarkerType["ActivityEventMarker"] = "ActivityEventMarker";
  TripsMarkerType["ActivityGuestPickupMarker"] = "ActivityGuestPickupMarker";
  TripsMarkerType["ActivityLocationMarker"] = "ActivityLocationMarker";
  TripsMarkerType["ActivityRedemptionMarker"] = "ActivityRedemptionMarker";
  TripsMarkerType["AirportMarker"] = "AirportMarker";
  TripsMarkerType["CarMarker"] = "CarMarker";
  TripsMarkerType["CruiseMarker"] = "CruiseMarker";
  TripsMarkerType["HotelMarker"] = "HotelMarker";
  TripsMarkerType["PlaceMarker"] = "PlaceMarker";
})(TripsMarkerType || (TripsMarkerType = {}));
export var TripsPlanSourceType;
(function (TripsPlanSourceType) {
  TripsPlanSourceType["ACTIVITY_DETAILS"] = "ACTIVITY_DETAILS";
  TripsPlanSourceType["ACTIVITY_SEARCH"] = "ACTIVITY_SEARCH";
  TripsPlanSourceType["CAR_INFOSITE"] = "CAR_INFOSITE";
  TripsPlanSourceType["CAR_INFOSITE_V2"] = "CAR_INFOSITE_V2";
  TripsPlanSourceType["CAR_SEARCH"] = "CAR_SEARCH";
  TripsPlanSourceType["CAR_SEARCH_V2"] = "CAR_SEARCH_V2";
  /** @deprecated Use LOGING_SEARCH instead */
  TripsPlanSourceType["HOTEL_SEARCH"] = "HOTEL_SEARCH";
  TripsPlanSourceType["LODGING_DETAILS"] = "LODGING_DETAILS";
  TripsPlanSourceType["LODGING_SEARCH"] = "LODGING_SEARCH";
  TripsPlanSourceType["PACKAGE_DETAILS"] = "PACKAGE_DETAILS";
  TripsPlanSourceType["TRIP_OVERVIEW"] = "TRIP_OVERVIEW";
})(TripsPlanSourceType || (TripsPlanSourceType = {}));
export var TripsResponseStatus;
(function (TripsResponseStatus) {
  TripsResponseStatus["FAIL"] = "FAIL";
  TripsResponseStatus["SUCCESS"] = "SUCCESS";
})(TripsResponseStatus || (TripsResponseStatus = {}));
export var TripsSaveItemVariant;
(function (TripsSaveItemVariant) {
  TripsSaveItemVariant["CONTROL"] = "CONTROL";
  TripsSaveItemVariant["VARIANT_B"] = "VARIANT_B";
  TripsSaveItemVariant["VARIANT_C"] = "VARIANT_C";
})(TripsSaveItemVariant || (TripsSaveItemVariant = {}));
export var TripsScreen;
(function (TripsScreen) {
  TripsScreen["INVITE_LANDING_PAGE"] = "INVITE_LANDING_PAGE";
  TripsScreen["PUBLIC_TRIP_BOARD"] = "PUBLIC_TRIP_BOARD";
  TripsScreen["TRIP_ASSIST"] = "TRIP_ASSIST";
  TripsScreen["TRIP_ITEM_DETAILS"] = "TRIP_ITEM_DETAILS";
  TripsScreen["TRIP_ITEM_ITINERARY"] = "TRIP_ITEM_ITINERARY";
  TripsScreen["TRIP_ITEM_MANAGE_BOOKING"] = "TRIP_ITEM_MANAGE_BOOKING";
  TripsScreen["TRIP_ITEM_PRICING_REWARDS"] = "TRIP_ITEM_PRICING_REWARDS";
  TripsScreen["TRIP_ITEM_RECEIPT"] = "TRIP_ITEM_RECEIPT";
  TripsScreen["TRIP_LIST"] = "TRIP_LIST";
  TripsScreen["TRIP_MANAGE_GUESTS"] = "TRIP_MANAGE_GUESTS";
  TripsScreen["TRIP_OVERVIEW"] = "TRIP_OVERVIEW";
  TripsScreen["TRIP_OVERVIEW_ITINERARY"] = "TRIP_OVERVIEW_ITINERARY";
})(TripsScreen || (TripsScreen = {}));
export var TripsSearchBookingView;
(function (TripsSearchBookingView) {
  TripsSearchBookingView["SEARCH_BY_ITINERARY_NUMBER"] = "SEARCH_BY_ITINERARY_NUMBER";
  TripsSearchBookingView["SEARCH_BY_ITINERARY_NUMBER_AND_EMAIL"] = "SEARCH_BY_ITINERARY_NUMBER_AND_EMAIL";
  TripsSearchBookingView["SEARCH_RESULT"] = "SEARCH_RESULT";
})(TripsSearchBookingView || (TripsSearchBookingView = {}));
export var TripsSignalPairKey;
(function (TripsSignalPairKey) {
  TripsSignalPairKey["PRIMARY"] = "PRIMARY";
  TripsSignalPairKey["SECONDARY"] = "SECONDARY";
  TripsSignalPairKey["STATUS"] = "STATUS";
})(TripsSignalPairKey || (TripsSignalPairKey = {}));
export var TripsSignalType;
(function (TripsSignalType) {
  TripsSignalType["REMOVE"] = "REMOVE";
  TripsSignalType["UPDATE"] = "UPDATE";
})(TripsSignalType || (TripsSignalType = {}));
export var TripsTimelineStates;
(function (TripsTimelineStates) {
  TripsTimelineStates["CURRENT"] = "CURRENT";
  TripsTimelineStates["FUTURE"] = "FUTURE";
  TripsTimelineStates["PAST"] = "PAST";
})(TripsTimelineStates || (TripsTimelineStates = {}));
export var TripsToggleStatus;
(function (TripsToggleStatus) {
  TripsToggleStatus["DISABLED"] = "DISABLED";
  TripsToggleStatus["ENABLED"] = "ENABLED";
})(TripsToggleStatus || (TripsToggleStatus = {}));
export var TripsUIAttachQualificationType;
(function (TripsUIAttachQualificationType) {
  TripsUIAttachQualificationType["CARMIP"] = "CARMIP";
  TripsUIAttachQualificationType["HOTMIP"] = "HOTMIP";
})(TripsUIAttachQualificationType || (TripsUIAttachQualificationType = {}));
export var TripsUIAttachSavingsVariant;
(function (TripsUIAttachSavingsVariant) {
  TripsUIAttachSavingsVariant["BANNER"] = "BANNER";
  TripsUIAttachSavingsVariant["SHEET"] = "SHEET";
})(TripsUIAttachSavingsVariant || (TripsUIAttachSavingsVariant = {}));
export var TripsUICardBackgroundTheme;
(function (TripsUICardBackgroundTheme) {
  TripsUICardBackgroundTheme["GLOBAL_LOYALTY_EXTRA_HIGH"] = "GLOBAL_LOYALTY_EXTRA_HIGH";
  TripsUICardBackgroundTheme["GLOBAL_LOYALTY_HIGH"] = "GLOBAL_LOYALTY_HIGH";
  TripsUICardBackgroundTheme["GLOBAL_LOYALTY_LOW"] = "GLOBAL_LOYALTY_LOW";
  TripsUICardBackgroundTheme["GLOBAL_LOYALTY_MID"] = "GLOBAL_LOYALTY_MID";
  TripsUICardBackgroundTheme["HIGHLIGHT"] = "HIGHLIGHT";
  TripsUICardBackgroundTheme["PRIMARY"] = "PRIMARY";
  TripsUICardBackgroundTheme["SECONDARY"] = "SECONDARY";
})(TripsUICardBackgroundTheme || (TripsUICardBackgroundTheme = {}));
export var TripsUICardBorder;
(function (TripsUICardBorder) {
  TripsUICardBorder["SELECTED"] = "SELECTED";
  TripsUICardBorder["SOLID"] = "SOLID";
})(TripsUICardBorder || (TripsUICardBorder = {}));
export var TripsUICustomerNotificationTripScheduleChangeStatus;
(function (TripsUICustomerNotificationTripScheduleChangeStatus) {
  TripsUICustomerNotificationTripScheduleChangeStatus["FLIGHT_SCHEDULE_CHANGE"] = "FLIGHT_SCHEDULE_CHANGE";
  TripsUICustomerNotificationTripScheduleChangeStatus["NO_CHANGE"] = "NO_CHANGE";
})(TripsUICustomerNotificationTripScheduleChangeStatus || (TripsUICustomerNotificationTripScheduleChangeStatus = {}));
export var TripsUIDialogFooterButtonLayout;
(function (TripsUIDialogFooterButtonLayout) {
  TripsUIDialogFooterButtonLayout["INLINE"] = "INLINE";
  TripsUIDialogFooterButtonLayout["STACKED"] = "STACKED";
})(TripsUIDialogFooterButtonLayout || (TripsUIDialogFooterButtonLayout = {}));
export var TripsUIImageGalleryLayout;
(function (TripsUIImageGalleryLayout) {
  TripsUIImageGalleryLayout["GRID"] = "GRID";
  TripsUIImageGalleryLayout["LIST"] = "LIST";
})(TripsUIImageGalleryLayout || (TripsUIImageGalleryLayout = {}));
export var TripsUIItemCardMediaPosition;
(function (TripsUIItemCardMediaPosition) {
  TripsUIItemCardMediaPosition["LEFT"] = "LEFT";
  TripsUIItemCardMediaPosition["TOP"] = "TOP";
})(TripsUIItemCardMediaPosition || (TripsUIItemCardMediaPosition = {}));
export var TripsUIManageExternalItemsCheckboxCardActionType;
(function (TripsUIManageExternalItemsCheckboxCardActionType) {
  TripsUIManageExternalItemsCheckboxCardActionType["TOGGLE_ADD_LIST"] = "TOGGLE_ADD_LIST";
  TripsUIManageExternalItemsCheckboxCardActionType["TOGGLE_REMOVE_LIST"] = "TOGGLE_REMOVE_LIST";
})(TripsUIManageExternalItemsCheckboxCardActionType || (TripsUIManageExternalItemsCheckboxCardActionType = {}));
export var TripsUIMessagingBannerCardTheme;
(function (TripsUIMessagingBannerCardTheme) {
  TripsUIMessagingBannerCardTheme["CRITICAL"] = "CRITICAL";
  TripsUIMessagingBannerCardTheme["DEFAULT"] = "DEFAULT";
  TripsUIMessagingBannerCardTheme["ERROR"] = "ERROR";
})(TripsUIMessagingBannerCardTheme || (TripsUIMessagingBannerCardTheme = {}));
export var TripsUIResponseStatus;
(function (TripsUIResponseStatus) {
  TripsUIResponseStatus["FAIL"] = "FAIL";
  TripsUIResponseStatus["SUCCESS"] = "SUCCESS";
})(TripsUIResponseStatus || (TripsUIResponseStatus = {}));
export var TripsViewType;
(function (TripsViewType) {
  TripsViewType["ACTIVITIES_SEARCH_RESULTS"] = "ACTIVITIES_SEARCH_RESULTS";
  TripsViewType["ASC_ACCEPT"] = "ASC_ACCEPT";
  TripsViewType["ASC_CANCEL"] = "ASC_CANCEL";
  TripsViewType["BOOKING_FLIGHT_CANCEL"] = "BOOKING_FLIGHT_CANCEL";
  TripsViewType["BOOKING_FLIGHT_CHANGE"] = "BOOKING_FLIGHT_CHANGE";
  TripsViewType["BOOKING_SERVICING_HEADS_UP"] = "BOOKING_SERVICING_HEADS_UP";
  TripsViewType["CAMPAIGNS"] = "CAMPAIGNS";
  TripsViewType["CARS_SEARCH_RESULTS"] = "CARS_SEARCH_RESULTS";
  TripsViewType["CHECKOUT_VAS_CART"] = "CHECKOUT_VAS_CART";
  TripsViewType["FILTERED"] = "FILTERED";
  TripsViewType["FIND_ITINERARY_BY_OTHER_EMAIL"] = "FIND_ITINERARY_BY_OTHER_EMAIL";
  TripsViewType["FIND_ITINERARY_BY_USER_EMAIL"] = "FIND_ITINERARY_BY_USER_EMAIL";
  TripsViewType["FLIGHTS_SEARCH_RESULTS"] = "FLIGHTS_SEARCH_RESULTS";
  TripsViewType["ITEM_DETAILS"] = "ITEM_DETAILS";
  TripsViewType["ITEM_ESSENTIAL_INFO"] = "ITEM_ESSENTIAL_INFO";
  TripsViewType["ITEM_PRICING_AND_REWARDS"] = "ITEM_PRICING_AND_REWARDS";
  TripsViewType["ITEM_VOUCHER"] = "ITEM_VOUCHER";
  TripsViewType["ITINERARY"] = "ITINERARY";
  TripsViewType["LOB_SELECTION_VIEW"] = "LOB_SELECTION_VIEW";
  TripsViewType["LODGING_SEARCH_RESULTS"] = "LODGING_SEARCH_RESULTS";
  TripsViewType["LOGIN_PROMPT"] = "LOGIN_PROMPT";
  TripsViewType["MANAGE_BOOKING"] = "MANAGE_BOOKING";
  TripsViewType["MANAGE_GUESTS"] = "MANAGE_GUESTS";
  TripsViewType["OVERVIEW"] = "OVERVIEW";
  TripsViewType["PACKAGES_FC_SEARCH_RESULTS"] = "PACKAGES_FC_SEARCH_RESULTS";
  TripsViewType["PACKAGES_FHC_SEARCH_RESULTS"] = "PACKAGES_FHC_SEARCH_RESULTS";
  TripsViewType["PACKAGES_HC_SEARCH_RESULTS"] = "PACKAGES_HC_SEARCH_RESULTS";
  TripsViewType["PACKAGES_HF_SEARCH_RESULTS"] = "PACKAGES_HF_SEARCH_RESULTS";
  TripsViewType["PENDING_INVITE"] = "PENDING_INVITE";
  TripsViewType["SEARCH_BY_ITINERARY_NUMBER"] = "SEARCH_BY_ITINERARY_NUMBER";
  TripsViewType["SEARCH_BY_ITINERARY_NUMBER_AND_EMAIL"] = "SEARCH_BY_ITINERARY_NUMBER_AND_EMAIL";
  TripsViewType["TRIPS_LIST"] = "TRIPS_LIST";
  TripsViewType["TRIP_ASSIST"] = "TRIP_ASSIST";
})(TripsViewType || (TripsViewType = {}));
export var UILinkTarget;
(function (UILinkTarget) {
  UILinkTarget["EXTERNAL"] = "EXTERNAL";
  UILinkTarget["INTERNAL"] = "INTERNAL";
})(UILinkTarget || (UILinkTarget = {}));
export var UISignal;
(function (UISignal) {
  UISignal["ABANDON_BOOKING"] = "ABANDON_BOOKING";
  UISignal["ABANDON_BOOKING_SUCCESS"] = "ABANDON_BOOKING_SUCCESS";
  UISignal["BOOKING_ALREADY_BOOKED"] = "BOOKING_ALREADY_BOOKED";
  UISignal["BOOKING_ERROR"] = "BOOKING_ERROR";
  UISignal["BOOKING_SUCCESS"] = "BOOKING_SUCCESS";
  UISignal["BOOKING_SUSPENDED"] = "BOOKING_SUSPENDED";
  UISignal["CONFIGURATION_ADDED"] = "CONFIGURATION_ADDED";
  UISignal["CONTINUE_BOOKING"] = "CONTINUE_BOOKING";
  UISignal["DUPLICATE_REQUEST"] = "DUPLICATE_REQUEST";
  UISignal["GENERIC_DOWNSTREAM_ERROR"] = "GENERIC_DOWNSTREAM_ERROR";
  UISignal["INSURANCE_UNAVAILABLE"] = "INSURANCE_UNAVAILABLE";
  UISignal["INVALID_EMAIL"] = "INVALID_EMAIL";
  UISignal["OFFER_ADDED"] = "OFFER_ADDED";
  UISignal["OFFER_CHANGED"] = "OFFER_CHANGED";
  UISignal["OFFER_REMOVED"] = "OFFER_REMOVED";
  UISignal["OFFER_UNAVAILABLE"] = "OFFER_UNAVAILABLE";
  UISignal["OPERATION_FAILED"] = "OPERATION_FAILED";
  UISignal["PAYMENT_ERROR"] = "PAYMENT_ERROR";
  UISignal["PAYMENT_METHODS_APPLIED"] = "PAYMENT_METHODS_APPLIED";
  UISignal["PAYMENT_METHOD_UPDATED"] = "PAYMENT_METHOD_UPDATED";
  UISignal["PRICE_ADJUSTED"] = "PRICE_ADJUSTED";
  UISignal["PRICE_CHANGED"] = "PRICE_CHANGED";
  UISignal["PROMOTION_CHANGED"] = "PROMOTION_CHANGED";
  UISignal["REQUEST_CONFLICT"] = "REQUEST_CONFLICT";
  UISignal["SESSION_EXPIRED"] = "SESSION_EXPIRED";
  UISignal["SESSION_TOKEN_UPDATED"] = "SESSION_TOKEN_UPDATED";
  UISignal["UNKNOWN"] = "UNKNOWN";
  UISignal["UNRECOVERABLE_FAILURE"] = "UNRECOVERABLE_FAILURE";
  UISignal["USER_STATE_UPDATED"] = "USER_STATE_UPDATED";
})(UISignal || (UISignal = {}));
export var UniversalProfileAccountManagementOptionType;
(function (UniversalProfileAccountManagementOptionType) {
  UniversalProfileAccountManagementOptionType["TRAVELER_ARRANGER"] = "TRAVELER_ARRANGER";
})(UniversalProfileAccountManagementOptionType || (UniversalProfileAccountManagementOptionType = {}));
export var UniversalProfileAccountTakeOverWidgetTypeRequest;
(function (UniversalProfileAccountTakeOverWidgetTypeRequest) {
  UniversalProfileAccountTakeOverWidgetTypeRequest["CAPTCHA"] = "CAPTCHA";
  UniversalProfileAccountTakeOverWidgetTypeRequest["CSRF"] = "CSRF";
})(UniversalProfileAccountTakeOverWidgetTypeRequest || (UniversalProfileAccountTakeOverWidgetTypeRequest = {}));
export var UniversalProfileAnalyticsEventSubType;
(function (UniversalProfileAnalyticsEventSubType) {
  UniversalProfileAnalyticsEventSubType["BLURRED"] = "BLURRED";
  UniversalProfileAnalyticsEventSubType["FOCUSED"] = "FOCUSED";
  UniversalProfileAnalyticsEventSubType["INPUTTED"] = "INPUTTED";
  UniversalProfileAnalyticsEventSubType["SUCCEEDED"] = "SUCCEEDED";
})(UniversalProfileAnalyticsEventSubType || (UniversalProfileAnalyticsEventSubType = {}));
export var UniversalProfileAvatarUploadRequestType;
(function (UniversalProfileAvatarUploadRequestType) {
  UniversalProfileAvatarUploadRequestType["ADD_IMAGE"] = "ADD_IMAGE";
  UniversalProfileAvatarUploadRequestType["CHANGE_IMAGE"] = "CHANGE_IMAGE";
})(UniversalProfileAvatarUploadRequestType || (UniversalProfileAvatarUploadRequestType = {}));
export var UniversalProfileCategorySwitchOperator;
(function (UniversalProfileCategorySwitchOperator) {
  UniversalProfileCategorySwitchOperator["OFF"] = "OFF";
  UniversalProfileCategorySwitchOperator["ON"] = "ON";
})(UniversalProfileCategorySwitchOperator || (UniversalProfileCategorySwitchOperator = {}));
export var UniversalProfileCheckboxAnalyticsEventSubType;
(function (UniversalProfileCheckboxAnalyticsEventSubType) {
  UniversalProfileCheckboxAnalyticsEventSubType["DESELECTED"] = "DESELECTED";
  UniversalProfileCheckboxAnalyticsEventSubType["SELECTED"] = "SELECTED";
})(UniversalProfileCheckboxAnalyticsEventSubType || (UniversalProfileCheckboxAnalyticsEventSubType = {}));
export var UniversalProfileClientType;
(function (UniversalProfileClientType) {
  UniversalProfileClientType["ANDROID"] = "ANDROID";
  UniversalProfileClientType["IOS"] = "IOS";
  UniversalProfileClientType["WEB"] = "WEB";
})(UniversalProfileClientType || (UniversalProfileClientType = {}));
export var UniversalProfileCommunicationPreferencesCategoryType;
(function (UniversalProfileCommunicationPreferencesCategoryType) {
  UniversalProfileCommunicationPreferencesCategoryType["ACCOUNT_SUPPORT"] = "ACCOUNT_SUPPORT";
  UniversalProfileCommunicationPreferencesCategoryType["CONFIRMATION_AND_CRITICAL_UPDATES"] = "CONFIRMATION_AND_CRITICAL_UPDATES";
  UniversalProfileCommunicationPreferencesCategoryType["OFFERS_AND_INSPIRATION"] = "OFFERS_AND_INSPIRATION";
  UniversalProfileCommunicationPreferencesCategoryType["ONE_KEY"] = "ONE_KEY";
  UniversalProfileCommunicationPreferencesCategoryType["ONE_KEY_MONTHLY_STATEMENTS"] = "ONE_KEY_MONTHLY_STATEMENTS";
  UniversalProfileCommunicationPreferencesCategoryType["ONE_KEY_REWARD_ACCOUNT_UPDATES"] = "ONE_KEY_REWARD_ACCOUNT_UPDATES";
  UniversalProfileCommunicationPreferencesCategoryType["PARTNERS_OFFERS_WITH_US"] = "PARTNERS_OFFERS_WITH_US";
  UniversalProfileCommunicationPreferencesCategoryType["PERSONALIZED_TRIP_RECOMMENDATIONS"] = "PERSONALIZED_TRIP_RECOMMENDATIONS";
  UniversalProfileCommunicationPreferencesCategoryType["REVIEWS"] = "REVIEWS";
  UniversalProfileCommunicationPreferencesCategoryType["SURVEYS"] = "SURVEYS";
  UniversalProfileCommunicationPreferencesCategoryType["THE_TRAVEL_EDIT"] = "THE_TRAVEL_EDIT";
  UniversalProfileCommunicationPreferencesCategoryType["TRIP_BOARD_ACTIVITY"] = "TRIP_BOARD_ACTIVITY";
  UniversalProfileCommunicationPreferencesCategoryType["TRIP_PLANNING_ASSISTANCE"] = "TRIP_PLANNING_ASSISTANCE";
})(UniversalProfileCommunicationPreferencesCategoryType || (UniversalProfileCommunicationPreferencesCategoryType = {}));
export var UniversalProfileCommunicationPreferencesFormType;
(function (UniversalProfileCommunicationPreferencesFormType) {
  UniversalProfileCommunicationPreferencesFormType["COMMUNICATION_PREFERENCE_CATEGORY_FORM"] = "COMMUNICATION_PREFERENCE_CATEGORY_FORM";
  UniversalProfileCommunicationPreferencesFormType["COMMUNICATION_PREFERENCE_COMPLETENESS_MODULE"] = "COMMUNICATION_PREFERENCE_COMPLETENESS_MODULE";
  UniversalProfileCommunicationPreferencesFormType["COMMUNICATION_PREFERENCE_MANAGE_EMAIL_FORM"] = "COMMUNICATION_PREFERENCE_MANAGE_EMAIL_FORM";
  UniversalProfileCommunicationPreferencesFormType["COMMUNICATION_PREFERENCE_UNSUBSCRIBE_CATEGORY_FORM"] = "COMMUNICATION_PREFERENCE_UNSUBSCRIBE_CATEGORY_FORM";
  UniversalProfileCommunicationPreferencesFormType["COMMUNICATION_PREFERENCE_UNSUBSCRIBE_STRICT_FORM"] = "COMMUNICATION_PREFERENCE_UNSUBSCRIBE_STRICT_FORM";
})(UniversalProfileCommunicationPreferencesFormType || (UniversalProfileCommunicationPreferencesFormType = {}));
export var UniversalProfileCompletenessModulePromptType;
(function (UniversalProfileCompletenessModulePromptType) {
  UniversalProfileCompletenessModulePromptType["EMAIL_PREFERENCES"] = "EMAIL_PREFERENCES";
  UniversalProfileCompletenessModulePromptType["ONE_KEY_CREDIT_CARD"] = "ONE_KEY_CREDIT_CARD";
  UniversalProfileCompletenessModulePromptType["PUSH_NOTIFICATION_PREFERENCES"] = "PUSH_NOTIFICATION_PREFERENCES";
})(UniversalProfileCompletenessModulePromptType || (UniversalProfileCompletenessModulePromptType = {}));
export var UniversalProfileDashboardOptionType;
(function (UniversalProfileDashboardOptionType) {
  UniversalProfileDashboardOptionType["COMMUNICATIONS"] = "COMMUNICATIONS";
  UniversalProfileDashboardOptionType["CONNECTIONS"] = "CONNECTIONS";
  UniversalProfileDashboardOptionType["COUPONS"] = "COUPONS";
  UniversalProfileDashboardOptionType["CREDITS"] = "CREDITS";
  UniversalProfileDashboardOptionType["EG_LABS"] = "EG_LABS";
  UniversalProfileDashboardOptionType["HELP"] = "HELP";
  UniversalProfileDashboardOptionType["LEGAL"] = "LEGAL";
  UniversalProfileDashboardOptionType["PAYMENTS"] = "PAYMENTS";
  UniversalProfileDashboardOptionType["PROFILE"] = "PROFILE";
  UniversalProfileDashboardOptionType["PUBLIC_PROFILE"] = "PUBLIC_PROFILE";
  UniversalProfileDashboardOptionType["REVIEWS"] = "REVIEWS";
  UniversalProfileDashboardOptionType["SETTINGS"] = "SETTINGS";
})(UniversalProfileDashboardOptionType || (UniversalProfileDashboardOptionType = {}));
export var UniversalProfileDependsOnFieldIdentificationCriteriaOperator;
(function (UniversalProfileDependsOnFieldIdentificationCriteriaOperator) {
  UniversalProfileDependsOnFieldIdentificationCriteriaOperator["EXCLUDES"] = "EXCLUDES";
  UniversalProfileDependsOnFieldIdentificationCriteriaOperator["INCLUDES"] = "INCLUDES";
})(UniversalProfileDependsOnFieldIdentificationCriteriaOperator || (UniversalProfileDependsOnFieldIdentificationCriteriaOperator = {}));
export var UniversalProfileExperienceType;
(function (UniversalProfileExperienceType) {
  UniversalProfileExperienceType["ADDITIONAL_TRAVELER"] = "ADDITIONAL_TRAVELER";
  UniversalProfileExperienceType["COMMUNICATION_PREFERENCES"] = "COMMUNICATION_PREFERENCES";
  UniversalProfileExperienceType["HELP"] = "HELP";
  UniversalProfileExperienceType["LEGAL"] = "LEGAL";
  UniversalProfileExperienceType["PROFILE"] = "PROFILE";
  UniversalProfileExperienceType["SESSION_MANAGEMENT"] = "SESSION_MANAGEMENT";
  UniversalProfileExperienceType["SETTINGS"] = "SETTINGS";
  UniversalProfileExperienceType["TRAVELER_DOCUMENTS"] = "TRAVELER_DOCUMENTS";
})(UniversalProfileExperienceType || (UniversalProfileExperienceType = {}));
export var UniversalProfileImageUploadCategory;
(function (UniversalProfileImageUploadCategory) {
  UniversalProfileImageUploadCategory["PROFILE_AVATAR"] = "PROFILE_AVATAR";
})(UniversalProfileImageUploadCategory || (UniversalProfileImageUploadCategory = {}));
export var UniversalProfileInformationFormType;
(function (UniversalProfileInformationFormType) {
  UniversalProfileInformationFormType["COMMUNICATION_PREFERENCE_UNSUB"] = "COMMUNICATION_PREFERENCE_UNSUB";
  UniversalProfileInformationFormType["COMPLETENESS_MODULE"] = "COMPLETENESS_MODULE";
  UniversalProfileInformationFormType["CONFIRM_AVATAR_UPLOAD"] = "CONFIRM_AVATAR_UPLOAD";
  UniversalProfileInformationFormType["DELETE_VERIFIED_PHONE_NUMBER"] = "DELETE_VERIFIED_PHONE_NUMBER";
  UniversalProfileInformationFormType["UPDATE_ADDITIONAL_TRAVELER_INFO"] = "UPDATE_ADDITIONAL_TRAVELER_INFO";
  UniversalProfileInformationFormType["UPDATE_BASIC_INFORMATION"] = "UPDATE_BASIC_INFORMATION";
  UniversalProfileInformationFormType["UPDATE_COMMUNICATION_PREFERENCE"] = "UPDATE_COMMUNICATION_PREFERENCE";
  UniversalProfileInformationFormType["UPDATE_CONTACT_INFORMATION"] = "UPDATE_CONTACT_INFORMATION";
  UniversalProfileInformationFormType["UPDATE_CONTACT_INFORMATION_EMAIL"] = "UPDATE_CONTACT_INFORMATION_EMAIL";
  UniversalProfileInformationFormType["UPDATE_CONTACT_INFORMATION_PHONE_NUMBER"] = "UPDATE_CONTACT_INFORMATION_PHONE_NUMBER";
  UniversalProfileInformationFormType["UPDATE_PASSPORT_INFO"] = "UPDATE_PASSPORT_INFO";
  UniversalProfileInformationFormType["UPDATE_TRAVELER_DOCUMENT"] = "UPDATE_TRAVELER_DOCUMENT";
  UniversalProfileInformationFormType["UPDATE_TSA_INFORMATION"] = "UPDATE_TSA_INFORMATION";
})(UniversalProfileInformationFormType || (UniversalProfileInformationFormType = {}));
export var UniversalProfileLocationAndLanguageOptionType;
(function (UniversalProfileLocationAndLanguageOptionType) {
  UniversalProfileLocationAndLanguageOptionType["COUNTY"] = "COUNTY";
  UniversalProfileLocationAndLanguageOptionType["CURRENCY"] = "CURRENCY";
  UniversalProfileLocationAndLanguageOptionType["LANGUAGE"] = "LANGUAGE";
})(UniversalProfileLocationAndLanguageOptionType || (UniversalProfileLocationAndLanguageOptionType = {}));
export var UniversalProfileLoginInformationSettingsOptionType;
(function (UniversalProfileLoginInformationSettingsOptionType) {
  UniversalProfileLoginInformationSettingsOptionType["CHANGE_PASSWORD"] = "CHANGE_PASSWORD";
  UniversalProfileLoginInformationSettingsOptionType["CONNECTED_DEVICES"] = "CONNECTED_DEVICES";
  UniversalProfileLoginInformationSettingsOptionType["DELETE_PHONE_NUMBER"] = "DELETE_PHONE_NUMBER";
  UniversalProfileLoginInformationSettingsOptionType["EMAIL"] = "EMAIL";
  UniversalProfileLoginInformationSettingsOptionType["PHONE_NUMBER"] = "PHONE_NUMBER";
})(UniversalProfileLoginInformationSettingsOptionType || (UniversalProfileLoginInformationSettingsOptionType = {}));
export var UniversalProfileMoreDetailsOptionType;
(function (UniversalProfileMoreDetailsOptionType) {
  UniversalProfileMoreDetailsOptionType["AIRPORT_SECURITY"] = "AIRPORT_SECURITY";
  UniversalProfileMoreDetailsOptionType["FLIGHT_PREFERENCES"] = "FLIGHT_PREFERENCES";
  UniversalProfileMoreDetailsOptionType["REWARD_PROGRAM"] = "REWARD_PROGRAM";
  UniversalProfileMoreDetailsOptionType["TRAVELER_DOCUMENTS"] = "TRAVELER_DOCUMENTS";
})(UniversalProfileMoreDetailsOptionType || (UniversalProfileMoreDetailsOptionType = {}));
export var UniversalProfilePreferencesOptionType;
(function (UniversalProfilePreferencesOptionType) {
  UniversalProfilePreferencesOptionType["COUNTRY"] = "COUNTRY";
  UniversalProfilePreferencesOptionType["CURRENCY"] = "CURRENCY";
  UniversalProfilePreferencesOptionType["DISPLAY_APPEARANCE"] = "DISPLAY_APPEARANCE";
  UniversalProfilePreferencesOptionType["LANGUAGE"] = "LANGUAGE";
})(UniversalProfilePreferencesOptionType || (UniversalProfilePreferencesOptionType = {}));
export var UniversalProfileSettingsFormType;
(function (UniversalProfileSettingsFormType) {
  UniversalProfileSettingsFormType["DELETE_ACCOUNT"] = "DELETE_ACCOUNT";
  UniversalProfileSettingsFormType["UPDATE_COUNTRY"] = "UPDATE_COUNTRY";
  UniversalProfileSettingsFormType["UPDATE_EMAIL"] = "UPDATE_EMAIL";
  UniversalProfileSettingsFormType["UPDATE_LANGUAGE"] = "UPDATE_LANGUAGE";
  UniversalProfileSettingsFormType["UPDATE_PASSWORD"] = "UPDATE_PASSWORD";
})(UniversalProfileSettingsFormType || (UniversalProfileSettingsFormType = {}));
export var UniversalProfileSubExperienceType;
(function (UniversalProfileSubExperienceType) {
  UniversalProfileSubExperienceType["COMMUNICATION_PREFERENCES_EXPEDIA"] = "COMMUNICATION_PREFERENCES_EXPEDIA";
  UniversalProfileSubExperienceType["COMMUNICATION_PREFERENCES_HOTELS"] = "COMMUNICATION_PREFERENCES_HOTELS";
  UniversalProfileSubExperienceType["COMMUNICATION_PREFERENCES_VRBO"] = "COMMUNICATION_PREFERENCES_VRBO";
  UniversalProfileSubExperienceType["COMMUNICATION_PREFERENCES_WOTIF"] = "COMMUNICATION_PREFERENCES_WOTIF";
  UniversalProfileSubExperienceType["VISIT_HELP_CENTER"] = "VISIT_HELP_CENTER";
})(UniversalProfileSubExperienceType || (UniversalProfileSubExperienceType = {}));
export var UniversalProfileTravelerDocumentsOptionType;
(function (UniversalProfileTravelerDocumentsOptionType) {
  UniversalProfileTravelerDocumentsOptionType["PASSPORT"] = "PASSPORT";
})(UniversalProfileTravelerDocumentsOptionType || (UniversalProfileTravelerDocumentsOptionType = {}));
export var UniversalProfileVerifiedPhoneIntent;
(function (UniversalProfileVerifiedPhoneIntent) {
  UniversalProfileVerifiedPhoneIntent["DELETE"] = "DELETE";
})(UniversalProfileVerifiedPhoneIntent || (UniversalProfileVerifiedPhoneIntent = {}));
export var UpdateAction;
(function (UpdateAction) {
  UpdateAction["ACCEPT"] = "ACCEPT";
  UpdateAction["SHARE"] = "SHARE";
})(UpdateAction || (UpdateAction = {}));
export var UpdateHistory;
(function (UpdateHistory) {
  UpdateHistory["REMOVE"] = "REMOVE";
})(UpdateHistory || (UpdateHistory = {}));
export var UriClassification;
(function (UriClassification) {
  UriClassification["CLASSIFICATION_DEALS"] = "CLASSIFICATION_DEALS";
  UriClassification["CLASSIFICATION_INVALID"] = "CLASSIFICATION_INVALID";
  UriClassification["CLASSIFICATION_LEGAL"] = "CLASSIFICATION_LEGAL";
})(UriClassification || (UriClassification = {}));
export var UserActivitySignal;
(function (UserActivitySignal) {
  UserActivitySignal["COLD"] = "COLD";
  UserActivitySignal["WARM"] = "WARM";
})(UserActivitySignal || (UserActivitySignal = {}));
export var VRCancellationReason;
(function (VRCancellationReason) {
  VRCancellationReason["CONFLICT_WITH_HOUSE_RULES"] = "CONFLICT_WITH_HOUSE_RULES";
  VRCancellationReason["DATES_BOOKED_EXTERNALLY"] = "DATES_BOOKED_EXTERNALLY";
  VRCancellationReason["EXTENUATING_CIRCUMSTANCES"] = "EXTENUATING_CIRCUMSTANCES";
  VRCancellationReason["GUEST_NEEDS_TO_CANCEL"] = "GUEST_NEEDS_TO_CANCEL";
  VRCancellationReason["NEW_OWNERSHIP_OF_PROPERTY"] = "NEW_OWNERSHIP_OF_PROPERTY";
  VRCancellationReason["PAYMENT_NOT_RECEIVED"] = "PAYMENT_NOT_RECEIVED";
})(VRCancellationReason || (VRCancellationReason = {}));
export var VideoExperienceComponentType;
(function (VideoExperienceComponentType) {
  VideoExperienceComponentType["CAROUSEL"] = "CAROUSEL";
  VideoExperienceComponentType["VIDEO_PLAYER"] = "VIDEO_PLAYER";
})(VideoExperienceComponentType || (VideoExperienceComponentType = {}));
export var VideoExperienceInteractionType;
(function (VideoExperienceInteractionType) {
  VideoExperienceInteractionType["BUTTON_TAP"] = "BUTTON_TAP";
  VideoExperienceInteractionType["CLICK"] = "CLICK";
  VideoExperienceInteractionType["PAUSE"] = "PAUSE";
  VideoExperienceInteractionType["PLAY"] = "PLAY";
  VideoExperienceInteractionType["SWIPE"] = "SWIPE";
  VideoExperienceInteractionType["VIEW"] = "VIEW";
  VideoExperienceInteractionType["WATCH_TIME"] = "WATCH_TIME";
})(VideoExperienceInteractionType || (VideoExperienceInteractionType = {}));
export var VideoExperienceProductId;
(function (VideoExperienceProductId) {
  VideoExperienceProductId["EG_STORIES"] = "EG_STORIES";
  VideoExperienceProductId["MESO_ADS"] = "MESO_ADS";
  VideoExperienceProductId["TRAVEL_SHOP"] = "TRAVEL_SHOP";
})(VideoExperienceProductId || (VideoExperienceProductId = {}));
export var VirtualAgentCheckoutStatus;
(function (VirtualAgentCheckoutStatus) {
  VirtualAgentCheckoutStatus["FAILED"] = "FAILED";
  VirtualAgentCheckoutStatus["SUCCESS"] = "SUCCESS";
})(VirtualAgentCheckoutStatus || (VirtualAgentCheckoutStatus = {}));
export var VirtualAgentControlAnalyticEventSubType;
(function (VirtualAgentControlAnalyticEventSubType) {
  VirtualAgentControlAnalyticEventSubType["BLURRED"] = "BLURRED";
  VirtualAgentControlAnalyticEventSubType["FOCUSED"] = "FOCUSED";
  VirtualAgentControlAnalyticEventSubType["INPUTTED"] = "INPUTTED";
  VirtualAgentControlAnalyticEventSubType["SUCCEEDED"] = "SUCCEEDED";
})(VirtualAgentControlAnalyticEventSubType || (VirtualAgentControlAnalyticEventSubType = {}));
export var VirtualAgentControlEventType;
(function (VirtualAgentControlEventType) {
  VirtualAgentControlEventType["CONVERSATION_READ"] = "CONVERSATION_READ";
  VirtualAgentControlEventType["TYPING_INDICATOR"] = "TYPING_INDICATOR";
})(VirtualAgentControlEventType || (VirtualAgentControlEventType = {}));
export var VirtualAgentControlMenuItemType;
(function (VirtualAgentControlMenuItemType) {
  VirtualAgentControlMenuItemType["DOWNLOAD_TRANSCRIPT"] = "DOWNLOAD_TRANSCRIPT";
  VirtualAgentControlMenuItemType["REPORT_SPAM"] = "REPORT_SPAM";
  VirtualAgentControlMenuItemType["TOGGLE_BLOCK_CONVERSATION"] = "TOGGLE_BLOCK_CONVERSATION";
  VirtualAgentControlMenuItemType["TOGGLE_SOUND"] = "TOGGLE_SOUND";
})(VirtualAgentControlMenuItemType || (VirtualAgentControlMenuItemType = {}));
export var VirtualAgentControlMessageTriggerLocation;
(function (VirtualAgentControlMessageTriggerLocation) {
  VirtualAgentControlMessageTriggerLocation["EXTERNAL"] = "EXTERNAL";
  VirtualAgentControlMessageTriggerLocation["INTERNAL"] = "INTERNAL";
})(VirtualAgentControlMessageTriggerLocation || (VirtualAgentControlMessageTriggerLocation = {}));
export var VirtualAgentControlMessageType;
(function (VirtualAgentControlMessageType) {
  VirtualAgentControlMessageType["FILE"] = "FILE";
  VirtualAgentControlMessageType["INTENT"] = "INTENT";
  VirtualAgentControlMessageType["POSTBACK"] = "POSTBACK";
  VirtualAgentControlMessageType["TEXT"] = "TEXT";
})(VirtualAgentControlMessageType || (VirtualAgentControlMessageType = {}));
export var VirtualAgentControlPresenceMode;
(function (VirtualAgentControlPresenceMode) {
  VirtualAgentControlPresenceMode["HEARTBEAT"] = "HEARTBEAT";
  VirtualAgentControlPresenceMode["NONE"] = "NONE";
})(VirtualAgentControlPresenceMode || (VirtualAgentControlPresenceMode = {}));
export var VirtualAgentControlProgressBarTheme;
(function (VirtualAgentControlProgressBarTheme) {
  VirtualAgentControlProgressBarTheme["MUTED"] = "MUTED";
  VirtualAgentControlProgressBarTheme["POSITIVE"] = "POSITIVE";
  VirtualAgentControlProgressBarTheme["STANDARD"] = "STANDARD";
})(VirtualAgentControlProgressBarTheme || (VirtualAgentControlProgressBarTheme = {}));
export var WaiverType;
(function (WaiverType) {
  WaiverType["ELP"] = "ELP";
  WaiverType["INSURANCE"] = "INSURANCE";
  WaiverType["NO_WAIVER"] = "NO_WAIVER";
  WaiverType["SUPPLIER"] = "SUPPLIER";
})(WaiverType || (WaiverType = {}));
export var WaivingSource;
(function (WaivingSource) {
  WaivingSource["COMBINED"] = "COMBINED";
  WaivingSource["EXPEDIA"] = "EXPEDIA";
  WaivingSource["NO_WAIVER"] = "NO_WAIVER";
  WaivingSource["SUPPLIER"] = "SUPPLIER";
})(WaivingSource || (WaivingSource = {}));
export var WalletLedgerTabType;
(function (WalletLedgerTabType) {
  WalletLedgerTabType["ONE_KEY_CASH"] = "ONE_KEY_CASH";
  WalletLedgerTabType["REFUND_CREDITS"] = "REFUND_CREDITS";
})(WalletLedgerTabType || (WalletLedgerTabType = {}));
export var WidgetCardType;
(function (WidgetCardType) {
  WidgetCardType["GET_THERE"] = "GET_THERE";
  WidgetCardType["THINGS_TO_DO"] = "THINGS_TO_DO";
  WidgetCardType["VIBES"] = "VIBES";
  WidgetCardType["WEATHER"] = "WEATHER";
})(WidgetCardType || (WidgetCardType = {}));
export var WishlistPageType;
(function (WishlistPageType) {
  WishlistPageType["DISCOVER"] = "DISCOVER";
  WishlistPageType["EXPANDED_WISHLIST"] = "EXPANDED_WISHLIST";
  WishlistPageType["TRIPS"] = "TRIPS";
})(WishlistPageType || (WishlistPageType = {}));
export var WizardMode;
(function (WizardMode) {
  WizardMode["CREATE"] = "CREATE";
  WizardMode["EDIT"] = "EDIT";
})(WizardMode || (WizardMode = {}));
export var WizardStep;
(function (WizardStep) {
  WizardStep["AD"] = "AD";
  WizardStep["AD_GROUP"] = "AD_GROUP";
  WizardStep["CAMPAIGN"] = "CAMPAIGN";
  WizardStep["REVIEW"] = "REVIEW";
})(WizardStep || (WizardStep = {}));
export const LodgingEGDSHeadingFragmentFragmentDoc = gql`
    fragment LodgingEGDSHeadingFragment on EGDSHeading {
  __typename
  text
}
    `;
export const ContainerHeadingDisclaimerFragmentFragmentDoc = gql`
    fragment ContainerHeadingDisclaimerFragment on LodgingPlainDialog {
  ... on LodgingPlainDialog {
    __typename
    title
    content
    primaryUIButton {
      __typename
      primary
    }
    trigger {
      __typename
      icon {
        __typename
        id
        title
      }
      clientSideAnalytics {
        referrerId
        linkName
      }
    }
  }
}
    `;
export const ContainerHeadingFragmentFragmentDoc = gql`
    fragment ContainerHeadingFragment on ShoppingListingContainerHeading {
  __typename
  ...LodgingEGDSHeadingFragment
  ... on ShoppingListingHeader {
    title
    subTitle
    disclaimer {
      ...ContainerHeadingDisclaimerFragment
    }
  }
}
    ${LodgingEGDSHeadingFragmentFragmentDoc}
${ContainerHeadingDisclaimerFragmentFragmentDoc}`;
export const LodgingEGDSIconFragmentFragmentDoc = gql`
    fragment LodgingEGDSIconFragment on Icon {
  __typename
  description
  id
  size
  theme
  spotLight
}
    `;
export const LodgingProductAmenityFragmentFragmentDoc = gql`
    fragment LodgingProductAmenityFragment on EGDSIconText {
  icon {
    ...LodgingEGDSIconFragment
  }
  text
}
    ${LodgingEGDSIconFragmentFragmentDoc}`;
export const LodgingEGDSParagraphFragmentFragmentDoc = gql`
    fragment LodgingEGDSParagraphFragment on EGDSParagraph {
  __typename
  text
}
    `;
export const EGDSStylizedTextFragmentFragmentDoc = gql`
    fragment EGDSStylizedTextFragment on EGDSStylizedText {
  __typename
  text
  theme
  weight
  size
  decorative
  accessibility
}
    `;
export const LodgingEGDSGraphicTextFragmentFragmentDoc = gql`
    fragment LodgingEGDSGraphicTextFragment on EGDSGraphicText {
  accessibility
  text
  graphic {
    ... on Icon {
      id
      size
    }
    ... on Mark {
      url {
        ... on HttpURI {
          value
        }
      }
    }
  }
}
    `;
export const LodgingEGDSTextFragmentFragmentDoc = gql`
    fragment LodgingEGDSTextFragment on EGDSText {
  ... on EGDSPlainText {
    __typename
    text
  }
  ... on EGDSParagraph {
    ...LodgingEGDSParagraphFragment
  }
  ... on EGDSStylizedText {
    ...EGDSStylizedTextFragment
  }
  ... on EGDSInlineLink {
    __typename
    action {
      accessibility
      analytics {
        linkName
        referrerId
      }
      resource {
        value
      }
      target
    }
    disabled
    linkSize: size
    text
  }
  ... on EGDSGraphicText {
    ...LodgingEGDSGraphicTextFragment
  }
}
    ${LodgingEGDSParagraphFragmentFragmentDoc}
${EGDSStylizedTextFragmentFragmentDoc}
${LodgingEGDSGraphicTextFragmentFragmentDoc}`;
export const LodgingCardPropertyDescriptionSectionFragmentFragmentDoc = gql`
    fragment LodgingCardPropertyDescriptionSectionFragment on LodgingCardPropertyDescriptionSection {
  heading {
    text
  }
  description {
    text
  }
  link {
    action {
      resource {
        value
      }
    }
  }
}
    `;
export const EGDSDialogToolbarFragmentFragmentDoc = gql`
    fragment EGDSDialogToolbarFragment on EGDSDialogToolbar {
  closeText
  title
}
    `;
export const LodgingCardMoreDetailsTriggerFragmentFragmentDoc = gql`
    fragment LodgingCardMoreDetailsTriggerFragment on LodgingCardMoreDetailsTrigger {
  accessibilityLabel
  analytics {
    linkName
    referrerId
  }
  icon {
    ...LodgingEGDSIconFragment
  }
  label
}
    ${LodgingEGDSIconFragmentFragmentDoc}`;
export const LodgingCardChangeUnitDialogActionFragmentFragmentDoc = gql`
    fragment LodgingCardChangeUnitDialogActionFragment on LodgingCardChangeUnitDialogAction {
  dialog {
    closeAnalytics {
      linkName
      referrerId
    }
    toolbar {
      ...EGDSDialogToolbarFragment
    }
  }
  trigger {
    ...LodgingCardMoreDetailsTriggerFragment
  }
}
    ${EGDSDialogToolbarFragmentFragmentDoc}
${LodgingCardMoreDetailsTriggerFragmentFragmentDoc}`;
export const LodgingCardChangeUnitSheetActionFragmentFragmentDoc = gql`
    fragment LodgingCardChangeUnitSheetActionFragment on LodgingCardChangeUnitSheetAction {
  sheet {
    closeAnalytics {
      linkName
      referrerId
    }
    closeText
  }
  trigger {
    ...LodgingCardMoreDetailsTriggerFragment
  }
}
    ${LodgingCardMoreDetailsTriggerFragmentFragmentDoc}`;
export const LodgingCardMoreDetailsLinkTriggerFragmentFragmentDoc = gql`
    fragment LodgingCardMoreDetailsLinkTriggerFragment on LodgingCardMoreDetailsTrigger {
  accessibilityLabel
  analytics {
    linkName
    referrerId
  }
  icon {
    ...LodgingEGDSIconFragment
  }
  label
}
    ${LodgingEGDSIconFragmentFragmentDoc}`;
export const LodgingCardUnitDetailsDialogActionFragmentFragmentDoc = gql`
    fragment LodgingCardUnitDetailsDialogActionFragment on LodgingCardUnitDetailsDialog {
  dialog {
    closeAnalytics {
      linkName
      referrerId
    }
    toolbar {
      ...EGDSDialogToolbarFragment
    }
  }
  trigger {
    ...LodgingCardMoreDetailsLinkTriggerFragment
  }
}
    ${EGDSDialogToolbarFragmentFragmentDoc}
${LodgingCardMoreDetailsLinkTriggerFragmentFragmentDoc}`;
export const LodgingCardPropertyDetailsDialogActionFragmentFragmentDoc = gql`
    fragment LodgingCardPropertyDetailsDialogActionFragment on LodgingCardPropertyDetailsDialog {
  dialog {
    closeAnalytics {
      linkName
      referrerId
    }
    toolbar {
      ...EGDSDialogToolbarFragment
    }
  }
  trigger {
    ...LodgingCardMoreDetailsLinkTriggerFragment
  }
}
    ${EGDSDialogToolbarFragmentFragmentDoc}
${LodgingCardMoreDetailsLinkTriggerFragmentFragmentDoc}`;
export const LodgingCardPropertyDialogActionFragmentFragmentDoc = gql`
    fragment LodgingCardPropertyDialogActionFragment on LodgingCardDetailsAction {
  __typename
  ... on LodgingCardUnitDetailsDialog {
    ...LodgingCardUnitDetailsDialogActionFragment
  }
  ... on LodgingCardPropertyDetailsDialog {
    ...LodgingCardPropertyDetailsDialogActionFragment
  }
}
    ${LodgingCardUnitDetailsDialogActionFragmentFragmentDoc}
${LodgingCardPropertyDetailsDialogActionFragmentFragmentDoc}`;
export const EGDSIconRatingFragmentFragmentDoc = gql`
    fragment EGDSIconRatingFragment on EGDSIconRating {
  __typename
  accessibility
  icon {
    token
    theme
    size
  }
  rating
}
    `;
export const EGDSBadgeFragmentFragmentDoc = gql`
    fragment EGDSBadgeFragment on EGDSBadge {
  accessibility
  text
  badgeSize: size
  badgeTheme: theme
}
    `;
export const ShoppingActionableIconFragmentFragmentDoc = gql`
    fragment ShoppingActionableIconFragment on ShoppingActionableIcon {
  __typename
  actionId
  icon {
    id
    token
    theme
    size
  }
}
    `;
export const LodgingCardBadgedRatingFragmentFragmentDoc = gql`
    fragment LodgingCardBadgedRatingFragment on LodgingCardBadgedRating {
  badge {
    ...EGDSBadgeFragment
  }
  phrases {
    phraseParts {
      ...EGDSStylizedTextFragment
      ...ShoppingActionableIconFragment
    }
  }
}
    ${EGDSBadgeFragmentFragmentDoc}
${EGDSStylizedTextFragmentFragmentDoc}
${ShoppingActionableIconFragmentFragmentDoc}`;
export const LodgingCardRatingFragmentFragmentDoc = gql`
    fragment LodgingCardRatingFragment on LodgingCardRating {
  badge {
    ...EGDSBadgeFragment
  }
  phrases {
    phraseParts {
      ...EGDSIconRatingFragment
      ...EGDSStylizedTextFragment
      ...ShoppingActionableIconFragment
    }
  }
}
    ${EGDSBadgeFragmentFragmentDoc}
${EGDSIconRatingFragmentFragmentDoc}
${EGDSStylizedTextFragmentFragmentDoc}
${ShoppingActionableIconFragmentFragmentDoc}`;
export const LodgingEGDSImageFragmentFragmentDoc = gql`
    fragment LodgingEGDSImageFragment on Image {
  description
  url
  __typename
  thumbnailClickAnalytics {
    referrerId
    linkName
    __typename
  }
}
    `;
export const MarkFragmentsFragmentDoc = gql`
    fragment MarkFragments on Mark {
  description
  id
  markSize: size
  url {
    ... on HttpURI {
      __typename
      relativePath
      value
    }
  }
}
    `;
export const IllustrationFragmentsFragmentDoc = gql`
    fragment IllustrationFragments on Illustration {
  description
  id
  link: url
}
    `;
export const UIGraphicFragmentFragmentDoc = gql`
    fragment UIGraphicFragment on UIGraphic {
  ... on Icon {
    ...LodgingEGDSIconFragment
  }
  ... on Mark {
    ...MarkFragments
  }
  ... on Illustration {
    ...IllustrationFragments
  }
}
    ${LodgingEGDSIconFragmentFragmentDoc}
${MarkFragmentsFragmentDoc}
${IllustrationFragmentsFragmentDoc}`;
export const LodgingEGDSPlainTextFragmentFragmentDoc = gql`
    fragment LodgingEGDSPlainTextFragment on EGDSPlainText {
  __typename
  text
}
    `;
export const EGDSGraphicTextFragmentFragmentDoc = gql`
    fragment EGDSGraphicTextFragment on EGDSGraphicText {
  text
  textGraphic: graphic {
    ...UIGraphicFragment
  }
  trailingGraphics {
    ...UIGraphicFragment
  }
}
    ${UIGraphicFragmentFragmentDoc}`;
export const EGDSHeadingFragmentFragmentDoc = gql`
    fragment EGDSHeadingFragment on EGDSHeading {
  text
  headingType
}
    `;
export const EGDSInlineLinkFragmentFragmentDoc = gql`
    fragment EGDSInlineLinkFragment on EGDSInlineLink {
  __typename
  action {
    accessibility
    analytics {
      linkName
      referrerId
    }
    resource {
      value
    }
    target
  }
  disabled
  linkSize: size
  text
}
    `;
export const EGDSStandardLinkFragmentFragmentDoc = gql`
    fragment EGDSStandardLinkFragment on EGDSStandardLink {
  text
  icon {
    id
    size
    title
  }
  action {
    accessibility
    analytics {
      linkName
      referrerId
    }
    resource {
      value
    }
  }
}
    `;
export const EGDSSpannableTextContentFragmentFragmentDoc = gql`
    fragment EGDSSpannableTextContentFragment on EGDSText {
  ...EGDSGraphicTextFragment
  ...EGDSHeadingFragment
  ...EGDSInlineLinkFragment
  ...LodgingEGDSPlainTextFragment
  ...EGDSStandardLinkFragment
  ...EGDSStylizedTextFragment
}
    ${EGDSGraphicTextFragmentFragmentDoc}
${EGDSHeadingFragmentFragmentDoc}
${EGDSInlineLinkFragmentFragmentDoc}
${LodgingEGDSPlainTextFragmentFragmentDoc}
${EGDSStandardLinkFragmentFragmentDoc}
${EGDSStylizedTextFragmentFragmentDoc}`;
export const EGDSSpannableTextFragmentFragmentDoc = gql`
    fragment EGDSSpannableTextFragment on EGDSSpannableText {
  text
  contents {
    ...LodgingEGDSPlainTextFragment
  }
  inlineContent {
    ...EGDSSpannableTextContentFragment
  }
}
    ${LodgingEGDSPlainTextFragmentFragmentDoc}
${EGDSSpannableTextContentFragmentFragmentDoc}`;
export const EGDSSpannableListItemFragmentFragmentDoc = gql`
    fragment EGDSSpannableListItemFragment on EGDSSpannableListItem {
  graphic {
    ...UIGraphicFragment
    __typename
  }
  subText
  text {
    ...EGDSSpannableTextFragment
    __typename
  }
  __typename
}
    ${UIGraphicFragmentFragmentDoc}
${EGDSSpannableTextFragmentFragmentDoc}`;
export const LodgingProductHighlightMessagesFragmentFragmentDoc = gql`
    fragment LodgingProductHighlightMessagesFragment on EGDSSpannableList {
  items {
    ...EGDSSpannableListItemFragment
    __typename
  }
  __typename
}
    ${EGDSSpannableListItemFragmentFragmentDoc}`;
export const LodgingProductFooterMessagesFragmentFragmentDoc = gql`
    fragment LodgingProductFooterMessagesFragment on EGDSTextWithMarkListItem {
  __typename
  mark {
    description
    id
    token
    url {
      value
    }
  }
  style
  text
}
    `;
export const LodgingProductSpannableFooterMessagesFragmentFragmentDoc = gql`
    fragment LodgingProductSpannableFooterMessagesFragment on EarnMessageContainerListItem {
  __typename
  graphic {
    ... on Mark {
      __typename
      description
      id
      token
      url {
        value
        relativePath
      }
    }
    ... on Icon {
      __typename
      description
      id
      size
      theme
      title
    }
    ... on Illustration {
      __typename
      id
      description
    }
  }
  style
  text
  stylizedText {
    ... on EGDSSpannableText {
      __typename
      text
      contents {
        ... on EGDSPlainText {
          __typename
          text
        }
        ... on EGDSStylizedText {
          __typename
          text
          theme
          weight
          size
          decorative
        }
      }
      inlineContent {
        ... on EGDSPlainText {
          __typename
          text
        }
        ... on EGDSStylizedText {
          __typename
          text
          theme
          weight
          size
          decorative
        }
      }
    }
  }
}
    `;
export const LodgingCardProductSummarySectionFragmentFragmentDoc = gql`
    fragment LodgingCardProductSummarySectionFragment on LodgingCardProductSummarySection {
  heading {
    ...LodgingEGDSHeadingFragment
  }
  amenities {
    ...LodgingProductAmenityFragment
  }
  messages {
    ...LodgingEGDSTextFragment
  }
  descriptionSection {
    ...LodgingCardPropertyDescriptionSectionFragment
  }
  changeActionDialog {
    ...LodgingCardChangeUnitDialogActionFragment
  }
  changeActionSheet {
    ...LodgingCardChangeUnitSheetActionFragment
  }
  detailsAction {
    ...LodgingCardPropertyDialogActionFragment
  }
  guestRating {
    accessibilityLabel
    parts {
      ...LodgingEGDSTextFragment
    }
    phraseParts {
      ...EGDSIconRatingFragment
    }
  }
  guestRatingSection {
    __typename
    ... on LodgingCardPhrase {
      accessibilityLabel
      parts {
        ...LodgingEGDSTextFragment
      }
      phraseParts {
        ...EGDSStylizedTextFragment
        ...EGDSIconRatingFragment
      }
    }
    ... on LodgingCardBadgedRating {
      ...LodgingCardBadgedRatingFragment
    }
  }
  guestRatingSectionV2 {
    __typename
    ... on LodgingCardPhrase {
      accessibilityLabel
      parts {
        ...LodgingEGDSTextFragment
      }
      phraseParts {
        ...EGDSStylizedTextFragment
        ...EGDSIconRatingFragment
      }
    }
    ... on LodgingCardRating {
      ...LodgingCardRatingFragment
    }
  }
  vendorLogo {
    ... on Image {
      ...LodgingEGDSImageFragment
    }
  }
  highlightMessages {
    ...LodgingProductHighlightMessagesFragment
  }
  footerMessages {
    listItems {
      ...LodgingProductFooterMessagesFragment
      ...LodgingProductSpannableFooterMessagesFragment
    }
  }
}
    ${LodgingEGDSHeadingFragmentFragmentDoc}
${LodgingProductAmenityFragmentFragmentDoc}
${LodgingEGDSTextFragmentFragmentDoc}
${LodgingCardPropertyDescriptionSectionFragmentFragmentDoc}
${LodgingCardChangeUnitDialogActionFragmentFragmentDoc}
${LodgingCardChangeUnitSheetActionFragmentFragmentDoc}
${LodgingCardPropertyDialogActionFragmentFragmentDoc}
${EGDSIconRatingFragmentFragmentDoc}
${EGDSStylizedTextFragmentFragmentDoc}
${LodgingCardBadgedRatingFragmentFragmentDoc}
${LodgingCardRatingFragmentFragmentDoc}
${LodgingEGDSImageFragmentFragmentDoc}
${LodgingProductHighlightMessagesFragmentFragmentDoc}
${LodgingProductFooterMessagesFragmentFragmentDoc}
${LodgingProductSpannableFooterMessagesFragmentFragmentDoc}`;
export const LodgingEGDSRatingFragmentFragmentDoc = gql`
    fragment LodgingEGDSRatingFragment on EGDSRating {
  accessibility
  rating
  ... on EGDSIconRating {
    icon {
      id
    }
  }
}
    `;
export const LodgingCardFeaturedMessageFragmentFragmentDoc = gql`
    fragment LodgingCardFeaturedMessageFragment on LodgingCardFeaturedMessage {
  icon {
    id
    description
    size
  }
  text
}
    `;
export const LodgingEGDSLoyaltyBadgeFragmentFragmentDoc = gql`
    fragment LodgingEGDSLoyaltyBadgeFragment on EGDSLoyaltyBadge {
  accessibility
  text
  theme
  impressionAnalytics {
    event
    referrerId
  }
}
    `;
export const LodgingBadgeFragmentFragmentDoc = gql`
    fragment LodgingBadgeFragment on EGDSStandardBadge {
  text
  theme
  graphic {
    __typename
    ... on Icon {
      description
      id
    }
    ... on Mark {
      description
      id
    }
  }
  size
}
    `;
export const LodgingCardHeadingSectionFragmentFragmentDoc = gql`
    fragment LodgingCardHeadingSectionFragment on LodgingCardHeadingSection {
  heading
  productRating {
    ...LodgingEGDSRatingFragment
  }
  featuredMessages {
    ...LodgingCardFeaturedMessageFragment
  }
  messages {
    ...LodgingEGDSTextFragment
  }
  amenities {
    ...LodgingProductAmenityFragment
  }
  perkBadges {
    ...LodgingEGDSLoyaltyBadgeFragment
  }
  missingFilters {
    ...LodgingEGDSTextFragment
  }
  standardBadges {
    ...LodgingBadgeFragment
  }
  locationInfo {
    primaryText {
      text
      graphic {
        ...LodgingEGDSIconFragment
      }
    }
    subText
  }
}
    ${LodgingEGDSRatingFragmentFragmentDoc}
${LodgingCardFeaturedMessageFragmentFragmentDoc}
${LodgingEGDSTextFragmentFragmentDoc}
${LodgingProductAmenityFragmentFragmentDoc}
${LodgingEGDSLoyaltyBadgeFragmentFragmentDoc}
${LodgingBadgeFragmentFragmentDoc}
${LodgingEGDSIconFragmentFragmentDoc}`;
export const LodgingCardLinkActionFragmentFragmentDoc = gql`
    fragment LodgingCardLinkActionFragment on LodgingCardLinkAction {
  __typename
  link {
    text
    action {
      target
      resource {
        __typename
        ... on HttpURI {
          value
          relativePath
        }
      }
      useRelativePath
      analytics {
        referrerId
        linkName
      }
    }
    egdsElementId
    icon {
      id
    }
  }
}
    `;
export const ShoppingLinkFragmentFragmentDoc = gql`
    fragment ShoppingLinkFragment on ShoppingLink {
  __typename
  actionId
  text
  trailingIcon {
    id
  }
}
    `;
export const ProductCardFooterLineItemFragmentFragmentDoc = gql`
    fragment ProductCardFooterLineItemFragment on ShoppingProductCardFooterLineItem {
  texts {
    ...EGDSStylizedTextFragment
  }
  actions {
    ...ShoppingLinkFragment
  }
}
    ${EGDSStylizedTextFragmentFragmentDoc}
${ShoppingLinkFragmentFragmentDoc}`;
export const ProductCardFooterSectionContentFragmentFragmentDoc = gql`
    fragment ProductCardFooterSectionContentFragment on ShoppingProductCardFooterSectionContent {
  items {
    ...EGDSStylizedTextFragment
    ...ShoppingLinkFragment
  }
}
    ${EGDSStylizedTextFragmentFragmentDoc}
${ShoppingLinkFragmentFragmentDoc}`;
export const LodgingMediaItemFragmentFragmentDoc = gql`
    fragment LodgingMediaItemFragment on LodgingMediaItem {
  id
  media {
    __typename
    ... on Image {
      ...LodgingEGDSImageFragment
      __typename
    }
  }
  trackingId
  __typename
}
    ${LodgingEGDSImageFragmentFragmentDoc}`;
export const LodgingAdaptExAnalyticsEventFragmentFragmentDoc = gql`
    fragment LodgingAdaptExAnalyticsEventFragment on LodgingAdaptExAnalyticsEvent {
  __typename
  campaignId
  clientSideAnalytics {
    __typename
    referrerId
    eventType
    linkName
  }
  events {
    __typename
    eventType
    eventTarget
    banditDisplayed
    payloadId
  }
}
    `;
export const LodgingGalleryCarouselFragmentFragmentDoc = gql`
    fragment LodgingGalleryCarouselFragment on LodgingGalleryCarousel {
  accessibilityHeadingText
  navClickAnalytics {
    linkName
    referrerId
  }
  intersectionAnalytics {
    linkName
    referrerId
    uisPrimeMessages {
      messageContent
      schemaName
    }
  }
  media {
    ...LodgingMediaItemFragment
    __typename
  }
  nextButtonText
  previousButtonText
  adaptExAnalyticsAttemptEvents {
    ...LodgingAdaptExAnalyticsEventFragment
  }
  adaptExAnalyticsSuccessEvents {
    ...LodgingAdaptExAnalyticsEventFragment
  }
}
    ${LodgingMediaItemFragmentFragmentDoc}
${LodgingAdaptExAnalyticsEventFragmentFragmentDoc}`;
export const LodgingEGDSStandardBadgeFragmentFragmentDoc = gql`
    fragment LodgingEGDSStandardBadgeFragment on EGDSStandardBadge {
  __typename
  graphic {
    ... on Icon {
      __typename
      description
      id
    }
    ... on Mark {
      __typename
      description
      id
    }
  }
  text
  theme
  size
}
    `;
export const AdaptexCampaignTrackingDetailFragmentFragmentDoc = gql`
    fragment AdaptexCampaignTrackingDetailFragment on AdaptexCampaignTrackingDetail {
  campaignId
  eventTarget
}
    `;
export const TripsSaveItemPropertiesFragmentFragmentDoc = gql`
    fragment TripsSaveItemPropertiesFragment on TripsSaveItemProperties {
  accessibility
  analytics {
    linkName
    referrerId
    uisPrimeMessages {
      messageContent
      schemaName
    }
  }
  adaptexSuccessCampaignIds {
    ...AdaptexCampaignTrackingDetailFragment
  }
  label
  tooltip
}
    ${AdaptexCampaignTrackingDetailFragmentFragmentDoc}`;
export const CommonDateFieldsFragmentDoc = gql`
    fragment CommonDateFields on Date {
  year
  month
  day
}
    `;
export const TripsSaveStayAttributesFragmentFragmentDoc = gql`
    fragment TripsSaveStayAttributesFragment on TripsSaveStayAttributes {
  regionId
  roomConfiguration {
    childAges
    numberOfAdults
  }
  checkInDate {
    ...CommonDateFields
  }
  checkoutDate {
    ...CommonDateFields
  }
}
    ${CommonDateFieldsFragmentDoc}`;
export const TripsSaveActivityAttributesFragmentFragmentDoc = gql`
    fragment TripsSaveActivityAttributesFragment on TripsSaveActivityAttributes {
  regionId
  dateRange {
    start {
      ...CommonDateFields
    }
    end {
      ...CommonDateFields
    }
  }
}
    ${CommonDateFieldsFragmentDoc}`;
export const AdTransparencyButtonFragmentFragmentDoc = gql`
    fragment AdTransparencyButtonFragment on AdTransparencyButton {
  primary
  icon {
    ...LodgingEGDSIconFragment
  }
}
    ${LodgingEGDSIconFragmentFragmentDoc}`;
export const TransparencyOverlayErrorFragmentFragmentDoc = gql`
    fragment TransparencyOverlayErrorFragment on TransparencyOverlayError {
  icon {
    ...LodgingEGDSIconFragment
  }
  description
  button {
    primary
  }
  allowedRetryAttempts
  exceededAttemptsMessage
}
    ${LodgingEGDSIconFragmentFragmentDoc}`;
export const TransparencyOverlaySkeletonFragmentFragmentDoc = gql`
    fragment TransparencyOverlaySkeletonFragment on TransparencyOverlaySkeleton {
  title
  error {
    ...TransparencyOverlayErrorFragment
  }
}
    ${TransparencyOverlayErrorFragmentFragmentDoc}`;
export const LodgingButtonsFragmentFragmentDoc = gql`
    fragment LodgingButtonsFragment on AdTransparencyTrigger {
  adTransparencyId
  adTransparencyButton {
    ...AdTransparencyButtonFragment
  }
  adTransparencyOverlaySkeleton {
    ...TransparencyOverlaySkeletonFragment
  }
}
    ${AdTransparencyButtonFragmentFragmentDoc}
${TransparencyOverlaySkeletonFragmentFragmentDoc}`;
export const LodgingCardMediaSectionFragmentFragmentDoc = gql`
    fragment LodgingCardMediaSectionFragment on LodgingCardMediaSection {
  gallery {
    ...LodgingGalleryCarouselFragment
  }
  badges {
    primaryBadge {
      ...LodgingEGDSStandardBadgeFragment
    }
    secondaryBadge {
      ...LodgingEGDSStandardBadgeFragment
    }
    tertiaryBadge {
      ...LodgingEGDSStandardBadgeFragment
    }
  }
  saveItem {
    analytics {
      linkName
      referrerId
    }
    initialChecked
    items {
      itemId
    }
    labels {
      removeLabel
      saveLabel
    }
    messages {
      removeMessage
      saveMessage
    }
  }
  saveTripItem {
    initialChecked
    itemId
    source
    save {
      ...TripsSaveItemPropertiesFragment
    }
    remove {
      ...TripsSaveItemPropertiesFragment
    }
    attributes {
      __typename
      ...TripsSaveStayAttributesFragment
      ...TripsSaveActivityAttributesFragment
    }
  }
  buttons {
    primaryButton {
      ...LodgingButtonsFragment
    }
    secondaryButton {
      ...LodgingButtonsFragment
    }
    tertiaryButton {
      ...LodgingButtonsFragment
    }
  }
}
    ${LodgingGalleryCarouselFragmentFragmentDoc}
${LodgingEGDSStandardBadgeFragmentFragmentDoc}
${TripsSaveItemPropertiesFragmentFragmentDoc}
${TripsSaveStayAttributesFragmentFragmentDoc}
${TripsSaveActivityAttributesFragmentFragmentDoc}
${LodgingButtonsFragmentFragmentDoc}`;
export const LodgingCardDateSectionFragmentFragmentDoc = gql`
    fragment LodgingCardDateSectionFragment on LodgingCardDateSection {
  __typename
  date {
    ...EGDSStylizedTextFragment
  }
  messages {
    ...EGDSStylizedTextFragment
  }
}
    ${EGDSStylizedTextFragmentFragmentDoc}`;
export const ClientSideAnalyticsFragmentFragmentDoc = gql`
    fragment ClientSideAnalyticsFragment on ClientSideAnalytics {
  eventType
  linkName
  referrerId
  uisPrimeMessages {
    schemaName
    messageContent
  }
}
    `;
export const LodgingCardCompareSectionFragmentFragmentDoc = gql`
    fragment LodgingCardCompareSectionFragment on LodgingCardCompareSection {
  __typename
  compareAction {
    ... on EGDSBasicCheckBox {
      __typename
      label {
        text
      }
      checkedAnalytics {
        ...ClientSideAnalyticsFragment
      }
      uncheckedAnalytics {
        ...ClientSideAnalyticsFragment
      }
    }
  }
}
    ${ClientSideAnalyticsFragmentFragmentDoc}`;
export const PriceMessageFragmentFragmentDoc = gql`
    fragment PriceMessageFragment on DisplayPrice {
  __typename
  role
  price {
    formatted
    accessibilityLabel
  }
  disclaimer {
    content
    primaryUIButton {
      accessibility
      primary
    }
  }
}
    `;
export const EnrichedMessageFragmentFragmentDoc = gql`
    fragment EnrichedMessageFragment on LodgingEnrichedMessage {
  __typename
  value
  state
}
    `;
export const PriceSummaryFragmentFragmentDoc = gql`
    fragment PriceSummaryFragment on PropertyPrice {
  displayMessages {
    lineItems {
      ...PriceMessageFragment
      ...EnrichedMessageFragment
    }
  }
  options {
    leadingCaption
    displayPrice {
      formatted
    }
    disclaimer {
      value
    }
    priceDisclaimer {
      content
      primaryButton {
        text
      }
      trigger {
        icon {
          description
        }
      }
    }
    accessibilityLabel
    strikeOut {
      formatted
    }
    loyaltyPrice {
      unit
      amount {
        formatted
      }
      totalStrikeOutPoints {
        formatted
      }
    }
    priceHeaderDialog {
      title
      content
      primaryUIButton {
        primary
        __typename
      }
      trigger {
        icon {
          id
          token
          description
          __typename
        }
        dialogTrigger {
          dialogName
          __typename
        }
        value
        __typename
      }
      __typename
    }
  }
  priceMessaging {
    value
    theme
  }
  marketingFeeDetails {
    tierMessage {
      accessibilityLabel
      value
      egdsMark {
        id
      }
    }
    marketingFeeMessageDialog {
      content
      dialog {
        footer {
          buttons {
            primary
          }
        }
      }
    }
  }
}
    ${PriceMessageFragmentFragmentDoc}
${EnrichedMessageFragmentFragmentDoc}`;
export const LodgingstandardBadgeFragmentDocFragmentDoc = gql`
    fragment LodgingstandardBadgeFragmentDoc on LodgingStandardBadge {
  __typename
  standardBadge {
    __typename
    graphic {
      ... on Icon {
        __typename
        description
        id
      }
      ... on Mark {
        __typename
        description
        id
      }
    }
    text
    theme
    size
    impressionAnalytics {
      referrerId
      linkName
      event
    }
  }
  impressionEventAnalytics {
    __typename
    referrerId
    linkName
    event
  }
}
    `;
export const ClickstreamEventFragmentFragmentDoc = gql`
    fragment ClickstreamEventFragment on EGClickstreamEvent {
  eventCategory
  eventName
  eventType
  eventVersion
}
    `;
export const ProductListFragmentFragmentDoc = gql`
    fragment ProductListFragment on LodgingProduct {
  product_id
}
    `;
export const RequestFragmentFragmentDoc = gql`
    fragment RequestFragment on LodgingSearchRequest {
  search_id
}
    `;
export const ProductEventFragmentFragmentDoc = gql`
    fragment ProductEventFragment on LodgingProductEvent {
  event {
    ...ClickstreamEventFragment
  }
  product_list {
    ...ProductListFragment
  }
  search_request {
    ...RequestFragment
  }
}
    ${ClickstreamEventFragmentFragmentDoc}
${ProductListFragmentFragmentDoc}
${RequestFragmentFragmentDoc}`;
export const ShoppingProductCardSheetFragmentFragmentDoc = gql`
    fragment ShoppingProductCardSheetFragment on EGDSSheet {
  closeAnalytics {
    linkName
    referrerId
  }
  closeText
  sheetType
}
    `;
export const ProductCardOverLayFragmentFragmentDoc = gql`
    fragment ProductCardOverLayFragment on ShoppingOverlayContainer {
  overlayId
  contentId
  overlay {
    __typename
    ...ShoppingProductCardSheetFragment
  }
}
    ${ShoppingProductCardSheetFragmentFragmentDoc}`;
export const ProductCardSheetListBodySectionFragmentFragmentDoc = gql`
    fragment ProductCardSheetListBodySectionFragment on ShoppingProductCardSheetListBodySection {
  heading {
    icon {
      description
      id
      size
    }
    text
    theme
  }
  items {
    __typename
    listItems {
      __typename
      style
      text
    }
  }
}
    `;
export const ShoppingPriceFragmentFragmentDoc = gql`
    fragment ShoppingPriceFragment on ShoppingPrice {
  __typename
  displayMessages {
    __typename
    lineItems {
      ... on ShoppingDisplayPrice {
        __typename
        clickActionId
        price {
          __typename
          accessibilityLabel
          formatted
        }
        role
      }
      ... on ShoppingEnrichedMessage {
        __typename
        state
        value
      }
    }
  }
}
    `;
export const ProductCardSheetFooterFragmentFragmentDoc = gql`
    fragment ProductCardSheetFooterFragment on ShoppingProductCardSheetPriceAndButtonFooter {
  priceSection {
    ...ShoppingPriceFragment
  }
  button {
    accessibility
    actionId
    disabled
    icon {
      id
      description
      size
    }
    primary
    buttonType
  }
}
    ${ShoppingPriceFragmentFragmentDoc}`;
export const ProductCardSheetListContentFragmentFragmentDoc = gql`
    fragment ProductCardSheetListContentFragment on ShoppingProductCardSheetListContent {
  title
  subtitle
  body {
    ...ProductCardSheetListBodySectionFragment
  }
  footer {
    ...ProductCardSheetFooterFragment
  }
}
    ${ProductCardSheetListBodySectionFragmentFragmentDoc}
${ProductCardSheetFooterFragmentFragmentDoc}`;
export const ProductCardSheetTabularContentFragmentFragmentDoc = gql`
    fragment ProductCardSheetTabularContentFragment on ShoppingProductCardSheetTabularContent {
  title
  subtitle
  tabularBody: body {
    selectedTabId
    tabs {
      accessibility
      label
      tabId
      clickAnalytics {
        linkName
        referrerId
      }
      contentId
    }
  }
  footer {
    ...ProductCardSheetFooterFragment
  }
}
    ${ProductCardSheetFooterFragmentFragmentDoc}`;
export const ProductCardSheetTabListContentFragmentFragmentDoc = gql`
    fragment ProductCardSheetTabListContentFragment on ShoppingProductCardSheetTabListContent {
  content {
    ...ProductCardSheetListBodySectionFragment
  }
}
    ${ProductCardSheetListBodySectionFragmentFragmentDoc}`;
export const ProductCardSheetSectionLineItemFragmentFragmentDoc = gql`
    fragment ProductCardSheetSectionLineItemFragment on ShoppingProductCardSheetSectionLineItem {
  icons {
    ...LodgingEGDSIconFragment
  }
  price {
    ...ShoppingPriceFragment
  }
  texts {
    ...EGDSStylizedTextFragment
  }
}
    ${LodgingEGDSIconFragmentFragmentDoc}
${ShoppingPriceFragmentFragmentDoc}
${EGDSStylizedTextFragmentFragmentDoc}`;
export const ProductCardSheetSectionFragmentFragmentDoc = gql`
    fragment ProductCardSheetSectionFragment on ShoppingProductCardSheetSection {
  lineItems {
    ...ProductCardSheetSectionLineItemFragment
  }
}
    ${ProductCardSheetSectionLineItemFragmentFragmentDoc}`;
export const ShoppingButtonFragmentFragmentDoc = gql`
    fragment ShoppingButtonFragment on ShoppingButton {
  accessibility
  actionId
  primary
  buttonType
  disabled
}
    `;
export const ProductCardSheetSectionalContentFragmentFragmentDoc = gql`
    fragment ProductCardSheetSectionalContentFragment on ShoppingProductCardSheetSectionalContent {
  __typename
  header {
    ...EGDSSpannableTextFragment
  }
  sections {
    ...ProductCardSheetSectionFragment
  }
  button {
    ...ShoppingButtonFragment
  }
}
    ${EGDSSpannableTextFragmentFragmentDoc}
${ProductCardSheetSectionFragmentFragmentDoc}
${ShoppingButtonFragmentFragmentDoc}`;
export const ProductCardContentFragmentFragmentDoc = gql`
    fragment ProductCardContentFragment on ShoppingProductCardContentContainer {
  contentId
  content {
    __typename
    ...ProductCardSheetListContentFragment
    ...ProductCardSheetTabularContentFragment
    ...ProductCardSheetTabListContentFragment
    ...ProductCardSheetSectionalContentFragment
    ... on ShoppingLoadSharedUI {
      componentName
    }
  }
}
    ${ProductCardSheetListContentFragmentFragmentDoc}
${ProductCardSheetTabularContentFragmentFragmentDoc}
${ProductCardSheetTabListContentFragmentFragmentDoc}
${ProductCardSheetSectionalContentFragmentFragmentDoc}`;
export const LodgingCardFieldsFragmentDoc = gql`
    fragment LodgingCardFields on LodgingCard {
  __typename
  id
  featuredHeader {
    header {
      text
    }
  }
  cardLink {
    accessibility
    resource {
      __typename
      ... on HttpURI {
        relativePath
        value
      }
    }
    analytics {
      linkName
      referrerId
      uisPrimeMessages {
        messageContent
        schemaName
      }
      urls
    }
    useRelativePath
    target
  }
  summarySections {
    ...LodgingCardProductSummarySectionFragment
  }
  headingSection {
    ...LodgingCardHeadingSectionFragment
  }
  footerActions {
    ...LodgingCardLinkActionFragment
  }
  footers {
    ...ProductCardFooterLineItemFragment
  }
  footer {
    contents {
      ...ProductCardFooterSectionContentFragment
    }
    actionId
  }
  mediaSection {
    ...LodgingCardMediaSectionFragment
  }
  dateSection {
    ...LodgingCardDateSectionFragment
  }
  compareSection {
    ...LodgingCardCompareSectionFragment
  }
  callOut {
    ...LodgingEGDSTextFragment
  }
  priceSection {
    priceSummary {
      ...PriceSummaryFragment
    }
    badge {
      ...LodgingEGDSStandardBadgeFragment
    }
    standardBadge {
      ...LodgingstandardBadgeFragmentDoc
    }
    button {
      accessibility
      action {
        analytics {
          linkName
          referrerId
        }
        resource {
          value
        }
      }
      analytics {
        linkName
        referrerId
      }
      disabled
      icon {
        description
        id
      }
      primary
      attemptEvents {
        campaignId
        clientSideAnalytics {
          linkName
          eventType
          referrerId
        }
        events {
          eventType
          eventTarget
          banditDisplayed
          payloadId
        }
      }
      successEvents {
        campaignId
        clientSideAnalytics {
          linkName
          eventType
          referrerId
        }
        events {
          eventType
          eventTarget
          banditDisplayed
          payloadId
        }
      }
      adaptexSuccessActionTracking {
        ...AdaptexCampaignTrackingDetailFragment
      }
    }
  }
  clickstreamEvents {
    productPresented {
      ...ProductEventFragment
    }
    productSelected {
      ...ProductEventFragment
    }
  }
  impressionAnalytics {
    url
    referrerId
    linkName
    uisPrimeMessages {
      messageContent
      schemaName
    }
  }
  renderAnalytics {
    urls
  }
  adaptexSuccessActionTracking {
    ...AdaptexCampaignTrackingDetailFragment
  }
  clickActionId
  shoppingJoinListContainer {
    actions {
      analytics {
        linkName
        referrerId
        __typename
      }
      event {
        eventCategory
        eventName
        eventType
        eventVersion
        __typename
      }
      ... on ShoppingNavigateToOverlay {
        accessibility
        actionId
        overlayId
        __typename
      }
      ... on ShoppingNavigateToURI {
        accessibility
        actionId
        resource {
          value
          ... on HttpURI {
            relativePath
          }
          __typename
        }
        target
        useRelativePath
        __typename
      }
      ... on ShoppingScrollsToView {
        targetRef
      }
      __typename
    }
    overlays {
      ...ProductCardOverLayFragment
      __typename
    }
    __typename
  }
  contents {
    ...ProductCardContentFragment
    __typename
  }
}
    ${LodgingCardProductSummarySectionFragmentFragmentDoc}
${LodgingCardHeadingSectionFragmentFragmentDoc}
${LodgingCardLinkActionFragmentFragmentDoc}
${ProductCardFooterLineItemFragmentFragmentDoc}
${ProductCardFooterSectionContentFragmentFragmentDoc}
${LodgingCardMediaSectionFragmentFragmentDoc}
${LodgingCardDateSectionFragmentFragmentDoc}
${LodgingCardCompareSectionFragmentFragmentDoc}
${LodgingEGDSTextFragmentFragmentDoc}
${PriceSummaryFragmentFragmentDoc}
${LodgingEGDSStandardBadgeFragmentFragmentDoc}
${LodgingstandardBadgeFragmentDocFragmentDoc}
${AdaptexCampaignTrackingDetailFragmentFragmentDoc}
${ProductEventFragmentFragmentDoc}
${ProductCardOverLayFragmentFragmentDoc}
${ProductCardContentFragmentFragmentDoc}`;
export const ShoppingCarouselButtonFragmentFragmentDoc = gql`
    fragment ShoppingCarouselButtonFragment on ShoppingCarouselButton {
  analytics {
    linkName
    referrerId
  }
  accessibilityLabel
}
    `;
export const ShoppingCarouselFormatFragmentFragmentDoc = gql`
    fragment ShoppingCarouselFormatFragment on ShoppingCarouselFormat {
  nextButton {
    ...ShoppingCarouselButtonFragment
  }
  previousButton {
    ...ShoppingCarouselButtonFragment
  }
  itemsVisible
}
    ${ShoppingCarouselButtonFragmentFragmentDoc}`;
export const ShoppingListingContentListAttributesFragmentFragmentDoc = gql`
    fragment ShoppingListingContentListAttributesFragment on ShoppingListingContentListAttributes {
  __typename
  ... on ProductListContentEventAttributes {
    productId
    productLine
  }
  ... on DestinationListContentEventAttributes {
    destinationGeoId
  }
}
    `;
export const ContainerClickstreamEventsFragmentFragmentDoc = gql`
    fragment ContainerClickstreamEventsFragment on ShoppingListingContainerClickstreamEvents {
  presented {
    eventCategory
    eventName
  }
  selected {
    eventCategory
    eventName
  }
  experienceTemplateId
  searchId
  eventContext {
    type
    id
  }
  additionalContext {
    componentName
    componentId
    componentPosition
    contentId
  }
  contentListAttributes {
    ...ShoppingListingContentListAttributesFragment
  }
}
    ${ShoppingListingContentListAttributesFragmentFragmentDoc}`;
export const ShoppingListingContainerFragmentFragmentDoc = gql`
    fragment ShoppingListingContainerFragment on ShoppingListingContainer {
  __typename
  containerHeading: heading {
    ...ContainerHeadingFragment
  }
  items {
    ...LodgingCardFields
  }
  format {
    __typename
    ...ShoppingCarouselFormatFragment
  }
  clickstreamAnalytics {
    ...ContainerClickstreamEventsFragment
  }
}
    ${ContainerHeadingFragmentFragmentDoc}
${LodgingCardFieldsFragmentDoc}
${ShoppingCarouselFormatFragmentFragmentDoc}
${ContainerClickstreamEventsFragmentFragmentDoc}`;
export const ShoppingSearchListingHeaderFragmentFragmentDoc = gql`
    fragment ShoppingSearchListingHeaderFragment on ShoppingSearchListingHeader {
  ...ShoppingListingContainerFragment
}
    ${ShoppingListingContainerFragmentFragmentDoc}`;
export const EGDSStandardMessagingCardFragmentFragmentDoc = gql`
    fragment EGDSStandardMessagingCardFragment on EGDSStandardMessagingCard {
  graphic {
    ...LodgingEGDSIconFragment
  }
  message
}
    ${LodgingEGDSIconFragmentFragmentDoc}`;
export const ShoppingJoinListContainerFragmentFragmentDoc = gql`
    fragment ShoppingJoinListContainerFragment on ShoppingJoinListContainer {
  overlays {
    ...ProductCardOverLayFragment
  }
  actions {
    analytics {
      linkName
      referrerId
      __typename
    }
    event {
      eventCategory
      eventName
      eventType
      eventVersion
      __typename
    }
    ... on ShoppingNavigateToOverlay {
      accessibility
      actionId
      overlayId
      __typename
    }
    ... on ShoppingInvokeFunction {
      accessibility
      actionId
      paramsId
      name
      functionType
      __typename
    }
    ... on ShoppingScrollsToView {
      targetRef
    }
    __typename
  }
}
    ${ProductCardOverLayFragmentFragmentDoc}`;
export const ProductActionParamFragmentFragmentDoc = gql`
    fragment ProductActionParamFragment on ShoppingProductCardFunctionParametersContainers {
  keyValueParams {
    key
    value
  }
  paramsId
}
    `;
export const EGDSPlainTextFragmentFragmentDoc = gql`
    fragment EGDSPlainTextFragment on EGDSPlainText {
  text
}
    `;
export const SelectedBundleProductTextFragmentFragmentDoc = gql`
    fragment SelectedBundleProductTextFragment on SelectedBundleProductText {
  ...EGDSSpannableTextFragment
  ...EGDSStylizedTextFragment
  ...EGDSPlainTextFragment
}
    ${EGDSSpannableTextFragmentFragmentDoc}
${EGDSStylizedTextFragmentFragmentDoc}
${EGDSPlainTextFragmentFragmentDoc}`;
export const SelectedBundleProductContentPrimarySectionFragmentFragmentDoc = gql`
    fragment SelectedBundleProductContentPrimarySectionFragment on SelectedBundleProductContentPrimarySection {
  badge {
    ...LodgingBadgeFragment
    __typename
  }
  primary {
    ...SelectedBundleProductTextFragment
    __typename
  }
  secondary {
    ...SelectedBundleProductTextFragment
    __typename
  }
}
    ${LodgingBadgeFragmentFragmentDoc}
${SelectedBundleProductTextFragmentFragmentDoc}`;
export const SelectedBundleProductContentSecondarySectionFragmentFragmentDoc = gql`
    fragment SelectedBundleProductContentSecondarySectionFragment on SelectedBundleProductContentSecondarySection {
  primary {
    ...EGDSStylizedTextFragment
  }
  secondary {
    ...EGDSStylizedTextFragment
  }
}
    ${EGDSStylizedTextFragmentFragmentDoc}`;
export const SelectedBundleProductContentFragmentFragmentDoc = gql`
    fragment SelectedBundleProductContentFragment on SelectedBundleProductContent {
  graphics {
    ...UIGraphicFragment
    __typename
  }
  primarySection {
    ...SelectedBundleProductContentPrimarySectionFragment
  }
  secondarySections {
    ...SelectedBundleProductContentSecondarySectionFragment
  }
}
    ${UIGraphicFragmentFragmentDoc}
${SelectedBundleProductContentPrimarySectionFragmentFragmentDoc}
${SelectedBundleProductContentSecondarySectionFragmentFragmentDoc}`;
export const SelectedBundleProductCardActionFragmentFragmentDoc = gql`
    fragment SelectedBundleProductCardActionFragment on SelectedBundleProductCardAction {
  ...ShoppingButtonFragment
  ...ShoppingLinkFragment
}
    ${ShoppingButtonFragmentFragmentDoc}
${ShoppingLinkFragmentFragmentDoc}`;
export const SelectedBundleProductCardFragmentFragmentDoc = gql`
    fragment SelectedBundleProductCardFragment on SelectedBundleProductCard {
  productContents {
    ...SelectedBundleProductContentFragment
  }
  actions {
    ...SelectedBundleProductCardActionFragment
    __typename
  }
}
    ${SelectedBundleProductContentFragmentFragmentDoc}
${SelectedBundleProductCardActionFragmentFragmentDoc}`;
export const PackageProductCardFragmentFragmentDoc = gql`
    fragment PackageProductCardFragment on SelectedBundleProductWrapper {
  header {
    ...ShoppingLinkFragment
    ...LodgingEGDSHeadingFragment
  }
  disclaimer {
    ...EGDSStandardMessagingCardFragment
  }
  shoppingJoinListContainer {
    ...ShoppingJoinListContainerFragment
  }
  params {
    ...ProductActionParamFragment
  }
  contents {
    ... on ShoppingProductCardContentContainer {
      contentId
      content {
        ... on ShoppingProductCardSheetListContent {
          title
          subtitle
          __typename
        }
        __typename
      }
    }
  }
  cards {
    ...SelectedBundleProductCardFragment
  }
}
    ${ShoppingLinkFragmentFragmentDoc}
${LodgingEGDSHeadingFragmentFragmentDoc}
${EGDSStandardMessagingCardFragmentFragmentDoc}
${ShoppingJoinListContainerFragmentFragmentDoc}
${ProductActionParamFragmentFragmentDoc}
${SelectedBundleProductCardFragmentFragmentDoc}`;
export const PriceDisplayChangeOnboardingContentFragmentFragmentDoc = gql`
    fragment PriceDisplayChangeOnboardingContentFragment on PriceDisplayChangeOnboardingContent {
  button {
    ...ShoppingButtonFragment
  }
  disclaimer
  image {
    url
    description
  }
  title
}
    ${ShoppingButtonFragmentFragmentDoc}`;
export const PriceDisplayChangeOnboardingOverlayFragmentFragmentDoc = gql`
    fragment PriceDisplayChangeOnboardingOverlayFragment on ShoppingOverlayContainer {
  contentId
  overlayId
  overlay {
    ... on EGDSSheet {
      closeText
      sheetTypes {
        sheetType
        viewSize
      }
      sheetType
      closeAnalytics {
        linkName
        referrerId
      }
    }
  }
}
    `;
export const MapToolbarFragmentFragmentDoc = gql`
    fragment MapToolbarFragment on MapToolbar {
  title
  subtitle
  __typename
}
    `;
export const LodgingEGDSBasicCheckBoxFragmentFragmentDoc = gql`
    fragment LodgingEGDSBasicCheckBoxFragment on EGDSBasicCheckBox {
  egdsElementId
  description
  enabled
  checkedAnalytics {
    linkName
    referrerId
    __typename
  }
  uncheckedAnalytics {
    linkName
    referrerId
    __typename
  }
  label {
    text
    __typename
  }
  name
  required
  state
  __typename
}
    `;
export const LodgingEGDSCheckboxFragmentFragmentDoc = gql`
    fragment LodgingEGDSCheckboxFragment on EGDSBasicCheckBox {
  ...LodgingEGDSBasicCheckBoxFragment
  __typename
}
    ${LodgingEGDSBasicCheckBoxFragmentFragmentDoc}`;
export const LodgingUILinkActionFragmentFragmentDoc = gql`
    fragment LodgingUILinkActionFragment on UILinkAction {
  resource {
    ... on HttpURI {
      relativePath
      value
    }
  }
  target
  useRelativePath
}
    `;
export const LodgingEGDSFloatingButtonFragmentFragmentDoc = gql`
    fragment LodgingEGDSFloatingButtonFragment on UIPrimaryButton {
  accessibility
  analytics {
    ...ClientSideAnalyticsFragment
    __typename
  }
  disabled
  primary
  action {
    ...LodgingUILinkActionFragment
    __typename
  }
  icon {
    ...LodgingEGDSIconFragment
    __typename
  }
  egdsElementId
  __typename
}
    ${ClientSideAnalyticsFragmentFragmentDoc}
${LodgingUILinkActionFragmentFragmentDoc}
${LodgingEGDSIconFragmentFragmentDoc}`;
export const DynamicMapFragmentFragmentDoc = gql`
    fragment DynamicMapFragment on EGDSBasicMap {
  label
  initialViewport
  center {
    latitude
    longitude
  }
  zoom
  bounds {
    northeast {
      latitude
      longitude
    }
    southwest {
      latitude
      longitude
    }
  }
  computedBoundsOptions {
    coordinates {
      latitude
      longitude
    }
    gaiaId
    lowerQuantile
    upperQuantile
    marginMultiplier
    minMargin
    minimumPins
    interpolationRatio
  }
  config {
    ... on EGDSDynamicMapConfig {
      accessToken
      egdsMapProvider
      externalConfigEndpoint {
        value
      }
      mapId
    }
  }
  markers {
    ... on EGDSMapFeature {
      id
      description
      markerPosition {
        latitude
        longitude
      }
      type
      markerStatus
      qualifiers
      text
      clientSideAnalytics {
        linkName
        referrerId
      }
      onSelectAccessibilityMessage
      onEnterAccessibilityMessage
    }
  }
}
    `;
export const MapTriggerFragmentFragmentDoc = gql`
    fragment MapTriggerFragment on LodgingMapTrigger {
  icon {
    id
    title
    token
  }
  label
  small {
    description
    url
  }
  medium {
    description
    url
  }
  large {
    description
    url
  }
  clientSideAnalytics {
    linkName
    referrerId
  }
}
    `;
export const MultiItemPropertySearchContextFieldsFragmentDoc = gql`
    fragment MultiItemPropertySearchContextFields on MultiItemSearchContext {
  properties {
    primary {
      rooms {
        adults
      }
      destination {
        regionId
        regionName
      }
      dateRange {
        checkInDate {
          ...CommonDateFields
        }
        checkOutDate {
          ...CommonDateFields
        }
      }
    }
  }
}
    ${CommonDateFieldsFragmentDoc}`;
export const MessageTitleFieldsFragmentDoc = gql`
    fragment MessageTitleFields on MessagingResultTitle {
  text
  icon {
    description
    id
    size
    spotLight
  }
  egdsMark {
    id
    description
    url {
      value
    }
  }
  illustration {
    assetUri {
      value
    }
    description
  }
}
    `;
export const MessagingActionFieldsFragmentDoc = gql`
    fragment MessagingActionFields on MessagingAction {
  actionDetails {
    action
    details
    name
    title
    accessibilityLabel
  }
  analytics {
    referrerId
    linkName
  }
  linkUrl
  referrerId
  text
}
    `;
export const MessageFieldsFragmentDoc = gql`
    fragment MessageFields on MessageResult {
  __typename
  title {
    ...MessageTitleFields
  }
  subtitle {
    ...MessageTitleFields
  }
  action {
    primary {
      ...MessagingActionFields
    }
    secondary {
      ...MessagingActionFields
    }
  }
  footerText
  type
}
    ${MessageTitleFieldsFragmentDoc}
${MessagingActionFieldsFragmentDoc}`;
export const LodgingIntentCardFieldsFragmentDoc = gql`
    fragment LodgingIntentCardFields on LodgingIntentCard {
  __typename
  headingTitle
  cardMessage: message
  gallery {
    media {
      id
      media {
        ... on Image {
          thumbnailClickAnalytics {
            linkName
            referrerId
            uisPrimeMessages {
              messageContent
              schemaName
            }
          }
          url
          description
        }
      }
    }
  }
  primaryButton {
    primary
    action {
      analytics {
        __typename
        linkName
        referrerId
      }
    }
  }
  impressionAnalyticsTracking: impressionTracking {
    __typename
    event
    referrerId
  }
}
    `;
export const OfferBadgeFragmentFragmentDoc = gql`
    fragment OfferBadgeFragment on Badge {
  text
  theme_temp
  icon_temp {
    id
    description
    title
  }
  mark {
    id
    description
  }
}
    `;
export const DisplayPriceFragmentFragmentDoc = gql`
    fragment DisplayPriceFragment on DisplayPrice {
  __typename
  role
  price {
    formatted
    accessibilityLabel
  }
  disclaimer {
    content
    primaryUIButton {
      accessibility
      primary
    }
  }
}
    `;
export const CommonPropertyCardFieldsFragmentDoc = gql`
    fragment CommonPropertyCardFields on Property {
  id
  name
  propertyImage {
    image {
      description
      url
    }
    fallbackImage {
      description
      url
    }
  }
  offerBadge {
    primary {
      ...OfferBadgeFragment
    }
    secondary {
      ...OfferBadgeFragment
    }
    tertiary {
      ...OfferBadgeFragment
    }
  }
  price {
    displayMessages {
      lineItems {
        ...DisplayPriceFragment
        ...EnrichedMessageFragment
      }
    }
    lead {
      formatted
    }
    strikeOutType
    strikeOut {
      formatted
    }
    disclaimer {
      value
    }
    roomNightMessage
    priceMessages {
      value
    }
    marketingFeeDetails {
      tierName
      tierIcon
      marketingFeeMessage {
        value
      }
    }
    options {
      leadingCaption
      displayPrice {
        formatted
      }
      strikeOut {
        formatted
      }
      loyaltyPrice {
        amount {
          formatted
        }
        totalStrikeOutPoints {
          formatted
        }
        unit
      }
      disclaimer {
        value
      }
    }
  }
  reviews {
    scoreDenominator
    score
    localizedScore
    total
    localizedTotal
    superlative
    localizedSubtitle
    localizedSubtitleA11y {
      accessibilityLabel
      value
    }
    mark {
      id
      description
    }
  }
  destinationInfo {
    distanceFromMessaging
  }
  vipMessaging
  propertyDetailsLink {
    uri {
      value
      relativePath
    }
    clientSideAnalytics {
      referrerId
      linkName
    }
  }
  sponsoredListing {
    id
    clickRedirectTrackingUrl
    clickTrackingUrl
    details
    detailsHeadline
    hotelImage
    impressionTrackingUrl
    trackingData
    brand
    clickRedirectLinkoffTrackingUrl
    logo
    lotagline
    rating
    slots
    vanityUrl
    rank
    position
  }
}
    ${OfferBadgeFragmentFragmentDoc}
${DisplayPriceFragmentFragmentDoc}
${EnrichedMessageFragmentFragmentDoc}`;
export const CommonDateTimeFieldsFragmentDoc = gql`
    fragment CommonDateTimeFields on DateTime {
  year
  month
  day
  hour
  minute
  second
}
    `;
export const featuredMessageFragmentDoc = gql`
    fragment featuredMessage on LodgingEnrichedMessage {
  icon {
    id
    description
    size
  }
  value
}
    `;
export const EGDSIconTextFragmentFragmentDoc = gql`
    fragment EGDSIconTextFragment on EGDSIconText {
  icon {
    ...LodgingEGDSIconFragment
  }
  text
}
    ${LodgingEGDSIconFragmentFragmentDoc}`;
export const LodgingListingDividerFieldsFragmentDoc = gql`
    fragment LodgingListingDividerFields on LodgingListingDivider {
  __typename
  title {
    titleText: text
    headingType
  }
  subtitle {
    subtitleText: text
  }
  graphic {
    ... on Icon {
      __typename
      description
      id
      iconSize: size
      theme
      title
      withBackground
    }
    ... on Illustration {
      __typename
      description
      url
      id
    }
    ... on Mark {
      __typename
      description
      markUrl: url {
        relativePath
        value
      }
      size
      id
    }
  }
  impressionAnalytics {
    event
    referrerId
  }
  backgroundTheme
}
    `;
export const PropertyListingStandardMessagingCardFragmentFragmentDoc = gql`
    fragment PropertyListingStandardMessagingCardFragment on EGDSStandardMessagingCard {
  __typename
  graphic {
    __typename
    ... on Illustration {
      description
      link: url
    }
    ... on Icon {
      description
      id
      iconSize: size
      theme
      title
      withBackground
      spotLight
    }
  }
  dismiss {
    accessibility
    analytics {
      ...ClientSideAnalyticsFragment
    }
    label
  }
  heading
  egdsElementId
  message
  themes
  links {
    action {
      accessibility
      analytics {
        linkName
        referrerId
      }
      resource {
        __typename
        ... on HttpURI {
          relativePath
        }
      }
      useRelativePath
    }
    text
  }
  impressionTracking {
    linkName
    referrerId
  }
}
    ${ClientSideAnalyticsFragmentFragmentDoc}`;
export const PropertyListingExpandoCardFragmentFragmentDoc = gql`
    fragment PropertyListingExpandoCardFragment on PropertyListingsIconTextExpando {
  __typename
  graphic {
    __typename
    ... on Illustration {
      link: url
      description
      id
    }
  }
  expandoCard {
    collapsedLabel
    expandedLabel
    expanded
    subtitle
    expandAnalytics {
      linkName
      referrerId
    }
    collapseAnalytics {
      linkName
      referrerId
    }
  }
  expandedContent {
    text
    graphic {
      __typename
      ... on Icon {
        id
        description
        token
        size
        theme
      }
    }
  }
}
    `;
export const LodgingMoreInfoTriggerFragmentFragmentDoc = gql`
    fragment LodgingMoreInfoTriggerFragment on EGDSStandardLink {
  __typename
  text
  icon {
    description
    id
    size
    title
    token
    withBackground
  }
  action {
    accessibility
    analytics {
      referrerId
      linkName
    }
    resource {
      __typename
      ... on HttpURI {
        relativePath
        value
      }
    }
    target
    useRelativePath
  }
  egdsElementId
  disabled
}
    `;
export const SponsoredContentPlacementFieldsFragmentDoc = gql`
    fragment SponsoredContentPlacementFields on SponsoredContentPlacement {
  __typename
  productType
  sponsoredContentContext {
    pageName
    variant
    sponsoredContentId
    targeting {
      adults
      kids
      origin
      destination
      locationResolver
      dateStart {
        day
        month
        year
      }
      dateEnd {
        day
        month
        year
      }
    }
  }
}
    `;
export const PropertySearchListingsSoldOutFieldsFragmentDoc = gql`
    fragment PropertySearchListingsSoldOutFields on PropertySearchListingsSoldOutCard {
  __typename
  cardContent: content {
    ... on EGDSStandardMessagingCard {
      __typename
      heading
      graphic {
        __typename
        ... on Icon {
          description
          id
          iconSize: size
          theme
          title
          withBackground
        }
      }
    }
  }
  actionButton {
    __typename
    ... on EGDSButton {
      primary
      analytics {
        __typename
        referrerId
        linkName
      }
    }
  }
  analytics {
    __typename
    event
    referrerId
    linkName
  }
}
    `;
export const PropertyListingFlexibleDateSearchButtonFragmentFragmentDoc = gql`
    fragment PropertyListingFlexibleDateSearchButtonFragment on FlexibleDateSearchButton {
  __typename
  button {
    __typename
    ... on UISecondaryButton {
      __typename
      primary
      analytics {
        __typename
        referrerId
        linkName
      }
    }
  }
  datePeriods {
    __typename
    ... on DatePeriod {
      __typename
      length
      unit
      bound
    }
  }
}
    `;
export const PropertyListingFlexibleDateSearchFieldsFragmentDoc = gql`
    fragment PropertyListingFlexibleDateSearchFields on FlexibleDateSearchCard {
  __typename
  cardContent: content {
    ... on EGDSStandardMessagingCard {
      __typename
      background
      heading
      message
      graphic {
        __typename
        ... on Icon {
          __typename
          description
          id
          iconSize: size
          theme
          title
          withBackground
        }
      }
      impressionTracking {
        __typename
        referrerId
        linkName
      }
    }
  }
  changeDatesButton {
    __typename
    ... on UISecondaryButton {
      __typename
      primary
      analytics {
        __typename
        referrerId
        linkName
      }
    }
  }
  flexibleDateSearchButtons {
    __typename
    ...PropertyListingFlexibleDateSearchButtonFragment
  }
}
    ${PropertyListingFlexibleDateSearchButtonFragmentFragmentDoc}`;
export const LodgingEGDSRemovablePillFragmentFragmentDoc = gql`
    fragment LodgingEGDSRemovablePillFragment on EGDSBasicRemovablePill {
  __typename
  primary
  accessibility
  name
  value
  remove {
    __typename
    ... on Icon {
      id
      description
      size
    }
  }
  removeAnalytics {
    __typename
    referrerId
    linkName
  }
}
    `;
export const SearchFilterPillsFragmentFragmentDoc = gql`
    fragment SearchFilterPillsFragment on PropertySearchListingsOverfilteredCard {
  __typename
  filterPills {
    __typename
    ... on BasicShoppingAppliedFilter {
      filter {
        __typename
        ...LodgingEGDSRemovablePillFragment
      }
      linkUrl {
        __typename
        ... on UILinkAction {
          resource {
            __typename
            ... on HttpURI {
              value
              relativePath
            }
          }
          target
          useRelativePath
        }
      }
    }
  }
}
    ${LodgingEGDSRemovablePillFragmentFragmentDoc}`;
export const LodgingLinkButtonFragmentFragmentDoc = gql`
    fragment LodgingLinkButtonFragment on LodgingLinkButton {
  button {
    __typename
    ... on UISecondaryButton {
      accessibility
      disabled
      primary
      analytics {
        __typename
        ... on ClientSideAnalytics {
          referrerId
          linkName
        }
      }
    }
  }
  action {
    __typename
    ... on UILinkAction {
      resource {
        __typename
        ... on HttpURI {
          value
          relativePath
        }
      }
      target
      useRelativePath
    }
  }
}
    `;
export const PropertyListingOverfilteredFragmentFragmentDoc = gql`
    fragment PropertyListingOverfilteredFragment on PropertySearchListingsOverfilteredCard {
  __typename
  cardContent: content {
    __typename
    ... on EGDSStandardMessagingCard {
      heading
      message
      dismiss {
        label
        analytics {
          linkName
          referrerId
        }
      }
      impressionTracking {
        linkName
        referrerId
      }
      graphic {
        __typename
        ... on Icon {
          description
          id
          iconSize: size
          withBackground
        }
      }
    }
  }
  ...SearchFilterPillsFragment
  action {
    ...LodgingLinkButtonFragment
  }
  impressionAnalytics {
    __typename
    ... on ClientSideImpressionEventAnalytics {
      event
      referrerId
      linkName
    }
  }
}
    ${SearchFilterPillsFragmentFragmentDoc}
${LodgingLinkButtonFragmentFragmentDoc}`;
export const NestedListingContainerFragmentFragmentDoc = gql`
    fragment NestedListingContainerFragment on NestedListingContainer {
  __typename
  nestedContainerHeading: heading {
    ...ContainerHeadingFragment
  }
  items {
    ...ShoppingListingContainerFragment
  }
  layout {
    ... on EGDSBasicTabs {
      __typename
      tabs {
        ... on EGDSTab {
          tabId
          label
        }
      }
    }
  }
  impressionAnalytics {
    linkName
    referrerId
  }
}
    ${ContainerHeadingFragmentFragmentDoc}
${ShoppingListingContainerFragmentFragmentDoc}`;
export const LoadingA11yLabelFragmentDoc = gql`
    fragment LoadingA11yLabel on OfferDetails {
  loading {
    accessibilityLabel
  }
}
    `;
export const SummaryHeaderFragmentDoc = gql`
    fragment SummaryHeader on PropertyInfo {
  summary {
    name
  }
}
    `;
export const ShoppingContextFragmentDoc = gql`
    fragment ShoppingContext on OfferDetails {
  shoppingContext {
    multiItem {
      id
    }
  }
}
    `;
export const AddPropertyToCartDocument = gql`
    mutation AddPropertyToCart($id: UUID!, $context: ContextInput!, $propertyOffer: PropertyOfferInput!, $cartPropertySearchContextInput: CartPropertySearchContextInput!) {
  cartItems(id: $id, context: $context) {
    addProperty(key: $propertyOffer, searchContext: $cartPropertySearchContextInput) {
      id
      itemCount
    }
  }
}
    `;
export const CreateMultiItemSessionDocument = gql`
    mutation CreateMultiItemSession($context: ContextInput!, $multiItemSearchContext: MultiItemSearchContextInput!) {
  createMultiItemSession(
    context: $context
    searchContext: $multiItemSearchContext
  ) {
    __typename
    ... on MultiItemSearchContextCreatedResponse {
      shoppingContext {
        multiItem {
          id
          packageType
        }
      }
    }
  }
}
    `;
export const CreateSessionCartDocument = gql`
    mutation CreateSessionCart($context: ContextInput!, $cartOptions: CartOptionsInput!) {
  createSessionCart(context: $context, cartOptions: $cartOptions) {
    id
  }
}
    `;
export const EmptyCartDocument = gql`
    mutation EmptyCart($context: ContextInput!, $id: UUID!) {
  cartItems(id: $id, context: $context) {
    emptyCart {
      id
      itemCount
    }
  }
}
    `;
export const FederatedCartDocument = gql`
    query FederatedCart($context: ContextInput!, $id: UUID) {
  cart(id: $id, context: $context) {
    itemCount
    items {
      id
      ... on PropertyCartItem {
        offers {
          offerIndex
          naturalKeys {
            id
            roomTypeId
            ratePlanId
            checkIn {
              isoFormat
            }
            checkOut {
              isoFormat
            }
            inventoryType
            rooms {
              numberOfAdults
              childAges
            }
          }
        }
      }
    }
  }
}
    `;
export const CartDocument = gql`
    query Cart($context: ContextInput!, $id: UUID) {
  cart(id: $id, context: $context) {
    itemCount
    items {
      id
      ... on PropertyCartItem {
        offers {
          offerIndex
          naturalKeys {
            id
            roomTypeId
            ratePlanId
            ratePlanType
            checkIn {
              isoFormat
            }
            checkOut {
              isoFormat
            }
            inventoryType
            rooms {
              numberOfAdults
              childAges
            }
          }
        }
        summary {
          name
          location {
            address {
              city
            }
          }
        }
        gallery {
          featured {
            url
          }
        }
      }
    }
  }
}
    `;
export const InitiateMultiItemSessionDocument = gql`
    mutation InitiateMultiItemSession($context: ContextInput!, $searchContext: MultiItemSearchContextInput!) {
  multiItemShopping(context: $context) {
    initiate(searchContext: $searchContext) {
      __typename
      ... on MultiItemSearchContextCreatedResponse {
        shoppingContext {
          multiItem {
            id
            packageType
          }
        }
        redirectUrl
      }
      ... on MultiItemSearchContextErrorResponse {
        message
      }
    }
  }
}
    `;
export const LodgingPwaPackagesSearchDocument = gql`
    query LodgingPwaPackagesSearch($context: ContextInput!, $destination: DestinationInput!, $rooms: [RoomInput!], $dateRange: PropertyDateRangeInput, $sort: PropertySort, $filters: PropertySearchFiltersInput, $marketing: PropertyMarketingInfoInput, $propertyShopOptions: PropertyShopOptionsInput, $searchPagination: PaginationInput, $searchIntent: SearchIntentInput, $shoppingContext: ShoppingContextInput, $criteria: PropertySearchCriteriaInput, $productShoppingCriteriaInput: ProductShoppingCriteriaInput, $returnPropertyType: Boolean = true, $includeDynamicMap: Boolean = false) {
  propertySearch(
    context: $context
    destination: $destination
    rooms: $rooms
    dateRange: $dateRange
    sort: $sort
    filters: $filters
    marketing: $marketing
    propertyShopOptions: $propertyShopOptions
    searchPagination: $searchPagination
    searchIntent: $searchIntent
    shoppingContext: $shoppingContext
    criteria: $criteria
    productShoppingCriteriaInput: $productShoppingCriteriaInput
    returnPropertyType: $returnPropertyType
  ) {
    clickstream {
      additionalEvents
      __typename
    }
    packageProductCard {
      ...PackageProductCardFragment
      __typename
    }
    dynamicMap @include(if: $includeDynamicMap) {
      mapToolbar {
        ...MapToolbarFragment
        __typename
      }
      autoSearchOnMapMove {
        ...LodgingEGDSCheckboxFragment
        __typename
      }
      action {
        ...LodgingEGDSFloatingButtonFragment
        __typename
      }
      adaptExSuccessEvents {
        ...LodgingAdaptExAnalyticsEventFragment
        __typename
      }
      adaptExAttemptEvents {
        ...LodgingAdaptExAnalyticsEventFragment
        __typename
      }
      filterPlacement
      map {
        ...DynamicMapFragment
        __typename
      }
      __typename
    }
    propertyListingAdaptexAnalyticsSuccessEvents {
      ...LodgingAdaptExAnalyticsEventFragment
    }
    propertyListingsToast {
      text
      __typename
    }
    pagination {
      paginationLabel
      trigger {
        __typename
        ... on LodgingPaginationNavigationButtons {
          previousButton {
            analytics {
              referrerId
              linkName
            }
            disabled
            primary
            accessibility
            icon {
              token
            }
          }
          nextButton {
            analytics {
              referrerId
              linkName
            }
            disabled
            primary
            accessibility
            icon {
              token
            }
          }
        }
        ... on UIPrimaryButton {
          analytics {
            referrerId
            linkName
          }
          accessibility
          disabled
          egdsElementId
          icon {
            description
            id
            title
          }
          primary
        }
        ... on LodgingScrollTrigger {
          triggerAfterPosition
          analytics {
            linkName
            referrerId
          }
        }
      }
      subSets {
        nextSubSet {
          size
          startingIndex
        }
        previousSubset {
          size
          startingIndex
        }
      }
    }
    criteria {
      primary {
        dateRange {
          checkInDate {
            ...CommonDateFields
          }
          checkOutDate {
            ...CommonDateFields
          }
        }
        destination {
          regionId
          regionName
        }
        rooms {
          adults
          children {
            age
          }
        }
        flexSearchCriteria {
          flexibility {
            bound
            unit
            length
          }
          searchRange {
            start {
              ...CommonDateFields
            }
            end {
              ...CommonDateFields
            }
          }
          flexibleSearchRequirement
        }
      }
      secondary {
        booleans {
          id
          value
        }
        counts {
          id
          value
        }
        dates {
          id
          value {
            ...CommonDateFields
          }
        }
        ranges {
          id
          max
          min
        }
        selections {
          id
          value
        }
      }
    }
    propertySearchListings {
      ... on RecentlyViewedPropertiesCarouselWrapper {
        __typename
        position
      }
      ... on LodgingCard {
        ...LodgingCardFields
      }
      ... on ShoppingListingContainer {
        ...ShoppingListingContainerFragment
      }
      ... on PropertySearchListingPlaceholder {
        __typename
        loadingComponent
      }
      ... on MessageResult {
        ...MessageFields
      }
      ... on LodgingHeading {
        __typename
        value
      }
      ... on StaticLodgingMultiItemCrossSell {
        __typename
        multiItemSearchContext {
          properties {
            primary {
              dateRange {
                checkInDate {
                  ...CommonDateFields
                }
                checkOutDate {
                  ...CommonDateFields
                }
              }
              destination {
                coordinates {
                  latitude
                  longitude
                }
                mapBounds {
                  latitude
                  longitude
                }
                pinnedPropertyId
                propertyIds
                regionId
                regionName
              }
              rooms {
                adults
                children {
                  age
                }
              }
            }
            secondary {
              booleans {
                id
                value
              }
              counts {
                id
                value
              }
              dates {
                id
                value {
                  ...CommonDateFields
                }
              }
              ranges {
                id
                max
                min
              }
              selections {
                id
                value
              }
            }
          }
        }
        crossSellProductType
        fallbackListing {
          __typename
          ... on MessageResult {
            ...MessageFields
          }
        }
      }
      ... on LodgingIntentCard {
        ...LodgingIntentCardFields
      }
      ... on LodgingListingDivider {
        ...LodgingListingDividerFields
      }
      ... on SponsoredContentPlacement {
        ...SponsoredContentPlacementFields
      }
      ... on PropertySearchListingsSoldOutCard {
        ...PropertySearchListingsSoldOutFields
      }
      ... on FlexibleDateSearchCard {
        ...PropertyListingFlexibleDateSearchFields
      }
      ... on PropertySearchListingsOverfilteredCard {
        ...PropertyListingOverfilteredFragment
      }
      ... on EGDSStandardMessagingCard {
        ...PropertyListingStandardMessagingCardFragment
      }
      ... on PropertyListingsIconTextExpando {
        ...PropertyListingExpandoCardFragment
      }
      ... on NestedListingContainer {
        ...NestedListingContainerFragment
      }
    }
    summary {
      resultMessages {
        __typename
        ... on EGDSPlainText {
          ...LodgingEGDSPlainTextFragment
        }
        ... on EGDSStandardLink {
          ...LodgingMoreInfoTriggerFragment
        }
      }
      messagingModule {
        header {
          ... on EGDSHeading {
            text
          }
        }
      }
      loyaltyInfo {
        pointsAppliedMessage
        saveWithPointsMessage
        saveWithPointsActionMessage
      }
      priceDisplayChangeOnboardingAction {
        actionId
      }
      shoppingInvokeFunctionParams {
        ... on ShoppingProductCardFunctionParametersContainers {
          keyValueParams {
            key
            value
          }
          paramsId
        }
      }
      contents {
        content {
          ... on PriceDisplayChangeOnboardingContent {
            ...PriceDisplayChangeOnboardingContentFragment
          }
        }
        contentId
      }
      shoppingJoinListContainer {
        actions {
          __typename
          accessibility
          analytics {
            linkName
            referrerId
          }
          actionId
          ... on ShoppingNavigateToOverlay {
            overlayId
          }
          ... on ShoppingNavigateToURI {
            resource {
              value
              ... on HttpURI {
                relativePath
              }
            }
            target
            useRelativePath
          }
          ... on ShoppingInvokeFunction {
            name
            paramsId
            functionType
          }
          ... on ShoppingScrollsToView {
            targetRef
          }
        }
        overlays {
          ...PriceDisplayChangeOnboardingOverlayFragment
        }
      }
      matchedPropertiesSize
      resultsHeading
      header {
        text
      }
    }
    shoppingContext {
      multiItem {
        id
        packageType
      }
    }
    multiItemSearchContext {
      ...MultiItemPropertySearchContextFields
      cars {
        primary {
          pickUpDateTime {
            ...CommonDateTimeFields
          }
          dropOffDateTime {
            ...CommonDateTimeFields
          }
          pickUpLocation {
            airportCode
          }
          dropOffLocation {
            airportCode
          }
        }
      }
      flights {
        primary {
          travelers {
            type
            age
          }
          tripType
          journeyCriterias {
            destination
            origin
            departureDate {
              day
              month
              year
            }
          }
          searchPreferences {
            airline
            advancedFilters
            cabinClass
          }
        }
      }
    }
    errorResponse {
      header
      body
      icon {
        description
        id
      }
      button {
        primary
        action {
          ... on UILinkAction {
            analytics {
              referrerId
              linkName
            }
            resource {
              ... on HttpURI {
                relativePath
                value
              }
            }
          }
        }
        disabled
      }
      errorRecoveryButtons {
        primary
        action {
          ... on UILinkAction {
            analytics {
              referrerId
              linkName
            }
            resource {
              ... on HttpURI {
                relativePath
                value
              }
            }
          }
        }
        disabled
      }
    }
    dialogErrorResponse {
      body
      button {
        primary
        action {
          ... on UILinkAction {
            analytics {
              referrerId
              linkName
            }
            resource {
              ... on HttpURI {
                relativePath
                value
              }
            }
          }
        }
        disabled
      }
    }
    mapTrigger {
      ...MapTriggerFragment
    }
    shoppingSearchListingHeader {
      ...ShoppingSearchListingHeaderFragment
    }
  }
}
    ${PackageProductCardFragmentFragmentDoc}
${MapToolbarFragmentFragmentDoc}
${LodgingEGDSCheckboxFragmentFragmentDoc}
${LodgingEGDSFloatingButtonFragmentFragmentDoc}
${LodgingAdaptExAnalyticsEventFragmentFragmentDoc}
${DynamicMapFragmentFragmentDoc}
${CommonDateFieldsFragmentDoc}
${LodgingCardFieldsFragmentDoc}
${ShoppingListingContainerFragmentFragmentDoc}
${MessageFieldsFragmentDoc}
${LodgingIntentCardFieldsFragmentDoc}
${LodgingListingDividerFieldsFragmentDoc}
${SponsoredContentPlacementFieldsFragmentDoc}
${PropertySearchListingsSoldOutFieldsFragmentDoc}
${PropertyListingFlexibleDateSearchFieldsFragmentDoc}
${PropertyListingOverfilteredFragmentFragmentDoc}
${PropertyListingStandardMessagingCardFragmentFragmentDoc}
${PropertyListingExpandoCardFragmentFragmentDoc}
${NestedListingContainerFragmentFragmentDoc}
${LodgingEGDSPlainTextFragmentFragmentDoc}
${LodgingMoreInfoTriggerFragmentFragmentDoc}
${PriceDisplayChangeOnboardingContentFragmentFragmentDoc}
${PriceDisplayChangeOnboardingOverlayFragmentFragmentDoc}
${MultiItemPropertySearchContextFieldsFragmentDoc}
${CommonDateTimeFieldsFragmentDoc}
${MapTriggerFragmentFragmentDoc}
${ShoppingSearchListingHeaderFragmentFragmentDoc}`;
export const PrepareCheckoutByCartIdDocument = gql`
    mutation PrepareCheckoutByCartId($context: ContextInput!, $id: UUID!, $totalPrice: MoneyInput!, $marketingChannelId: Int, $checkoutOptions: [CheckoutOptionInput!]) {
  prepareCheckoutByCartId(
    context: $context
    cartId: $id
    totalPrice: $totalPrice
    marketingChannelId: $marketingChannelId
    checkoutOptions: $checkoutOptions
  ) {
    tripId
    checkoutUrl
    failureReason {
      ... on MaxRoomsFailure {
        groupLinkMessage
        message
      }
    }
  }
}
    `;
export const PrepareCheckoutDocument = gql`
    mutation PrepareCheckout($context: ContextInput!, $properties: [PropertyNaturalKeyInput!], $cookies: String!) {
  prepareCheckout(context: $context, properties: $properties, cookies: $cookies) {
    tripId
    checkoutUrl
  }
}
    `;
export const PropertyInfoPageLayoutDocument = gql`
    query PropertyInfoPageLayout($context: ContextInput!, $propertyId: String!, $shoppingContext: ShoppingContextInput) {
  propertyInfo(
    context: $context
    propertyId: $propertyId
    shoppingContext: $shoppingContext
  ) {
    experimental {
      pageLayoutExperimentalFeature {
        templateName
      }
    }
  }
}
    `;
export const RemoveItemFromCartDocument = gql`
    mutation RemoveItemFromCart($cartId: UUID!, $context: ContextInput!, $itemId: UUID!) {
  cartItems(id: $cartId, context: $context) {
    remove(id: $itemId) {
      id
      itemCount
    }
  }
}
    `;
export const SelectProductsDocument = gql`
    mutation SelectProducts($context: ContextInput!, $sessionId: String!, $priceToken: String, $properties: [PropertyNaturalKeyInput!], $flights: [FlightNaturalKeyInput!], $cars: [CarNaturalKeyInput!], $dealMarker: String) {
  multiItemShopping(context: $context) {
    selectProducts(
      sessionId: $sessionId
      priceToken: $priceToken
      properties: $properties
      flights: $flights
      cars: $cars
      dealMarker: $dealMarker
    ) {
      __typename
      ... on MultiItemSearchContextCreatedResponse {
        shoppingContext {
          multiItem {
            id
            packageType
          }
        }
        redirectUrl
      }
      ... on MultiItemSearchContextErrorResponse {
        message
      }
    }
  }
}
    `;
const defaultWrapper = (action, _operationName, _operationType) => action();
const AddPropertyToCartDocumentString = print(AddPropertyToCartDocument);
const CreateMultiItemSessionDocumentString = print(CreateMultiItemSessionDocument);
const CreateSessionCartDocumentString = print(CreateSessionCartDocument);
const EmptyCartDocumentString = print(EmptyCartDocument);
const FederatedCartDocumentString = print(FederatedCartDocument);
const CartDocumentString = print(CartDocument);
const InitiateMultiItemSessionDocumentString = print(InitiateMultiItemSessionDocument);
const LodgingPwaPackagesSearchDocumentString = print(LodgingPwaPackagesSearchDocument);
const PrepareCheckoutByCartIdDocumentString = print(PrepareCheckoutByCartIdDocument);
const PrepareCheckoutDocumentString = print(PrepareCheckoutDocument);
const PropertyInfoPageLayoutDocumentString = print(PropertyInfoPageLayoutDocument);
const RemoveItemFromCartDocumentString = print(RemoveItemFromCartDocument);
const SelectProductsDocumentString = print(SelectProductsDocument);
export function getSdk(client, withWrapper = defaultWrapper) {
  return {
    AddPropertyToCart(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(AddPropertyToCartDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'AddPropertyToCart', 'mutation');
    },
    CreateMultiItemSession(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(CreateMultiItemSessionDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'CreateMultiItemSession', 'mutation');
    },
    CreateSessionCart(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(CreateSessionCartDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'CreateSessionCart', 'mutation');
    },
    EmptyCart(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(EmptyCartDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'EmptyCart', 'mutation');
    },
    FederatedCart(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(FederatedCartDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'FederatedCart', 'query');
    },
    Cart(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(CartDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'Cart', 'query');
    },
    InitiateMultiItemSession(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(InitiateMultiItemSessionDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'InitiateMultiItemSession', 'mutation');
    },
    LodgingPwaPackagesSearch(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(LodgingPwaPackagesSearchDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'LodgingPwaPackagesSearch', 'query');
    },
    PrepareCheckoutByCartId(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(PrepareCheckoutByCartIdDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'PrepareCheckoutByCartId', 'mutation');
    },
    PrepareCheckout(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(PrepareCheckoutDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'PrepareCheckout', 'mutation');
    },
    PropertyInfoPageLayout(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(PropertyInfoPageLayoutDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'PropertyInfoPageLayout', 'query');
    },
    RemoveItemFromCart(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(RemoveItemFromCartDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'RemoveItemFromCart', 'mutation');
    },
    SelectProducts(variables, requestHeaders) {
      return withWrapper(wrappedRequestHeaders => client.rawRequest(SelectProductsDocumentString, variables, Object.assign(Object.assign({}, requestHeaders), wrappedRequestHeaders)), 'SelectProducts', 'mutation');
    }
  };
}